import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../shared/services/config';
import { BlockageFlag, BPReportData, HrLocationData, HumanReviewOverview, OverviewArgs } from 'app/shared/models/blockage-prediction';

@Injectable({
    providedIn: 'root'
})

export class BlockagePredictService {
    constructor(private http: HttpClient) {}

    getBPdailyData(date: string) {
        let url = Config.urls.blockagePredictionDaily;
        url += `?date=${date}`

        return this.http.get<BPReportData>(url);
    }

    getHumanReview(args: OverviewArgs) {
        return this.http.post<HumanReviewOverview>(Config.urls.blockagePredictionReview, args)
    }

    getHumanReviewByLocation(customerId: number, locationId: number, daysToPlot: number, date: string) {
        const url = `${Config.urls.blockagePredictionReviewLocation}?cid=${customerId}&lid=${locationId}&date=${date}&daysToPlot=${daysToPlot}`;

        return this.http.get<HrLocationData>(url);
    }

    overrideDayFlags(customerId: number, locationId: number, date: string, flag: BlockageFlag) {
        const url = `${Config.urls.blockagePredictionOverride}${customerId}/${locationId}/?date=${date}&blockageFlag=${flag}`;

        return this.http.post(url, {});
    }
}

