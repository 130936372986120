import { DataEditType } from '../constant';
import { EntitySelectorEntity } from './entities';
import { HGGraphData } from './hydrographNEW';
import { EntityData } from './scatter-data';
import { Selectable } from './selectable';
import { compactData } from './view-data';

export interface DataEditPreviewParams {
    sessionGuid?: string;
    dataEditType: DataEditType;
    start?: string;
    end?: string;
    snapPoints: Array<SnapPoints>;
    updatePoints: Array<UpdatePoints>;
    curve?: any;
    depthOnY?: boolean;
    bestFit: Array<BestFit>;
    temporaryFlags: Array<string>;
    editingCurve?: DataEditingCurveTypes;
    blockEntity: BlockEntity;
    scattergraphTolerance?: number;
    isSelectedPoints?: boolean;
    isRawVelEntitySelected?: boolean;
}

export interface GetConfigTemplate<T> {
    name: string;
    id: string;
    lastEdited: string|Date;
    settings: T;
    userCreated: string;
    userLastEdited: string;
}

export interface ConfigTemplate<T> {
    configName?: string;
    id?: string;
    configType?: 'be' | 'fmr';
    partitionSuffixFilter?: string;
    existingConfigId?: string;
    settings: T;
}

export interface BEconfigCondition {
    entities: EntitySelectorEntity[];
    locationId: number;
    operator: string;
    value: number;
}

export interface BEconfig {
    start: Date|string;
    end: Date|string;
    action: string;
    entityToEdit: EntitySelectorEntity[];
    entityToBaseOf: EntitySelectorEntity[];
    conditions: BEconfigCondition[];
    typeToBaseOf: string;
    valueToCopy: number;
}

export interface SaveConfigData {
    cid?: number;
    lid?: number,
    type?: 'be' | 'fmr';
    settings: BEconfig;
    partitionSuffixFilter: string;
}

export interface ConfigTableData {
    cid?: number;
    lid?: number;
    configType: 'be' | 'fmr';
    partitionSuffixFilter: string;
}

export interface EntityInfoParams {
    cid?: number;
    lid?: number;
    eids: number[]
}

export enum DataEditingCurveTypes {
    BestFit,
    StevensSchutzbach,
    ManualLinear,
    ManualSmooth,
    Colebrook,
    ManningDesign,
    LanfearColl,
    SavedCurve
}

export enum DataEditingCurveTypesShortcuts {
    ManningDesign = 'MD',
    LanfearColl = 'LC'
}

export enum DataEditingCurveAPINames {
    LanfearColl = 'Manning: Lanfear-Coll',
    ManningDesign = 'Manning Design'
}
/** Index of curves in UI */
export enum DataEditingCurveTypesUIIndexes {
    None = 0,
    BestFit = 1,
    Colebrook = 2,
    LanfearColl = 3,
    ManningDesign = 4,
    StevensSchutzbach = 5,
    ManualLinear = 6,
    ManualSmooth = 7,
    SavedCurve = 8
}

export interface BlockEntity {
    startDate: string;
    endDate: string;
    formulaEntities: FormulaEntity[];
    equation: string;
    unitId: number;
    name: string;
    description: string;
    displayGroupId: number;
    formulaType: number;
    calculatedEntityId: number; // <--- set this to entity we're editing
}

export interface FormulaEntity {
    locationId: number;
    entityId: number;
    variableName: string;
    orderId: number;
}

export interface SnapPoints {
    dateTime: string;
    x: number;
    y: number;
    ignore?: boolean;
}

export interface UpdatePoints {
    dateTime?: number;
    timeStamp?: Date | string;
    isEntityUpdate?: boolean;
    eid?: number;
    reading?: number;
    reason?: string;
    ignore?: boolean;
    originalValue?: DataEditOriginalValue;
    user?: string;
    editDate?: Date;
}

export interface DataEditOriginalValue {
    x?: number;
    y?: number;
    edited?: boolean;
    flagged?: boolean;
    eid?: number;
    dateTime?: number;
    correctedY?: number;
    editable?: boolean;
    action?: DataEditStoreActionTypes;
    index?: number;
}

export enum DataEditStoreActionTypes {
    HGedit,
    Flag,
    Unflag,
    Snap,
    Ignore,
    Unignore
}

export interface UpdatePointForSG {
    id: number;
    stamp: number;
    value: number;
    flagged?: boolean;
    edited?: boolean
}

export interface UpdatePointForSGXY {
    xId?: number;
    yId?: number;
    stamp: number;
    x?: number;
    y?: number;
    flagged?: boolean;
    edited?: boolean
}

export interface BestFit {
    x: number;
    y: number;
}

export interface DataEditReport {
    count: number;
    logs: Array<DataEditLog>;
}

export interface DataEditLog {
    user: string;
    location: string;
    entity: string;
    entityID: number;
    timestamp: string;
    timestampValue: string;
    reason: string;
    originalValue: number;
    editedValue: number;
}

export interface RevertDataEdit {
    timestamp: string;
    eid: number;
    timestampValue?: string;
}

export interface Approval {
    approvalID: string;
    customerId: number;
    locationId: number;
    approvedBy: string;
    approvedPrevious: Date;
    approvedThrough: Date;
    approvedOn: Date;
}

export interface DataEditPreview {
    c?: string[];
    d?: {
        [key: number]: string;
    }[];
    sgd?: string[];
    sessionTS?: string;
    isSelectPoints?: boolean;
}

export interface StoredEdit {
    ts: string;
    response: DataEditPreview | SnapDataResponse;
    parsedResponse?: Map<number, Map<number, DataEditOriginalValue>>;
    originalValues: Map<number, Map<number, DataEditOriginalValue>>;
    action?: DataEditStoreActionTypes;
    originalCurve?: string[];
    originalDistances?: Map<number, number>;
    snappedPoints?: EntityData[];
    previousSnappedPoints?: EntityData[];
    lastCopyTimestamps?: Map<number, HGGraphData>;
}

export interface EntitiesUpdate {
    dataType: number;
    entityId: number
}

export interface DataEditSessions {
    ts?: number;
    result?: DataEditPreview;
}

export interface SnapDataResponse {
    curve?: string[];
    sgd?: string[];
    sessionTS?: string;
    snapData?: { stamp: number, entities: { id: number; xValue: number }[] }[];
}

export interface DataRevertRequest {
    dataEditType: DataEditType;
    start: Date;
    end: Date;
    depthOnY: boolean;
    editingCurve: number;
    updatePoints: RevertDataEdit[];
    isRawVelEntitySelected: boolean;
}


export const DATA_AVERAGING_DAILY = 4;

export interface ScatterLastGainEditModel {
    results: Map<number, Map<number, DataEditOriginalValue>>;
    forceUpdateDateRanges: string[][];
}
