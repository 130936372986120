<div id="dataCollectionDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'HOME.COLLECTION_WIDGET.DATA_COLLECTION_HEADER' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <div class="full-width pad-t-15">
            <mat-form-field>
                <input
                    matInput
                    readonly
                    placeholder="{{ 'COMMON.START_DATE' | translate }}"
                    (change)="onChangeStartDateTime($event)"
                    [value]="startDate | date: customDateFormat"
                    appDatetimepickerPosition
                    [matDatetimepickerComp]="dtStartDate"
                />
                <input matInput hidden name="startDate" (dateChange)="onChangeStartDateTime($event)" [(ngModel)]="startDate" [matDatetimepicker]="dtStartDate" />
                <mat-datetimepicker-toggle matSuffix [for]="dtStartDate"></mat-datetimepicker-toggle>
                <mat-datetimepicker #dtStartDate timeInterval="1" type="datetime"></mat-datetimepicker>
            </mat-form-field>
        </div>
        <div class="full-width">
            <mat-form-field>
                <input
                    matInput
                    readonly
                    placeholder="{{ 'COMMON.END_DATE' | translate }}"
                    (change)="onChangeEndDateTime($event)"
                    [value]="endDate | date: customDateFormat"
                    appDatetimepickerPosition
                    [matDatetimepickerComp]="dtEndDate"
                />
                <input matInput hidden name="endDate" (dateChange)="onChangeEndDateTime($event)" [(ngModel)]="endDate" [matDatetimepicker]="dtEndDate" />
                <mat-datetimepicker-toggle matSuffix [for]="dtEndDate"></mat-datetimepicker-toggle>
                <mat-datetimepicker #dtEndDate timeInterval="1" type="datetime"></mat-datetimepicker>
            </mat-form-field>
        </div>
        <div class="mat-error" *ngIf="!isDaySpanValid">
            {{ dayErrorMessage }}
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button [disabled]="!isDaySpanValid" (click)="Collect()">
                {{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
