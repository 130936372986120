import { ChartXY, ColorRGBA } from "@arction/lcjs";
import { BPRAIN_ENTITY, RAIN_ENTITY } from "../constant";
import { ElementRef } from "@angular/core";

export const SCORE_ABOVE_DEFAULT = 0.5;
export const DAYS_TO_PLOT_DEFAULT = 30;
export const DAYS_IN_YEAR = 365 * 24 * 60 * 60 * 1000;
export const ADMIN_DATE_FORMAT = 'MM/dd/yyyy';
// Color constants
export const BP_REPORT_COLORS = {
    FLAG_POSITIVE: '#FF6347',  // Manual Flag
    FLAG_NEGATIVE: '#5CB85C',  // Manual UnFlag
    TYPE_ONE: '#FF6347',      // System Flag
    SCORE_LOW: '#ADD8E6',     // No Blockage
    SCORE_HIGH: '#F08000'     // Blockage
};

export const BP_GRAPH_MAX_INTERVAL_OFFSET = 1.2;
export const RAIN_ENTITY_IDS = [RAIN_ENTITY, BPRAIN_ENTITY];

export enum HrLegendShapes {
    Line = 'line',
    Square = 'square',
    Dot = 'dot'
}

export const BLOCKAGE_MARK_THRESHOLD = 50;
export const BLOCKAGE_SCORE_PRECISION = 4;
export const BAR_GRAPH_LINE_THICKNESS = 1;
export const BP_HYDROGRAPH_LINE_THICKNESS = 2;
export const BP_HYDROGRAPH_TICK_LINE_WIDTH = 1;
export const BP_HYDROGRAPH_RAIN_MIN = 0;
export const BP_GRAPH_START_INTERVAL = 0;
export const BP_PIPE_HEIGHT_PATTERN_SCALE = 4;
export const BP_GRAPH_ID = 'chart';

export const BAR_WIDTH_VS_DAY = 0.6;
export const BAR_GRAPH_MID_LINE_Z_INDEX = 1000;
export const BAR_GRAPH_LOWER_REC_START_Y = -0.5;
export const BAR_GRAPH_LOWER_REC_END_Y = 0;
export const BAR_GRAPH_BLOCKAGE_THRESHOLD = 0;
export const BAR_GRAPH_ML_SERIES_NAME = 'ML';
export const BAR_GRAPH_ZERO_LINE_NAME = 'Zero Line';
export const BAR_GRAPH_Y_AXIS_INTERVAL_MIN = -1;
export const BAR_GRAPH_Y_AXIS_INTERVAL_MAX = 1;

export const BAR_GRAPH_MENU_INITIAL_POSITION = { x: 0, y: 0 };
export const BAR_GRAPH_MENU_POSITION_X = 0.05;
export const BAR_GRAPH_MENU_POSITION_Y = 15;

export const BP_SCATTERGRAPH_LAST_DAY_COLOR = '#FFD700';
export const BP_SCATTERGRAPH_DEFAULT_COLOR = '#00376d';

export const GRAPH_WHITE_COLOR_TRANSPARENT = ColorRGBA(255, 255, 255, 0);
export const GRAPH_WHITE_COLOR_OPAQUE = ColorRGBA(255, 255, 255, 255);
export const GRAPH_BLACK_COLOR = ColorRGBA(0, 0, 0);

export const BP_SCATTER_POINT_SIZE = 5;
export const TOOLTIP_DEFAULT_PRECISION = 2;
const TOOLTIP_MIN_WIDTH = 150;

export const TOOLTIP_ELEMENT_STYLES = (isDarkTheme: boolean) => ({
    position: 'absolute',
    borderRadius: '4px',
    padding: `0px`,
    pointerEvents: 'none',
    zIndex: '1000',
    minWidth: `${TOOLTIP_MIN_WIDTH}px`,
    display: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    color: isDarkTheme ? '#ffffff' : '#333',
    backgroundColor: isDarkTheme ? '#333' : '#ffffff',
    border: '1px solid #ccc'
})

export const TOOLTIP_ARROW_STYLES = {
    position: 'absolute',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#000',
    pointerEvents: 'none',
    zIndex: '1000',
    display: 'none'
}

export interface BlockagePredictionLocationData {
    blockStatus?: number;
    locationId: number;
    locationName: string;
    bpScore: number;
    bpFlag: boolean;
    date: Date;
    depthTrend: Array<number>;
    status: string;
}

export interface BlockagePredictionResults {
    totalPredictionCount: number;
    locationBlockages: Array<BlockagePredictionLocationData>;
}

export interface BlockagePredictionSpanArgs {
    customerId: number;
    locationGroupId?: number;
    locationId?: Array<number>;
    index: number;
    pageSize: number;
    start?: string;
    end?: string;
    includeInactiveLocations?: boolean;
    blockageStatus?: Array<number>;
    isCallForTileCount?: boolean;
}


export interface BPReportData {
    totalSites: number;
    flaggedSites: number;
    flaggedText: string;
    flaggedPercentText: string;
    successfulSites: number;
    successfulText: string;
    successfulPercentText: string;
    didNotRunSites: number;
    didNotRunText: string;
    didNotRunPercentText: string;
    noDataSites: number;
    noDataText: string;
    noDataPercentText: string;
    procesingErrorSites: number;
    processingErrorText: string;
    processingErrorPercentText: string;
    reviewSites: number;
    reviewSitesText: string;
    reviewSitesPercentText: string;
    sites: BPReportCustomer[];
}

export interface BPReportCustomer {
    cid: number;
    custname: string;
    lid: number;
    lname: string;
    ldesc: string;
    ms: string;
    bpscore: number;
    bpflag: 0 | -1 | 1;
    bptype: 0 | -1 | 1;
    bpdate: string;
    lookupdesc: string;
    status: number;
}

export enum BlockageType {
    /// No blockage.
    None = 0,
    /// Blockage reported.
    Blockage = 1,
}

/// Blockage flag. Can be toggled by
/// the user for handling false positive/false negative
/// detections.
export enum BlockageFlag {
    /// Explicit no-blockage selected.
    NoBlockage = -1,
    /// Unflagged/no-blockage.
    Unflagged = 0,
    /// Explicit blockage selected.
    Blockage = 1,
}

export interface BPReviewBarData {
    date: string;
    bptype: number;
    bpflag: BlockageFlag;
    bpscore: number;
}

export interface OverviewArgs {
    cid?: number;
    lids?: number[];
    bpdate: string;
    bpscore: number;
    blockages: boolean;
    nonBlockages: boolean;
    unflagged: boolean;
    details: boolean;
}

export interface HumanReviewOverview {
    totalSites: number;
    flaggedSites: number;
    flaggedText: string;
    flaggedPercentText: string;
    successfulSites: number;
    successfulText: string;
    successfulPercentText: string;
    didNotRunSites: number;
    didNotRunText: string;
    didNotRunPercentText: string;
    noDataSites: number;
    noDataText: string;
    noDataPercentText: string;
    processingErrorSites: number;
    processingErrorText: string;
    processingErrorPercentText: string;
    reviewSites: number;
    reviewSitesText: string;
    reviewSitesPercentText: string;
    sites: HRsite[];
}

export interface HRsite {
    cid: number;
    lid: number;
}

export interface HrLocationDay {
    date: string;
    bptype: number;
    bpflag: number;
    bpscore: number;
}

export interface HrLocationData {
    cid: number;
    custname: string;
    lid: number;
    lname: string;
    ldesc: string;
    ms: string;
    days: HrLocationDay[];
}

export interface HumanReviewDialogData {
    cid: number;
    lid: number;
    date: Date;
}

export interface HRLegend {
    name: string;
    color: string;
    shape: HrLegendShapes;
}

export interface ICanUnfreeze {
    isUnfrozen: boolean;
    unfreeze(): void;
}

interface ChartComponent {
    isUnfrozen: boolean; 
    chart: ChartXY; 
    createChart(): void;
}

export function unfreezeGraph(component: ChartComponent, chartContainer: ElementRef) {
    if (component.isUnfrozen) {
        return;
    }

    component.isUnfrozen = true;
    
    chartContainer.nativeElement.style.visibility = 'hidden';
    component.createChart();
    chartContainer.nativeElement.style.visibility = 'visible';
}