import { Directive } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { matSortKey } from '../constant'

@Directive({
  selector: '[sortingCache]'
})
export class matSortDirective {

  get matSort(): MatSortData {
    return JSON.parse(sessionStorage.getItem(matSortKey)!);
  }

  set matSort(mat: MatSortData) {
    sessionStorage.setItem(matSortKey, JSON.stringify(mat));
  }

  constructor(
    private matsort: MatSort,
  ) {}

  ngOnInit(): void {
    if(this.matSort) {
      this.matsort.active = this.matSort.active;
      this.matsort.direction = this.matSort.direction;
    }
    this.matsort.sortChange.subscribe((sort: Sort) => {
      this.matSort = { 
        active: sort.active, 
        direction: sort.direction
      }
    });
  }
}

interface MatSortData {
    active: string;
    direction: SortDirection;
  }