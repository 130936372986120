import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'utcDate'
})
export class UTCDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) { }

    transform(value: string, format: string = 'medium'): string {
        if (!value) return '';
        // Append 'Z' if it's not already there
        const utcValue = value.endsWith('Z') ? value : value + 'Z';
        return this.datePipe.transform(utcValue, format) || '';
    }
}