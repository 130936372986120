<form>
    <div class="select-method">
        <mat-form-field class="select-method-list">
            <mat-select
                placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SELECT_BASIN_INFILTRATION' | translate }}"
                [ngModel]="state.method"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="onSelectMethodChange($event.value)"
                [disabled]="isStudyLocked"
            >
                <mat-option *ngFor="let option of wasteWaterMethodOptions" [value]="option">
                    {{ wasteWaterMethodNames[option] | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            *ngIf="state.method !== BaseInfiltrationMethod.StevensSchutzbach && state.method !== BaseInfiltrationMethod.Mitchell"
            class="constant"
            >
            <input
                matInput
                placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_CONSTANT' | translate }}"
                type="number"
                [ngModel]="state.constant"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onConstantChange($event)"
                [disabled]="isStudyLocked"
                #wasteWaterConstant="ngModel"
                [pattern]="isPositiveInteger"
            />
            <span matSuffix>%</span>
        </mat-form-field>
        <div class="customMessage" *ngIf="showErrorMessage && state.method!='StevensSchutzbach'">
            <span class="mat-error">{{
                'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate
            }}</span>
        </div>
    </div>
    <p class="method-desc">
        {{ wasteWaterMethodDescriptions[state.method] | translate}}
    </p>
</form>
