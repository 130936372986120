import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DesignStorm, DesignStormDisplayValues, DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { isRight } from 'fp-ts/es6/Either';
import { None, none, some } from 'fp-ts/es6/Option';
import * as Utils from './design-storms-dialog.utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-design-storms-dialog',
    templateUrl: './design-storms-dialog.component.html',
    styleUrls: ['./design-storms-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DesignStormsDialogComponent implements OnInit {
    public columns: Array<keyof DesignStormDisplayValues> = [
        'name',
        'totalEventRain',
        'totalStormRain',
        'cumulativePeakRain',
        'peakRain',
        'active',
        'actions'
    ];
    public dataSource = new MatTableDataSource<DesignStorm>();
    public state: Utils.State;
    public unitOfMeasure: string;
    public isStudyLocked: boolean;
    public required: string = '*';
    public isDirty: boolean = false;
    public decimals: '1.1-1' | '1.2-2';
    public allowedDecimals: 1 | 2;

    private isMetric: boolean;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: Utils.DesignStormsDialogData,
        private dialogRef: MatDialogRef<DesignStormsDialogComponent, DesignStorm[]>,
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private dialog: MatLegacyDialog
    ) { }

    public ngOnInit() {
        this.state = Utils.initState(Utils.tagDesignStorms(this.dialogData.storms), this.dialogData.activeStorms);
        this.isStudyLocked = this.dialogData.isStudyLocked;
        this.isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.decimals = this.isMetric ? '1.1-1' : '1.2-2';
        this.allowedDecimals = this.isMetric ? 1 : 2;
        this.unitOfMeasure = this.isMetric ? this.translate.instant('COMMON.MM') : this.translate.instant('COMMON.IN');
    }

    public delete(id: number) {
        if (this.state.view === 'edit') return;

        const title = this.translate.instant('SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DELETE_STORM_DIALOG_TITLE');
        const message = this.translate.instant('SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DELETE_STORM_MESSAGE');
        const okText = this.translate.instant('COMMON.DELETE_BUTTON');
        const cancelText = this.translate.instant('COMMON.TEST_NO');

        const confirmationData = {
            title,
            message,
            cancelText,
            okText,
        };

        this.dialog
            .open(ConfirmationDialogComponent, {
                data: confirmationData,
            }).afterClosed().subscribe((res) => {
                if (!res || res.whichButtonWasPressed !== 'ok') {
                    return;
                }

                this.isDirty = true;
                this.state = Utils.deleteSelectedStorms(this.state as Utils.ListState, id);
            });

    }

    public new() {
        this.state = Utils.initEditState(none, this.state.data.storms, this.isMetric);
    }

    public save() {
        this.state.data.storms.forEach(v => delete v.localId);  // remove localId since it was only used inside dialog
        this.dialogRef.close(this.state.data.storms);
    }

    public close() {
        if (this.isDirty) {
            const title = this.translate.instant('COMMON.CANCEL_BUTTON');
            const message = this.translate.instant('SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CANCEL_MESSAGE');
            const okText = this.translate.instant('COMMON.TEST_YES');
            const cancelText = this.translate.instant('COMMON.TEST_NO');

            const confirmationData = {
                title,
                message,
                cancelText,
                okText,
            };

            this.dialog
                .open(ConfirmationDialogComponent, {
                    data: confirmationData,
                }).afterClosed().subscribe((res) => {
                    if (!res || res.whichButtonWasPressed !== 'ok') {
                        return;
                    }

                    this.dialogRef.close();
                });
        } else {
            this.dialogRef.close();
        }
    }

    public editStorm(item: Utils.DesignStormItemD) {
        this.state = Utils.initEditState(some(item), this.state.data.storms, this.isMetric);
    }

    public cancelEditStorm() {
        if (this.state.data.storms.length !== 0) {
            this.state = Utils.initListState(this.state.data.storms, this.dialogData.activeStorms);
        } else {
            this.close();
        }
    }

    public saveStorm() {
        if (this.state.view === 'edit') {
            const result = Utils.stormEditResult(this.state);
            if (isRight(result)) {
                this.isDirty = true;
                const newActiveList = new Set(result.value.filter(v => v.isActive).map(v => v.name));
                this.dialogData.activeStorms = newActiveList;
                this.state = Utils.initListState(result.value, this.dialogData.activeStorms);
            }
        }
    }

    public activeStormChange(storm: Utils.DesignStormItemD, isChecked: boolean) {
        if (this.state.view === 'list') {
            this.isDirty = true;
            this.state = Utils.stormSelectedChanged(storm, isChecked)(this.state);

            this.dialogData.activeStorms = new Set(this.state.data.storms.filter(v => v.isActive).map(v => v.name));
        }
    }

    public nameChanged(str: string) {
        if (this.state.view === 'edit') {
            const localId = this.state.data.localId instanceof None ? null : this.state.data.localId['value'];            
            const uniqueSet = new Set(this.state.data.storms.filter(v => v.localId !== localId).map(v => v.name.toLowerCase().trim()));

            this.state = Utils.nameChanged(str, uniqueSet)(this.state);
        }
    }

    public peakRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.peakRainChanged(str, this.allowedDecimals)(this.state);
        }
    }

    public cumulativePeakRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.cumulativePeakRainChanged(str, this.allowedDecimals)(this.state);
        }
    }

    public totalStormRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.totalStormRainChanged(str, this.allowedDecimals)(this.state);
        }
    }

    public totalEventRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.totalEventRainChanged(str, this.allowedDecimals)(this.state);
        }
    }

    public isFormValid(): boolean {
        if (this.state.view === 'list') return true;

        const { name, peakRain, cumulativePeakRain, totalStormRain, totalEventRain } = this.state.data;

        return [name, peakRain, cumulativePeakRain, totalStormRain, totalEventRain].every(field => field.val._tag === 'Right');
    }
}
