<div id="welcomePageContent">
    <div class="loginContainer" [ngClass]="{ 'login-full-page-view': isResetMode }">
        <div class="login-full-page-cover"></div>
        <div class="logInSection">
            <div class="text-align-center">
                <img class="welcome-logo" src="/assets/images/new_prism_logo.png" alt="ADS PRISM" />
            </div>
            <form #normalLoginForm="ngForm">
                <mat-form-field class="full-width top">
                    <input
                        matInput
                        placeholder="User Name"
                        #userInputName="ngModel"
                        required
                        [(ngModel)]="userName"
                        name="userName"
                        minlength="4"
                        (input)="removeWarning()"
                    />
                    <mat-error class="mat-error" *ngIf="userInputName.errors?.required && userInputName.touched">
                        User Name is required
                    </mat-error>
                    <mat-error class="mat-error" *ngIf="userInputName.hasError('minlength')">
                        Username should contain 4 characters
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width pad-t-6">
                    <input
                        type="password"
                        matInput
                        placeholder="Password"
                        #passwordInput="ngModel"
                        required
                        [(ngModel)]="password"
                        name="password"
                        minlength="8"
                        (input)="removeWarning()"
                    />
                    <mat-error class="mat-error" *ngIf="passwordInput.errors?.required && passwordInput.touched">
                        Password is required
                    </mat-error>
                    <mat-error class="mat-error" *ngIf="passwordInput.hasError('minlength')">
                        Password should contain 8 characters
                    </mat-error>
                </mat-form-field>
                <div class="mat-error" *ngIf="isloginError">Invalid Login Credentials. Please try again.</div>
                <div class="mat-error" *ngIf="lockOutMessage">
                    You account has been locked. Please Contact customercare
                </div>

                <div class="remember-me">
                    <mat-checkbox [checked]="rememberMe" (change)="rememberMeChange($event)" matTooltip="{{ 'COMMON.REMEMBER_ME_TOOLTIP' | translate }}">
                        {{ 'COMMON.REMEMBER_ME' | translate }}
                    </mat-checkbox>
                </div>
                <div class="full-width password-login">
                    <button
                        class="full-width"
                        mat-raised-button
                        color="accent"
                        [disabled]="normalLoginForm.invalid"
                        (click)="normalLogin(userInputName.value, passwordInput.value)"
                    >
                        SIGN IN
                    </button>
                    <button class="forgot-password" mat-button (click)="openResetPassword(true)">
                        Forgot Password?
                    </button>
                </div>
            </form>
            <app-loader [isLoading]="isLoading"></app-loader>

            <button
                class="full-width signInWithMicrosoft margin-t-15"
                mat-raised-button
                color="primary"
                (click)="loginWithMicrosoft()"
            >
                <span class="microsoft"></span>
                SIGN IN WITH MICROSOFT
            </button>

            <!-- <div class="text-align-center NotRegd margin-t-15">Not Registered?</div>
                <a href="https://account.microsoft.com/account" target="_blank" rel="noopener">
                    <button class="full-width" mat-button>REGISTER NEW USER</button>
                </a> -->
        </div>
        <div class="topSection">
            <div class="headerText">Welcome to</div>
            <div class="bigText">PRISM</div>
            <div class="headerText">Proactive Insight for System Management</div>
            <div>
                <a href="http://www.adsenv.com" target="_blank" rel="noopener" mat-raised-button>LEARN MORE</a>
            </div>
        </div>
    </div>

    <header *ngIf="false">
        <mat-toolbar>
            <mat-toolbar-row>
                <img src="/assets/images/new_prism_logo.png" alt="ADS PRISM" class="logo" />
                <span class="app-flex-filler"></span>

                <button mat-button (click)="contactUs()">Contact Us</button>
                <button mat-raised-button color="accent" (click)="login()">Sign In</button>

                <a href="https://account.microsoft.com/account" target="_blank" rel="noopener">
                    <button mat-button>Create New Microsoft Account</button>
                </a>
            </mat-toolbar-row>
        </mat-toolbar>
    </header>

    <div class="topSection">
        <div class="headerText">Welcome to</div>
        <div class="bigText">PRISM</div>
        <div class="headerText">Proactive Insight for System Management</div>
        <div>
            <a href="http://www.adsenv.com" target="_blank" rel="noopener" mat-raised-button>LEARN MORE</a>
            <button mat-raised-button class="contactUs" (click)="contactUs()">CONTACT US</button>
        </div>
    </div>
    <div class="contentSection">

        <!-- announcement wrapper in order to make porisition relative -->
        <div class="annoncement-wrapper" *ngIf="announcementActive">
            <div class="announcement-container">
                
                
                <!-- ANNOUNCEMENT LOGO -->
                <div class="logo-container">
                    <img src="/assets/images/icons/Warning_icon.png" />
                </div>

                <!-- NOTICE -->
                <div class="notice-header">
                    <h1>NOTICE:</h1>
                </div>

                <!-- Announcement Text -->
                <ng-container *ngIf="announcementText">
                    <div class="announcement-text">
                        <p *ngFor="let text of announcementText.split('\n')">
                            {{ text }}
                        </p>
                        <!-- [innerHTML]="announcementText" -->

                    </div>
                </ng-container>   
            
            </div>
        </div>
        
        <mat-card class="middleSection boxShadowNone">
            <mat-card-content>
                <div>
                    <div class="headerTitle">About Us</div>
                    <div>
                        PRISM is a web application that puts critical data at your fingertips to support management,
                        engineering, and operational decisions within your wastewater collection system. PRISM connects
                        clients to an ADS monitoring network, delivering near real-time operational intelligence on the
                        status of the wastewater collection system. It is the fastest and easiest way to visualize the
                        condition of your collection system monitored by ADS sensing technology.
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="headerTitle">Our Products</div>
        <mat-card class="boxShadowNone">
            <div class="prismProductContainer">
                <mat-card class="eachPrismProduct">
                    <div class="productImage prod_bg_Green">
                        <img src="../../../assets/images/echo.png" />
                    </div>
                    <mat-card-content>
                        <div class="productName">ECHO</div>
                        <div class="productTitle">
                            Early Overflow Detection, Surface Installation Level Monitor, and Low Cost of Ownership
                        </div>
                        <ul>
                            <li>Notification when sewer levels exceed critical thresholds</li>
                            <li>Yields up to a 5-year battery life</li>
                            <li>Provides reliable, on-demand data accessibility</li>
                            <li>Lowest purchase price and operational cost</li>
                        </ul>
                    </mat-card-content>
                    <mat-card-actions>
                        <a href="https://www.adsenv.com/echo/" target="_blank" rel="noopener">
                            <button mat-button class="link">Learn More</button>
                        </a>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="eachPrismProduct">
                    <div class="productImage prod_bg_Orange">
                        <img src="../../../assets/images/rain_alert.png" />
                    </div>
                    <mat-card-content>
                        <div class="productName">Rain Alert III</div>
                        <div class="productTitle">
                            Cost Effective, Easy-to-use Rainfall Monitor for Precipitation Analysis
                        </div>
                        <ul>
                            <li>Logs and time-stamps rainfall totals at user-specified intervals down to one minute</li>
                            <li>Designed for long life and low maintenance</li>
                            <li>Get alerts via emails or text message</li>
                            <li>Nema Type 4X watertight enclosure; stainless steel hardware; mil-spec connectors</li>
                        </ul>
                    </mat-card-content>
                    <mat-card-actions>
                        <a href=" https://www.adsenv.com/rainalert/" target="_blank" rel="noopener">
                            <button mat-button class="link">Learn More</button>
                        </a>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="eachPrismProduct">
                    <div class="productImage prod_bg_Yellow">
                        <img src="../../../assets/images/Triton.png" />
                    </div>
                    <mat-card-content>
                        <div class="productName">TRITON+</div>
                        <div class="productTitle">Industry's Most Versatile Flow Monitoring System</div>
                        <ul>
                            <li>
                                Multiple communication, power, sensor, connectivity, monitor interface, and operating
                                mode options
                            </li>
                            <li>
                                Durability, reliability, accuracy, and safety are the focus of ADS certification efforts
                            </li>
                            <li>The lowest purchase and maintenance costs for any IS monitor in the industry</li>
                            <li>Includes four multiple-technology sensor options</li>
                        </ul>
                    </mat-card-content>
                    <mat-card-actions>
                        <a href="https://www.adsenv.com/triton/" target="_blank" rel="noopener">
                            <button mat-button class="link">Learn More</button>
                        </a>
                    </mat-card-actions>
                </mat-card>
            </div>
        </mat-card>

        <div class="adsProductContainer" *ngIf="false">
            <mat-card>
                <button
                    mat-icon-button
                    (click)="previousProduct()"
                    class="previous lightBackGround"
                    matTooltip="Previous"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div class="productContainers" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                    <div class="adsProduct">
                        <div class="productImage">
                            <img src="../../../assets/images/echo.png" />
                        </div>
                        <div>
                            <mat-card-title>ECHO</mat-card-title>
                            <mat-card-content>
                                Early Overflow Detection, Surface Installation Level Monitor, and Low Cost of Ownership
                                <ul>
                                    <li>Notification when sewer levels exceed critical thresholds</li>
                                    <li>Yields up to a 5-year battery life</li>
                                    <li>Provides reliable, on-demand data accessibility</li>
                                    <li>Lowest purchase price and operational cost</li>
                                </ul>
                            </mat-card-content>
                            <mat-card-actions>
                                <a href="https://www.adsenv.com/echo/" target="_blank" rel="noopener">
                                    <button mat-button class="link">Learn More</button>
                                </a>
                            </mat-card-actions>
                        </div>
                    </div>
                    <div class="adsProduct">
                        <div class="productImage">
                            <img src="../../../assets/images/rain_alert.png" />
                        </div>
                        <div>
                            <mat-card-title>Rain Alert III</mat-card-title>
                            <mat-card-content>
                                Cost Effective, Easy-to-use Rainfall Monitor for Precipitation Analysis
                                <ul>
                                    <li>
                                        Logs and time-stamps rainfall totals at user-specified intervals down to one
                                        minute
                                    </li>
                                    <li>Designed for long life and low maintenance</li>
                                    <li>Get alerts via emails or text message</li>
                                    <li>
                                        Nema Type 4X watertight enclosure; stainless steel hardware; mil-spec connectors
                                    </li>
                                </ul>
                            </mat-card-content>
                            <mat-card-actions>
                                <a href="https://www.adsenv.com/rainalert/" target="_blank" rel="noopener">
                                    <button mat-button class="link">Learn More</button>
                                </a>
                            </mat-card-actions>
                        </div>
                    </div>
                    <div class="adsProduct">
                        <div class="productImage">
                            <img src="../../../assets/images/Triton.png" />
                        </div>
                        <div>
                            <mat-card-title>TRITON+</mat-card-title>
                            <mat-card-content>
                                Industry's Most Versatile Flow Monitoring System
                                <ul>
                                    <li>
                                        Multiple communication, power, sensor, connectivity, monitor interface, and
                                        operating mode options
                                    </li>
                                    <li>
                                        Durability, reliability, accuracy, and safety are the focus of ADS certification
                                        efforts
                                    </li>
                                    <li>
                                        The lowest purchase and maintenance costs for any IS monitor in the industry
                                    </li>
                                    <li>Includes four multiple-technology sensor options</li>
                                </ul>
                            </mat-card-content>
                            <mat-card-actions>
                                <a href="https://www.adsenv.com/triton/" target="_blank" rel="noopener">
                                    <button mat-button class="link">Learn More</button>
                                </a>
                            </mat-card-actions>
                        </div>
                    </div>
                </div>
                <button mat-icon-button (click)="nextProduct()" class="next lightBackGround" matTooltip="Next">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </mat-card>
        </div>
    </div>
</div>
