import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HumanReviewDialogData } from 'app/shared/models/blockage-prediction';

@Component({
    selector: 'app-hr-report-dialog',
    template: '<app-human-review-report [dialogData]="data" [reportDate]="data.date" (closeDialog)="dialogRef.close()"></app-human-review-report>'
})

export class HumanReviewReportDialog implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<HumanReviewReportDialog>,
        @Inject(MAT_DIALOG_DATA) public data: HumanReviewDialogData
    ) { }

    ngOnInit(): void {}
}
