import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { FlexmonsterPivot } from 'ngx-flexmonster';
import { Toolbar } from 'flexmonster';
import { environment } from 'app/environments/environment';
import { AREA, BASIN_QVSI_MEASURES, BasinQvsiProperties, createPivotMapping, csvToJSON, EVENT_NET_II_COLUMN, getBasinQvsIPivotMeasures, getBasinQvsiPivotOptions, getBasinQvsiPivotSlice, getExistingProps, getKeyByProp, PEAK_II, PROJECTED, PROJECTED_NET_II, PROJECTED_NET_II_SPACED, R_VALUE, RAINFALL_DEPTH, SEWER_LENGTH, STORM_NET_II_COLUMN, STORM_PERIOD, TOTAL_EVENT_RDII, YVALUE_FIELD_SUFIX } from './basin-qvsi-pivot-table.constants'
import { Subscription } from 'rxjs';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DatePipe } from '@angular/common';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { TranslateService } from '@ngx-translate/core';
import { BASIN_QVI_EXPORT_NAME, EXCLUDED_CHART_ITEMS, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_HTML_ID, EXPORT_IMG_ID, EXPORT_PDF_ID, EXPORT_VAULT_ID, TOOLBAR_CHARTS_TAB_ID, TOOLBAR_EXPORT_TAB_ID, TOOLBAR_FIELDS_TAB_ID, TOOLBAR_FORMAT_TAB_ID, TOOLBAR_FULLSCREEN_TAB_ID, TOOLBAR_GRID_TAB_ID, TOOLBAR_OPTIONS_TAB_ID, VAULT_ICON } from '../flexmonster.constant';
import { CustomerService } from 'app/shared/services/customer.service';
import { UnitOfMeasureType } from 'app/shared/constant';

const blankText = '(blank)';

@Component({
	selector: 'app-basin-qvsi-pivot-table',
	templateUrl: './basin-qvsi-pivot-table.component.html',
	styleUrls: ['./basin-qvsi-pivot-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BasinQvsiPivotTableComponent implements OnInit, OnDestroy {
	public license = environment.flexmonsterLicense;
	public report: Object;
	@ViewChild('pivot') private pivotTable: FlexmonsterPivot;

	public isLoading = true;

	private caseStudyId: string;
	private customerId: number;

    public noData = false;
	private data;

	private studyDateForExportNaming: string;
	private initialExportTabs: Flexmonster.ToolbarTab[];

	private units: UnitOfMeasureType;
	private designStorms: string[];
	private subscriptions: Subscription[] = [];
	constructor(
		private sliicerService: SliicerService,
		private dateutilService: DateutilService,
		private datePipe: DatePipe,
		private snackBarNotificationService: SnackBarNotificationService,
		private translate: TranslateService,
		private customerService: CustomerService
	) { }

	ngOnInit(): void {
		this.designStorms = this.sliicerService.caseStudyDetails.getValue()?.settings?.designStorms?.map(v => v.name) || [];
		this.units = this.customerService.customer.unitsType;
		this.subscriptions.push(this.sliicerService.studyDetailsData$.subscribe((study: SliicerCaseStudy) => {
			this.caseStudyId = study.id;
			this.customerId = study.customerId;

			const format = this.dateutilService.getFormat().replace(/\//g, '');
			const start = new Date(study.config.startDate);
			const end = new Date(study.config.endDate);

			this.studyDateForExportNaming = `${this.datePipe.transform(start, format)}-${this.datePipe.transform(end, format)}`

			this.getAllQviBasinResults();
		}));
	}

	public customizeToolbar(toolbar: Toolbar) {
		toolbar.showShareReportTab = true;

		const tabs = this.getTabs(toolbar);

		toolbar.getTabs = function () {
			return tabs;
		}
	}

	private getTabs(toolbar: Toolbar, isGridView = false): Flexmonster.ToolbarTab[] {
		const tabs = toolbar.getTabs();

		const exportTab = tabs.find(v => v.id === TOOLBAR_EXPORT_TAB_ID);

		if (!this.initialExportTabs) {
			this.initialExportTabs = exportTab.menu;
		}

		const chartsTab = tabs.find(v => v.id === TOOLBAR_CHARTS_TAB_ID);
		const gridTab = tabs.find(v => v.id === TOOLBAR_GRID_TAB_ID);

		const formatTab = tabs.find(v => v.id === TOOLBAR_FORMAT_TAB_ID);
		const optionsTab = tabs.find(v => v.id === TOOLBAR_OPTIONS_TAB_ID);
		const fieldsTab = tabs.find(v => v.id === TOOLBAR_FIELDS_TAB_ID);
		const fullScreenTab = tabs.find(v => v.id === TOOLBAR_FULLSCREEN_TAB_ID);

		this.formatExportsTab(exportTab);

		gridTab.handler = this.onGridView.bind(this);

		chartsTab.menu = chartsTab.menu.filter(v => !EXCLUDED_CHART_ITEMS.includes(v.id));
		chartsTab.menu.forEach((item) => {
			item.handler = this.onChartView.bind(this);
		});

		return [
			exportTab,
			{ divider: true } as Flexmonster.ToolbarTab,
			chartsTab,
			gridTab,
			formatTab,
			optionsTab,
			fieldsTab,
			{ divider: true } as Flexmonster.ToolbarTab,
			fullScreenTab
		];
	}

	private removeBlanks() {
		this.pivotTable.flexmonster.customizeCell((cell, data) => {
			if (data.label === blankText) {
				cell.text = '-';
			}

			if (data.type === 'value' && data.measure.uniqueName.includes(YVALUE_FIELD_SUFIX) && this.data[data.rowIndex - 1]) {
				const rowData = this.data[data.rowIndex - 1];
				const decimals = this.getPrecisionByType(rowData.Type);
				cell.text = data.value.toFixed(decimals);
			}
		});

	}

	private getAllQviBasinResults() {
		this.isLoading = true;

		this.sliicerService.getAllQviBasinResults(this.customerId, this.caseStudyId).subscribe(csv => {
			if (!csv) {
				this.isLoading = false;
				return;
			}

			const json: {}[] = csvToJSON(csv);
			if (!json || !json.length) {
				this.isLoading = false;
				return;
			}

			const data = json.filter(v => Object.keys(v).length > 0).map(item => {
				return Object.keys(item).reduce((acc, k) => {
					const key = k.replace(/"/g, '').trim();
					const value = item[k];

					if (key.includes(SEWER_LENGTH) || key.includes(AREA)) {
						let formattedValue;

						if (value && value.replace) {
							formattedValue = Number(value.replace(',', ''));
						} else if (value) {
							formattedValue = value;
						} else {
							formattedValue = '-'
						}
						
						acc[key] = formattedValue;
						return acc;
					}

					if (key.includes(R_VALUE)) {
						const unitsMatch = key.match(/\((.*?)\)$/);
						const units = (unitsMatch && unitsMatch[0]) ? unitsMatch[0].replace(/"/g, '').trim() : '';
	
						const designStormMatch = key.match(/^(.*?)\s+R-Value/);
						const designStormName = (designStormMatch && designStormMatch[1]) ? designStormMatch[1].replace(/"/g, '').trim() : '';

						const rValueKey = `${designStormName} ${PROJECTED} ${EVENT_NET_II_COLUMN} ${R_VALUE} ${units}`;

						acc[rValueKey] = value || '-';

						return acc;
					}

					if (!key.includes(PROJECTED_NET_II)) {
						acc[key] = value || '-';
	
						return acc;
					} else {
						const unitsMatch = key.match(/\((.*?)\)$/);
						const units = (unitsMatch && unitsMatch[0]) ? unitsMatch[0].replace(/"/g, '').trim() : '';

						const designStormMatch = key.match(/^(.*?)\s+Projected/);
						const designStormName = (designStormMatch && designStormMatch[1]) ? designStormMatch[1].replace(/"/g, '').trim() : '';

						const projectedNetIIKey = `${designStormName} ${PROJECTED_NET_II_SPACED} ${units}`;

						acc[projectedNetIIKey] = value || '-';
	
						return acc;
					}
				}, {});
			});

			this.data = data;
            if(!data || !data.length) {

                this.noData = true;
            } else {
                this.noData = false;
			    this.generateTable(this.data);
            }

			this.isLoading = false;
		},
			() => { this.isLoading = false; }
		)
	}
	private generateTable(data) {
		const mapping = createPivotMapping(data[0]);

		this.report = {
			options: getBasinQvsiPivotOptions(),
			dataSource: { data, mapping },
			slice: getBasinQvsiPivotSlice(data[0]),
			formats: [
				{
					name: "3_decimal_format",
					decimalPlaces: 3,
					maxDecimalPlaces: 3,
					decimalSeparator: ".",
					thousandsSeparator: "",
				},
				{
					name: "2_decimal_format",
					decimalPlaces: 2,
					maxDecimalPlaces: 2,
					decimalSeparator: ".",
					thousandsSeparator: "",
				},
			],
		};
		this.isLoading = false;
		this.pivotTable.flexmonster.setReport(this.report);
		this.removeBlanks();
	}

	private formatExportsTab(tab: Flexmonster.ToolbarTab) {
		const exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID];

		tab.menu = tab.menu.filter(menuItem => {
			if (!exportTypes.includes(menuItem.id)) {
				return false;
			}

			this.setExportHandler(menuItem);

			return true;
		});

		tab.menu.unshift({
			title: 'To Vault(Raw)',
			id: EXPORT_VAULT_ID,
			icon: VAULT_ICON,
			handler: () => this.exportVaultHandler()
		});
	}

	private setExportHandler(menuItem: Flexmonster.ToolbarTab) {
		switch (menuItem.id) {
			case EXPORT_HTML_ID: {
				menuItem.handler = this.exportToHTMLhandler();
				break;
			}
			case EXPORT_EXCEL_ID: {
				menuItem.handler = this.exportToExcelHandler()
				break;
			}
			case EXPORT_CSV_ID: {
				menuItem.handler = this.exportToCsvHandler();
				break;
			}
		}
	}

	private exportToExcelHandler() {
		return () => this.pivotTable.flexmonster.exportTo('excel', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
			excelSheetName: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});

	}

	private exportToCsvHandler() {
		return () => this.pivotTable.flexmonster.exportTo('csv', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToHTMLhandler() {
		return () => this.pivotTable.flexmonster.exportTo('html', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportVaultHandler() {
		const successMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_BASIN_SAVE_MESSAGE');
		const errorMessage = this.translate.instant('VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG');
		const dissmissText = this.translate.instant('COMMON.DISMISS_TEXT');

		this.isLoading = true;
		this.sliicerService
			.vaultExportBasicQvsiStatistics(this.customerId, this.caseStudyId)
			.subscribe(
				(res) => {
					this.isLoading = false;
					this.snackBarNotificationService.raiseNotification(
						successMessage,
						dissmissText,
						{
							duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
						},
						true,
					);
				},
				(err) => {
					this.snackBarNotificationService.raiseNotification(errorMessage, dissmissText, {
						panelClass: 'custom-error-snack-bar',
					}, false);
					this.isLoading = false;
				},
			);
	}

	private onChartView(type: string) {
		this.pivotTable.flexmonster.showCharts(type, true);

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		const item = this.data[0];

		(report.slice as Flexmonster.Slice).measures = getBasinQvsIPivotMeasures(item);
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, false);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	private onGridView() {
		this.pivotTable.flexmonster.showGrid();

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		const item = this.data[0];

		(report.slice as Flexmonster.Slice).measures = getBasinQvsIPivotMeasures(item);
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, true);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	// #42240 #42203 #42239 update decimal places for Projected Y value
	// 	US Customary (MGD)
		// Peak I/I vs. Rolling Peak Rainfall Intensity - 3 decimal places
		// Peak I/I vs. Rainfall to Time of Peak I/I - 3 decimal places
		// Storm Period RDII vs. Rainfall Depth - 3 decimal places
		// Total Event RDII vs. Rainfall Depth - 3 decimal places
	// US Customary (CFS)
		// Peak I/I vs. Rolling Peak Rainfall Intensity - 3 decimal places
		// Peak I/I vs. Rainfall to Time of Peak I/I - 3 decimal places
		// Storm Period RDII vs. Rainfall Depth - 0 decimal places
		// Total Event RDII vs. Rainfall Depth - 0 decimal places
	// Metric
		// Peak I/I vs. Rolling Peak Rainfall Intensity - 1 decimal places
		// Peak I/I vs. Rainfall to Time of Peak I/I - 1 decimal places
		// Storm Period RDII vs. Rainfall Depth - 3 decimal places
		// Total Event RDII vs. Rainfall Depth - 3 decimal places
    private getPrecisionByType(type: string) {
		if (this.units === UnitOfMeasureType.METRIC) {
			return type.includes(PEAK_II) ? 1 : 3;
		}

		if (this.units === UnitOfMeasureType.MGD) {
			return 3;
		}

		if (this.units === UnitOfMeasureType.CFS) {
			return type.includes(PEAK_II) ? 3 : 0;
		}
    }

	ngOnDestroy() {
		this.subscriptions.forEach(v => v.unsubscribe());
	}
}
