<div
    id="commSummaryOverviewId"
    class="contentLayout"
    *ngIf="isReport"
>
    <mat-card class="widgetSection collectionLeaderBoardContent full-width-leaderboard">
        <div class="activeWidgeHeader">
            <span>{{ 'HOME.COLLECTION_TILE.TITLE' | translate }}</span>
            <span class="app-flex-filler"></span>
        </div>

        <mat-card-content
            id="collectionWidgetCardContentId"
            class="widgetFixPagination"
        >
            <div class="scrollOverlay"></div>
            <app-widget-filters
                sendDataToWidget)="notifyWidget($event)"
                [hidden]="!showCollectionSearch"
                [filterSettings]="reportFilterSettings"
                [customerID]="customerID"
                [locationGroupID]="locationGroupID"
                [toggleState]="toggleState"
                [includeInactiveLocations]="includeInactiveLocations"
            >
            </app-widget-filters>

            <div
                *ngIf="toggleState && showErrorMessage && (collectionWidgetData?.length === 0 || !collectionWidgetData)"
                class="notabledataFound custom-table"
            >
                <span>{{ 'COMMON.NO_TEXT' | translate }}
                    <span *ngIf="!showCollectionSearch">{{ 'HOME.COLLECTION_WIDGET.FAILED_SCHEDULE_TEXT' | translate }}
                    </span>
                    {{ 'HOME.COLLECTION_WIDGET.COLLECTS_FOUND_TEXT' | translate }}</span>
            </div>

            <!-- <app-loader [isLoading]="collectionWidgetLoadingState"></app-loader> -->
            <app-loader [isLoading]="locationsLoadingState"></app-loader>
            <div
                [hidden]="collectionWidgetData.length === 0 || !toggleState"
                id="collectionLeaderBoardTable"
            >
                <mat-table
                    #table="matSort"
                    [dataSource]="collectionWidgetDataSource"
                    matSort
                >
                    <!-- LOCATION  -->
                    <ng-container matColumnDef="locationname">
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >{{
                            'COMMON.LOCATION_COLUMN_TITLE' | translate
                            }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionHistoryItem">
                            <div
                                class="nowrap-white-space fullScreenShow"
                                [ngClass]="{
                                    customLongName: validateLocationNameLength(collectionHistoryItem.locationname, 48)
                                }"
                                [title]="
                                    validateLocationNameLength(collectionHistoryItem.locationname, 48)
                                        ? collectionHistoryItem.locationname
                                        : ''
                                "
                                [innerHTML]="collectionHistoryItem.locationname | sanitize: 'html'"
                            ></div>
                            <a
                                class="nowrap-white-space fullScreenHide"
                                [ngClass]="{
                                    customLongName: validateLocationNameLength(collectionHistoryItem.locationname, 20)
                                }"
                                [title]="
                                    validateLocationNameLength(collectionHistoryItem.locationname, 20)
                                        ? collectionHistoryItem.locationname
                                        : ''
                                "
                                (click)="getMarkerLocationDetails(collectionHistoryItem.locationid)"
                                [innerHTML]="collectionHistoryItem.locationname | sanitize: 'html'"
                            ></a>
                        </mat-cell>
                    </ng-container>

                    <!-- LAST COLLECTED -->
                    <ng-container matColumnDef="lastcollecteddate">
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            class="text-right fix-width-160"
                        >{{
                            'HOME.COLLECTION_WIDGET.LAST_COLLECT_COLUMN_TITLE' | translate
                            }}</mat-header-cell>
                        <mat-cell
                            *matCellDef="let collectionHistoryItem"
                            class="text-right"
                        >{{
                            collectionHistoryItem.lastcollecteddate ? collectionHistoryItem.lastcollecteddate : '-'
                            }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell
                            *matCellDef="let collectionHistoryItem"
                            class="text-right collection-action"
                        >
                            <button
                                mat-icon-button
                                (click)="navigateToScheduleCollectDashboard(collectionHistoryItem.locationid)"
                                [disabled]="!isScheduleCollectAllowed"
                            >
                                <mat-icon matTooltip="{{
                                        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT' | translate
                                    }}">query_builder</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                [disabled]="collectionHistoryItem.collectinprogress"
                                (click)="collectLocation(collectionHistoryItem.locationid)"
                            >
                                <mat-icon matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}">save_alt</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="collectionWidgetDisplayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: collectionWidgetDisplayedColumns"></mat-row>
                </mat-table>
            </div>
            <!-- data communication expand window screen -->
            <div
                [hidden]="toggleState"
                id="communication-expand-table"
            >
                <mat-table
                    #longtable="matSort"
                    [dataSource]="collectionDetailsDataSource"
                    matSort
                >
                    <!-- location name -->
                    <ng-container matColumnDef="locationName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LOCATION_NAME' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.locationName }}</mat-cell>
                    </ng-container>
                    <!-- monitor series -->
                    <ng-container matColumnDef="monitorSeries">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.monitorSeries }} </mat-cell>
                    </ng-container>
                    <!-- failed -->
                    <ng-container matColumnDef="failureAttempts">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.FAILED' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.failureAttempts }}
                        </mat-cell>
                    </ng-container>
                    <!-- attempted -->
                    <ng-container matColumnDef="totalAttempts">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.ATTEMPTED' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.totalAttempts }} </mat-cell>
                    </ng-container>
                    <!-- Success -->
                    <ng-container matColumnDef="successPercent">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.SUCCESS' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.successPercent }} </mat-cell>
                    </ng-container>
                    <!-- Last Collection -->
                    <ng-container matColumnDef="lastCollectedDate">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.LAST_COLLECTION' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastCollectedDate ? collectionDetails.lastcollecteddate : '-' }}
                        </mat-cell>
                    </ng-container>
                    <!-- Last Schedule  -->
                    <ng-container matColumnDef="lastScheduledCollection">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.LAST_SCHEDULED' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{
                            collectionDetails.lastScheduledCollection
                            ? collectionDetails.lastScheduledCollection
                            : '-'
                            }}
                        </mat-cell>
                    </ng-container>
                    <!-- Last Manual -->
                    <ng-container matColumnDef="lastManualCollection">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.LAST_MANUAL' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastManualCollection ? collectionDetails.lastManualCollection : '-' }}
                        </mat-cell>
                    </ng-container>
                    <!-- Last Failed  -->
                    <ng-container matColumnDef="lastFailedCollection">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.LAST_FAILED' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastFailedCollection ? collectionDetails.lastFailedCollection : '-' }}
                        </mat-cell>
                    </ng-container>
                    <!-- Next Scheduled  -->
                    <ng-container matColumnDef="nextScheduledCollection">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.NEXT_SCHEDULED' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let collectionDetails">{{
                            collectionDetails.nextScheduledCollection
                            ? collectionDetails.nextScheduledCollection
                            : '-'
                            }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <mat-header-cell
                            *matHeaderCellDef
                            class="fixCellWidth"
                        ></mat-header-cell>
                        <mat-cell
                            *matCellDef="let collectionDetails"
                            class="text-right collection-action"
                        >
                            <button
                                mat-icon-button
                                (click)="navigateToScheduleCollectDashboard(collectionDetails.locationId)"
                                [disabled]="!isScheduleCollectAllowed"
                            >
                                <mat-icon matTooltip="{{
                                        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT' | translate
                                    }}">query_builder</mat-icon>
                            </button>
                            <button
                                [disabled]="collectionDetails.collectInProgress"
                                mat-icon-button
                                (click)="collectLocation(collectionDetails.locationId)"
                            >
                                <mat-icon matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}">save_alt</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="collectionDetailsDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: collectionDetailsDisplayedColumns"></mat-row>
                </mat-table>
                <div
                    *ngIf="
                        !toggleState &&
                        showErrorMessage &&
                        (collectionDetailsData?.length === 0 || !collectionDetailsData)
                    "
                    class="notabledataFound custom-table"
                >
                    <span>{{ 'COMMON.NO_TEXT' | translate }}
                        <span *ngIf="!showCollectionSearch">{{ 'HOME.COLLECTION_WIDGET.FAILED_SCHEDULE_TEXT' | translate }}
                        </span>
                        {{ 'HOME.COLLECTION_WIDGET.COLLECTS_FOUND_TEXT' | translate }}</span>
                </div>
            </div>
        </mat-card-content>
        <div class="widgetFooter">
            <div
                class="auto-margin"
                [hidden]="!(totalPaginationLength > 10) || !toggleState"
            >
                <mat-paginator
                    #paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
            <div
                class="auto-margin"
                [hidden]="totalPaginationLength < 10 || toggleState"
            >
                <mat-paginator
                    #paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="25"
                    (page)="onPageChange($event)"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
            <div class="app-flex-filler"></div>
            <div
                [hidden]="toggleState"
                class="auto-margin custom-Flat-Button"
            >
                <button
                    class="uptimeOverviewDownloadHint"
                    (click)="downloadCSV()"
                    mat-button
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin">
                <button
                    mat-button
                    [disabled]="locationsLoadingState || !isScheduleCollectAllowed"
                    (click)="navigateToScheduleCollectDashboard()"
                >
                    {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_COLLECT_TITLE' | translate }}
                </button>
            </div>
        </div>
    </mat-card>
</div>
<mat-card-content
    id="collectionWidgetCardContentId"
    class="widgetFixPagination"
    *ngIf="!isReport"
>
    <div class="scrollOverlay"></div>
    <app-widget-filters
        (sendDataToWidget)="notifyWidget($event)"
        [hidden]="!showCollectionSearch"
        [filterSettings]="reportFilterSettings"
        [customerID]="customerID"
        [locationGroupID]="locationGroupID"
        [toggleState]="toggleState"
        [includeInactiveLocations]="includeInactiveLocations"
    >
    </app-widget-filters>

    <div
        *ngIf="toggleState && showErrorMessage && (collectionWidgetData?.length === 0 || !collectionWidgetData)"
        class="notabledataFound custom-table"
    >
        <span>{{ 'COMMON.NO_TEXT' | translate }}
            <span *ngIf="!showCollectionSearch">{{ 'HOME.COLLECTION_WIDGET.FAILED_SCHEDULE_TEXT' | translate }} </span>
            {{ 'HOME.COLLECTION_WIDGET.COLLECTS_FOUND_TEXT' | translate }}</span>
    </div>

    <!-- <app-loader [isLoading]="collectionWidgetLoadingState"></app-loader> -->
    <app-loader [isLoading]="locationsLoadingState"></app-loader>
    <div
        [hidden]="collectionWidgetData.length === 0 || !toggleState"
        id="collectionLeaderBoardTable"
        class="filtersOff widget-table"
    >
        <mat-table
            #table="matSort"
            [dataSource]="collectionWidgetDataSource"
            matSort
        >
            <!-- LOCATION  -->
            <ng-container matColumnDef="locationname">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{
                    'COMMON.LOCATION_COLUMN_TITLE' | translate
                    }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionHistoryItem">
                    <a
                        class="nowrap-white-space fullScreenHide location-name-class"
                        [title]="
                            validateLocationNameLength(collectionHistoryItem.locationname, 14)
                                ? collectionHistoryItem.locationname
                                : ''
                        "
                        (click)="getMarkerLocationDetails(collectionHistoryItem.locationid)"
                        [innerHTML]="collectionHistoryItem.locationname | sanitize: 'html'"
                    ></a>
                </mat-cell>
            </ng-container>

            <!-- LAST COLLECTED -->
            <ng-container matColumnDef="lastcollecteddate">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="text-right fix-width-160"
                >{{
                    'HOME.COLLECTION_WIDGET.LAST_COLLECT_COLUMN_TITLE' | translate
                    }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let collectionHistoryItem"
                    class="text-right"
                >{{
                    collectionHistoryItem.lastcollecteddate ? collectionHistoryItem.lastcollecteddate : '-'
                    }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell
                    *matCellDef="let collectionHistoryItem"
                    class="text-right collection-action"
                >
                    <button
                        class="collection-action-btn-sc"
                        mat-icon-button
                        (click)="navigateToScheduleCollectDashboard(collectionHistoryItem.locationid)"
                        [disabled]="!isScheduleCollectAllowed"
                    >
                        <mat-icon matTooltip="{{
                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT' | translate
                            }}">query_builder</mat-icon>
                    </button>
                    <button
                        class="collection-action-btn-c"
                        mat-icon-button
                        [disabled]="collectionHistoryItem.collectinprogress"
                        (click)="collectLocation(collectionHistoryItem.locationid)"
                    >
                        <mat-icon matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}">save_alt</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="collectionWidgetDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: collectionWidgetDisplayedColumns"></mat-row>
        </mat-table>
    </div>
    <!-- data communication expand window screen -->
    <div
        [hidden]="toggleState"
        id="communication-expand-table"
        [ngClass]="{'mt-150': availablePageHint}"
    >
        <mat-table
            #longtable="matSort"
            [dataSource]="collectionDetailsDataSource"
            (matSortChange)="onSortChanged($event)"
            matSort
        >
            <!-- location name -->
            <ng-container matColumnDef="locationName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LOCATION_NAME' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.locationName }}</mat-cell>
            </ng-container>
            <!-- monitor series -->
            <ng-container matColumnDef="monitorSeries">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.monitorSeries }} </mat-cell>
            </ng-container>
            <!-- failed -->
            <ng-container matColumnDef="failureAttempts">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.FAILED' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.failureAttempts }} </mat-cell>
            </ng-container>
            <!-- attempted -->
            <ng-container matColumnDef="totalAttempts">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.ATTEMPTED' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.totalAttempts }} </mat-cell>
            </ng-container>
            <!-- Success -->
            <ng-container matColumnDef="successPercent">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.SUCCESS' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let collectionDetails; let i = index"
                    [ngClass]="{ 'data-comm-item-hint': i === 0 }"
                >{{ collectionDetails.successPercent }}
                </mat-cell>
            </ng-container>
            <!-- Last Collection -->
            <ng-container matColumnDef="lastCollectedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LAST_COLLECTION' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastCollectedDate ? collectionDetails.lastCollectedDate : '-' }}
                </mat-cell>
            </ng-container>
            <!-- Last Schedule  -->
            <ng-container matColumnDef="lastScheduledCollection">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LAST_SCHEDULED' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastScheduledCollection ? collectionDetails.lastScheduledCollection : '-' }}
                </mat-cell>
            </ng-container>
            <!-- Last Manual -->
            <ng-container matColumnDef="lastManualCollection">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LAST_MANUAL' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastManualCollection ? collectionDetails.lastManualCollection : '-' }}
                </mat-cell>
            </ng-container>
            <!-- Last Failed  -->
            <ng-container matColumnDef="lastFailedCollection">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.LAST_FAILED' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastFailedCollection ? collectionDetails.lastFailedCollection : '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastFailedReason">
                <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'COMMON.LAST_FAILED_REASON_EXPLAINED' | translate }}">{{ 'COMMON.LAST_FAILED_REASON' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.lastFailedReason ? collectionDetails.lastFailedReason : '-' }}
                </mat-cell>
            </ng-container>
            <!-- Next Scheduled  -->
            <ng-container matColumnDef="nextScheduledCollection">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMMON.NEXT_SCHEDULED' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let collectionDetails">{{ collectionDetails.nextScheduledCollection ? collectionDetails.nextScheduledCollection : '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="fixCellWidth"
                ></mat-header-cell>
                <mat-cell
                    *matCellDef="let collectionDetails; let i = index"
                    class="text-right collection-action"
                >
                    <button
                        [ngClass]="{ 'data-comm-schedule-hint': i === 0 }"
                        mat-icon-button
                        (click)="navigateToScheduleCollectDashboard(collectionDetails.locationId)"
                        [disabled]="!isScheduleCollectAllowed && !availablePageHint"
                    >
                        <mat-icon matTooltip="{{
                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT' | translate
                            }}">query_builder</mat-icon>
                    </button>
                    <button
                        [ngClass]="{ 'data-comm-collect-hint': i === 0 }"
                        [disabled]="collectionDetails.collectInProgress && !availablePageHint"
                        mat-icon-button
                        (click)="collectLocation(collectionDetails.locationId)"
                    >
                        <mat-icon matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}">save_alt</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row
                *matHeaderRowDef="collectionDetailsDisplayedColumns"
                class="data-comm-headers-hint"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: collectionDetailsDisplayedColumns"></mat-row>
        </mat-table>
    </div>
</mat-card-content>
<div
    class="widgetFooter"
    [ngClass]="{ 'footer-on-hint': availablePageHint }"
    *ngIf="!isReport"
>
    <div
        class="auto-margin"
        [hidden]="!(totalPaginationLength > 10) || !toggleState"
    >
        <mat-paginator
            #paginator
            [length]="totalPaginationLength"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[10, 25, 100]"
        >
        </mat-paginator>
    </div>
    <div
        class="auto-margin"
        [hidden]="totalPaginationLength < 10 || toggleState"
    >
        <mat-paginator
            #paginator
            [length]="totalPaginationLength"
            [pageIndex]="0"
            [pageSize]="25"
            (page)="onPageChange($event)"
            [pageSizeOptions]="[10, 25, 100]"
        >
        </mat-paginator>
    </div>
    <div class="app-flex-filler"></div>
    <div></div>
</div>
