import { LocationGroup } from './location-group';
import { LocationData } from 'app/shared/models/locations';
import { GetPermissionsResponseCustomer, GetPermissionUserFeature, User } from './users-permission';

/**
 * Represents the global custommer object.
 */
export class Customer {
    public customerID: number;
    public customerName: string;
    public dataTTL?: number;
    public shortName?: string;
    public isActive?: boolean;
    public isNewCollect?: boolean = false;
    public unitsType?: number;
    public dateFormat?: string;
    public timeFormat?: string;
    public timeZone?: string;
    public isDaylightSavings?: boolean;
    public isSelected? = true;
    public isCurrentlySelected?: boolean;
    public permissionID?: string;
    public defaultCustomer?: boolean;
    public userName?: string;
    public collectProcessType?: CollectProcessType;
    public customer?: any;
    public customerFilter?: string;
    public workOrderLink?: string;
    public idleTimeOut?: number;
    public contractNumber?: string;
    public subscriptionLevel?: number;
    public roughness?: number;
}

export enum CollectProcessType {
    FlowViewAndCore = 0,
    FlowView,
    Core,
}
export enum customerFilter {
    Active = 'true',
    Inactive = 'false',
    All = '',
}

/**
 * Represents the app data object.
 */
export interface AppCustomer {
    /**
     * Collection of customers.
     */
    customers: Array<GetPermissionsResponseCustomer>;

    /**
     * Collection of Location Groups.
     */
    locationGroups: Array<LocationGroup>;

    /**
     * Logged In User Role
     */
    userRole: Array<string>;

    /**
     * Logged In User Details
     */
    firstName?: string;
    lastName?: string;
    userEmail: string;
    secondaryEmail?: string;
    features?: Array<GetPermissionUserFeature>;
    isIdex: boolean;

    locations?: Array<LocationData>;
}

export enum DialogQueryParams {
    None = '0',
    Usage = '1',
    CostAllocation = '2'
}

/**
 * Represents the Query Params object.
 */
export interface AppQueryParams {
    /**
     * The customer surrogate identifier.
     */
    c: number;

    /**
     * The location group surrogate identifier.
     */
    lg?: number;

    /**
     * The location name
     */
    ln?: string;

    /**
     * The location ID
     */
    lid?: number;

    /**
     * Active and Inactive location identifier
     */
    lt?: number;

    cid?: number;

    /**
     * Added field to store any search value
     */
    s?: string;

    /**
     * Added field to store page index
     */
    pi?: number;

    /**
     * Added field to store page size
     */
    ps?: number;

    /* Id for anything needing ID other than location or customer (such as notification id) */
    id?: string;

    /** Refresh cache. Used for LD details -> LD. 1 = LD details to LD */
    rc?: number;

    dialog?: DialogQueryParams;
}

export type DashboardQueryParams = {
    widget?: WIDGET_PARAM;
};

export const dashboardQueryParamsKeys: { [key in keyof DashboardQueryParams]: keyof DashboardQueryParams } = {
    widget: 'widget',
};

export enum WIDGET_PARAM {
    ALL,
    ALARM,
    BATTERY,
    DATA_COMMUNICATION,
    BLOCKAGE_PREDICT,
    AUTO_REVIEW,
    EVENT,
    CUMULATIVE_RAIN
}

export const userEmailFromRoute = 'u';

export const customerQueryParam = 'c';

export const caseStudyQueryParam = 'cs';

export const customerEditorQueryParam = 'cid';

export const customerLocationGroupQueryParam = 'lg';

export const customerLocationNameQueryParam = 'ln';

export const activeInactiveLocationQueryParam = 'lt';

export const referringLocationIdQueryParam = 'refId';

export const locationIdQueryParam = 'lid';

export const isNewTab = 'nt';

// id of parent window
export const parentId = 'srcId';

export const genericIdQueryParam = 'id';

// #16939 whenever force reoload cache
export const reloadCacheQueryParam = 'rc';

export const userSearchParam = 's';

export const userSearchPageIndexParam = 'pi';

export const userSearchPageSizeParam = 'ps';

export const Active = 'Active';

export const InActive = 'Inactive';

export const All = 'All';

export const MULTIPLE = 'Multiple';

export const COLUMN = 'Column';

export const SINGLE = 'Single';

export const CSV = 'CSV';

export const EXCEL = 'EXCEL';

export const ONE_HOUR = 3;

export const NONE = 0;

export const OMIT = 'omit';

export const BLANK = 'blank';

export const CUSTOM = 'custom';

export const LOCATIONLABEL = 'Location';

export const TELOG = 'TELOG';
export const TELEMETRY = 'TELEMETRY';

/**
 * interface used for customer user association dialog
 */
export interface CustomerUserAssociactionData {
    /**
     * list of customers
     */
    customers: Array<GetPermissionsResponseCustomer>;

    /**
     * data of users associated with Customer
     */
    user: User;

    /**
     * deafult user set on pop up open
     */
    defaultUser: string;
}
export interface GetCustomers {
    customSummary: Array<GetCustomerSummary>;
    totalCount: number;
}

export interface GetCustomerSummary {
    id: number;
    name: string;
    summary: Array<string>;
    activeLocations: number;
    inactiveLocations: number;
    active: boolean;
    highlightRow?: boolean;
    subscriptionLevel?: number;
    addOns: Array<SubscriptionsInfoFeatures>
}
export interface GetCustomerFeatureResponse {
    id?: number;
    name?: string;
    canassigncustomer?: boolean;
    isChecked?: boolean;
    children?: GetCustomerFeatureResponse[];
    parentId?: number;
}

export interface GetCustomAlarmsSummary {
    enabled: boolean;
    cid: number;
    eid: number;
    threshold: number;
    rgint: number;
    period: number;
    locs: Array<GetLocationsResponse>;
}

export interface GetLocationsResponse {
    lid?: number;
    locname?: string;
    adsrgids?: Array<GetRainGaugeResponse>;
    nwslat?: number;
    nwslong?: number;
    selected?: boolean;
    highLight?: boolean;
    enabled?: boolean;
}

export interface GetRainGaugeResponse {
    rgid?: number;
    rgname?: string;
    rainGaugeList?: Array<GetLocationsRainGaugeResponse>;
}

export interface GetLocationsRainGaugeResponse {
    customerID: number;
    isActiveLocation: boolean;
    latitude: number;
    locationId: number;
    locationName: string;
    longitude: number;
    monitoringPoint: number;
    series: string;
    seriesID: number;
    viewable: boolean;
    isChecked?: boolean;
}

export interface SliicerUserConfig {
    etag: string;
    id: string;
    docType: string;
    partitionKey: string;
    userId: string;
    customerID: 0;
    viewableColumns: string[];
    hiddenColumns: string[];
    otherConfig: string;
}

export interface ProjectDetailsAdditionalFeature {
    id: number,
    canassigncustomer?: boolean
}

export interface ProjectDetails {
    type: string;
    cid: number;
    pk: string;
    id: string;
    _etag: string;
    _self: string;
    contractNumber: string;
    subscriptionLevel: number;
    originalContractStart: string;
    currentContractStart: string;
    currentContractEnd: string;
    dataAnalyst: string;
    regionalManager: string;
    salesPerson: string;
    projectManager: string;
    markedInactiveOn: Date;
    additionalFeaturesList?: ProjectDetailsAdditionalFeature[];
}

export interface UpdateProjectDetails {
    contractNumber?: string;
    subscriptionLevel?: number;
    originalContractStart?: Date;
    currentContractStart?: Date;
    currentContractEnd?: Date;
    dataAnalyst?: string;
    regionalManager?: string;
    salesPerson?: string;
    projectManager?: string;
    markedInactiveOn?: Date;
    additionalFeaturesList?: {
        id: number
    }[];
}

export enum SubscriptionLevel {
    Prism = 0,
    PrismPro,
    PrismLimited,
}

/** Enum feature = ID */
export enum CustomerFeatures {
    BlockagePrediction = 2,
    AutoReview = 100,
    WetDryOverflow = 108,
    AdvanceReport = 112,
    ANSREngine = 113,
    // TODO: NOT USED: KMLLayers = 115,
    DataEditComments = 116,
    AdvancedDataEditing = 117,
    DataEditing = 118,
}

export interface CustomerFeature {
    viewable?: boolean;
    value: boolean;
    isbeta?: boolean;
}

export interface DdfTable {
    ddfTable?:Array<unknown>;
    docType:string;
    id?:string;
    pk:number;
}

export const SUBSCRIPTION_LEVELS = [
    { value: SubscriptionLevel.Prism, text: 'PRISM' },
    { value: SubscriptionLevel.PrismPro, text: 'PRISMPro' },
    { value: SubscriptionLevel.PrismLimited, text: 'PRISM Limited' },
];

export const DEFAULT_SUBSCRIPTION_LEVEL = SubscriptionLevel.Prism;

export interface SubscriptionTier {
    id:       number;
    tier:     string;
    features: number[];
}

export interface SubscriptionsInfo {
    availableAddOns: SubscriptionsInfoFeatures[];
    subscriptions:   SubscriptionsInfoTier[];
}

export interface SubscriptionsInfoFeatures {
    id:                number;
    name:              string;
    displayname:       string;
    canassigncustomer: boolean;
    desc:              string;
    isbeta:            boolean;
}

export interface SubscriptionsInfoTier {
    level:    number;
    name:     string;
    features: SubscriptionsInfoFeatures[];
}
