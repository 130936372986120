import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SliicerCaseStudy, RainfallMonitorBlockDays, StormEvent } from 'app/shared/models/sliicer';
import { MonitorName, SeriesData, BlockDaysElement } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { RainfallBlockDaysComponent } from './rainfall-block-days/rainfall-block-days.component';
import { RainfallGraphComponent } from './rainfall-graph/rainfall-graph.component';
import { UpdatesWidgetService } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.service';
import { UpdateInfo } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { DatePipe } from '@angular/common';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { Observable, combineLatest } from 'rxjs';
import { StudyCalcState } from 'app/shared/models/sliicer/metadata';

const UPDATES_MODEL = 'studyRainfall';

@Component({
    selector: 'app-rainfall-hytograph',
    templateUrl: './rainfall-hytograph.component.html',
    styleUrls: ['./rainfall-hytograph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainfallHytographComponent implements OnInit, OnDestroy {
    @Input() public customerId: number;
    @Output() private acceptChangesResult = new EventEmitter();
    @ViewChild('rainfallGraph') public rainfallGraph: RainfallGraphComponent;
    @ViewChild('blockDaysTable') public blockDayComponent: RainfallBlockDaysComponent;

    private caseStudyId: string;
    private currentSelectedMonitorNames: string[] = [];
    private savedBlockDays: RainfallMonitorBlockDays[] = [];
    private ignoreNextUpdate = false;

    // TODO: WPS - make this a constant in constants.ts
    private static readonly BLOCK_DAYS_COLOR = 'rgba(128,128,128,0.2)';

    public rainfallMonitors: MonitorName[];
    public isLoadingState = false;
    public rainFallsDown = false;
    public currentBlockDayOverlays: BlockDaysElement[] = [];
    public currentBlockDays: RainfallMonitorBlockDays[] = [];
    public updated = false;
    public start: Date;
    public end: Date;
    public startDateString: string;
    public endDateString: string;
    public isStudyLocked: boolean;
    public stormEvents: StormEvent[] = [];
    public tooltipEnabled = true;

    // translation strings
    private dismissMessage: string;
    private deleteAllBlockdaysForRainfallMonitor: string;
    private cancelButton: string;
    private clearAllButton: string;
    private clearAllBlockDays: string;
    private monitor: string;
    private monitors: string;
    private updateConfirmationTitle: string;
    private discardChangesTitle: string;
    private discardButton: string;
    private updateText: string;
    private saveChangesMsg: string;
    private updateSuccess: string;
    private updateFail: string;
    private subscriptions = [];

    constructor(
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private matDialog: MatDialog,
        private snackBarNotificationService: SnackBarNotificationService,
        private translateService: TranslateService,
        private updatesWidgetService: UpdatesWidgetService,
        private datePipe: DatePipe,
    ) {}

    public ngOnInit() {
        this.initTranslation();

        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.discardChanges();
                            break;
                        case 'applyChanges':
                            this.saveChanges();
                            break;
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );

        const csSubscription = this.sliicerService.studyDetailsData$.subscribe((caseStudy: SliicerCaseStudy) => {
            if (this.ignoreNextUpdate) {
                this.ignoreNextUpdate = false;
                return;
            }

            if (caseStudy.config) {
                this.dateutilService.dateFormat.subscribe(() => {
                    const format = this.dateutilService.getDateFormat();
                    this.startDateString = moment(caseStudy.config.startDate).format(format);
                    this.endDateString = moment(caseStudy.config.endDate).format(format);
                    this.start = new Date(caseStudy.config.startDate);
                    this.end = new Date(caseStudy.config.endDate);
                });
            }

            this.customerId = caseStudy.customerId;
            this.caseStudyId = caseStudy.id;
            this.rainFallsDown = caseStudy.rainfallGraphFlag;

            this.setCaseStudy(caseStudy);

            this.cdr.markForCheck();
        });

        this.subscriptions.push(csSubscription);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
        this.subscriptions = [];
    }

    /** Tooltip enabled button event handler.  */
    public onTooltipEnabledChanged() {
        this.tooltipEnabled = !this.tooltipEnabled;
        this.rainfallGraph.graphComponentChild.chart.update({
            tooltip: {
                enabled: this.tooltipEnabled,
            },
        });
    }

    private initTranslation() {
        const translateKeys: Array<string> = [
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.DELETE_ALL_BLOCKDYS_FOR_RAINFALL_MONITOR',
            'COMMON.DISMISS_TEXT',
            'COMMON.DISCARD_CHANGES',
            'COMMON.CANCEL_BUTTON',
            'COMMON.DISCARD_TEXT',
            'COMMON.CLEAR_ALL',
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.CLEAR_ALL_BLOCK_DAYS',
            'COMMON.MONITORS',
            'COMMON.MONITOR',
            'COMMON.UPDATE_CONFIRMATION_TITLE',
            'SLIICER.COMMON.SAVE_BUTTON_TITLE',
            'SLIICER.RAINFALL_TAB.SAVE_SUCCESS',
            'SLIICER.RAINFALL_TAB.SAVE_FAIL',
            'COMMON.CHANGES_SAVED',
        ];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.deleteAllBlockdaysForRainfallMonitor =
                values[
                    'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.DELETE_ALL_BLOCKDYS_FOR_RAINFALL_MONITOR'
                ];
            this.dismissMessage = values['COMMON.DISMISS_TEXT'];
            this.discardChangesTitle = values['COMMON.DISCARD_CHANGES'];
            this.cancelButton = values['COMMON.CANCEL_BUTTON'];
            this.discardButton = values['COMMON.DISCARD_TEXT'];
            this.clearAllButton = values['COMMON.CLEAR_ALL'];
            this.clearAllBlockDays =
                values['SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.CLEAR_ALL_BLOCK_DAYS'];
            this.monitors = values['COMMON.MONITORS'];
            this.monitor = values['COMMON.MONITOR'];
            this.updateConfirmationTitle = values['COMMON.UPDATE_CONFIRMATION_TITLE'];
            this.updateText = values['SLIICER.COMMON.SAVE_BUTTON_TITLE'];
            this.updateSuccess = values['SLIICER.RAINFALL_TAB.SAVE_SUCCESS'];
            this.updateFail = values['SLIICER.RAINFALL_TAB.SAVE_FAIL'];
            this.saveChangesMsg = values['COMMON.CHANGES_SAVED'];
        });
    }

    private setCaseStudy(caseStudyDetails: SliicerCaseStudy) {
        this.currentSelectedMonitorNames = [];
        if (caseStudyDetails == null) {
            this.rainfallMonitors = [];
            this.currentBlockDays = [];
            this.currentBlockDayOverlays = [];
            this.stormEvents = [];
        } else {
            if (caseStudyDetails.config && caseStudyDetails.config.rainfallMonitors) {
                this.rainfallMonitors = caseStudyDetails.config.rainfallMonitors?.sort((a,b) => a.name > b.name ? 1 : -1);
                if (this.rainfallMonitors.length > 0) {
                    this.getRainMonitorData(
                        this.customerId,
                        this.rainfallMonitors,
                        caseStudyDetails.meta.telemetryDatabaseName,
                        caseStudyDetails.config.startDate,
                        caseStudyDetails.config.endDate,
                    );
                }
            } else {
                this.rainfallMonitors = [];
            }
            if (caseStudyDetails.overrides && caseStudyDetails.overrides.rainfallMonitorBlockDays) {
                this.currentBlockDays = Object.values(
                    Object.assign({}, JSON.parse(JSON.stringify(caseStudyDetails.overrides.rainfallMonitorBlockDays))),
                );
                this.savedBlockDays = caseStudyDetails.overrides.rainfallMonitorBlockDays;
                this.buildGraphOverlays();
            } else {
                this.currentBlockDays = [];
                this.currentBlockDayOverlays = [];
            }

            this.loadStormEvents(caseStudyDetails.customerId, caseStudyDetails.id);
        }
    }

    private loadStormEvents(customerId: number, caseStudyId: string) {
        this.sliicerService.getStorms(customerId, caseStudyId).subscribe(
            (results) => {
                if (results && results.storms) {
                    this.stormEvents = results.storms;
                } else {
                    this.stormEvents = [];
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.stormEvents = [];
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public editBlockDaysToggle(e) {
        this.rainfallGraph.editBlockDays(e.checked);
    }

    /** Show storms toggle click event handler.  */
    public showStormsToggle(e) {
        this.rainfallGraph.showStorms(e.checked);
    }

    private buildGraphOverlays() {
        const copyBlockOverlayDays: Array<BlockDaysElement> = [];
        this.currentBlockDays.forEach((r) => {
            if (this.currentSelectedMonitorNames.findIndex((m) => m === r.name) >= 0) {
                r.days.forEach((d) => {
                    const startTimeStamp = moment(d).valueOf().toString();
                    const endTimeStamp = moment(d).add(1, 'days').valueOf().toString();
                    copyBlockOverlayDays.push({
                        from: startTimeStamp,
                        to: endTimeStamp,
                        id: r.name + startTimeStamp,
                        color: RainfallHytographComponent.BLOCK_DAYS_COLOR,
                    });
                });
            }
        });
        this.currentBlockDayOverlays = copyBlockOverlayDays;
    }

    /**
       Method to fetch the Rainfall Monitors data for selected RainFall Monitor
    **/
    // tslint:disable-next-line:max-line-length
    private getRainMonitorData(
        customerId: number,
        rainfallMonitors: Array<MonitorName>,
        telemetryKey: string,
        startDate: string,
        endDate: string,
    ) {
        this.isLoadingState = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        const reqs: Observable<SeriesData>[] = [];
        rainfallMonitors.forEach((rm) => {
            reqs.push(this.sliicerService
                .getRainfall(
                    customerId,
                    this.caseStudyId,
                    telemetryKey,
                    rm.name,
                    startDate.toString(),
                    endDate.toString(),
                ));
        });

        combineLatest(reqs).subscribe((arr) => {
            arr.forEach((a) => {
                if(a.data) {
                    if (!this.currentSelectedMonitorNames.includes(a.seriesName)) {
                        this.currentSelectedMonitorNames.push(a.seriesName);
                    }
                }
            });
            this.buildGraphOverlays();
            this.rainfallGraph.buildSeries(arr);

            this.isLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }, (error) => {
            this.isLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

    }

    /**
     * This is an event that is emitted by the rainfall-block-days component
     * contained in this page. It lets us know when the user has removed block days
     * from the rainfall monitors.
     */
    public removeBlockDate(blockDays: RainfallMonitorBlockDays) {
        const index = this.currentBlockDays.findIndex((b) => b.name === blockDays.name);
        if (index < 0) {
            return;
        }
        blockDays.days.forEach((d) => {
            const dayIndex = this.currentBlockDays[index].days.indexOf(d);
            if (dayIndex < 0) {
                return;
            }
            this.currentBlockDays[index].days.splice(dayIndex, 1);
        });
        this.buildGraphOverlays();

        // to trigger changes in children components
        this.currentBlockDays = [...this.currentBlockDays];

        this.setUpdatesInfo();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * This is an event that is emitted by the app-rainfall-graph component
     * contained in this page. It lets us know when the user has selected
     * a region of the screen to be added or removed from the list of
     * block days
     * @param addedOrRemovedBlockDays an array of integers corresponding
     *                                to (TODO: HOPEFULLY) timestamps.
     *                                TODO: LOOK AT THE CODE!
     */
    public addedOrRemovedBlockDays(addedOrRemovedBlockDays: number[]) {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const studyEndDate = new Date(study.config.endDate);

        addedOrRemovedBlockDays.forEach((timestamp) => {
            let blockDayString = moment(timestamp).toISOString();
            if (blockDayString.endsWith('Z')) {
                blockDayString = blockDayString.slice(0, blockDayString.length - 2);
            }

            // #39444 need additional check for study end date to not allow to block extra days
            if (new Date(blockDayString).getTime() > studyEndDate.getTime()) {
                return;
            }
            // we only add or remove days for the currently selected monitors
            this.currentSelectedMonitorNames.forEach((monitorName) => {
                const monitorIndex = this.currentBlockDays.findIndex((b) => b.name === monitorName);

                const startTimeStamp = moment(blockDayString).valueOf().toString();
                const plotId = monitorName + startTimeStamp;
                const existingPlotBandIndex = this.currentBlockDayOverlays.map((a) => a.id).indexOf(plotId);

                if (existingPlotBandIndex >= 0) {
                    // We already have a plot band. Remove it from my internal list if it is there.
                    if (monitorIndex >= 0) {
                        const dayIndex = this.currentBlockDays[monitorIndex].days.indexOf(blockDayString);
                        if (dayIndex >= 0) {
                            this.currentBlockDays[monitorIndex].days.splice(dayIndex, 1);
                        }
                    }
                } else {
                    // We are adding a plot band. Add one to my internal list if it is not already there.
                    if (monitorIndex >= 0) {
                        // We have something for the monitor. Make sure we have the given day.
                        const dayIndex = this.currentBlockDays[monitorIndex].days.indexOf(blockDayString);
                        if (dayIndex < 0) {
                            // Add the day if it is not there.
                            this.currentBlockDays[monitorIndex].days.push(blockDayString);
                        }
                    } else {
                        // There's nothing for this monitor yet. Add one.
                        this.currentBlockDays.push({
                            name: monitorName,
                            days: [blockDayString],
                        });
                    }
                }
            });
        });

        this.buildGraphOverlays();

        // to trigger changes in rainfall table
        this.currentBlockDays = [...this.currentBlockDays];

        this.setUpdatesInfo();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public clearAll() {
        const monitorOrMonitors = this.rainfallMonitors.length > 1 ? this.monitors : this.monitor;
        const confirmationMsg = `${this.deleteAllBlockdaysForRainfallMonitor} ${monitorOrMonitors}`;
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: <IComponentDialog>{
                    title: this.clearAllBlockDays,
                    message: confirmationMsg,
                    cancelText: this.cancelButton,
                    okText: this.clearAllButton,
                    hidebackContent: false,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.currentBlockDayOverlays = [];
                    this.currentBlockDays = [];
                    this.setUpdatesInfo();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
    }

    public discardChanges() {
        if (this.savedBlockDays && this.savedBlockDays.length > 0) {
            this.currentBlockDays = this.savedBlockDays.map((s) => {
                return { name: s.name, days: [...s.days] };
            });
        } else {
            this.currentBlockDays = [];
        }
        this.buildGraphOverlays();
        this.setUpdatesInfo();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public saveChanges() {
        this.updatesWidgetService.updatesLoader = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.sliicerService.putBlockRainDays(this.customerId, this.caseStudyId, this.currentBlockDays).subscribe(
            (response: SliicerCaseStudy) => {
                this.sliicerService.bustQviCache();
                this.sliicerService.bustStormCache();

                this.updateStudyCalcState();
                this.updatesWidgetService.updatesLoader = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.savedBlockDays = (response.overrides && response.overrides.rainfallMonitorBlockDays) || [];
                this.ignoreNextUpdate = true;
                this.sliicerService.caseStudyDetails.next(response);

                this.loadStormEvents(response.customerId, response.id);

                this.sliicerService.showToast(this.updateSuccess);
                this.setUpdatesInfo();
            },
            () => {
                this.updatesWidgetService.updatesLoader = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.updateFail, true);
                // TODO: WPS - show message on (or near) the graph that there was an
                //             error retrieving rainfall data
            },
        );
    }

    private updateStudyCalcState() {
        this.sliicerService.getStudyCalcState(this.customerId, this.caseStudyId).subscribe((data: StudyCalcState) => {
            const study = this.sliicerService.caseStudyDetails.getValue();

            study.meta.basinState = data.basinState;
            study.meta.calculationRunId = data.calculationRunId;
            study.meta.calculationRunTime = data.calculationRunTime;
        });
    }
    public selectedMonitorChanged(monitorName: string) {
        const index = this.currentSelectedMonitorNames.indexOf(monitorName);
        if (index < 0) {
            this.currentSelectedMonitorNames.push(monitorName);
        } else {
            this.currentSelectedMonitorNames.splice(index, 1);
        }
        this.buildGraphOverlays();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /********************************************************************************/
    /* PRIVATE                                                                      */
    /********************************************************************************/

    /**
     * This will check all changes and structure udpates info data if any change is detected
     */
    private setUpdatesInfo() {
        const updatesInfo: UpdateInfo[] = [];
        const blockDaysChange = !_.isEqual(this.currentBlockDays, this.savedBlockDays);
        if (blockDaysChange) {
            RainfallHytographComponent.AddUpdateInfo(
                updatesInfo,
                this.getBlockDaysUpdateInfo(this.currentBlockDays, this.savedBlockDays),
            );
        }
        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    /**
     * This will structure and return the block days update info
     * @param currentBlockDays
     * @param savedBlockDays
     */
    private getBlockDaysUpdateInfo(
        currentBlockDays: RainfallMonitorBlockDays[],
        savedBlockDays: RainfallMonitorBlockDays[],
    ) {
        const updatesInfo: Array<UpdateInfo> = [];
        for (let i = 0; i < this.rainfallMonitors.length; i++) {
            const currentRainfallMonitor: RainfallMonitorBlockDays = currentBlockDays.find(
                (c) => c.name === this.rainfallMonitors[i].name,
            );
            const savedRainfallMonitor: RainfallMonitorBlockDays = savedBlockDays.find(
                (s) => s.name === this.rainfallMonitors[i].name,
            );
            const monitorCurrentBlockDays =
                (currentRainfallMonitor &&
                    currentRainfallMonitor.days &&
                    currentRainfallMonitor.days.map((c) => moment(c).format())) ||
                [];
            const monitorSavedBlockDays =
                (savedRainfallMonitor &&
                    savedRainfallMonitor.days &&
                    savedRainfallMonitor.days.map((c) => moment(c).format())) ||
                [];
            const monitorAddedBlockDays = SliicerService.FindDifferentElements(
                monitorCurrentBlockDays,
                monitorSavedBlockDays,
            );
            const monitorRemovedBlockDays = SliicerService.FindDifferentElements(
                monitorSavedBlockDays,
                monitorCurrentBlockDays,
            );
            if (monitorAddedBlockDays.length > 0) {
                updatesInfo.push({
                    title: 'RAINFALL_BLOCK_DAYS_ADDED',
                    values: monitorAddedBlockDays.map((m) =>
                        RainfallHytographComponent.GetDateInUserFormat(this.datePipe, this.dateutilService, m),
                    ),
                    appendTitle: ' ' + this.rainfallMonitors[i].name,
                });
            }
            if (monitorRemovedBlockDays.length > 0) {
                updatesInfo.push({
                    title: 'RAINFALL_BLOCK_DAYS_REMOVED',
                    values: monitorRemovedBlockDays.map((m) =>
                        RainfallHytographComponent.GetDateInUserFormat(this.datePipe, this.dateutilService, m),
                    ),
                    appendTitle: ' ' + this.rainfallMonitors[i].name,
                });
            }
        }
        return updatesInfo;
    }

    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    /**
     * Static wrapper around private function that does the same
     * @param pipe
     * @param dateUtilService
     * @param date
     * @constructor
     */
    private static GetDateInUserFormat(pipe: DatePipe, dateUtilService: DateutilService, date: string): string {
        return pipe.transform(date, dateUtilService.getFormat());
    }

    /**
     * This will handle the update to the updatesInfo array
     * since the updateInfo can be Object or Array depending on what is updated
     * @param updatesInfo
     * @param updateInfo
     * @constructor
     */
    private static AddUpdateInfo(updatesInfo: UpdateInfo[], updateInfo: UpdateInfo | UpdateInfo[]) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }
}
