<div id="vaultTelemetryDialog" class="modal-dialog vault-telemetry-dialog">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
    ></app-customized-confirmation-box>
    <div class="popupHeader" mat-dialog-title>
        <span> {{ 'VAULT.VAULT_TELEMETRY.IMPORT.TITLE' | translate }}</span>
        <span
            class="file-name-header"
            *ngIf="isTelemetryFile && telemetryValidatedFiles && telemetryValidatedFiles.length === 1"
            >: {{ telemetryValidatedFiles[0].fileName }}</span
        >
        <span class="app-flex-filler"></span>
    </div>

    <div class="uploaded-files" *ngIf="isFileUploaded && telemetryValidatedFiles.length > 0">
        <button mat-icon-button>
            <mat-icon class="file-checked">check</mat-icon>
        </button>
        <span> {{ telemetryValidatedFiles.length }} {{ 'COMMON.FILE_SUCCESSFULLY_UPLOADED' | translate }}</span>
    </div>

    <!-- Telog Import Section -->
    <div class="validation" *ngIf="isTelogDataFile && !isTelogDataDialog">
        <mat-dialog-content>
            <mat-form-field class="full-width margin-t-15">
                <input
                    matInput
                    name="filePath"
                    placeholder="{{ 'VAULT.VAULT_TELEMETRY.IMPORT.FILE_PATH_PLACEHOLDER' | translate }}"
                    readonly
                    value="{{ telemetryInputData.id }}"
                />
            </mat-form-field>
            <div>
                <app-loader [isLoading]="isLoading"></app-loader>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="importTelogFile()" [disabled]="isLoading">
                    {{ 'COMMON.IMPORT_HEADING' | translate }}
                </button>
            </div>
        </mat-dialog-actions>
    </div>

    <!-- Telog Import Confirmation Section -->
    <div class="validation" *ngIf="isTelogDataDialog">
        <mat-dialog-content>
            <p>{{ 'VAULT.VAULT_UPLOAD_TELOG.TELOG_FILE_IMPORT_CONFIRMATION' | translate }}</p>
            <div>
                <app-loader [isLoading]="isLoading"></app-loader>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="importTelogDialog()">{{ 'COMMON.OK' | translate }}</button>
            </div>
        </mat-dialog-actions>
    </div>

    <div class="telemetry-validate-section" *ngIf="isTelemetryFile">
        <div>
            <app-loader [isLoading]="isLoading"></app-loader>
        </div>
        <mat-dialog-content *ngIf="telemetryValidatedFiles.length === 1">
            <p class="margin-15">
                {{ 'VAULT.VAULT_TELEMETRY.IMPORT.PASSED_VALIDATION_DIALOG_p' | translate }}
                <strong>{{ 'VAULT.IMPORT_TEXT' | translate }}</strong>
                {{ 'VAULT.VAULT_TELEMETRY.IMPORT.PASSED_VALIDATION_DIALOG_STRONG' | translate }}
            </p>
            <div class="margin-15">
                <app-vault-import-file-summary 
                    [file]="telemetryValidatedFiles[0]"
                    (selectedTimespanChange) = "selectedTimespanChange($event)">                 
                </app-vault-import-file-summary>
            </div>
        </mat-dialog-content>
        <mat-dialog-content *ngIf="telemetryValidatedFiles.length > 1">
            <p class="margin-15">{{ 'VAULT.VAULT_TELEMETRY.IMPORT.MULTISELECT_VALIDATION_MESSAGE' | translate }}</p>
            <mat-accordion class="vault-file-import-containt">
                <mat-expansion-panel
                    [expanded]="telemetryValidatedFiles || telemetryValidatedFiles.length === 1"
                    *ngFor="let file of telemetryValidatedFiles; let i = index"
                >
                    <mat-expansion-panel-header>
                        <mat-checkbox
                            [disabled]="!file.isValidationSuccessful"
                            [checked]="file.isChecked"
                            name="validatedFile"
                            (change)="importFileSelectChange(file.id)"
                            (click)="$event.stopPropagation()"
                            class="file-import-check"
                        ></mat-checkbox>
                        <mat-panel-title>
                            <span class="panel-name">{{ file.fileName }}</span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="panel-error-style" *ngIf="!file.isValidationSuccessful">
                                <mat-icon>error</mat-icon>
                                <span> {{ 'COMMON.FILE_NOT_VALID' | translate }} </span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-vault-import-file-summary 
                        [file]="file"
                        (selectedTimespanChange) = "selectedTimespanChange($event)">                 
                    ></app-vault-import-file-summary>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button [disabled]="!isImportEnable || isLoading" (click)="overwriteConfirmBeforeImport()">
                    {{ 'COMMON.IMPORT_HEADING' | translate }}
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</div>
