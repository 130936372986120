<div class="billing-metrics-dialog customDialogLayer">
	<div class="customDialog">
		<div class="customDialogPosition">
			<div id="resonForEditId">
				<div class="popupHeaderWithButton" mat-dialog-title>
					<div class="auto-margin pad-l-15">
						{{ 'ADMIN.ADMIN_USAGE.USAGE_REPORT' | translate }}
					</div>
					<div class="app-flex-filler"></div>
				</div>
				<div mat-dialog-content class="eachTabContent">
					<div>
						<app-date-range-picker [minDate]="minDate" [maxDayRange]="maxDaysAllowed" [startDate]="startDate" [endDate]="endDate"
							(startDateChange)="onStartDateChange($event)" [label]="dateRangePlaceholder"
							(endDateChange)="onEndDateChange($event)" [autoFocus]="false" [dateRangeType]="'date'"
							[startDateBoundaryErrorMsg]="startDateErrorMsg"></app-date-range-picker>
					</div>
				</div>
				<div mat-dialog-actions>
					<div class="app-flex-filler"></div>
					<div class="auto-margin">
						<button mat-flat-button (click)="cancel()">
							{{ 'ADMIN.ADMIN_USAGE.CANCEL' | translate }}
						</button>
					</div>
					<div class="auto-margin">
						<button (click)="generateReport()" mat-button>
							{{ 'ADMIN.ADMIN_USAGE.GENERATE_REPORT' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
