<div id="plotting-confirmation-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'PLOTTING_CONFIRMATION.ADD_OR_EDIT_CONFIRMATION_POINT' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>

    <app-loader [isLoading]="isLoading"></app-loader>
    <div mat-dialog-content>
        <form>
        <div class="full-width pad-t-15 pad-b-15">
            <div class="full-width">
                <mat-slide-toggle
                    [color]="'accent'"
                    [(ngModel)]="data.ignore"
                    name="ignore"
                >{{ 'COMMON.IGNORE' | translate }}</mat-slide-toggle>
            </div>
        </div>
        <div class="custom-33p">
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        #startDateInput
                        matInput
                        placeholder="{{ 'PLOTTING_CONFIRMATION.SELECT_TIMESTAMP' | translate }}"
                        (change)="onDateChange($event)"
                        required
                        [max]="maxConfirmationPointDate"
                        name="confirmationDate"
                        [value]="data.confirmationDate | date: customDateFormat"
                        tabindex="-1"
                        class="datepickerStyles"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="startDte"
                    />
                    <input
                        matInput
                        hidden
                        name="startDate"
                        [max]="maxConfirmationPointDate"
                        [(ngModel)]="data.confirmationDate"
                        (dateInput)="onDateChange($event)"
                        [matDatetimepicker]="startDte"
                    />
                    <mat-datetimepicker-toggle matSuffix [for]="startDte"></mat-datetimepicker-toggle>
                    <mat-datetimepicker [showSeconds]="true" #startDte timeInterval="1" type="datetime"></mat-datetimepicker>
                    
                </mat-form-field>
                <div class="mat-error" *ngIf="!isValidConfirmationDate">
                    {{ 'COMMON.INVALID_CONFIRMATION_DATE' | translate }}
                </div>
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        (input)="onDepthVelocityChange($event)"
                        (blur)="onDepthVelocitySiltAvgToPeakBlur()"
                        matInput
                        placeholder="{{ 'COMMON.MEASURED_SILT' | translate }} {{ siltUnit }}"
                        [(ngModel)]="data.silt"
                        id="silt"
                        #silt="ngModel"
                        name="silt"
                        [pattern]="siltValidator"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="silt?.errors?.pattern">
                    {{ (customerUnits.dp === 1 ? 'COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' : 'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS')
                        | translate }}
                </div>
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        (input)="onDepthVelocityChange($event)"
                        (blur)="onDepthVelocitySiltAvgToPeakBlur()"
                        matInput
                        placeholder="{{ 'COMMON.AVG_TO_PEAK_RATIO' | translate }}"
                        [(ngModel)]="data.averageToPeak"
                        name="averageToPeak"
                        id="averageToPeak"
                        #averageToPeak="ngModel"
                        [pattern]="numericWith2DecimalPlaces"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="averageToPeak?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate }}
                </div>
            </div>
        </div>
        <div class="custom-33p">
            <div class="each-Item">
                <div class="custom-50p">
                    <div class="custom-75p">
                        <mat-form-field class="full-width">
                            <input
                                (input)="onDepthVelocityChange($event)"
                                (blur)="onDepthVelocitySiltAvgToPeakBlur()"
                                matInput
                                required
                                placeholder="{{ 'COMMON.MEASURED_DEPTH' | translate }} {{ depthUnit }}"
                                [(ngModel)]="data.depth"
                                name="depth"
                                id="depth"
                                #depth="ngModel"
                                [pattern]="depthValidator"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="depth?.errors?.pattern">
                            {{ (customerUnits.dp === 1 ? 'COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' : 'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS')
                            | translate }}
                        </div>
                    </div>
                    <div class="custom-25p">
                        <mat-form-field class="full-width">
                            <input
                                (input)="onDepthVelocityChange($event)"
                                matInput
                                placeholder="{{ 'COMMON.PLUS_MINUS' | translate }}"
                                [(ngModel)]="data.depthAccuracy"
                                name="depthpm"
                                id="depthpm"
                                #depthpm="ngModel"
                                [pattern]="numericGeneralPattern"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="depthpm?.errors?.pattern">
                            {{ 'COMMON.MUST_BE_POSITIVE_INCLUDING_ZERO' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="each-Item">
                <div class="custom-50p">
                    <div class="custom-75p">
                        <mat-form-field class="full-width">
                            <input
                                (input)="onDepthVelocityChange($event)"
                                (blur)="onDepthVelocitySiltAvgToPeakBlur()"
                                matInput
                                placeholder="{{ 'COMMON.MEASURED_PEAK_VELOCITY' | translate }} {{ velocityUnit }}"
                                [(ngModel)]="data.velocity"
                                name="velocity"
                                id="velocity"
                                #velocity="ngModel"
                                [pattern]="numericGeneralPattern"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="velocity?.errors?.pattern">
                            {{ 'COMMON.MUST_BE_POSITIVE_INCLUDING_ZERO' | translate }}
                        </div>
                    </div>
                    <div class="custom-25p">
                        <mat-form-field class="full-width">
                            <input
                                (input)="onDepthVelocityChange($event)"
                                matInput
                                placeholder="{{ 'COMMON.PLUS_MINUS' | translate }}"
                                [(ngModel)]="data.velocityAccuracy"
                                name="velocitypm"
                                id="velocitypm"
                                #velocitypm="ngModel"
                                [pattern]="numericGeneralPattern"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="velocitypm?.errors?.pattern">
                            {{ 'COMMON.MUST_BE_POSITIVE_INCLUDING_ZERO' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.FLOW' | translate }} {{ flowSmallerUnit }}"
                        [(ngModel)]="data.flow"
                        name="flow"
                        id="flow"
                        #flow="ngModel"
                        [pattern]="numericGeneralPattern"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="flow?.errors?.pattern">
                    {{ 'COMMON.MUST_BE_POSITIVE_INCLUDING_ZERO' | translate }}
                </div>
            </div>
        </div>
        <div class="custom-33p">
            <div class="each-Item">
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.CALCULATED_AVG_VELOCITY' | translate }} {{ velocityUnit }}"
                        [ngModel]="calculatedAvgVelocity | number: '1.' + customerUnits.vp + '-' + customerUnits.vp"
                        name="calculatedAvg"
                        disabled
                    />
                </mat-form-field>
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.CALCULATED_FLOW' | translate }} {{ flowUnit }}"
                        [ngModel]="data.calculatedFlow | number: '1.' + customerUnits.fp + '-' + customerUnits.fp"
                        name="calculatedFlow"
                        disabled
                    />
                </mat-form-field>
            </div>
        </div>
        <div class="full-width">
            <mat-form-field class="full-width">
                <input
                    matInput
                    placeholder="{{ 'COMMON.COMMENT' | translate }}"
                    [(ngModel)]="data.comment"
                    name="comment"
                    maxlength="255"
                />
            </mat-form-field>
        </div>
        <div class="full-width">
            <h1>{{ 'COMMON.MONITOR_READINGS' | translate }}</h1>
            <table>
                <thead>
                    <th></th>
                    <th>{{ 'COMMON.TIMESTAMP' | translate }}</th>
                    <th>{{ 'COMMON.DEPTH' | translate }} {{ depthUnit }} </th>
                    <th>{{ 'COMMON.DEPTH_DIFF' | translate }}</th>
                    <th>{{ 'COMMON.VELOCITY' | translate }} {{ velocityUnit }}</th>
                    <th>{{ 'COMMON.VELOCITY_DIFF' | translate }}</th>
                    <th>{{ 'COMMON.CALCULATED_GAIN' | translate }}</th>
                    <th>{{ 'COMMON.QUANTITY' | translate }} {{ quantityUnit }}</th>
                </thead>
                <tbody>
                    <tr *ngIf="!readings">
                        No Data Available
                    </tr>
                    <tr *ngFor="let r of readings">
                        <td>{{r.name}}</td>
                        <td>{{r.ts | date: customDateFormat}}</td>
                        <td>{{r.dr && r.dr !== 'NaN' && r.dr !== 'Infinity' ? (r.dr | number: '1.' + customerUnits.dp + '-' + customerUnits.dp) : '--'}}</td>
                        <td>{{r.dd && r.dd !== 'NaN' && r.dd !== 'Infinity' ? (r.dd | number: '1.' + customerUnits.dp + '-' + customerUnits.dp) : '--'}}</td>
                        <td>{{r.vr && r.vr !== 'NaN' && r.vr !== 'Infinity' ? (r.vr | number: '1.' + customerUnits.vp + '-' + customerUnits.vp) : '--'}}</td>
                        <td>{{r.vd && r.vd !== 'NaN' && r.vd !== 'Infinity' ? (r.vd | number: '1.' + customerUnits.vp + '-' + customerUnits.vp) : '--'}}</td>
                        <td>{{r.cg && r.cg !== 'NaN' && r.cg !== 'Infinity' ? (r.cg | number: '1.2-2') : '--'}}</td>
                        <td>{{r.qr && r.qr !== 'NaN' && r.qr !== 'Infinity' ? (r.qr | number: '1.' + customerUnits.fp + '-' + customerUnits.fp) : '--'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button
                [disabled]="!isValidConfirmationDate || isFlowLoading || depth?.errors || silt?.errors || averageToPeak?.errors || flow?.errors || velocity?.errors || depthpm?.errors || velocitypm?.errors"
                (click)="save()">{{ 'COMMON.SAVE_BUTTON' | translate }}</button>
        </div>
    </div>
</div>
