<app-loader [isLoading]="isLoading"></app-loader>
<div id="basin-qvi-pivot">
    <fm-pivot
        #pivot
        [hidden]="noData"
        [height]="'100%'"
        [toolbar]="true"
        [report]="report"
        (beforetoolbarcreated)="customizeToolbar($event)"
        [licenseKey]="license"
        >
    </fm-pivot>
</div>
<div *ngIf="noData">{{ 'SLIICER.FLOW_TAB.NO_STORMS_TO_DISPLAY' | translate }}</div>
