import { BROWN_COLOR, EDIT_COLOR, ORANGE_COLOR_HEX, RED_COLOR_HEX } from 'app/shared/constant';
import { DataEditingCurveTypes } from 'app/shared/models/data-edit';
import { AdvanceScatterOptions, ScatterSeries, ToleranceLine } from 'app/shared/models/scatter-data';
import { AnnotationSettings } from 'app/shared/models/view-data';

export const DATA_QUALITY = 15;
export const BEST_FIT = 'Bestfit';
export const STEVENS_CURVE = 'Stevens-Schutzbach';
export const COLEBROOK_CURVE = 'Colebrook-White: Regression';
export const MANNING_DESIGN_CURVE = 'Manning Design';
export const LANFEAR_COLL_CURVE = 'Manning: Lanfear-Coll';
export const MANUAL_LINEAR_CURVE = "Manual Linear";
export const MANUAL_SMOOTH_CURVE = "Manual Smooth";
export const bestfitColor = '#3D5AFE';
export const ssCurveColor = '#673AB7';
export const FLAGGED_POINT_COLOR = '#FF8C00';
export const DEFAULT_POINT_COLOR = 'rgba(119, 152, 191, .5)';
export const HOVER_COLOR = '#ffde03';
export const LINKED_COLOR = '#ec9706';
export const LINKED_BORDER_GREEN_COLOR = 'rgb(11 255 0)';
export const TOLERANCE_LINE = 'ToleranceLines';
export const SELECTED_COLOR = 'blue';
export const SELECTION_ZOOM_BOX_COLOR = 'rgba(51,92,173,0.25)';
export const SELECTION_ZOOM_BOX_INVISIBLE = 'rgba(0,0,0,0)';



export function sgCurveType(annotationSettings: AnnotationSettings, isSaveCurve?: boolean) {
    if (isSaveCurve) {
        return DataEditingCurveTypes.SavedCurve;
    } else if (annotationSettings.isBestFit) {
        return DataEditingCurveTypes.BestFit;
    } else if (annotationSettings.isSSCurve) {
        return DataEditingCurveTypes.StevensSchutzbach;
    } else if (annotationSettings.isManualLinear) {
        return DataEditingCurveTypes.ManualLinear;
    } else if (annotationSettings.isManualSmooth) {
        return DataEditingCurveTypes.ManualSmooth;
    } else if (annotationSettings.isCWRcurve) {
        return DataEditingCurveTypes.Colebrook;
    } else if (annotationSettings.isManningDesign) {
        return DataEditingCurveTypes.ManningDesign;
    } else if (annotationSettings.isLanfearColl) {
        return DataEditingCurveTypes.LanfearColl;
    }

    return null;
}

export const ScatterDataEntityIds = {
    Velocity: 4202,
    Unidepth: 4122,
    Dfinal: 2102,
    VFinal: 2203,
};

export enum ScattergraphSeriesNames {
    highlighted = 'highlightSeries',
    linked = 'linkedPoints',
    selected = 'selectedPoints',
    flagged = 'flaggedSeries',
    ignored = 'ignoredSeries',
    snapped = 'snappedPoints',
    previousSnapped = 'previousSnapped',
    edited = 'editedSeries',
    zoomed = 'zoomedPoints',
    scatter = 'scatterPoints',
    blank = 'blankSeries'
}

export const SCATTER_SERIES_NAMES = [
    ScattergraphSeriesNames.highlighted,
    ScattergraphSeriesNames.linked,
    ScattergraphSeriesNames.selected,
    ScattergraphSeriesNames.flagged,
    ScattergraphSeriesNames.ignored,
    ScattergraphSeriesNames.snapped,
    ScattergraphSeriesNames.previousSnapped,
    ScattergraphSeriesNames.edited,
    ScattergraphSeriesNames.zoomed,
    ScattergraphSeriesNames.scatter,
    ScattergraphSeriesNames.blank,
];

export const SGFindSeries = (series, serieType: ScattergraphSeriesNames): ScatterSeries => {
    return series.find(v => v.name === serieType);
}

export const defaultScatterSeries = {
    type: 'scatter',
    zIndex: 5,
    allowPointSelect: true,
    name: 'scatterPoints',
    marker: {
        radius: 4,
        symbol: 'diamond',
        states: {
            hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
                fillColor: HOVER_COLOR,
            },
            select: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
                lineWidth: 0.1,
                fillColor: undefined,
            },
        },
    },
    column: { groupPadding: 0, pointPadding: 0, pointWidth: 1, borderWidth: 0, shadow: false },
    line: { lineWidth: 0.5 },
    events: {},
    scatter: {
        marker: {
            radius: 5,
            enabled: true,
        },
    },
    turboThreshold: 1000000000,
    tooltip: {
        useHTML: true,
        headerFormat: '',
    },
};

export const advanceScatterOptions: AdvanceScatterOptions = {
    global: {
        useUTC: true,
    },
    boost: {
        enabled: true,
        allowForce: false,
    },
    lang: {
        contextButtonTitle: 'Print/Download',
    },
    exporting: {
        enabled: false,
        chartOptions: { chart: { events: false } },
        buttons: {
            contextButton: {
                symbol: 'url(assets/images/baseline-print-24px.svg)',
                x: 20,
                symbolSize: 24,
                theme: {
                    states: {
                        hover: {
                            fill: '#fff',
                        },
                        select: {
                            fill: '#fff',
                        },
                    },
                },
            },
        },
    },
    navigation: {
        buttonOptions: {
            verticalAlign: 'top',
        },
    },
    credits: false,
    chart: {
        selectionMarkerFill: SELECTION_ZOOM_BOX_COLOR,
        zoomType: 'xy',
        height: 600,
        panning: true,
        panKey: 'shift',
        animation: true,
        events: {
            // tslint:disable-next-line: cyclomatic-complexity
            load: () => {
                setTimeout(() => {});
            },
        },
    },
    plotOptions: {
        scatter: {
            turboThreshold: 5000,
            stickyTracking: false,
            point: {
                events: {},
            },
            allowPointSelect: true,
        },
        line: {
            marker: {
                enabled: false,
            },
        },
        series: {
            animation: false,
            boostThreshold: 1000,
            states: {
                inactive: {
                    opacity: 1,
                },
            },
        },
    },
    title: {
        text: '',
    },
    scrollbar: {
        enabled: false,
        liveRedraw: false,
    },
    navigator: { enabled: false, margin: 0 },
    series: [JSON.parse(JSON.stringify(defaultScatterSeries))],
    rangeSelector: {
        enabled: false,
    },
};

export function generateEntityInformationData(
    lineType: string,
    toleranceLine: ToleranceLine[],
    above: boolean,
    toleranceLinesBoth?: undefined,
) {
    return {
        width: 1.5,
        smoothCurve: lineType === 'spline' ? true : false,
        name: !toleranceLinesBoth ? (above ? 'ToleranceLines_1' : 'ToleranceLines_0') : 'ToleranceLines_1',
        type: lineType,
        data: toleranceLine,
        label: { enabled: false },
        color: 'red',
        dashStyle: 'longdash',
        zIndex: 80,
        lineWidth: 1.5,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        enableMouseTracking: false,
    };
}

export const scatterLegend = {
    useHTML: true,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemMarginBottom: -30,
    symbolWidth: 0,
    labelFormatter: function () {
        if (this.index === 0) {
            return `
      <table>
        <tr>
          <td style="background: #FF0000; padding: 7px;"></td>
          <td style="background: #FFFF00; padding: 7px;"></td>
          <td style="background: #FF00FF; padding: 7px;"></td>
          <td style="background: #0000ff; padding: 7px;"></td>
          <td style="background: #808000; padding: 7px;"></td>
          <td style="background: #008080; padding: 7px;"></td>
          <td style="background: #000080; padding: 7px;"></td>
          <td style="background: #800000; padding: 7px;"></td>
          <td style="background: #008000; padding: 7px;"></td>
          <td style="background: #00ff00; padding: 7px;"></td>
        </tr>
        <tr>
          <td style="padding: 6px;">1</td>
          <td style="padding: 6px;">2</td>
          <td style="padding: 6px;">3</td>
          <td style="padding: 6px;">4</td>
          <td style="padding: 6px;">5</td>
          <td style="padding: 6px;">6</td>
          <td style="padding: 6px;">7</td>
          <td style="padding: 6px;">8</td>
          <td style="padding: 6px;">9</td>
          <td style="padding: 6px;">10</td>
        </tr>
      <table>`;
        }
    },
};

export const ScattergraphSeries: { [key: string]: { zIndex: number, name: ScattergraphSeriesNames, color: string } } = {
    [ScattergraphSeriesNames.highlighted]: {
        zIndex: 10,
        name: ScattergraphSeriesNames.highlighted,
        color: HOVER_COLOR,
    },
    [ScattergraphSeriesNames.linked]: {
        zIndex: 9,
        name: ScattergraphSeriesNames.linked,
        color: LINKED_COLOR
    },
    [ScattergraphSeriesNames.selected]: {
        zIndex: 8,
        name: ScattergraphSeriesNames.selected,
        color: SELECTED_COLOR
    },
    [ScattergraphSeriesNames.flagged]: {
        zIndex: 7,
        name: ScattergraphSeriesNames.flagged,
        color: RED_COLOR_HEX
    },
    [ScattergraphSeriesNames.ignored]: {
        zIndex: 5,
        name: ScattergraphSeriesNames.ignored,
        color: BROWN_COLOR
    },
    [ScattergraphSeriesNames.snapped]: {
        zIndex: 9,
        name: ScattergraphSeriesNames.snapped,
        color: EDIT_COLOR
    },
    [ScattergraphSeriesNames.previousSnapped]: {
        zIndex: 9,
        name: ScattergraphSeriesNames.previousSnapped,
        color: EDIT_COLOR
    },
    [ScattergraphSeriesNames.edited]: {
        zIndex: 6,
        name: ScattergraphSeriesNames.edited,
        color: EDIT_COLOR
    },
    [ScattergraphSeriesNames.zoomed]: {
        zIndex: 6,
        name: ScattergraphSeriesNames.zoomed,
        color: ORANGE_COLOR_HEX
    },
    [ScattergraphSeriesNames.blank]: {
        zIndex: 1,
        name: ScattergraphSeriesNames.blank,
        color: DEFAULT_POINT_COLOR
    }
};

export const generateNewSeries = (name: ScattergraphSeriesNames) => {
    const defaultSeries = JSON.parse(JSON.stringify(defaultScatterSeries));

    return { ...defaultSeries, ...ScattergraphSeries[name] };
}
