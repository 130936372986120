import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'app/environments/environment';
import { UsersService } from 'app/pages/admin/users.service';
import { VaultCreateFolderComponent } from 'app/pages/vault/vault-create-folder/vault-create-folder.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { VaultFilesDeleteActionComponent } from '../vault-files-delete-action/vault-files-delete-action.component';
import { VaultFilesMoveActionComponent } from '../vault-files-move-action/vault-files-move-action.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { LoadSettingComponent } from '../load-setting/load-setting.component';
import { VaultItemShareComponent } from '../vault-item-share/vault-item-share.component';
import { AdsPrismVaultLocationExportComponent } from '../vault-location-export/vault-location-export.component';
import { TelemetryImportComponent } from '../vault-telemetry/vault-telemetry-import/vault-telemetry-import.component';
import { VaultUploadDialogComponent } from '../vault-upload-dialog/vault-upload-dialog.component';
import { VaultUploadLocationDialogComponent } from '../vault-upload-location-dialog/vault-upload-location-dialog.component';
import { VaultService } from '../vault.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { UnitOfMeasureType } from 'app/shared/constant';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TelemetryService } from 'app/shared/services/telemetry.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { SharedService } from 'app/shared/services/shared.service';
import { FileService } from 'app/shared/services/file.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { Config } from 'app/shared/services/config';
import {
    TelemetryImportDialogData,
    TelemetryImportResult,
    VaultDirectory,
    VaultFile,
    VaultLocationsWithXML,
    VaultRouteAbstarct,
    VaultShareDialogData,
    VaultSubDirectory,
    VaultUpload,
} from 'app/shared/models/vault';
import {
    activeInactiveLocationQueryParam,
    CSV,
    customerLocationGroupQueryParam,
    customerQueryParam,
    TELEMETRY,
    TELOG,
} from 'app/shared/models/customer';
import { MonitorAddLocationSelectable, MonitorIdentifier } from 'app/shared/models/monitorsetting';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { IDataTransfer } from 'app/shared/models/modal';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { FileType, IFileView } from 'app/shared/models/file-view';
import { ExportSetting } from 'app/shared/models/telemetry';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FileViewComponent } from 'app/shared/components/file-view/file-view.component';
import { delay, first, map, startWith } from 'rxjs/operators';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SignalRMessage, SignalRMessageType, VAULT_MOVE_FAIL_MESSAGE, VAULT_MOVE_SUCCESS_MESSAGE } from 'app/shared/models/signalr-message';
import { SignalRService } from 'app/shared/services/signalr.service';
import _ from 'lodash';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
const EXCEL_FILE_EXTENSION = 'xlsx';
const ACTIVE = 'active';
const SUCCESSFUL = 'SUCCESSFUL';

const ITEMS_PER_PAGE = 40;
const DATA_EXPORTS_DIRECTORY = '/dataExports/';
const SLIICER_FOLDER_NAME = 'SLiiCER';
const SITE_DOCUMENTS_FOLDER_NAME = 'SiteDocuments';

@Component({
    selector: 'app-vault-dashboard',
    templateUrl: './vault-dashboard.component.html',
    styleUrls: ['./vault-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VaultDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('resizable') public resizable: ElementRef<HTMLDivElement>;
    /**
     * Access & clear the value of search box control
     */
    @ViewChild('searchCtrl') public searchCtrl: ElementRef;
    /**
     * Represents the observable that will hold the colleciton of files and folders.
     */
    public vaultData: Observable<VaultDirectory>;

    /**
     * Represents the collection of files that are contained in the currently selected directory.
     * For the initial state, this will be root, so files belonging to the root folder will be
     * contained within this collection.
     */
    public containingFiles = new Observable<Array<VaultFile>>();

    /**
     * Represents the selected customer;
     */
    public selectedCustomerId: number;

    /**
     * Represents the selected directory
     */
    public currentlySelectedDirectoryId: Observable<string>;

    /**
     * Indicates that a loading event is in progress.
     */
    public isLoading = true;

    /**
     * Represents the subscription to the Customer Change observable.
     */
    private subscriptions = new Array<Subscription>();

    /**
     * Represents the api endpoint host portion of the URI.
     */
    public readonly apiUri = environment.serviceUrl;

    public selectedVaultFile: VaultFile;

    public vaultDirectoryDisplayedColumns = ['fileName', 'fileExtension', 'file_size', 'modifiedDate', 'actions'];
    public vaultDirectoryFilterColumns = ['fileName', 'fileExtension', 'file_size', 'modifiedDate'];
    public vaultDirectoryDataSource: MatTableDataSource<VaultFile>;
    @ViewChild(MatPaginator) public vaultDirectoryPaginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) public vaultDirectorySort: MatSort;
    @ViewChild('vaultList') public vaultList: ElementRef<HTMLDivElement>;
    @ViewChild('panel') public foldersPanel: MatExpansionPanel;
    @ViewChildren('imageFileThumbnail') public imageFileThumbnail: QueryList<HTMLImageElement>;
    @ViewChild('filesContainer') public filesContainer: ElementRef<HTMLDivElement>;
    public vaultDirectoryDataChange: BehaviorSubject<VaultFile[]> = new BehaviorSubject<VaultFile[]>([]);
    private setIntialSort: boolean;
    public get vaultDirectoryData(): VaultFile[] {
        return this.vaultDirectoryDataChange.value;
    }
    public totalPaginationLength: number;
    public containingFilesData = new Array<VaultFile>();
    public containingSubDirectories = new Array<VaultSubDirectory>();
    public subDirectories = new Array<VaultSubDirectory>();
    public filteredFiles: Observable<VaultFile[]>;
    public fileCtrl = new FormControl();
    public containingAllFiles = new Array<VaultFile>();
    public vaultDirectory: VaultDirectory;
    public selectedDirectoryId: string;
    public uploadVaultFile: VaultFile;
    public dateFormat: string;
    public timeFormat: string;
    public pageSize = 10;
    public pageIndex = 0;
    public paginatedFiles: VaultFile[] = [];
    public minViewSize = 10;

    /**
     * flag to show/hide uploaded location details
     */
    public showLocationDetails = false;

    /**
     * list of uploaded locations via xml
     */
    public uploadedLocations: Array<VaultLocationsWithXML>;

    /**
     * list of uploaded locations via xml
     */
    public allUploadedLocations: Array<VaultLocationsWithXML>;

    /**
     * flag to show/hide location name on panel header
     */
    public hideLocationName = true;

    /**
     * Represents the rain guage FormControl.
     */
    public assignedRainGaugeCtrl = new FormControl();

    /**
     * Represents the filtered Assigned Rain guage.
     */
    public filteredassignedRainGauge: Array<Locations>;

    /**
     * List of locations with series as rain
     */
    public rainGaugeLocations: Array<Locations>;

    /**
     * Selected Location Group from top nav by user
     */
    public selectedLocGroupIdentifier: number;

    /**
     * Flag to check if user has selected all/active locations
     */
    public includeInactiveLocations: boolean;

    /**
     * List of series
     */
    public monitorSeriesUI: Array<MonitorAddLocationSelectable>;

    /**
     * Flag to enable/disable Import button
     */
    public disableImport = true;

    /**
     * Reg Ex. Pattern for latitude
     */
    public latitudePattern = REGEX_CONFIG.latitudeZeroPattern;

    /**
     * Reg Ex. Pattern for longitude
     */
    public longitudePattern = REGEX_CONFIG.longitudeZeroPattern;

    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    /**
     * Reg Ex. Pattern for Inputs should be numeric values between 0 and 100000 with up to 2 decimal places
     */
    public installationValuePattern = REGEX_CONFIG.installationValuePattern;

    /**
     * flag to show/hide location import
     */
    public showLocationImport = false;

    public availablePageHint = false;

    public disableFolderEditing: boolean;
    public navigationConfirmationMsg: string;
    public dismissText: string;
    public noFileMsg: string;
    public csvUploadFilesErr: string;
    public filesUploadErr: string;
    public filesUploadSuccessMsg: string;
    public chooseCsvFileMsg: string;
    public chooseFilesMsg: string;
    public xmlUploadErrMsg: string;
    public invalidXmlErr: string;
    public invalidLocationSerialErr: string;
    public removedSuccessText: string;
    public deleteDialogTitle: string;
    public deleteDialogMsg: string;
    public deleteDialogFolderMessage: string;
    public importSuccessMsg: string;
    public importErrMsg: string;
    public cancelDialogTitle: string;
    public cancelBtn: string;
    public deleteBtn: string;
    public cancelText: string;
    public discardText: string;
    public fileText: string;
    public folderText: string;
    public commonServerErrorMessage: string;
    public exportedFileStatus: boolean;
    public isVaultDeletePermission: boolean;

    public isListMode = false;
    public selectedValue: string;

    public navClosed: boolean;
    public breadcrumbDirectories: Array<VaultSubDirectory>;
    public isMonitorCanAddEdit: boolean;

    public enableMultiAction: boolean;
    public enableMultiImportAction: boolean;

    private metricUnitsText: string;
    private manholeDepthText: string;
    private usUnitsFromText: string;
    public manholeTextWithUnit: string;

    public isUploadEnabled = true;
    public fileUploadMonitorSeries: string;

    public restrictedFolderNames = [SLIICER_FOLDER_NAME, SITE_DOCUMENTS_FOLDER_NAME];
    public translateKeys: Array<string> = [
        'VAULT.VAULT_DASHBOARD.NAVIGATION_MSG',
        'VAULT.VAULT_DASHBOARD.NO_FILE_SELECTED',
        'VAULT.VAULT_DASHBOARD.CSV_FILE_UPLOAD_ERR',
        'VAULT.VAULT_DASHBOARD.FILE_UPLOAD_NUMBER_ERR',
        'VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS',
        'VAULT.VAULT_DASHBOARD.CHOOSE_CSV_MSG',
        'VAULT.VAULT_DASHBOARD.CHOOSE_FILES_MSG',
        'VAULT.VAULT_DASHBOARD.XML_FILES_ERR',
        'VAULT.VAULT_DASHBOARD.INVALID_XML',
        'VAULT.VAULT_DASHBOARD.INVALID_LOCATION_SERIAL',
        'VAULT.VAULT_DASHBOARD.REMOVED_SUCCESSFULY',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_TITLE',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_MSG',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_FOLDER_MSG',
        'VAULT.VAULT_DASHBOARD.IMPORT_SUCCESS',
        'VAULT.VAULT_DASHBOARD.IMPORT_FAILED',
        'VAULT.VAULT_DASHBOARD.CANCEL_DIALOG_TITLE',
        'COMMON.DISMISS_TEXT',
        'COMMON.CANCEL_BUTTON',
        'COMMON.DELETE_TOOLTIP',
        'COMMON.CANCEL_TEXT',
        'COMMON.DISCARD_TEXT',
        'COMMON.FILE_TEXT',
        'COMMON.FOLDER_TEXT',
        'COMMON.COMMON_SERVER_ERROR_MESSAGE',
        'HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS',
    ];
    public routeData: VaultRouteAbstarct;

    public recentExportSettings: ExportSetting[];

    public selectAllFiles = false;
    private needToScrollToSelectedFile = false;

    /** Resize Div, X position on drag start. */
    private resizeStartX = null;
    /** Resize Div, Initial width of resized component. */
    private resizeOrigWidth = null;

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        public vaultService: VaultService,
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private fileService: FileService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private locationGroupServices: LocationGroupService,
        private locationDashboardService: LocationDashboardService,
        private userService: UsersService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        private snackBarNotificationService: SnackBarNotificationService,
        private telemetryService: TelemetryService,
        private customerService: CustomerService,
        public signalRService: SignalRService,
    ) {
        const translatObservable = translate.get(this.translateKeys);

        const translateSubs = translatObservable.subscribe((translateValues: Array<string>) => {
            this.navigationConfirmationMsg = translateValues['VAULT.VAULT_DASHBOARD.NAVIGATION_MSG'];
            this.noFileMsg = translateValues['VAULT.VAULT_DASHBOARD.NO_FILE_SELECTED'];
            this.csvUploadFilesErr = translateValues['VAULT.VAULT_DASHBOARD.CSV_FILE_UPLOAD_ERR'];
            this.filesUploadErr = translateValues['VAULT.VAULT_DASHBOARD.FILE_UPLOAD_NUMBER_ERR'];
            this.filesUploadSuccessMsg = translateValues['VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS'];
            this.chooseCsvFileMsg = translateValues['VAULT.VAULT_DASHBOARD.CHOOSE_CSV_MSG'];
            this.chooseFilesMsg = translateValues['VAULT.VAULT_DASHBOARD.CHOOSE_FILES_MSG'];
            this.xmlUploadErrMsg = translateValues['VAULT.VAULT_DASHBOARD.XML_FILES_ERR'];
            this.invalidXmlErr = translateValues['VAULT.VAULT_DASHBOARD.INVALID_XML'];
            this.invalidLocationSerialErr = translateValues['VAULT.VAULT_DASHBOARD.INVALID_LOCATION_SERIAL'];
            this.removedSuccessText = translateValues['VAULT.VAULT_DASHBOARD.REMOVED_SUCCESSFULY'];
            this.deleteDialogTitle = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_TITLE'];
            this.deleteDialogMsg = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_MSG'];
            this.deleteDialogFolderMessage = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_FOLDER_MSG'];
            this.importSuccessMsg = translateValues['VAULT.VAULT_DASHBOARD.IMPORT_SUCCESS'];
            this.importErrMsg = translateValues['VAULT.VAULT_DASHBOARD.IMPORT_FAILED'];
            this.cancelDialogTitle = translateValues['VAULT.VAULT_DASHBOARD.CANCEL_DIALOG_TITLE'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.cancelBtn = translateValues['COMMON.CANCEL_BUTTON'];
            this.deleteBtn = translateValues['COMMON.DELETE_TOOLTIP'];
            this.cancelText = translateValues['COMMON.CANCEL_TEXT'];
            this.discardText = translateValues['COMMON.DISCARD_TEXT'];
            this.fileText = translateValues['COMMON.FILE_TEXT'];
            this.folderText = translateValues['COMMON.FOLDER_TEXT'];
            this.commonServerErrorMessage = translateValues['COMMON.COMMON_SERVER_ERROR_MESSAGE'];
            this.manholeDepthText = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH'];
            this.metricUnitsText = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS'];
            this.usUnitsFromText = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS'];
        });

        this.subscriptions.push(translateSubs);

        this.vaultService.isLocationVault = false;
        this.subscriptions.push(this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.routeData = this.router.getCurrentNavigation().extras.state;
                this.changeDirectory();
                this.downloadFile();
            }
        }));

        const activatedRouteSub = this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                const selectedCustomerIdentifier = Number(params.get(customerQueryParam));
                this.selectedLocGroupIdentifier = Number(params.get(customerLocationGroupQueryParam));

                // get updated locations
                this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

                // fetch new customers for change
                if (selectedCustomerIdentifier !== this.selectedCustomerId) {
                    this.selectAllFiles = false;
                    this.onCustomerChange(this.selectedCustomerId === undefined);
                }

                this.selectedCustomerId = selectedCustomerIdentifier;
                forkJoin(this.customerService.getCustomerById(this.selectedCustomerId), translatObservable).subscribe(
                    ([customer]) => {
                        const isMetric: boolean =
                            customer.unitsType && Number(customer.unitsType) === UnitOfMeasureType.METRIC;
                        const depthUnit = isMetric ? this.metricUnitsText : this.usUnitsFromText;
                        this.manholeTextWithUnit = `${this.manholeDepthText} (${depthUnit})`;
                    },
                );

                this.vaultData = this.vaultService.Vault;

                // check if 'exported' key exists in query params, it should be set
                // by telemetry export,  if it does then redirect immediately.
                // the redirect unfortunatly has to happen as currently there is no
                // way to remove a query param from the route, but there is a pending
                // issue about that: https://github.com/angular/angular/issues/17202
                if (this.activatedRoute.snapshot.queryParams.exported) {
                    // redirect
                    this.router.navigate([], <NavigationExtras>{
                        queryParams: { c: selectedCustomerIdentifier, lg: this.selectedLocGroupIdentifier },
                    });
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(activatedRouteSub);
        // get custom data if passed on route change
        this.routeData = this.router.getCurrentNavigation().extras.state;
    }

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized
     */
    public ngOnInit() {
        this.subscriptions.push(this.signalRService.messageReceived.subscribe((res: SignalRMessage) => {
            if (res.type === SignalRMessageType.User && (res.message === VAULT_MOVE_SUCCESS_MESSAGE || res.message === VAULT_MOVE_FAIL_MESSAGE)) {
                this.vaultService.getRootVaultListForCustomer().subscribe();
            }
        }));
        // check permission for Vault Delete feature
        this.subscriptions.push(
            this.userService.isVaultDelete.subscribe((response: boolean) => (this.isVaultDeletePermission = response)),
        );
        this.selectedValue = '1';
        this.timeFormat = this.dateutilService.getTimeFormat();
        this.dateFormat = this.dateutilService.getFormat();
        this.userService.isMonitorEditor.subscribe((response) => {
            this.isMonitorCanAddEdit = response;
        });
        this.userService.isLocationImportAllowed.subscribe(
            (response: boolean) => {
                if (response) {
                    this.showLocationImport = true;
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(
            this.vaultService.reportGenerated.subscribe(() => {
                this.importLocations();
            }),
        );

        // clear search box after navigation in directories
        const subscribe = this.sharedService.currentClearValue.subscribe(
            (response: boolean) => {
                if (response) {
                    this.searchClear();
                    this.sharedService.changeMessage(false);
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(subscribe);

        this.isLoading = true;

        // disable location group selector in top-nav
        this.locationGroupServices.locationGroupDisabledStatus = true;
        // setup observable for directory change notifications
        this.currentlySelectedDirectoryId = this.vaultService.CurrentDirectory;

        // set root as current directory
        this.vaultService.setCurrentDirectory('/');

        // setup initial data load
        this.vaultData = this.vaultService.Vault;

        this.subscriptions.push(
            this.vaultData.subscribe(
                (vault: VaultDirectory) => {
                    // ensure args
                    this.isLoading = this.vaultService.isVaultLoading;
                    if (this.vaultService.isServerError) {
                        this.snackBarNotificationService.raiseNotification(
                            this.commonServerErrorMessage,
                            this.dismissText,
                            {
                                panelClass: 'custom-error-snack-bar',
                            },
                            false,
                        );
                    }
                },
                (error) => {
                    // Error Block to handle errors
                    this.isLoading = false;
                },
            ),
        );

        this.containingFiles = this.vaultService.CurrentFiles;
        this.subscriptions.push(this.vaultService.CurrentFiles.subscribe(
            (files: Array<VaultFile>) => {
                if (files && files.length &&
                    ((this.containingFilesData && !_.isEqual(this.containingFilesData,files)) || (!this.containingFilesData))
                ) {
                    this.containingFilesData = files;
                    if (!this.fileCtrl || this.fileCtrl.value === '') {
                        this.selectedVaultFile = undefined;
                    }
                    // highlight uploaded file in table
                    if (this.uploadVaultFile) {
                        this.selectedVaultFile = this.uploadVaultFile;
                        this.fileCtrl.setValue('');
                    }
                    this.generateValutItemTable(this.selectedVaultFile);
                } else if (files && files.length === 0) {
                    this.vaultDirectoryDataSource = null;
                    this.containingFilesData = null;
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        ));

        this.subscriptions.push(this.vaultService.currentSubDirectory.subscribe(
            (subDirectors: Array<VaultSubDirectory>) => {
                this.containingSubDirectories = subDirectors;
                if(this.isListMode)
                {
                    this.setPagination(this.subDirectories,this.containingSubDirectories);
                }
                else
                {
                    this.subDirectories = this.containingSubDirectories;
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        ));
        this.subscriptions.push(this.vaultData.subscribe(
            (vault: VaultDirectory) => {
                this.vaultDirectory = vault;
                this.generateAutoCompleteForAllFiles();
            },
            (error) => {
                // Error Block to handle errors
            },
        ));
        this.subscriptions.push(this.vaultService.BreadcrumbDirectories.subscribe(
            (directories) => {
                if (directories) {
                    this.breadcrumbDirectories = directories;
                    this.disableFolderEditing = this.breadcrumbDirectories
                        .some(x => x.name === SLIICER_FOLDER_NAME || x.name === SITE_DOCUMENTS_FOLDER_NAME);
                }
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        ));
        // initialize location service parameter
        const locationArgs = <LocationArgs>{
            customerId: this.selectedCustomerId,
            locationGroupId: this.selectedLocGroupIdentifier,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        // setup API call to retrieve fresh locations

        const locationsSubscription = this.locationDashboardService.getLocations(locationArgs).subscribe(
            (result: Array<Locations>) => {
                // ensure response
                if (!result) {
                    return;
                }

                // set locations
                this.rainGaugeLocations = result;

                this.filteredassignedRainGauge = this.rainGaugeLocations.filter(
                    (location: Locations) => location.installationType === 5,
                );

                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(locationsSubscription);
        this.monitorSeriesUI = <Array<MonitorAddLocationSelectable>>[
            { monitorSeriesID: MonitorIdentifier.TritonPlus, value: 'TRITON+', text: 'TRITON+' },
            { monitorSeriesID: MonitorIdentifier.Echo, value: 'ECHO', text: 'ECHO' },
            { monitorSeriesID: MonitorIdentifier.RainAlertIII, value: 'RainAlert III', text: 'RainAlert III' },
        ];

        // Checks if page hint is available
        const hintSubs = this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;

            if (result) {
                this.vaultList.nativeElement.scrollTo(0, 0);

                if (this.foldersPanel) {
                    this.foldersPanel.close();
                }
            }
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        });

        const overlaySubs = this.domOperationUtilsService.vaultOverlayNumber.subscribe((overlay: number) => {
            if (!this.availablePageHint) return;

            this.isListMode = overlay > 1;
            this.selectedValue = this.isListMode ? '0' : '1';
            if (overlay === 4) {
                if (!this.domOperationUtilsService.vaultDialog) {
                    this.exportLocation();
                }

                this.domOperationUtilsService.vaultDialog.afterOpened().pipe(first(), delay(1000)).subscribe(() => {
                    this.domOperationUtilsService.pickVaultHint();
                })
            } else {
                if (this.domOperationUtilsService.vaultDialog) {
                    this.domOperationUtilsService.vaultDialog.close();
                }
                this.domOperationUtilsService.pickVaultHint();
            }
        });
        this.subscriptions.push(hintSubs);
        this.subscriptions.push(overlaySubs);

        this.changeDirectory();

        this.telemetryService.settingChanged.subscribe((result: string) => {
            if (result === 'yes') {
                this.getRecentExportSettings();
            }
        });
        this.getRecentExportSettings();
    }

    public downloadFile() {
        if (this.routeData && this.routeData.filePath) {

            setTimeout(() => {
                const files = [];
                files.push(this.routeData.filePath);
                this.fileDownload(files);
            }, 2000);
        }
    }

    public changeDirectory() {
        if (this.routeData && this.routeData.folderId) {
            this.selectEachDirectory(this.routeData.folderId);
        }
    }

    // Hide show side nav vault folder
    public vaultSidenavToggle() {
        this.navClosed = !this.navClosed;
    }

    public getRecentExportSettings() {
        this.telemetryService.settingChanged.next('no');
        this.isLoading = true;
        const recentExportSettingsSubscribe = this.telemetryService.getRecentExportSettings().subscribe(
            (result) => {
                this.recentExportSettings = [];
                if (result && result.length > 0) {
                    result.forEach((item, index) => {
                        const data: ExportSetting = {
                            name: item.name,
                            locationGroupId: item.locationGroupId || 0,
                            lids: item.lids || [],
                            eids: item.eids || [],
                            includeMissingTimestamps: item.includeMissingTimestamps,
                            missingTimestampValue: item.missingTimestampValue,
                            interval: item.interval,
                            entityPerColumn: item.entityPerColumn,
                            singleFile: item.singleFile,
                            quickSpan: item.quickSpan,
                            startDate: item.startDate,
                            endDate: item.endDate,
                            exportType: item.exportType,
                            excelStyle: item.excelStyle,
                            useRounding: item.useRounding
                        };
                        this.recentExportSettings.push(data);
                        this.uiUtilsService.safeChangeDetection(this.changeDetector);
                    });
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );
        this.subscriptions.push(recentExportSettingsSubscribe);
    }

    public getSelectedExportSetting(getSelectedExportSetting) {
        this.telemetryService.openExistingExport = true;
        this.dialog
            .open(AdsPrismVaultLocationExportComponent, {
                disableClose: true,
                data: getSelectedExportSetting,
            })
            .afterClosed()
            .subscribe((result) => { });
    }
    public loadSetting() {
        this.dialog
            .open(LoadSettingComponent, {
                disableClose: true,
            })
            .afterClosed()
            .subscribe();
    }
    /**
     * Function will use to filter value of locations.
     * @param assignedRainGauge
     */
    private filterAssignedRainGauge(assignedRainGauge: string) {
        return this.rainGaugeLocations.filter(
            (rainGauge) => rainGauge.locationName.toLowerCase().indexOf(assignedRainGauge.toLowerCase()) === 0,
        );
    }

    /**
     * Lifecycle hook that is called when a directive, pipe or service is destroyed.
     */
    public ngOnDestroy() {
        // re-enable location group selector in top-nav
        this.locationGroupServices.locationGroupDisabledStatus = false;

        // unsubscribe from customer change event
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public exportLocation() {
        this.telemetryService.openExistingExport = false;
        this.domOperationUtilsService.vaultDialog = this.dialog
            .open(AdsPrismVaultLocationExportComponent, {
                disableClose: true,
                data: '',
                hasBackdrop: false
            });
        this.domOperationUtilsService.vaultDialog
            .afterClosed()
            .subscribe((result) => {
                this.domOperationUtilsService.vaultDialog = null;
            });
    }

    /**
     * Route guard deciding if a route can be deactivated
     */
    public canDeactivate(): boolean {
        if (this.showLocationDetails) {
            const confirmation = window.confirm(this.navigationConfirmationMsg);
            if (!confirmation) {
                this.domOperationUtilsService.selectedHintPageName.next('noHintVaultclass');
            }
            return confirmation;
        } else {
            return true;
        }
    }

    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public directorySelected(id: string): void {
        this.vaultService.getFilesForDirectory(id);
        this.vaultDirectoryPaginator.pageIndex = 0;
    }
    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public selectEachDirectory(id: string): void {
        this.selectAllFiles = false;
        setTimeout(() => {
            this.adjustDrawerSliderHeight();
        }, 0);


        this.vaultService.getFilesForDirectory(id);
        this.vaultService.selectedDirectory.next(id);
        this.breadcrumbDirectories = this.vaultService.breadcrumbDirectories;
        this.isUploadEnabled = !id || !id.startsWith(DATA_EXPORTS_DIRECTORY);
        this.setDefaultPaginator();
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }


    private adjustDrawerSliderHeight() {
        const dragger = document.querySelector('.rg-right') as HTMLDivElement;
        const filesTree = document.querySelector('.file-tree') as HTMLDivElement;
        const drawer = document.querySelector('.file-tree-container') as HTMLElement;

        const draggerHeight = Math.max(filesTree.offsetHeight, drawer.offsetHeight);

        if (dragger) {
            dragger.style.height = `${draggerHeight}px`;
        }
    }


    /**
     * Represents the handler for opening the Upload window.
     * @param Represents the MouseEvent.
     */
    public openUploadDialog($event: MouseEvent, fileType?: string): void {
        // check if telemetry  dialog is not open, then open export dialog
        if (this.activatedRoute.snapshot.firstChild) {
            return;
        }

        // setup initial url
        let url = `${Config.urls.vaultUpload}?cid=${this.selectedCustomerId}`;

        let currentlySelectedDirectory = '';
        if (fileType === 'csv') {
            url = url + '&uploadType=2';
        }
        this.vaultService.CurrentDirectory.subscribe(
            (directory: string) => (currentlySelectedDirectory = directory),
            (error) => {
                // Error Block to handle errors
            },
        ).unsubscribe();

        if (currentlySelectedDirectory.length > 0) {
            if (currentlySelectedDirectory === '/') {
                url = `${url}&uri=//`;
            } else {
                url = `${url}&uri=${currentlySelectedDirectory}`;
            }
        }

        const uploadDialog = this.dialog.open(VaultUploadDialogComponent, {
            data: <IDataTransfer>{
                id: url,
                currentlySelectedDirectory: currentlySelectedDirectory,
                fileType: fileType,
            },
            panelClass: 'file-upload-background-panel',
            disableClose: true,
        });

        // setup dialog close handler subscription
        const postUploadSubscription = uploadDialog.afterClosed().subscribe(
            (vaultUpload: VaultUpload | string) => {
                // ensure args

                // if upload fails
                if (typeof vaultUpload === 'string') {
                    return;
                }

                if (vaultUpload) {
                    // if files have errors or no file selected
                    if (vaultUpload.uploadedFileCount === undefined || vaultUpload.uploadedFileCount === 0) {
                        const message = this.noFileMsg;
                        // display message for user
                        /* this.snackBar.open(message, 'Dismiss', {
                duration: 3000
              }); */
                        this.snackBar.open(message, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        return;
                    }

                    // if files have errors
                    if (vaultUpload.uploadedFileCount > 25) {
                        let message;
                        if (fileType === 'csv') {
                            message = this.csvUploadFilesErr;
                        } else {
                            message = this.filesUploadErr;
                        }
                        // display message for user
                        /* this.snackBar.open(message, 'Dismiss', {
                duration: 3000
              }); */
                        this.snackBar.open(message, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        return;
                    }

                    // display message for user when files uploaded successfully
                    this.snackBar.open(
                        `${vaultUpload.uploadedFileCount} ${this.filesUploadSuccessMsg}`,
                        this.dismissText,
                        {
                            duration: 10000,
                        },
                    );

                    // initiate pre-loader
                    this.isLoading = true;

                    if (vaultUpload && vaultUpload.uploadedFileCount === 1) {
                        const uploadFile: VaultFile = {
                            directoryAndFileName: vaultUpload.directoryAndFileName,
                            fileName: vaultUpload.fileName,
                        };
                        this.uploadVaultFile = uploadFile;
                    }

                    // get the latest vault data for current customer and stop preloader
                    this.vaultService
                        .getRootVaultListForCustomerNoAPI(JSON.parse(vaultUpload.response).root) //JSON.parse(vaultUpload.response).root
                        .subscribe(
                            () => {
                                this.isLoading = false;
                            },
                            (error) => {
                                // Error Block to handle errors
                            },
                        )
                        .unsubscribe(); // immediately unsubscribe

                    const telemetryData = [];
                    if (
                        fileType &&
                        fileType.toUpperCase() === CSV.toUpperCase() &&
                        vaultUpload.uploadedFileCount &&
                        vaultUpload.uploadedFileCount > 0
                    ) {
                        vaultUpload.uploadedFiles.forEach((file) => {
                            const data = {
                                id: file,
                                fileName: file.substring(file.lastIndexOf('/') + 1),
                                import: false,
                            };
                            telemetryData.push(data);
                        });
                        this.importTelemetryFiles(telemetryData, true);
                    }
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        // add subscription to subscription collection for cleanup
        this.subscriptions.push(postUploadSubscription);

        if (fileType === 'csv') {
            this.sharedService.setFileUploadMessage(this.chooseCsvFileMsg);
        } else {
            this.sharedService.setFileUploadMessage(this.chooseFilesMsg);
        }
    }

    /**
     * Represents the handler for opening the Upload location window.
     * @param Represents the MouseEvent.
     */
    public openLocationUploadDialog($event: MouseEvent): void {
        // check if telemetry  dialog is not open, then open export dialog
        if (this.activatedRoute.snapshot.firstChild) {
            return;
        }

        // setup initial url
        let url = `${Config.urls.vaultUploadLocation}?cid=${this.selectedCustomerId}`;

        let currentlySelectedDirectory = '';

        this.vaultService.CurrentDirectory.subscribe(
            (directory: string) => (currentlySelectedDirectory = directory),
            (error) => {
                // Error Block to handle errors
            },
        ).unsubscribe();

        if (currentlySelectedDirectory.length > 0) {
            if (currentlySelectedDirectory === '/') {
                url = `${url}&uri=//`;
            } else {
                url = `${url}&uri=${currentlySelectedDirectory}`;
            }
        }

        const uploadDialog = this.dialog.open(VaultUploadLocationDialogComponent, {
            data: <IDataTransfer>{
                id: url,
                currentlySelectedDirectory: currentlySelectedDirectory,
            },
            panelClass: 'file-upload-background-panel',
            disableClose: true,
        });

        // setup dialog close handler subscription
        const postUploadSubscription = uploadDialog.afterClosed().subscribe(
            (vaultUpload: VaultUpload | string) => {
                // if upload fails
                if (typeof vaultUpload === 'string') {
                    return;
                }

                // ensure args
                if (
                    vaultUpload &&
                    (vaultUpload.uploadedFileCount === undefined ||
                        vaultUpload.uploadedFileCount === 0 ||
                        vaultUpload.uploadedFileCount > 25)
                ) {
                    /* this.snackBar.open('You can not upload more than 25 XML files', 'Dismiss', {
                    duration: 3000
                    }); */
                    this.snackBar.open(this.xmlUploadErrMsg, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });

                    return;
                }

                if (vaultUpload && vaultUpload.uploadedFileCount === 1) {
                    if (vaultUpload.uploadedLocations.find(loc => loc.validation === 'InvalidSerialNumber')) {
                        this.disableImport = true;
                        this.snackBar.open(this.invalidLocationSerialErr, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                    else {
                        const uploadFile: VaultFile = {
                        directoryAndFileName: vaultUpload.directoryAndFileName,
                        fileName: vaultUpload.fileName,
                        };
                        this.uploadVaultFile = uploadFile;
                    }

                }

                if (vaultUpload) {
                    this.uploadedLocations = vaultUpload.uploadedLocations;
                    this.allUploadedLocations = this.uploadedLocations;
                    this.vaultService
                        .getRootVaultListForCustomer()
                        .subscribe(
                            (response) => {
                                this.isLoading = false;
                            },
                            (error) => {
                                // Error Block to handle errors
                            },
                        )
                        .unsubscribe(); // immediately unsubscribe
                }

                if (
                    vaultUpload &&
                    vaultUpload.uploadedLocations &&
                    vaultUpload.uploadedLocations.length > 0 &&
                    vaultUpload.uploadedLocations[0].validation !== 'InvalidXML' &&
                    vaultUpload.uploadedLocations[0].validation !== 'InvalidSerialNumber'
                ) {
                    // display message for user
                    this.snackBar.open(
                        `${vaultUpload.uploadedFileCount} ${this.filesUploadSuccessMsg}`,
                        this.dismissText,
                        {
                            duration: 10000,
                        },
                    );
                    this.showLocationDetails = true;
                    this.domOperationUtilsService.selectedHintPageName.next('noHintVaultclass');
                    this.disableImport = this.uploadedLocations.find((loc) => loc.isExistingLocation === true)
                        ? true
                        : false;
                } else if (vaultUpload.uploadedLocations[0].validation === 'InvalidXML') {
                    this.disableImport = true;
                    /* this.snackBar.open(`Invalid XML`, 'Dismiss', {
              duration: 3000
            }); */
                    this.snackBar.open(this.invalidXmlErr, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                }
            },
            (error) => {
                // Error Block to handle errors
            },
        );
        // add subscription to subscription collection for cleanup
        this.subscriptions.push(postUploadSubscription);
    }

    public setDefaultPaginator()
    {
        this.pageSize = 10;
        this.pageIndex = 0;

        if(this.vaultDirectoryPaginator && this.vaultDirectoryDataSource)
        {
            this.vaultDirectoryPaginator.pageSize = this.pageSize;
            this.vaultDirectoryPaginator.pageIndex = this.pageIndex;

            this.vaultDirectoryDataSource.paginator = this.vaultDirectoryPaginator;
        }
    }

    public importLocationData(event: MouseEvent, file: VaultFile) {
        if (file.uri) {
            this.isLoading = false;
            const filePath = file.uri.substring(1);
            const importLocationDataSubscription = this.vaultService.getLocationXmlFileDetails(filePath).subscribe(
                (result) => {
                    if (result) {
                        const uploadedLocations = [];
                        uploadedLocations.push(result);
                        if (
                            uploadedLocations &&
                            uploadedLocations[0] &&
                            uploadedLocations[0].validation.toUpperCase() === SUCCESSFUL
                        ) {
                            this.uploadedLocations = uploadedLocations;
                            this.disableImport = this.uploadedLocations.find((loc) => loc.isExistingLocation === true)
                                ? true
                                : false;
                            this.showLocationDetails = true;
                        } else {
                            this.snackBarNotification(this.invalidXmlErr, 3000, 'custom-error-snack-bar', false);
                        }
                    }
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                },
                (error) => {
                    // Error Block to handle errors
                    this.snackBarNotification(error.error, 3000, 'custom-error-snack-bar', false);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                },
            );

            this.subscriptions.push(importLocationDataSubscription);
        }
    }

    public onDrawerScroll(event) {
        const drawer = (event.target as HTMLElement);
        const dragger = drawer.querySelector('.rg-right') as HTMLDivElement;

        dragger.style.right = `-${drawer.scrollLeft}px`;
    }

    public snackBarNotification(message: string, duration, notificationClass, dismiss) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissText, {
            panelClass: notificationClass,
        }, dismiss);
    }
    /**
     * Represents the file share handler.
     * @param id The vault file surrogate identifier.
     * @param name The vault file name.
     */
    public shareFile(fileShareData: VaultShareDialogData, id: string, name: string) {
        fileShareData.id = id;
        fileShareData.name = name;
        this.dialog.open(VaultItemShareComponent, {
            data: fileShareData,
            panelClass: 'file-upload-background-panel',
        });
    }

    /**
     * Represents the file remove handler.
     * @param id The vault file surrogate identifier.
     */
    public removeFile(id: string, isDeleteFolder: boolean, name: string) {
        // diplaying loader before service call
        this.isLoading = true;
        const indexedArray = `{"${id}": ${isDeleteFolder}}`;
        const deleteSubscription = this.vaultService.deleteFiles(indexedArray).subscribe(
            (result: boolean) => {
                // get the latest vault data for current customer and stop preloader
                const getSubscription = this.vaultService
                    .getRootVaultListForCustomer()
                    .subscribe(
                        () => {
                            this.isLoading = false;
                        },
                        (error) => {
                            // Error Block to handle errors
                        },
                    )
                    .unsubscribe(); // immediately unsubscribe for preventing duplicate calls

                this.containingFiles
                    .subscribe(
                        (files: Array<VaultFile>) => {
                            // find the file by id
                            const foundFile = files.find((f: VaultFile) => f.uri === id);

                            // set name for file
                            const fileName = isDeleteFolder ? name : foundFile ? foundFile.fileName : id;
                            const typeName = isDeleteFolder ? this.folderText : this.fileText;

                            // display snackbar with deletion message
                            const snackBarRef = this.snackBar.open(
                                `${typeName} "${fileName}"  ${this.removedSuccessText}`,
                                this.dismissText,
                                <MatSnackBarConfig>{ duration: 10000 },
                            );
                            snackBarRef.afterDismissed().subscribe(
                                () => {
                                    // remove selected file from autocomplete if selected
                                    if (fileName === this.fileCtrl.value) {
                                        this.fileCtrl.setValue('');
                                    }
                                },
                                (error) => {
                                    // Error Block to handle errors
                                },
                            );
                            // force detect changes
                            this.uiUtilsService.safeChangeDetection(this.changeDetector);
                        },
                        (error) => {
                            // Error Block to handle errors
                        },
                    )
                    .unsubscribe();
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(deleteSubscription);
    }

    /**
     * Delete click handler.
     * @param event Represents an instance of the MouseEvent.
     * @param fileId Represents the file surrogate identifier.
     * @param fileName Represents the name of file.
     */
    public deleteFile(event: MouseEvent, fileId: string, fileName: string, isDeleteFolder: boolean): void {
        if (!this.isVaultDeletePermission) {
            return;
        }

        const deleteMessageText = isDeleteFolder ? this.deleteDialogFolderMessage : this.deleteDialogMsg;

        // open delete confirmation dialog
        const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
            data: <IComponentDialog>{
                title: this.deleteDialogTitle,
                message: deleteMessageText,
                cancelText: this.cancelBtn,
                okText: this.deleteBtn,
            },
            panelClass: 'vault-file-delete-background-panel',
        });

        // listen for backdrop click which would indicate cancellation of delete
        const deleteDialogBackDropClickSubscription = deleteDialog.backdropClick().subscribe(
            () => {
                // detect changes
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(deleteDialogBackDropClickSubscription);

        // listen for close event
        const deleteDialogBeforeCloseSubscription = deleteDialog.beforeClosed().subscribe(
            (result: IComponentDialogConfirmationResult) => {
                if (result && result.whichButtonWasPressed === 'ok') {
                    this.removeFile(fileId, isDeleteFolder, fileName);
                }

                // detect changes
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(deleteDialogBeforeCloseSubscription);
    }

    /**
     * Handles setup of data upon customer change.
     */
    private onCustomerChange(isFirstCall: boolean): void {
        // set root as current directory
        this.vaultService.setCurrentDirectory('/');
        this.isUploadEnabled = true;
        // initate the preloader
        this.isLoading = true;
        // set default value when user change customer
        this.fileCtrl.setValue('');
        // set default page size 10
        if (this.vaultDirectoryPaginator) {
            this.vaultDirectoryPaginator.pageSize = 10;
        }
        // get reference to retrieving the potential customers
        const getVaultSubscription = this.vaultService
            .getRootVaultListForCustomer()
            .subscribe(
                () => (this.isLoading = false),
                (error) => {
                    // Error Block to handle errors
                },
            )
            .unsubscribe();

        // this.subscriptions.push(getVaultSubscription);
        if (!isFirstCall) {
            this.getRecentExportSettings();
        }
    }
    public multiFileDownload() {
        const selectedFile = this.vaultDirectoryDataSource.data.filter((selectable) => selectable.isChecked);
        const files = [];
        if (selectedFile.length > 0) {
            selectedFile.forEach((file, index) => {
                files.push(file.uri);
            });
        }
        if (files && files.length > 0) {
            this.isLoading = true;
            const splitFile = files[0].split('/');
            const fileName = files.length === 1 ? splitFile.find((x) => x.includes('.')) : 'Files';
            this.vaultService.downloadFile(files).subscribe((res) => {
                importedSaveAs(res, fileName);
            });
            this.isLoading = false;
        }
    }

    public multiFileDelete() {
        if (!this.isVaultDeletePermission) {
            return;
        }
        const selectedFile = this.vaultDirectoryDataSource.data.filter((selectable) => selectable.isChecked);
        if (selectedFile.length > 0) {
            this.dialog
                .open(VaultFilesDeleteActionComponent, {
                    disableClose: true,
                    data: {
                        selectedFiles: selectedFile,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.success) {
                        this.enableMultiAction = false;
                    }
                });
        }
    }
    public multiFileMove() {
        const selectedFile = this.vaultDirectoryDataSource.data.filter((selectable) => selectable.isChecked);
        const files = [];
        if (selectedFile.length > 0) {
            selectedFile.forEach((file, index) => {
                files.push(file.fileName);
            });
        }
        this.dialog
            .open(VaultFilesMoveActionComponent, {
                data: {
                    vaultTreeData: this.vaultData,
                    selectedFiles: files,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.success) {
                    this.enableMultiAction = false;
                }
            });
    }
    public importTelogData(event: MouseEvent, file: VaultFile): void {
        // setup dialog
        const telogImportDialog = this.dialog.open(TelemetryImportComponent, {
            data: <TelemetryImportDialogData>{
                files: [
                    {
                        id: file.uri,
                        fileName: file.fileName,
                        import: file.import,
                    },
                ],
                importFileType: TELOG,
                isFileUploaded: false,
            },
            panelClass: 'telemetry-import-background-panel',
        });

        // setup subscription for telemetry import dialog close
        const telogDialogSubscription = telogImportDialog.beforeClosed().subscribe(
            (telogImportResult: TelemetryImportResult) => {
                // if no result, just exit as dialog was just closed
                if (!telogImportResult) {
                    // change loading state
                    this.isLoading = false;

                    // detect changes and exit immediately
                    return this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }

                // if there is an object then we have something to say to the user
                // so we display in a snackbar
                this.snackBar.open(telogImportResult.message, this.dismissText, {
                    duration: 10000,
                });

                this.isLoading = false;

                // detect changes and exit immediately
                return this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(telogDialogSubscription);
    }

    public importTelemetryFiles(fileList, isFileUploaded) {
        // setup dialog
        const telemetryImportDialog = this.dialog.open(TelemetryImportComponent, {
            data: <TelemetryImportDialogData>{
                files: fileList,
                importFileType: TELEMETRY,
                isFileUploaded: isFileUploaded,
            },
            panelClass: 'telemetry-import-background-panel',
        });

        // setup subscription for telemetry import dialog close
        const telemetryDialogSubscription = telemetryImportDialog.beforeClosed().subscribe(
            (telemetryImportResult: TelemetryImportResult) => {
                // if no result, just exit as dialog was just closed
                if (!telemetryImportResult) {
                    // change loading state
                    this.isLoading = false;

                    // detect changes and exit immediately
                    return this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }

                // if there is an object then we have something to say to the user
                // so we display in a snackbar
                this.snackBar.open(telemetryImportResult.message, this.dismissText, {
                    duration: 10000,
                });

                this.isLoading = false;

                // detect changes and exit immediately
                return this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(telemetryDialogSubscription);
    }
    /**
     * Handles the click event for importing telemetry data from a viable data file.
     * @param event Represents the click event.
     */
    public importTelemetryData(event: MouseEvent, file: VaultFile): void {
        const telemetryData = [
            {
                id: file.uri,
                fileName: file.fileName,
                import: file.import,
            },
        ];
        this.importTelemetryFiles(telemetryData, false);
    }

    public selectAllChangeHandler(event: MatCheckboxChange) {
        const isChecked = event.checked;
        this.vaultDirectoryDataSource.data.forEach(v => v.isChecked = isChecked);
        this.selectAllFiles = isChecked;

        this.enableAction();
    }

    public fileSelectChangeHandler(event, uri) {
        const foundlItem = this.vaultDirectoryDataSource.data.find((s) => s.uri === uri);
        foundlItem.isChecked = !foundlItem.isChecked;
        this.enableAction();
    }
    public enableAction() {
        const selectedFile = this.vaultDirectoryDataSource.data.filter((selectable) => selectable.isChecked);
        const selectedCsvFile = selectedFile.filter((selectable) => selectable.fileExtension.toLowerCase() === 'csv');
        const selectedXmlFile = selectedFile.filter((selectable) => selectable.fileExtension.toLowerCase() === 'xml');
        this.enableMultiAction = selectedFile.length > 0;
        this.enableMultiImportAction = selectedFile.length === (selectedCsvFile.length || selectedXmlFile.length);
    }
    public multiFileImport() {
        if (this.enableMultiImportAction) {
            const selectedFile = this.vaultDirectoryDataSource.data.filter((selectable) => selectable.isChecked);
            const selectedCsvFile = selectedFile.filter(
                (selectable) => selectable.fileExtension.toLowerCase() === 'csv',
            );
            const selectedXmlFile = selectedFile.filter(
                (selectable) => selectable.fileExtension.toLowerCase() === 'xml',
            );
            if (selectedCsvFile.length > 0) {
                const telemetryData = [];
                selectedCsvFile.forEach((file, index) => {
                    const data = {
                        id: file.uri,
                        fileName: file.fileName,
                        import: file.import,
                    };
                    telemetryData.push(data);
                });
                this.importTelemetryFiles(telemetryData, false);
            }
            if (selectedXmlFile.length > 0) {
                const selectedXml = [];
                selectedXmlFile.forEach((file, index) => {
                    selectedXml.push(this.addRemoveRootPath(file.uri, false));
                });
                this.importLocationFiles(selectedXml);
            }
        }
    }
    public formatFilepath(files, isRootRequired) {
        const formatedFilePaths = [];
        files.forEach((file, index) => {
            formatedFilePaths.push(this.addRemoveRootPath(file, isRootRequired));
        });
    }
    public addRemoveRootPath(filePath, isRootRequired) {
        if (isRootRequired) {
            if (filePath.charAt(0) !== '/') {
                filePath = '/' + filePath;
            }
        } else {
            if (filePath.charAt(0) === '/') {
                filePath = filePath.substr(1, filePath.length);
            }
        }
        return filePath;
    }
    public importLocationFiles(files) {
        this.isLoading = true;
        const importLocationDataSubscription = this.vaultService.getLocationsXmlFileDetails(files).subscribe(
            (result) => {
                if (result) {
                    const res = [];
                    res.push(result);
                    let uploadedLocations = res[0];
                    const invalidXmls = uploadedLocations.filter((i) => i.validation.toUpperCase() !== SUCCESSFUL);
                    uploadedLocations = uploadedLocations.filter((i) => i.validation.toUpperCase() === SUCCESSFUL);
                    if (uploadedLocations.length > 0) {
                        this.uploadedLocations = uploadedLocations;
                        this.disableImport = this.uploadedLocations.find((loc) => loc.isExistingLocation === true)
                            ? true
                            : false;
                        this.showLocationDetails = true;
                    } else {
                        this.snackBarNotification(this.invalidXmlErr, 3000, 'custom-error-snack-bar', false);
                    }
                    if (invalidXmls.length > 0) {
                        let errorMessage = '';
                        invalidXmls.forEach((file, index) => {
                            errorMessage += `${file.fileName}, `;
                        });
                        errorMessage = `${this.invalidXmlErr} ${errorMessage.substr(0, errorMessage.lastIndexOf(','))}`;
                        this.snackBarNotification(errorMessage, 3000, 'custom-error-snack-bar', false);
                    }
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.snackBarNotification(error.error, 3000, 'custom-error-snack-bar', false);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );

        this.subscriptions.push(importLocationDataSubscription);
    }
    public generateValutItemTable(selectedVaultFile: VaultFile) {
        if (this.containingFilesData) {
            this.vaultDirectoryDataSource = null;
            this.vaultDirectoryDataChange = new BehaviorSubject<VaultFile[]>([]);
            let highLight = false;
            this.containingFilesData.sort(function (a, b) {
                const firstDate = new Date(a.modifiedDate);
                const secondDate = new Date(b.modifiedDate);
                return secondDate.getTime() - firstDate.getTime();
            });
            const imgCounter = 0;
            this.containingFilesData.forEach((file, index) => {
                if (selectedVaultFile && file.fileName === selectedVaultFile.fileName) {
                    if (this.containingFilesData.length > 0) {
                        const pageIndex = index / this.vaultDirectoryPaginator.pageSize;
                        const finalPageIndex = +pageIndex.toString().split('.')[0];
                        this.vaultDirectoryPaginator.pageIndex = finalPageIndex;
                        highLight = true;
                    }
                }

                const vaultCopiedData = this.vaultDirectoryData.slice();
                let isPotentialTelemetryFile = false;
                let isTelogFile = false;
                let fileExtension = '';

                if (file && file.file_size) {
                    fileExtension = this.fileService.getFileExtension(file.fileName);
                    isPotentialTelemetryFile = fileExtension === 'csv';
                    isTelogFile =
                        fileExtension === EXCEL_FILE_EXTENSION && file.fileName.toLowerCase().includes(ACTIVE);
                }

                const previewType = this.fileService.isImageExtension(fileExtension)
                    ? 'img'
                    : this.fileService.isTextExtension(fileExtension)
                        ? 'txt'
                        : 'default';

                const data = {
                    file_size: file.file_size.split(' ')[0],
                    file_ext: file.file_size.split(' ')[1],
                    fileName: file.fileName,
                    import: file.import,
                    uri: file.uri,
                    fileExtension: fileExtension,
                    isPotentialTelemetryFile: isPotentialTelemetryFile,
                    isTelogFile: isTelogFile,
                    modifiedDate: file.modifiedDate,
                    highLight: highLight,
                    previewType: previewType,
                    absoluteUri: previewType === 'img' ? file.absoluteUri : file.uri,
                    isChecked: false,
                };

                vaultCopiedData.push(data);
                this.vaultDirectoryDataChange.next(vaultCopiedData);
                highLight = false;
            });
            this.vaultDirectoryDataSource = new MatTableDataSource(this.vaultDirectoryData);
            this.paginatedFiles = this.vaultDirectoryData.slice(0, ITEMS_PER_PAGE);
            this.vaultDirectoryDataSource.paginator = this.vaultDirectoryPaginator;

            this.vaultDirectoryDataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                    case 'file_size':
                        return this.returnFileSize(item.file_size, item.file_ext);
                    case 'modifiedDate':
                        return this.returnDate(item.modifiedDate);
                    default:
                        return item[property];
                }
            };

            this.vaultDirectoryDataSource.sort = this.vaultDirectorySort;

            if (this.vaultDirectoryDataSource.sort && !this.setIntialSort) {
                this.setIntialSort = true;
                this.vaultDirectoryDataSource.sort.sort(<MatSortable>{
                    id: 'modifiedDate',
                    start: 'desc',
                });
            }

            if (this.needToScrollToSelectedFile) {
                this.needToScrollToSelectedFile = false;

                const selectedFile = this.vaultDirectoryData.find(v => v.highLight);

                if (selectedFile) {
                    this.scrollToSelectedFile(selectedFile);
                }
            }

            this.uiUtilsService.safeChangeDetection(this.changeDetector);

            // this.vaultDirectoryDataSource = new FilterDataSource(this.vaultDirectoryDataChange, this.vaultDirectoryData,
            //   this.vaultDirectoryPaginator, this.vaultDirectorySort, this.vaultDirectoryFilterColumns);
            this.totalPaginationLength = this.vaultDirectoryData.length;
        } else {
            this.totalPaginationLength = 1;
        }
    }

    public onScrollDown() {
        this.paginatedFiles = this.vaultDirectoryDataSource.data.slice(0, this.paginatedFiles.length + ITEMS_PER_PAGE);
    }

    /**
     * Below function would sort the vault data
     * @param sort represents materail sort event
     */
    public sortData(sort: Event) {
    }

    public compare(a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    /**
     * Handles file link click event.
     * @param event Represents the mouse click event.
     */
    public fileSelected(
        event: MouseEvent,
        fileExtension: string,
        selectedFileName: string,
        uri: string,
        isBoxPriview: boolean,
        absoluteUri = '',
    ): void {
        // determine if the file is an image
        const isImage = this.fileService.isImageExtension(fileExtension);

        let isText: boolean;

        if (!isImage) {
            // it it is not an image, determine if it is a text file
            isText = this.fileService.isTextExtension(fileExtension);
        }

        // if file is neither text or image, exit immediatly
        if (!isImage && !isText) {
            this.fileDownload([uri]);
            return;
        }

        // setup the file view model to pass to the file view component
        const fileViewModel: IFileView = {
            extension: fileExtension,
            hostUri: this.apiUri,
            name: selectedFileName,
            type: isImage ? FileType.Image : FileType.Text,
            uri: uri,
            absoluteUri: absoluteUri,
        };

        // prevent file from downloading by blocking default behavior
        event.preventDefault();

        // open image in dialog
        this.dialog.open(FileViewComponent, {
            data: fileViewModel,
            panelClass: 'vault-file-view-background-panel',
        });
    }

    private generateAutoCompleteForAllFiles() {
        if (this.vaultDirectory) {
            this.containingAllFiles = [];
            if (this.vaultDirectory.files) {
                for (const file of this.vaultDirectory.files) {
                    file.directoryAndFileName = this.vaultDirectory.name + file.fileName;
                    file.filePath = this.vaultDirectory.name;
                    this.containingAllFiles.push(file);
                }
            }
            if (this.vaultDirectory.sub_directories) {
                for (const dir of this.vaultDirectory.sub_directories) {
                    for (const file of dir.files) {
                        file.directoryAndFileName = dir.id + file.fileName;
                        file.filePath = dir.id;
                        this.containingAllFiles.push(file);
                    }
                    if (dir.sub_directories) {
                        this.addAllFile(dir.sub_directories, this.containingAllFiles);
                    }
                }
            }

            this.filteredFiles = this.fileCtrl.valueChanges
                .pipe(startWith(null))
                .pipe(map((file) => (file ? this.filterFiles(file) : this.containingAllFiles.slice())));
        }
    }

    public fileDownload(files: string[]) {
        if (files && files.length > 0) {
            const splitFile = files[0].split('/');
            const fileName = splitFile.find((x) => x.includes('.'));
            this.vaultService.downloadFile(files).subscribe((res) => {
                if (res.type === 'text/csv') {
                    const data = new Blob(['\ufeff', res], { type: 'text/csv;charset=utf-8' });
                    importedSaveAs(data, fileName );
                } else {
                    importedSaveAs(res, fileName);
                }
            });
            this.isLoading = false;
        }
    }

    private addAllFile(subDirectories, valutFileItem) {
        for (const dir of subDirectories) {
            for (const file of dir.files) {
                file.directoryAndFileName = dir.id + file.fileName;
                file.filePath = dir.id;
                valutFileItem.push(file);
            }
            if (dir.sub_directories) {
                this.addAllFile(dir.sub_directories, valutFileItem);
            }
        }
    }

    /**
     * implement fuzzy search in auto complete
     * @param fileName
     */
    private filterFiles(fileName: string) {
        return this.containingAllFiles.filter(
            (file) => file.directoryAndFileName.toLowerCase().indexOf(fileName.toLowerCase()) !== -1,
        );
    }

    public selectedFile(selectedVaultFile: VaultFile, event) {
        // if enent is null, i.e. file uploaded and mark it as selected
        if (event.source.selected) {
            this.fileCtrl.setValue(selectedVaultFile.directoryAndFileName);
            this.uploadVaultFile = undefined;
            this.findDirctoryFromFile(selectedVaultFile);
        }
    }

    private findDirctoryFromFile(selectedVaultFile: VaultFile) {
        this.selectedVaultFile = selectedVaultFile;
        this.selectedDirectoryId = '';
        for (const file of this.vaultDirectory.files) {
            if (file.directoryAndFileName === selectedVaultFile.directoryAndFileName) {
                this.selectedDirectoryId = '/';
            }
        }
        if (this.selectedDirectoryId === '') {
            for (const dir of this.vaultDirectory.sub_directories) {
                for (const file of dir.files) {
                    if (file.directoryAndFileName === selectedVaultFile.directoryAndFileName) {
                        this.selectedDirectoryId = dir.id;
                        break;
                    }
                }
                if (dir.sub_directories && this.selectedDirectoryId === '') {
                    this.selectDirectoryFile(dir.sub_directories, this.containingAllFiles, selectedVaultFile);
                }
            }
        }
        this.directorySelected(this.selectedDirectoryId);

        this.scrollToSelectedFile(selectedVaultFile);
    }

    private scrollToSelectedFile(selectedFile: VaultFile) {
        if (this.selectedDirectoryId === '/') {
            this.vaultDirectoryData.forEach(v => v.highLight = selectedFile.fileName === v.fileName);
        }

        const index = this.vaultDirectoryData.findIndex(v => v.fileName === selectedFile.fileName);

        if (index === -1) {
            return;
        }

        if (!this.paginatedFiles[index]) {
            this.paginatedFiles = this.vaultDirectoryData.slice(0, index + ITEMS_PER_PAGE);
        }

        setTimeout(() => {
            if (this.filesContainer && this.filesContainer.nativeElement.children[index]) {
                this.filesContainer.nativeElement.children[index].scrollIntoView();
            }
        }, 0);
    }

    private selectDirectoryFile(subDirectories, valutFileItem, selectedVaultFile) {
        for (const dir of subDirectories) {
            for (const file of dir.files) {
                if (file.directoryAndFileName === selectedVaultFile.directoryAndFileName) {
                    this.selectedDirectoryId = dir.id;
                    this.needToScrollToSelectedFile = true;
                    break;
                }
            }
            if (dir.sub_directories && this.selectedDirectoryId === '') {
                this.selectDirectoryFile(dir.sub_directories, valutFileItem, selectedVaultFile);
            }
        }
    }

    /**
     * open the crate folder dialog
     */
    public createFolder() {
        this.dialog.open(VaultCreateFolderComponent, {
            disableClose: true,
        });
    }

    /**
     * call on page change scroll to top;
     */
    public onPaginateChange(event: PageEvent) {
        this.vaultDirectoryPaginator.pageSize = event.pageSize;
        this.vaultDirectoryPaginator.pageIndex = event.pageIndex;

        this.vaultDirectoryDataSource.paginator = this.vaultDirectoryPaginator;
        this.domOperationUtilsService.scrollToTop('.vault-list');
    }

    public onPageChange(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        const startIndex = event.pageIndex * event.pageSize;
        let endIndex = startIndex + event.pageSize;
        if(endIndex > this.containingSubDirectories.length){
            endIndex = this.containingSubDirectories.length ;
        }
        this.subDirectories = this.containingSubDirectories.slice(startIndex , endIndex);
    }

    /**
     * To clear the search textbox
     */
    public searchClear() {
        this.searchCtrl.nativeElement.value = null;
        this.filteredFiles = this.fileCtrl.valueChanges
            .pipe(startWith(null))
            .pipe(map((file) => (file ? this.filterFiles(file) : this.containingAllFiles.slice())));
    }
    /**
     * Handler for Import button
     */
    public importLocations(): void {
        if (this.uploadedLocations) {
            this.isLoading = true;
            this.uploadedLocations.forEach((obj) => {
                if (this.rainGaugeLocations) {
                    const location = this.rainGaugeLocations.find(
                        (loc: Locations) => loc.locationName === obj.assignedRainGaugeName,
                    );
                    obj.assignedRainGaugeID = location ? location.locationId : 0;
                }
            });

            const importSubscribe = this.vaultService.importLocations(this.uploadedLocations).subscribe(
                (result) => {
                    // get the latest vault data for current customer and stop preloader
                    if (result && result[0] && result[0].validation === 'Successful') {
                        this.vaultService
                            .getRootVaultListForCustomer()
                            .subscribe(
                                (response) => {
                                    this.isLoading = false;
                                },
                                (error) => {
                                    // Error Block to handle errors
                                },
                            )
                            .unsubscribe(); // immediately unsubscribe
                        this.showLocationDetails = false;
                        this.domOperationUtilsService.selectedHintPageName.next('noHintclass');
                        this.snackBar.open(`${result[0].locationName} ${this.importSuccessMsg}`, this.dismissText, {
                            duration: 10000,
                        });
                    } else {
                        this.snackBar.open(this.importErrMsg, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.snackBar.open(this.importErrMsg, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
            this.subscriptions.push(importSubscribe);
        } else {
            this.vaultService.getRootVaultListForCustomer().subscribe();
        }
    }

    /**
     * Triggered when the monitor series is updates
     * @param items Represents the name of the series type.
     */
    public onChangeMonitorSeries(items: string): void {
        // ensure args
        if (!items) {
            this.uploadedLocations = this.allUploadedLocations;
        } else {
            const filteredItems = this.allUploadedLocations.filter(
                (panel) => panel.monitorSeries.toLowerCase() === items.toLowerCase(),
            );
            this.uploadedLocations = filteredItems;
        }
    }

    /**
     * Removes rejected row from uploaded locations list
     * @param index index of row to be rejected
     */
    public removeLocation(index: number): void {
        this.uploadedLocations.splice(index, 1);
        this.disableImport = this.uploadedLocations.find((loc) => loc.isExistingLocation === true) ? true : false;
        this.showLocationDetails = !(this.uploadedLocations.length === 0);
        this.domOperationUtilsService.selectedHintPageName.next('noHintclass');
    }

    /**
     * Handler for overwrite action
     * @param panel row to be overwrite
     */
    public overWriteLocation(panel: VaultLocationsWithXML): void {
        this.uploadedLocations.filter((item) => item.locationName === panel.locationName)[0].isExistingLocation = false;
        this.disableImport = this.uploadedLocations.find((loc) => loc.isExistingLocation === true) ? true : false;
    }

    /**
     * Handler for cancel button
     */
    public cancelImport(): void {
        this.dialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: <IComponentDialog>{
                    title: this.cancelDialogTitle,
                    message: this.navigationConfirmationMsg,
                    cancelText: this.cancelText,
                    okText: this.discardText,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result.whichButtonWasPressed === 'ok') {
                    this.showLocationDetails = false;
                    this.domOperationUtilsService.selectedHintPageName.next('noHintclass');
                    this.uploadedLocations = [];
                    this.allUploadedLocations = [];
                }
            });
    }

    /**
     * Converts the date string into parsable date format by the Javascript Date/Time method.
     * @param date string Object to be converted to Date Object
     */

    public returnDate(inputDate) {
        if (inputDate) {
            const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();
            if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                return new Date(inputDate).getTime();
            } else if (dateFormat === 'DD/MM/YYYY') {
                return new Date(String(inputDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).getTime();
            }
        } else {
            return '';
        }
    }

    public returnFileSize(val, ext) {
        if (!val) return 0;

        let v = Number(val);

        if (ext) {
            switch (ext) {
                case 'GB': v *= 1024;
                case 'MB': v *= 1024;
                case 'KB': v *= 1024;
                case 'B':
                default:
            }
        }

        return v;
    }

    public onViewChange(value: string) {
        this.selectedValue = value;
        this.isListMode = !(this.selectedValue === '1');

        if (!this.isListMode) {
            this.subDirectories = this.containingSubDirectories;
            if(this.vaultDirectoryDataSource)
            {
                this.paginatedFiles = this.vaultDirectoryDataSource.data.slice(0, ITEMS_PER_PAGE);
            }
        }
        else
        {
            this.setDefaultPaginator();
            const startIndex = this.pageIndex * this.pageSize;
            let endIndex = startIndex + this.pageSize;
            if(endIndex > this.containingSubDirectories.length){
                endIndex = this.containingSubDirectories.length ;
            }
            this.subDirectories = this.containingSubDirectories.slice(startIndex , endIndex);

        }
    }

    public setPagination(subDir: VaultSubDirectory[],contDir: VaultSubDirectory[])
    {
        if(contDir && contDir.length > this.minViewSize){
            this.subDirectories = this.containingSubDirectories.slice(0,this.minViewSize);
        }
        else{
            this.subDirectories = this.containingSubDirectories;
        }
    }


    public resizeStart(event) {
        this.resizeOrigWidth = this.resizable.nativeElement.getBoundingClientRect().width;
        this.resizeStartX = event.x;
        event.preventDefault();
    }

    public resizeTick(event) {
        if(this.resizeOrigWidth ?? false) {
            event.preventDefault();
            const width = this.resizeOrigWidth + event.x - this.resizeStartX;
            this.resizable.nativeElement.style.flexBasis = `${width}px`;
        }
    }

    public resizeEnd(event) {
        if(this.resizeOrigWidth ?? false) {
            event.preventDefault();
        }
        this.resizeStartX = null;
        this.resizeOrigWidth = null;
    }
}
