import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { Config } from 'app/shared/services/config';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { VaultLocationExport } from '../models/vault';
import { customerQueryParam, OMIT } from '../models/customer';
import {
    ExportSetting,
    TelemetryExportParmas,
    TelemetryValidationResult,
    VaultExcelExportParams,
    VaultExportParmas,
} from '../models/telemetry';
import { DateRangeForFile } from 'app/pages/vault/vault-telemetry/vault-import-file-summary/vault-import-file-summary.component';
import { DateRangeFileRequest } from 'app/pages/vault';

// vault Export Enums
export enum DataExportType {
    AdsCsv = 0,
    Excel,
}
export enum LocationType {
    Location = 0,
    LocationGroup,
}
export enum DataExportFile {
    Single = 0,
    Multiple,
}
export enum EntityExportType {
    Row = 0,
    Column,
}
export enum ExportPerSheet {
    Location = 0,
    Entity,
}
export enum ExportDataAveraging {
    None = 0,
    FiveMinute,
    Fifteenminute,
    OneHour,
    OneDay,
    OneWeek,
    OneMonth,
    Twominute = 8,
}

export enum DateQuickSpan {
    OneDay = 0,
    OneWeek,
    OneMonth,
    ThreeMonths,
    SixMonths,
    OneYear,
    Custom,
}

const MULTIPLE_FILES = 'MultipleFiles';

const EXPORT_PER_SHEET_TO_STYLE = {
    [ExportPerSheet.Entity]: 'OneSheetPerEntity',
    [ExportPerSheet.Location]: 'OneSheetPerLocation',
};

@Injectable()
export class TelemetryService {
    public settingChanged = new BehaviorSubject<string>('no');
    public openExistingExport = false;
    /**
     * Represents current customer ID
     */
    private customerID: number;

    /**
     * Represents a placeholder for subscriptions.
     */
    private subscriptions = new Array<Subscription>();

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerID = Number(params.get(customerQueryParam));
        });
    }

    public exportTelemetryData(telemetryParams: TelemetryExportParmas): Observable<any> {
        telemetryParams.cid = this.customerID;
        telemetryParams.uploadToAzure = true;

        let httpParams: HttpParams = new HttpParams();
        // setting all the existing key value of param to HttpParams
        Object.keys(telemetryParams).forEach(function (key) {
            if (key !== 'eids' && key !== 'lids') {
                httpParams = httpParams.append(key, telemetryParams[key]);
            }
        });

        telemetryParams.lids.forEach(function (lids) {
            httpParams = httpParams.append('lids', lids.toString());
        });

        telemetryParams.eids.forEach(function (eids) {
            httpParams = httpParams.append('eids', eids.toString());
        });

        return this.http.get<TelemetryExportParmas>(Config.urls.telemetryExport, { params: httpParams });
    }

    public importTelemetryFile(id: string): Observable<boolean> {
        // HACK: remove slash, this should be removed in API during GET
        if (id.charAt(0) === '/') {
            id = id.substr(1, id.length);
        }

        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('filePath', id);
        httpParams = httpParams.append('cid', this.customerID.toString());

        return this.http.post<boolean>(Config.urls.telemetryImport, null, { params: httpParams });
    }

    /**
     * Validates the file and returns an object that is
     * @param id The file surrogate identifier.
     */
    public validateTelemetryFile(id: string): Observable<TelemetryValidationResult> {
        // HACK: remove slash, this should be removed in API during GET
        if (id.charAt(0) === '/') {
            id = id.substr(1, id.length);
        }

        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('filePath', id);
        httpParams = httpParams.append('cid', this.customerID.toString());

        return this.http.get<TelemetryValidationResult>(Config.urls.telemetryValidation, { params: httpParams });
    }

    public validateTelemetryFiles(files) {
        return this.http.post<any>(Config.urls.telemetryValidateAll + `?cid=${this.customerID}`, files);
    }

    public importTelemetryFiles(files) {
        return this.http.post<any>(Config.urls.telemetryImportAll + `?cid=${this.customerID}`, files);
    }

    public importTelemetryFilesV3(files: DateRangeFileRequest[]) {
        return this.http.post<any>(Config.urls.telemetryImportAllV3 + `?cid=${this.customerID}`, files);
    }

    public importConfirmationFiles(files) {
        return this.http.post<any>(Config.urls.confirmationImport + `?cid=${this.customerID}`, files);
    }

    public vaultExportLocation(vaultExport, selectedEntities?, selectedLocations?, locationGroupId?) {
        const vaultParam: VaultExportParmas = new VaultExportParmas();
        vaultParam.includeMissingTimestamps = !(vaultExport.timeStampType === OMIT);
        vaultParam.missingTimestampValue = !vaultExport.missingTimestampValue ? '' : vaultExport.missingTimestampValue;
        vaultParam.interval = vaultExport.dataAverage;
        vaultParam.entityPerColumn = vaultExport.entityRowColumn === 1;
        vaultParam.locationGroupId = locationGroupId;
        vaultParam.singleFile = !(vaultExport.singleOrMultiple === 1);
        vaultParam.lIds = selectedLocations;
        vaultParam.style = vaultExport.singleOrMultiple ? '' : MULTIPLE_FILES;
        vaultParam.eIds = selectedEntities;
        vaultParam.startDate = vaultExport.startDate;
        vaultParam.endDate = vaultExport.endDate;
        vaultParam.uploadToAzure = true;
        vaultParam.useRounding = vaultExport.isRoundingEnabled;
        const baseURI = Config.urls.vaultLocationExport + `?cid=${vaultExport.cid}`;
        return this.http.post<any>(baseURI, vaultParam);
    }

    public vaultExcleExportLocation(
        vaultExport: VaultLocationExport,
        selectedEntities?,
        selectedLocations?,
        locationGroupId?,
    ) {
        const vaultParam: VaultExcelExportParams = new VaultExcelExportParams();
        vaultParam.includeMissingTimestamps = !(vaultExport.timeStampType === OMIT);
        vaultParam.missingTimestampValue = !vaultExport.missingTimestampValue ? '' : vaultExport.missingTimestampValue;
        vaultParam.cid = vaultExport.cid;
        vaultParam.lids = selectedLocations;
        vaultParam.eids = selectedEntities;
        vaultParam.startDate = vaultExport.startDate;
        vaultParam.endDate = vaultExport.endDate;
        vaultParam.useRounding = vaultExport.isRoundingEnabled;
        vaultParam.singleFile = vaultExport.singleOrMultiple === 1;
        if (vaultExport.singleOrMultiple === 0) {
            vaultParam.style = EXPORT_PER_SHEET_TO_STYLE[vaultExport.exportedPerSheet];
        } else {
            vaultParam.style = MULTIPLE_FILES;
        }
        vaultParam.lgid = locationGroupId;
        vaultParam.interval = vaultExport.dataAverage;
        const baseURI = Config.urls.vaultExcelExport + `?cid=${vaultExport.cid}`;
        return this.http.post<any>(baseURI, vaultParam);
    }

    public getAllLoadSetting() {
        return this.http.get<any>(`${Config.urls.vaultExportSettings}?cid=${this.customerID}&PageSize=0`);
    }

    public getRecentExportSettings() {
        return this.http.get<any>(`${Config.urls.vaultExportSettings}?cid=${this.customerID}&PageSize=10&StartPage=1`);
    }
    public saveExportSetting(exportSettingName, vaultExport, selectedEntities?, selectedLocations?, locationGroupId?) {
        const exportSettingParam: ExportSetting = new ExportSetting();
        exportSettingParam.name = exportSettingName;
        exportSettingParam.locationGroupId = locationGroupId;
        exportSettingParam.lids = selectedLocations;
        exportSettingParam.eids = selectedEntities;
        exportSettingParam.includeMissingTimestamps = !(vaultExport.timeStampType === OMIT);
        exportSettingParam.missingTimestampValue = !vaultExport.missingTimestampValue
            ? ''
            : vaultExport.missingTimestampValue;
        exportSettingParam.interval = vaultExport.dataAverage;
        exportSettingParam.entityPerColumn = vaultExport.entityRowColumn === 1;
        exportSettingParam.singleFile = !(vaultExport.singleOrMultiple === 1);
        exportSettingParam.quickSpan = 6;
        exportSettingParam.style = vaultExport.singleOrMultiple ? '' : MULTIPLE_FILES;
        exportSettingParam.startDate = vaultExport.startDate;
        exportSettingParam.endDate = vaultExport.endDate;
        exportSettingParam.useRounding = vaultExport.isRoundingEnabled;
        exportSettingParam.exportType = vaultExport.fileType;
        exportSettingParam.excelStyle = vaultExport.exportedPerSheet;
        const baseURI = Config.urls.vaultExportSettings + `?cid=${vaultExport.cid}`;
        return this.http.post<any>(baseURI, exportSettingParam, { responseType: 'blob' as 'json' });
    }
    public deleteExportSetting(name: string) {
        return this.http.delete(`${Config.urls.vaultExportSettings}?cid=${this.customerID}&name=${name}`);
    }
}
