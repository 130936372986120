import { Component, Inject, ElementRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { ADS_REFRESH_TOKEN_KEY, ADS_TOKEN_KEY, AuthService, MICROSOFT_LOGIN, REMEMBER_ME_KEY } from 'app/shared/services/auth.service';
import { DOCUMENT } from '@angular/common';
import { DomOperationUtilsService } from '../../shared/utils/dom-operation-utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { UsersService } from 'app/pages/admin/users.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { TermsDialogComponent } from 'app/pages/terms-dialog';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'app/shared/components/confirm-dialog.component';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';
import { map } from 'rxjs/operators';
import { AnnouncementService } from 'app/shared/services/announcement.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { environment } from 'app/environments/environment';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
    templateUrl: './welcome.component.html',
    styleUrls: ['welcome.component.scss'],
})
export class WelcomeComponent {
    public PAGE_TEXT: any;

    public translateKeys: Array<string> = ['COMMON.OK', 'COMMON.INACTIVE_ERROR_MESSAGE', 'COMMON.ATTENTION'];
    public okText: string;
    public errorMessage: string;
    public attention: string;


    /**
     * Used to set announcement
     */
    public announcementActive: boolean;
    public announcementText: string;


    /**
     * Used to set token respnse in seesion storage
     */
    public tokenResponse: string;
    public tokenRole: string[];
    /**
     * Used for get active Corousal Index
     */
    public activeCorousalIndex = 0;

    /**
     * Used for get last Corousal Index
     */
    public lastCorousalIndex = 0;

    /**
     * Used for get Corousal html element list
     */
    public corousalList: HTMLElement;

    /**
     * Used to get mouse over Status
     */
    private mouseOutFlag = true;
    /**
     * Used to get user Name
     */
    public userName: string;
    public password;
    /**
     * Used to set flag for error response
     */
    public isloginError: boolean;
    public isResetMode: boolean;
    /**
     * Used to set flag for error responseaccount lock out
     */
    public lockOutMessage: boolean;
    /**
     * Used to set flag loader
     */
    public isLoading: boolean;
    private autoplayIntervalId;
    public userInfo;

    public rememberMe: boolean = false;
    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router,
        private matDialog: MatDialog,
        public translate: TranslateService,
        private userService: UsersService,
        @Inject(DOCUMENT) private angularDocument: Document,
        private elements: ElementRef,
        private activatedRoute: ActivatedRoute,
        private domOperationUtilsService: DomOperationUtilsService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private announcementServices: AnnouncementService,
    ) {
        this.translate.use('en-us');
        this.translate.get(this.translateKeys).subscribe((translateValues) => {
            this.okText = translateValues['COMMON.OK'];
            this.errorMessage = translateValues['COMMON.INACTIVE_ERROR_MESSAGE'];
            this.attention = translateValues['COMMON.ATTENTION'];
        });

        this.translate.get('WELCOME').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });

        iconRegistry.addSvgIcon(
            'microsoft',
            sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/svgIcon/microsoft.svg'),
        );

        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const active = params.get('active');
            const error = params.get('error');
            if (active === 'no' && error === 'yes') {
                this.matDialog
                    .open(ConfirmationDialogComponent, {
                        disableClose: true,
                        data: <IComponentDialog>{
                            title: this.attention,
                            message: this.errorMessage,
                            cancelText: '',
                            okText: this.okText,
                            hidebackContent: true,
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result.whichButtonWasPressed === 'ok') {
                            sessionStorage.clear();
                            this.authService.logout();
                        }
                    });
            }
        });
    }

    ngOnInit(): void {
        this.addCookiesScript();
        //Checks and updates announcement.
        this.updateAnnouncement();

        this.authService.setErrorCountFlag = false;
    }

    private addCookiesScript() {
        const scriptUrl = environment.cookiesScriptUrl;
        const scriptAlreadyInserted = !!document.getElementById('cookieyes');

        if (!scriptUrl || scriptAlreadyInserted) return;
        // need to add script to head element
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');

        script.setAttribute('id', 'cookieyes');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', scriptUrl);

        head.insertBefore(script, head.lastChild);
    }


    // Updates Announcement if active
    public updateAnnouncement(){
        const announce = this.announcementServices.getActiveAnnouncement();

        announce.subscribe((result)=>{
            this.announcementText = result;
            this.announcementActive = !StringUtils.isEmpty(result)});
    }

    /**
     * go to login authentication
     */
    public login() {
        if (this.rememberMe) {
            sessionStorage.setItem(REMEMBER_ME_KEY, '1');
        }

        this.authService.login();
    }

    public loginWithMicrosoft() {
        if (this.rememberMe) {
            sessionStorage.setItem(REMEMBER_ME_KEY, '1');
        }
        sessionStorage.setItem(MICROSOFT_LOGIN, '1');

        this.authService.login();
    }

    public rememberMeChange(event: MatCheckboxChange) {
        this.rememberMe = event.checked;
    }

    /**
     * calls contact us dialog
     */
    public contactUs() {
        this.dialog.open(ContactUsComponent, {
            disableClose: true,
            data: {},
        });
    }

    /**
     * Used to authenticate the normal user
     */
    public normalLogin(userName, password) {
        this.isLoading = true;
        this.authService.authenticateNormalUser(userName, password).subscribe(
            (response) => {
                this.tokenResponse = response['token'];
                const refreshToken = response['refreshToken'];
                sessionStorage.setItem(ADS_TOKEN_KEY, this.tokenResponse);

                if (this.rememberMe) {
                    localStorage.setItem(ADS_REFRESH_TOKEN_KEY, refreshToken);
                }

                const passwordExpirationDays = response['passwordExpirationDays'];
                this.userService.passwordExpirationDays.next(passwordExpirationDays);

                const onLogin = () => {
                    this.authService.idleLogout = false;
                    this.tokenRole = response['roles'];
                    sessionStorage.setItem('adsRole', this.tokenRole[0]);

                    // #40551 Regular (not Microsoft) user did log in
                    this.authService.normalUserLoggedIn();

                    if (passwordExpirationDays < 7) {
                        let passwordChanged = false;
                        const dialogRef = this.matDialog
                            .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                                data: {
                                    title: this.PAGE_TEXT.WARNING,
                                    message: this.PAGE_TEXT.PASSWORD_EXPIRE?.replace('%', `${passwordExpirationDays}`),
                                    link: {
                                        title: 'Change Password',
                                        action: () => {
                                            this.openResetPassword(false).subscribe((res) => {
                                                if(res && res.success === false) {
                                                } else {
                                                    passwordChanged = true;
                                                    dialogRef.close();
                                                    this.isLoading = false;
                                                }
                                            })
                                        }
                                    }
                                },
                                disableClose: true,
                            });

                        dialogRef
                            .afterClosed()
                            .subscribe(() => {
                                if(!passwordChanged) {
                                    this.router.navigateByUrl(this.authService.redirectUrl);
                                }
                            });
                    } else {
                        this.router.navigateByUrl(this.authService.redirectUrl);
                    }
                };

                this.userInfo = combineLatest([
                    this.userService.getUserPermissions(),
                    this.userService.getUserSettings(),
                ]).subscribe(([permissions, settings]) => {
                    const userActive = permissions.payload.isActive;
                    if (!userActive) {
                        // To handle exceptions in permission API and prevent inactive user to enter in the application
                        this.matDialog
                            .open(ConfirmationDialogComponent, {
                                disableClose: true,
                                data: <IComponentDialog>{
                                    title: this.attention,
                                    message: this.errorMessage,
                                    cancelText: '',
                                    okText: this.okText,
                                    hidebackContent: true,
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                sessionStorage.clear();
                                this.isLoading = false;
                            });
                    } else {
                        if (response['forcePasswordReset'] === true) {
                            this.isLoading = false;
                            this.openResetPassword(false);
                        } else if (!settings.acceptedTerms) {
                            this.matDialog
                                .open(TermsDialogComponent, { disableClose: true })
                                .afterClosed()
                                .subscribe(() => {
                                    this.userService
                                        .updateUserSettings({
                                            ...settings,
                                            acceptedTerms: true,
                                        })
                                        .subscribe(() => onLogin());
                                });
                        } else {
                            onLogin();
                        }
                    }
                });
            },
            (error) => {
                this.isLoading = false;
                if (error.status === 423) {
                    this.lockOutMessage = true;
                }
                this.isloginError = true;
            },
        );
    }
    public openResetPassword(isForgotPassword) {
        this.isResetMode = true;

        return this.dialog
            .open(ChangePasswordComponent, {
                disableClose: true,
                data: {
                    userName: this.userName,
                    isForgotPassword: isForgotPassword,
                },
            })
            .afterClosed()
            .pipe(
                map((res) => {
                // to do after close
                this.isResetMode = false;
                this.userName = '';
                this.password = '';

                return res;
            }));
    }
    /**
     * Used to remove error mesage when user try to re enter username and password
     */
    public removeWarning() {
        this.isloginError = false;
    }
    /**
     * Used to show next Corousal
     */
    public nextProduct() {
        this.onChangeSlide('next');
    }

    /**
     * Used to show previous Corousal
     */
    public previousProduct() {
        this.onChangeSlide('prev');
    }

    /**
     * checks mouse Enter on Corousal
     */
    public mouseEnter() {
        this.mouseOutFlag = false;
        clearInterval(this.autoplayIntervalId);
    }

    /**
     * checks mouse Leave on Corousal
     */
    public mouseLeave() {
        this.mouseOutFlag = true;

        // initiate corousal auto play with 5 sec delay
        this.startAutoplay(5000);
    }

    /**
     * Used to startAutoplay Corousal
     * @param delay -delay time
     */
    private startAutoplay(delay: number): void {
        this.autoplayIntervalId = setInterval(() => {
            this.onChangeSlide('next');
        }, delay);
    }

    /**
     * Used for  Corousal page change
     * @param direction - decides direction
     */
    private onChangeSlide(direction: string): void {
        if (direction === 'prev') {
            if (this.activeCorousalIndex === 0) {
                this.activeCorousalIndex = this.lastCorousalIndex;
            } else if (this.activeCorousalIndex === -1) {
                this.activeCorousalIndex = this.lastCorousalIndex - 1;
            } else {
                this.activeCorousalIndex = this.activeCorousalIndex - 1;
            }
        } else {
            this.activeCorousalIndex = this.activeCorousalIndex + 1;
        }
        this.changeSlide();
    }

    /**
     * Used for Corousal page position change
     */
    private changeSlide() {
        if (this.lastCorousalIndex === this.activeCorousalIndex) {
            this.changeCorousalPosition(
                this.activeCorousalIndex - 2,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                0,
            );
            this.activeCorousalIndex = -1;
        } else if (this.activeCorousalIndex === 0) {
            this.changeCorousalPosition(
                this.lastCorousalIndex - 1,
                this.lastCorousalIndex,
                0,
                this.lastCorousalIndex,
                this.activeCorousalIndex,
                this.activeCorousalIndex + 1,
            );
        } else if (this.activeCorousalIndex === 1) {
            this.changeCorousalPosition(
                this.lastCorousalIndex,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                this.activeCorousalIndex + 1,
            );
        } else {
            this.changeCorousalPosition(
                this.activeCorousalIndex - 2,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                this.activeCorousalIndex - 1,
                this.activeCorousalIndex,
                this.activeCorousalIndex + 1,
            );
        }
    }

    /**
     * Used for Corousal page position change remove old position and adds new position
     * @param oldSlidePreviousIndex -old Corousal Previous Index
     * @param oldSlideActiveIndex -old Corousal Active Index
     * @param oldSlideNextIndex -old Corousal Next Index
     * @param newSlidePreviousIndex -new Corousal Previous Index
     * @param newSlideActiveIndex -new Corousal Active Index
     * @param newSlideNextIndex -new Corousal Next Index
     */
    private changeCorousalPosition(
        oldSlidePreviousIndex: number,
        oldSlideActiveIndex: number,
        oldSlideNextIndex: number,
        newSlidePreviousIndex: number,
        newSlideActiveIndex: number,
        newSlideNextIndex: number,
    ) {
        this.corousalList[oldSlidePreviousIndex].classList.remove('previousProduct');
        this.corousalList[oldSlidePreviousIndex].classList.remove('activeProduct');
        this.corousalList[oldSlidePreviousIndex].classList.remove('nextProduct');

        this.corousalList[oldSlideActiveIndex].classList.remove('previousProduct');
        this.corousalList[oldSlideActiveIndex].classList.remove('activeProduct');
        this.corousalList[oldSlideActiveIndex].classList.remove('nextProduct');

        this.corousalList[oldSlideNextIndex].classList.remove('previousProduct');
        this.corousalList[oldSlideNextIndex].classList.remove('activeProduct');
        this.corousalList[oldSlideNextIndex].classList.remove('nextProduct');

        this.corousalList[newSlidePreviousIndex].classList.add('previousProduct');
        this.corousalList[newSlideActiveIndex].classList.add('activeProduct');
        this.corousalList[newSlideNextIndex].classList.add('nextProduct');
    }

    /**
     * Used for scroll up and down
     */
    public scrollToUpDown(): void {
        // calls for scroll to Down
        this.domOperationUtilsService.scrollToUpDown('html', 'DOWN');
    }
}
