import {
    advanceScatterOptions,
    BEST_FIT,
    STEVENS_CURVE,
    scatterLegend,
    DEFAULT_POINT_COLOR,
    MANUAL_LINEAR_CURVE,
    MANUAL_SMOOTH_CURVE,
    SGFindSeries,
    ScattergraphSeriesNames,
    SELECTED_COLOR,
    COLEBROOK_CURVE,
} from './scatter-graph-constants';
import { optimizeScatterData } from './scatter-graph-utils';
import { Injectable } from '@angular/core';
import { AdvanceScattergraphComponent, FROUDE_LINE, ISO_Q_LINE } from './advance-scattergraph.component';
import * as Highcharts from 'highcharts';
import { StringUtils } from 'app/shared/utils/string-utils';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { AdvanceScatterOptions, EntityData, ScatterData, ScatterSelectionBox, ScatterPoint, ScatterSeries } from 'app/shared/models/scatter-data';
import { ANNOTATION_IGNORED_MARKER_COLOR, ANNOTATION_MARKER_COLOR, DEPTH_ENTITY, EDIT_COLOR, RAW_VELOCITY_ENTITY, RED_COLOR_HEX, VELOCITY_ENTITY } from 'app/shared/constant';
import { DataEditOriginalValue, DataEditPreview, DataEditStoreActionTypes } from 'app/shared/models/data-edit';
import { SeparateWindowActionTypes } from 'app/shared/models/view-data';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { MathUtils } from 'app/shared/utils/math-utils';
import { X } from '@angular/cdk/keycodes';
import { ConfirmationEntitiesEnum } from 'app/shared/models/view-data-filter';
import { SGSelectionMode } from '../scattergraph-edit-menu/scattergraph-edit-menu.component';
import { LassoSelectionReceiver } from './scatter-lasso-selection';
const highcharts = require('highcharts');

export const ACTIVE_ZOOM = 'activeZoomFlag';
export const SELECT_DATA = 'activeDataFlag';
export const CLEAR_SELECT = 'activeClearFlag';
export const IGNORE_DATA = 'activeFlagPoint';
export const UNIGNORE_DATA = 'activeUnflagPoint';
const MANUAL_CURVE = 'manualCurveAdd';

@Injectable()
export class ScatterGraphBuilder implements LassoSelectionReceiver {
    componentInstance: AdvanceScattergraphComponent;
    constructor(
        private viewDataService: ViewDataService,
        private separateWindowService: SeparateWindowHydrographService
    ) {}

    public initializeOptions(
        component: AdvanceScattergraphComponent,
        depthVelocityData: EntityData[],
        confirmations = [],
        tooltipConf: {isStatic: boolean, additionalText?: (tooltip, event) => string[] | null}
    ): AdvanceScatterOptions {
        this.componentInstance = component;
        const initialOptions: AdvanceScatterOptions = JSON.parse(JSON.stringify(advanceScatterOptions));
        initialOptions.chart.renderTo = this.componentInstance.advanceScatterId;
        initialOptions.chart.events.selection = this.selection.bind(this);
        initialOptions.chart.events.click = this.selectXYonPlot.bind(this);
        // #41825 Redraw pipe
        initialOptions.chart.events.redraw = this.redrawChart.bind(this);
        const plotEvents = {
            click: this.plotOnClick.bind(this),
            mouseOver: this.plotMouseOver.bind(this),
            mouseOut: this.plotMouseOut.bind(this),
        };

        let maxY: number;
        const maxX = MathUtils.getMathMaxMin(depthVelocityData.map(v => v.x), false);
        if (component.editedPoints && component.editedPoints.length) {
            const depth = component.editedPoints.filter(v => v.id === DEPTH_ENTITY).map(v => v.value);
            const velocity = component.editedPoints.filter(v => v.id === VELOCITY_ENTITY).map(v => v.value);

            maxY = component.annotationSettings.isScatterInvert ? MathUtils.getMathMaxMin([...depthVelocityData.map(v => v.y), ...velocity], false)
            : MathUtils.getMathMaxMin([...depthVelocityData.map(v => v.y), ...depth], false);
        } else {
            maxY = MathUtils.getMathMaxMin(depthVelocityData.map(v => v.y), false);
        }
        this.populateAxesWithValues(initialOptions, maxX, maxY);
        initialOptions.plotOptions.scatter.point.events = plotEvents;
        initialOptions.series[0].color = this.componentInstance.annotationSettings.isDataQuality
            ? depthVelocityData[0].color
            : DEFAULT_POINT_COLOR;
        initialOptions.series[0].data = depthVelocityData;

        initialOptions.series[0].events = {
            legendItemClick: (event) => {
                if (this.componentInstance.annotationSettings.isDataQuality) {
                    return false;
                }
            },
        };

        initialOptions.legend = this.setScatterGraphLegends();
        highcharts.setOptions({
            global: {
                useUTC: true,
            },
        });

        this.setPointFormatter(initialOptions, this.componentInstance, this, initialOptions.series[0]);

        initialOptions.tooltip = this.getTooltip(tooltipConf);

        if (component.annotationSettings.isConfirmationPoints) {
            const selectedConfirmationEntity = this.componentInstance.selectedConfirmationEntity;
            let velConfirmationLabel: string = '';
            if (selectedConfirmationEntity === ConfirmationEntitiesEnum.Average) {
                velConfirmationLabel = this.componentInstance.translate.instant('LOCATION_DASHBOARD.CONFIRMATION_AVG_VELOCITY');
            } else if (selectedConfirmationEntity === ConfirmationEntitiesEnum.Peak) {
                velConfirmationLabel = this.componentInstance.translate.instant('LOCATION_DASHBOARD.CONFIRMATION_PEAK_VELOCITY');
            }
            const xAxisEntName = component.xAxisLabel.split(' ')[0];
            const yAxisEntName = component.yAxisLabel.split(' ')[0];

            const confirmation = this.componentInstance.translate.instant('LOCATION_DASHBOARD.CONFIRMATION_LABEL');
            const xAxisLabel = component.annotationSettings.isScatterInvert ? `${confirmation} ${xAxisEntName}` : velConfirmationLabel;
            const yAxisLabel = component.annotationSettings.isScatterInvert ? velConfirmationLabel : `${confirmation} ${yAxisEntName}`;

            confirmations.forEach((v) => {
                const [x, y, dateTime, ignore, flagged] = v.split(':').map((i) => i.replace(/\]|\[/g, ''));
                const isFlagged = !!flagged;
                const markerColor = isFlagged ? ANNOTATION_IGNORED_MARKER_COLOR : ANNOTATION_MARKER_COLOR;
                const confirmationSeries: ScatterSeries = {
                    data: [
                        {
                            xUnit: component.xUnit,
                            yUnit: component.yUnit,
                            xPrecision: component.xAxisPrecision,
                            yPrecision: component.yAxisPrecision,
                            xAxisEntity: xAxisLabel,
                            yAxisEntity: yAxisLabel,
                            x: Number(component.annotationSettings.isScatterInvert ? y : x),
                            y: Number(component.annotationSettings.isScatterInvert ? x : y),
                            dateTime: dateTime * 1000,
                        },
                    ],
                    type: 'line',
                    showInLegend: false,
                    name: confirmation,
                    zIndex: 5,
                    fillColor: markerColor,
                    dashStyle: 'shortdash',
                    marker: { symbol: 'triangle-down', enabled: true, fillColor: markerColor, radius: 5, color: markerColor },
                    lineWidth: 1,
                    precision: 2,
                    location: 'test location',
                    step: 'right',
                    point: { events: plotEvents },
                    tooltip: {
                        useHTML: true,
                        headerFormat: '',
                        shared: true,
                    },
                };

                this.setPointFormatter(initialOptions, this.componentInstance, this, confirmationSeries);
                this.updateGraphScale(confirmationSeries.data, initialOptions);
                initialOptions.series.push(confirmationSeries);
            });
        }

        return initialOptions;
    }

    public redrawChart() {
        // #41825 Redraw pipe according to new zoom level
        this.componentInstance.setMaxYAxisValueByPipe();
    }

    public setPointFormatter(options, context, that, series: ScatterSeries) {
        series.tooltip.pointFormatter = function () {
            return that.pointFormatter.call(this, context);
        };
    }

    public updatePointFormatter(chart, tooltipConf: {isStatic: boolean, additionalText?: (tooltip, event) => string[] | null}) {
        if(!chart) return;
        const tooltipOptions = this.getTooltip(tooltipConf);
        chart.update({tooltip: tooltipOptions});
    }

    private getTooltip(tooltipConf: {isStatic: boolean, additionalText?: (tooltip, event) => string[] | null}) {
        const formatterFn = function (tooltip) {

            const defaultFormat = tooltip.defaultFormatter.call(this, tooltip);
            if(!tooltipConf.additionalText) {
                return defaultFormat;
            } else {
                const additionalFormat = tooltipConf.additionalText(tooltip, this);

                if(additionalFormat) {
                    return [...defaultFormat, ...additionalFormat];
                } else {
                    return defaultFormat;
                }
            }
        }

        return tooltipConf.isStatic ?
            {
                enabled: true,
                shape: 'rect',
                positioner: function() {
                    return {
                        x: this.chart.chartWidth - this.label.width - 50,
                        y: 0,
                    };
                },
                formatter: formatterFn
            }
            :
            {
                enabled: true,
                formatter: formatterFn
            }

    }

    public updateGraphScale(data: { x?: number, y?: number }[], options: AdvanceScatterOptions) {
        if(!data) return;

        const graph = this.componentInstance;

        let xMax = MathUtils.getMathMaxMin(data.map(v => v.x), false);
        let yMax = MathUtils.getMathMaxMin(data.map(v => v.y), false);

        yMax = yMax ? (yMax + (yMax * 0.2)) : 0;
        xMax = xMax ? (xMax + (xMax * 0.2)) : 0;

        if (!graph.maximumXvalue || graph.maximumXvalue < xMax) {
            graph.maximumXvalue = xMax;
        }

        if (!options.yAxis.max || options.yAxis.max < yMax) {
            options.yAxis.max = yMax;
        }

    }

    public onSelectionForIgnore(originalValues: DataEditOriginalValue[]) {
        const graph = this.componentInstance;
        let start, end;
        graph.chart.showLoading();
        const isRawVelEntitySelected = graph.selectedEntityIds.includes(RAW_VELOCITY_ENTITY);
        if (graph.syncZoomWithHg && graph.zoomFromHydro && graph.zoomFromHydro.length) {
            [start, end] = graph.zoomFromHydro;
        } else {
            const filters = this.viewDataService.filterValues.getValue();
            start = filters.startDate;
            end = filters.endDate;
        }
        const apiEditingCurve = graph.dataEditService.apiCurveForUICurve(graph.annotationSettings);

        graph.viewDataService
            .editScatterGraph(
                graph.customerId,
                graph.viewDataService.filterValues.getValue(),
                graph.annotationSettings.isScatterInvert,
                graph.ignoredPointsData.map((v) => v.dateTime) as number[],
                apiEditingCurve,
                new Date(start), new Date(end), isRawVelEntitySelected
            )
            .subscribe(
                (scatterResult: DataEditPreview) => {
                    if (!scatterResult || !scatterResult.sgd) {
                        return graph.chart.hideLoading();
                    }

                    if (scatterResult.c) {
                        if (graph.data && graph.data.curves && graph.data.curves.length) {
                            graph.shouldUpdateCurve = true;
                            graph.data.curves[0].d = [...scatterResult.c];
                        }
                    }

                    graph.dataEditService.cacheEdit(originalValues, scatterResult, false, true);
                    this.handleCurveAPIResponse(scatterResult, true);

                    graph.chart.hideLoading();
                },
                (error) => {
                    graph.chart.hideLoading();
                },
            );
    }

    public handleCurveAPIResponse(scatterResult: DataEditPreview, storeEdit = false) {
        const graph = this.componentInstance;

        if(!graph) return;

        this.onIgnorePointsResponse(scatterResult, storeEdit);
        this.separateWindowService.dispatchAction({
            type: SeparateWindowActionTypes.ignoreSGpoints,
            payload: { points: graph.flaggedPointsData, res: scatterResult }
        });
    }

    public onIgnorePointsResponse(scatterResult: DataEditPreview, storeEdit = false) {
        if (!scatterResult) {
            return;
        }
        const graph = this.componentInstance;
        const currentCurveName = graph.pickCurveKey();

        const oldCurveValues = graph.advanceScatteroptions.series.find(v => v.name === currentCurveName);

        if (storeEdit && oldCurveValues) {
            const curveData: { x: number; y: number; }[] = [];
            oldCurveValues.data.forEach(v => curveData.push({ x: v.x, y: v.y }));

            const cachedDistances = new Map<number, number>();
            graph.editedDistances.forEach((v, k) => cachedDistances.set(k, v));

            const edits = graph.dataEditService.storedEdits;
            const lastEdit = edits[edits.length - 1];

            if (lastEdit) {
                const inverted = graph.annotationSettings.isScatterInvert;
                lastEdit.originalDistances = cachedDistances;
                lastEdit.originalCurve = curveData.map(v => (inverted ? `[${v.y}:${v.x}]` : `[${v.x}:${v.y}]`));
            }
        }

        const shouldUpdateCurve = currentCurveName && currentCurveName !== MANUAL_LINEAR_CURVE && currentCurveName !== MANUAL_SMOOTH_CURVE;

        if (shouldUpdateCurve) {
            if (scatterResult && scatterResult.sgd && scatterResult.sgd.length) {
                graph.updateDistances(scatterResult.sgd);
            }

            const inverted = graph.annotationSettings.isScatterInvert;
            this.onEditScatterGraph({ c: (scatterResult as DataEditPreview).c}, inverted);
            graph.plotToleranceLines(graph.viewDataService.scatterToleranceRangeValue, graph.selected)
        }
    }

    public unSelectPoints() {
        const graph = this.componentInstance;

        if (!graph?.selectedPoints.length) {
            return;
        }

        graph.selectedPoints = [];
        graph.dataSelect.emit(false);
        graph.setSelectedSeries();

        const snappedForHG = graph.previousSnappedPoints.map((v) => v.dateTime);

        graph.dataSelectForSnap.emit({ selected: [], snapped: snappedForHG });
    }

    private filterRangeFn(highlightPoints: ScatterSelectionBox): (v: EntityData) => boolean {
        const filterFn = (v: EntityData) =>
            v &&
            v.x >= highlightPoints['xMinValue'] &&
            v.x <= highlightPoints['xMaxValue'] &&
            v.y >= highlightPoints['yMinValue'] &&
            v.y <= highlightPoints['yMaxValue'];

        return filterFn;
    }

    private getSelectedPoints(filterFn: (v: EntityData) => boolean, unFlag = false, isSelection = false, isIgnore = false): EntityData[] {
        const graph = this.componentInstance;
        const scatterSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.scatter);
        const editedDataSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.edited);
        const flaggedSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.flagged);
        const snappedSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.snapped);
        const prevSnappedSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.previousSnapped);

        /** SG DATA: apply to both points and edited points */
        // First generate list of points that could be affected
        let scatterPoints = [];
        if (unFlag && flaggedSeries) {
          scatterPoints = [...scatterSeries?.data, ...editedDataSeries ? editedDataSeries.data : [], ...flaggedSeries.data];
        } else if (isSelection && editedDataSeries) {
          scatterPoints = [...scatterSeries?.data, ...editedDataSeries.data];
        } else {
          scatterPoints = [...scatterSeries?.data];

          if (editedDataSeries && editedDataSeries.data.length) {
            scatterPoints.push(...editedDataSeries.data);
          }

          if (snappedSeries && snappedSeries.data.length) {
            scatterPoints.push(...snappedSeries.data);
          }

          if (prevSnappedSeries && prevSnappedSeries.data.length) {
            scatterPoints.push(...prevSnappedSeries.data)
          }
        }

        const pointsWithinRange = scatterPoints.filter(v => filterFn(v));
        // if no curve plotted then do not check distances
        if (!graph.pickCurveKey()) {
            return pointsWithinRange;
        }

        // Final calcs for resulting points
        // If unflagging points, don't care about tolerange lines
        // Otherwise, filter to only include points outside
        // shown tolerance line(s)
        if (unFlag) { return pointsWithinRange; }
        // Finally filter to only include points outside tolerance line(s)
        // And return the result

        // #40533 we need to include points that are outside of the curve, that points will have distance = null
        if (isIgnore) {
            switch (graph.selected) {
                case 3: // 'Above' and 'below' shown
                    return pointsWithinRange.filter(
                        (v) => v.distance === null || Math.abs(v.distance) > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor);
                case 2: // Only 'below' shown
                    return pointsWithinRange.filter(
                        (v) => v.distance === null || v.distance > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor);
                case 1: // only 'above' shown
                    return pointsWithinRange.filter(
                        (v) => v.distance === null || (-v.distance > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor));
                default: // No tolerance lines selected
                    return pointsWithinRange;
            }
        } else {
            switch (graph.selected) {
                case 3: // 'Above' and 'below' shown
                    return pointsWithinRange.filter(
                        (v) => Math.abs(v.distance) > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor);
                case 2: // Only 'below' shown
                    return pointsWithinRange.filter(
                        (v) => v.distance > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor);
                case 1: // only 'above' shown
                    return pointsWithinRange.filter(
                        (v) => -v.distance > graph.viewDataService.scatterToleranceRangeValue * graph.scalingFactor);
                default: // No tolerance lines selected
                    return pointsWithinRange;
            }
        }
    }

    private checkBeforeSelectPoints(highlightPoints: ScatterSelectionBox) {
        const graph = this.componentInstance;
        // If you have a manual curve, distance always null. Need to calculate it here
        if (graph.annotationSettings.isManualLinear || graph.annotationSettings.isManualSmooth) {
            let curvePoints = graph.viewDataService.scatterManualCurveSelectedPoints.getValue();
            if(graph.annotationSettings.isManualSmooth || graph.annotationSettings.isManualLinear) {
                curvePoints = this.getPointsFromSVGCurve(curvePoints.map(p => {return {...p, plotX: p.x, plotY: p.y}}));
            }
            this.getDistancesForManualCurve(curvePoints).subscribe((res) => {
                if (res && res.sgd) {
                    graph.updateDistances(res.sgd);
                    this.onSelectData(highlightPoints);
                }
            });
        } else {
            this.onSelectData(highlightPoints);
        }
    }

    private lassoComputeSelected(lassoSelectedPoints, unFlag = false, isSelection = false, isIgnore = false) {
        const dateTimeAssoc = [];
        for(const p of lassoSelectedPoints) {
            dateTimeAssoc[p.dateTime] = true;
        }

        const lassoSelectFn = (p) => dateTimeAssoc[p.dateTime];

        return this.getSelectedPoints(lassoSelectFn, unFlag, isSelection, isIgnore);
    }


    private lassoIgnorePoints(lassoSelectedPoints) {

        const graph = this.componentInstance;
        const selectedPoints = this.lassoComputeSelected(lassoSelectedPoints, false, false, true);
        const originalValues: DataEditOriginalValue[] = [];
        selectedPoints.forEach(v => originalValues.push({
            action: DataEditStoreActionTypes.Ignore, dateTime: (v.dateTime as number),
            x: v.x, y: v.y, eid: DEPTH_ENTITY
        }));

        let ignored: EntityData[] = [...graph.ignoredPointsData, ...selectedPoints];
        ignored = ignored.filter((v, i) => v.dateTime && ignored.findIndex((x: EntityData) => x.dateTime === v.dateTime) === i); //remove duplicate flag points
        graph.ignoredPointsData = ignored;
        graph.ignoredSeriesSet();
        graph.ignoredSeriesNotify();
        this.onSelectionForIgnore(originalValues);
    }

    private lassoUnIgnorePoints(lassoSelectedPoints) {
        const graph = this.componentInstance;
        const selectedPoints = this.lassoComputeSelected(lassoSelectedPoints, true);

        const originalValues: DataEditOriginalValue[] = [];
        selectedPoints.forEach(v => {
            if (graph.ignoredPointsData.find(i => i.dateTime === v.dateTime)) {
                originalValues.push({
                    action: DataEditStoreActionTypes.Unignore, dateTime: (v.dateTime as number),
                    x: v.x, y: v.y, eid: DEPTH_ENTITY
                });
            }
        });

        graph.ignoredPointsData = graph.ignoredPointsData.filter(
            (v) => !selectedPoints.find((i: EntityData) => i.dateTime === v.dateTime),
        );

        // #29387 if user is unignoring flagged points, then we need to add unflagged points to scatter series
        const unignoredPointsMap = selectedPoints.reduce((acc, curr: EntityData) => acc.set(curr.dateTime as number, curr), new Map<number, EntityData>());
        const scatterSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.scatter);
        scatterSeries.data.forEach(v => {
            if (unignoredPointsMap.get(v.dateTime as number)) {
                unignoredPointsMap.delete(v.dateTime as number);
            }
        });

        if (unignoredPointsMap.size > 0) {
            const toAddPoints = unignoredPointsMap.values();
            scatterSeries.data = [...scatterSeries.data, ...toAddPoints];
        }

        graph.ignoredSeriesSet();
        graph.ignoredSeriesNotify();
        this.onSelectionForIgnore(originalValues);
    }


    private lassoSelectPoints(lassoSelectedPoints) {
        const graph = this.componentInstance;
        const selectedPoints = this.lassoComputeSelected(lassoSelectedPoints);
        graph.selectedPoints = selectedPoints;

        graph.viewDataService.selectedSnappedPoints.next(graph.selectedPoints);

        if (graph.selectedPoints.length) {
            graph.dataSelect.emit(true);
        } else {
            graph.dataSelect.emit(false);
        }

        graph.setSelectedSeries();

        const snappedForHG = graph.previousSnappedPoints.map((v) => v.dateTime);
        const selectedForHG = graph.selectedPoints.map((v) => v.dateTime);

        graph.dataSelectForSnap.emit({ selected: selectedForHG, snapped: snappedForHG });
    }

    public onLassoSelection(lassoSelectedPoints) {
        const graph = this.componentInstance;
        if(graph.selectionMode !== SGSelectionMode.Lasso) return;

        const menuSelectedItem = graph.viewDataService.scatterMenuItem.getValue();
        const isEditMode = graph.isDataEditingModeEnabled;

        if(menuSelectedItem === IGNORE_DATA) {
            this.lassoIgnorePoints(lassoSelectedPoints);
        }
        if(menuSelectedItem === UNIGNORE_DATA) {
            this.lassoUnIgnorePoints(lassoSelectedPoints);
        }
        if (menuSelectedItem === SELECT_DATA && isEditMode) {
            this.lassoSelectPoints(lassoSelectedPoints);
        }
    }

    private onSelectData(highlightPoints: ScatterSelectionBox) {
        const graph = this.componentInstance;
        graph.selectedPoints = this.getSelectedPoints(this.filterRangeFn(highlightPoints), false, true);
        this.updateAxeValuesByHighlightPoints(highlightPoints);
        graph.viewDataService.selectedSnappedPoints.next(graph.selectedPoints);

        if (graph.selectedPoints.length) {
            graph.dataSelect.emit(true);
        } else {
            graph.dataSelect.emit(false);
        }

        graph.setSelectedSeries();

        const snappedForHG = graph.previousSnappedPoints.map((v) => v.dateTime);
        const selectedForHG = graph.selectedPoints.map((v) => v.dateTime);

        graph.dataSelectForSnap.emit({ selected: selectedForHG, snapped: snappedForHG });
    }

    private onClearData(highlightPoints) {
        const graph = this.componentInstance;

        const unSelectPoints = this.getSelectedPoints(this.filterRangeFn(highlightPoints));
        graph.selectedPoints = graph.selectedPoints.filter(
            (v) => !unSelectPoints.find((i: any) => i.dateTime === v.dateTime),
        );
        graph.viewDataService.selectedSnappedPoints.next(graph.selectedPoints);

        if (graph.selectedPoints.length) {
            graph.dataSelect.emit(true);
        } else {
            graph.dataSelect.emit(false);
        }

        graph.setSelectedSeries();

        const snappedForHG = graph.previousSnappedPoints.map((v) => v.dateTime);
        const selectedForHG = graph.selectedPoints.map((v) => v.dateTime);

        graph.dataSelectForSnap.emit({ selected: selectedForHG, snapped: snappedForHG });
    }

    private selection(event) {
        const graph = this.componentInstance;
        const menuSelectedItem = graph.viewDataService.scatterMenuItem.getValue();
        const isEditMode = graph.isDataEditingModeEnabled;

        if (event.resetSelection) {
            graph.zoomEvent = null;
            graph.setScatterGraphValueToService();
            return false;
        }

        if (menuSelectedItem === SELECT_DATA && isEditMode && graph.selectionMode === SGSelectionMode.Lasso) {
            return false;
        }

        if (graph.selectionMode === SGSelectionMode.Lasso && (menuSelectedItem === IGNORE_DATA || menuSelectedItem === UNIGNORE_DATA)) {
            return false;
        }

        if (menuSelectedItem === SELECT_DATA && isEditMode) {
            const highlightPoints = this.calculateScatterHighLightPoint(event);
            this.checkBeforeSelectPoints(highlightPoints);
            return false;
        }

        if (menuSelectedItem === CLEAR_SELECT) {
            const highlightPoints = this.calculateScatterHighLightPoint(event);
            this.onClearData(highlightPoints);

            return false;
        }

        if (menuSelectedItem === IGNORE_DATA) {
            const highlightPoints = this.calculateScatterHighLightPoint(event);
            const selectedPoints = this.getSelectedPoints(this.filterRangeFn(highlightPoints), false, false, true);

            const originalValues: DataEditOriginalValue[] = [];
            selectedPoints.forEach(v => originalValues.push({
                action: DataEditStoreActionTypes.Ignore, dateTime: (v.dateTime as number),
                x: v.x, y: v.y, eid: DEPTH_ENTITY
            }));

            let ignored: EntityData[] = [...graph.ignoredPointsData, ...selectedPoints];
            ignored = ignored.filter((v, i) => v.dateTime && ignored.findIndex((x: EntityData) => x.dateTime === v.dateTime) === i); //remove duplicate flag points
            graph.ignoredPointsData = ignored;
            graph.ignoredSeriesSet();
            graph.ignoredSeriesNotify();
            this.onSelectionForIgnore(originalValues);

            return false;
        }

        if (menuSelectedItem === UNIGNORE_DATA) {
            const highlightPoints = this.calculateScatterHighLightPoint(event);
            const selectedPoints = this.getSelectedPoints(this.filterRangeFn(highlightPoints), true);

            const originalValues: DataEditOriginalValue[] = [];
            selectedPoints.forEach(v => {
                if (graph.ignoredPointsData.find(i => i.dateTime === v.dateTime)) {
                    originalValues.push({
                        action: DataEditStoreActionTypes.Unignore, dateTime: (v.dateTime as number),
                        x: v.x, y: v.y, eid: DEPTH_ENTITY
                    });
                }
            });

            graph.ignoredPointsData = graph.ignoredPointsData.filter(
                (v) => !selectedPoints.find((i: EntityData) => i.dateTime === v.dateTime),
            );

            // #29387 if user is unignoring flagged points, then we need to add unflagged points to scatter series
            const unignoredPointsMap = selectedPoints.reduce((acc, curr: EntityData) => acc.set(curr.dateTime as number, curr), new Map<number, EntityData>());
            const scatterSeries = SGFindSeries(graph.advanceScatteroptions.series, ScattergraphSeriesNames.scatter);
            scatterSeries.data.forEach(v => {
                if (unignoredPointsMap.get(v.dateTime as number)) {
                    unignoredPointsMap.delete(v.dateTime as number);
                }
            });

            if (unignoredPointsMap.size > 0) {
                const toAddPoints = unignoredPointsMap.values();
                scatterSeries.data = [...scatterSeries.data, ...toAddPoints];
            }

            graph.ignoredSeriesSet();
            graph.ignoredSeriesNotify();
            this.onSelectionForIgnore(originalValues);

            return false;
        }

        if (menuSelectedItem === ACTIVE_ZOOM || !isEditMode) {
            if (event.yAxis) {
                graph.zoomEvent = event;
                const datePoints = this.findDatePointsOnSelection(event);
                graph.viewDataService.selectedScatterPoints.next(datePoints);
            }

            this.scalePipeOverlayToUserZoom(event);
        }
    }

    // scale pipe overlay to match scaling of scattergraph on user zoom
    // #11545 added isscatterinvert check since choosing of scatterinvert doesnt change current value of ispipeoverlay
    private scalePipeOverlayToUserZoom(event) {
        const graph = this.componentInstance;
        const { annotationSettings, data, pipeOverlaySettings } = graph;
        const { isScatterInvert, isPipeOverlay, isToleranceLines, isBestFit, isSSCurve } = annotationSettings;

        if (isPipeOverlay && data && data.pipeOverlay && !event.resetSelection && !isScatterInvert) {
            const { pipeHeight, minimumY, maximumY, xForMaximumY, xForMinimumY } = pipeOverlaySettings;
            setTimeout(() => {
                this.plotScatterGraphRenderPipe(pipeHeight, minimumY, maximumY, xForMinimumY, xForMaximumY, '#696969');
            });
        }

        if (isToleranceLines && !event.resetSelection && (isSSCurve || isBestFit)) {
            graph.isZoomedIn = true;
        }
    }

    private calculateScatterHighLightPoint(event) {
        return <ScatterSelectionBox> {
            xMinValue: event.xAxis[0].min,
            xMaxValue: event.xAxis[0].max,
            yMinValue: event.yAxis[0].min,
            yMaxValue: event.yAxis[0].max,
        };
    }

    private findDatePointsOnSelection(event) {
        const points = event.target.series[0].options.data;
        const { min: xMin, max: xMax } = event.xAxis[0];
        const { min: yMin, max: yMax } = event.yAxis[0];

        return points.filter(({ x, y }) => x > xMin && x < xMax && y > yMin && y < yMax).map((point) => point.dateTime);
    }

    private updateAxeValuesByHighlightPoints({ xMinValue, xMaxValue, yMinValue, yMaxValue }) {
        this.componentInstance.xValues = { min: xMinValue, max: xMaxValue };
        this.componentInstance.yValues = { min: yMinValue, max: yMaxValue };
    }

    public onEditScatterGraph({ c: result }: { c?: string[] }, inverted = false) {
        const isCurveLocked = this.viewDataService.lockSGCurve.getValue();

        if (!result || isCurveLocked) return;

        const { xUnit, yUnit } = this.componentInstance;
        const newCurve = optimizeScatterData(result, xUnit, yUnit, inverted);

        this.checkAndUpdateChartData(newCurve);
    }

    public setYaxisMax() {
        // #38803 #36646 we should not change the graph scale for froude and iso-q lines
        const notScaledSeries = [FROUDE_LINE, ISO_Q_LINE];
        const graph = this.componentInstance;
        const { advanceScatteroptions, pipeOverlaySettings, annotationSettings, data } = graph;

        advanceScatteroptions.yAxis.max = 0;
        advanceScatteroptions.series.forEach(v => {
            if (notScaledSeries.includes(v.name)) {
                return;
            }

            this.updateGraphScale(v.data, advanceScatteroptions)
        });

        if (data.yAxis.annotations && data.yAxis.annotations.length && annotationSettings.isScatterInvert === false) {
            const maxAnnotation = Math.max(...data.yAxis.annotations.map(v => v.value));
            advanceScatteroptions.yAxis.max = maxAnnotation > advanceScatteroptions.yAxis.max ? maxAnnotation : advanceScatteroptions.yAxis.max;
        }

        if (annotationSettings.isPipeOverlay && pipeOverlaySettings && pipeOverlaySettings.maximumSurchargeY) {
            advanceScatteroptions.yAxis.max = pipeOverlaySettings.maximumSurchargeY > advanceScatteroptions.yAxis.max ?
                pipeOverlaySettings.maximumSurchargeY : advanceScatteroptions.yAxis.max;
        }
    }

    public setCustomDashboardLegends(options: AdvanceScatterOptions) {
        options.legend = {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            squareSymbol: false,
            symbolHeight: 10,
            symbolRadius: 0,
            labelFormatter: function () {
                if (!this.xAxis || !this.yAxis || !this.xAxis.userOptions || !this.yAxis.userOptions) {
                    return this.name;
                }

                const xAxis = this.options.data[0].xAxisEntity;
                const yAxis = this.options.data[0].yAxisEntity;

                return `${this.name} (${xAxis}/${yAxis})`;
            },
        }
    }

    private checkAndUpdateChartData(newCurve) {
        if (this.componentInstance.annotationSettings.isBestFit) {
            this.updateChartLineData(BEST_FIT, newCurve);
        }

        if (this.componentInstance.annotationSettings.isSSCurve) {
            this.updateChartLineData(STEVENS_CURVE, newCurve);
        }

        if (this.componentInstance.annotationSettings.isManualLinear) {
            this.updateChartLineData(MANUAL_LINEAR_CURVE, newCurve);
        }

        if (this.componentInstance.annotationSettings.isManualSmooth) {
            this.updateChartLineData(MANUAL_SMOOTH_CURVE, newCurve);
        }

        if (this.componentInstance.annotationSettings.isCWRcurve) {
            this.updateChartLineData(COLEBROOK_CURVE, newCurve);
        }
    }

    private updateChartLineData(key: string, newCurve) {
        const graph = this.componentInstance;

        const curve = graph.advanceScatteroptions.series.find((v) => v.name === key);

        if (!curve) return;

        curve.data = newCurve;

        graph.setScatterGraphValueToService();
    }

    public swapDataPoints(data) {
        /* Adding Units to the first data point */
        data[0]['xUnits'] = this.componentInstance.xUnit;
        data[0]['yUnits'] = this.componentInstance.yUnit;
        for (let i = 0; i < data.length; i++) {
            data[i]['x'] = data[i]['x'] + data[i]['y'];
            data[i]['y'] = data[i]['x'] - data[i]['y'];
            data[i]['x'] = data[i]['x'] - data[i]['y'];
        }
        return data;
    }

    private plotMouseOver(event) {
        if (this.viewDataService.scatterMenuItem.getValue() === MANUAL_CURVE) {
            return;
        }

        const graph = this.componentInstance;

        graph.viewDataService.correlateAdvancedGraphs(
            event.target.series.chart.container,
            event.target.dateTime,
        );

        this.separateWindowService.dataHoverSubject.next({ id: 0, dateTime: event.target.dateTime });
        if (event && event.target && event.target.graphic) {
            event.target.graphic.toFront(); // To highlight scatter point if its overlapping with other points for more visibility
        }

        const current = graph.linkedData.get(event.target.dateTime);

        if (current) {
            const next = graph.linkedData.get(current.next);
            const prev = graph.linkedData.get(current.prev);

            graph.highlightLinkedPointsSubject.next([prev, next]);
        }
    }

    private plotMouseOut(event) {
        if (this.viewDataService.scatterMenuItem.getValue() === MANUAL_CURVE) {
            return;
        }

        const graph = this.componentInstance;

        graph.highlightLinkedPointsSubject.next([]);
        graph.viewDataService.setChartParameterAdvanced(
            event.target.dateTime,
            graph.hydrographChartIndex,
        );
        this.separateWindowService.dataHoverSubject.next({ id: 0, dateTime: null });
    }

    public isLasso() {
        const graph = this.componentInstance;
        const isEditMode = graph.isDataEditingModeEnabled;
        const menuSelectedItem = graph.viewDataService.scatterMenuItem.getValue();

        return graph.selectionMode === SGSelectionMode.Lasso && (
            (isEditMode && menuSelectedItem === SELECT_DATA)
            || menuSelectedItem === IGNORE_DATA
            || menuSelectedItem === UNIGNORE_DATA
        );
    }

    public installLassoSelection(chart: Highcharts.ChartObject, component: AdvanceScattergraphComponent) {
        if(!chart.container) {
            return;
        }

        component.lassoSelection.chart = chart;
        component.lassoSelection.receiver = this;
        component.lassoSelection.component = component;

        chart.container.addEventListener('mousedown', e => component.lassoSelection.lassoMouseDown(e));
        chart.container.addEventListener('mousemove', e => component.lassoSelection.lassoMouseMove(e));
        chart.container.addEventListener('mouseup', () => component.lassoSelection.lassoMouseUp());
    }

    private selectXYonPlot(event) {
        // Handles actual selection of location on chart and converting into rounded values

        // If you're not in point select or in edit mode, just return
        const graph = this.componentInstance;
        const menuSelectedItem = graph.viewDataService.scatterMenuItem.getValue();
        if(!this.viewDataService.isCurveEditable || menuSelectedItem !== MANUAL_CURVE) {
            return;
        }

        // Actual value conversions of the selected loction, round to 3 decimal places
        const roundedXValue = Math.round( 1000 * event.xAxis[0].value ) / 1000;
        const roundedYValue = Math.round( 1000 * event.yAxis[0].value ) / 1000;
        this.updateManualCurvePoints(roundedXValue, roundedYValue);
    }
    private updateManualCurvePoints(xValue: number, yValue: number) {
        // Takes value selected on chart and converts into array of selected points, by x value
        const graph = this.componentInstance;
        const isInvert = graph.annotationSettings && graph.annotationSettings.isScatterInvert;
        let pointsArray = graph.viewDataService.scatterManualCurveSelectedPoints.getValue();

        // First add the new point, then sort
        pointsArray.push({x: xValue, y: yValue});
        pointsArray = pointsArray.filter((v, i) => pointsArray.findIndex(x => x.x === v.x && x.y === v.y) === i);
        if (isInvert) { // Sort by x values
            pointsArray.sort((a,b) => {
                return a.x < b.x ? -1 : a.x > b.x ? 1 : 0;
            });
        } else { // Sort by y values
            pointsArray.sort((a,b) => {
                return a.y < b.y ? -1 : a.y > b.y ? 1 : 0;
            });
        }
        graph.refreshToleranceLines();
        graph.viewDataService.scatterManualCurveSelectedPoints.next(pointsArray);
    }

    // gets distances for manually drawn curve
    private getDistancesForManualCurve(points: EntityData[]) {
        const comp = this.componentInstance;

        const isManual = comp.annotationSettings.isManualLinear || comp.annotationSettings.isManualSmooth || this.viewDataService.isCurveEditable;
        const isSmooth = comp.annotationSettings.isManualSmooth;

        const currentEditTimestamp = comp.dataEditService.currentEditTimestamp;
        const isRawVelEntitySelected = comp.selectedEntityIds.includes(RAW_VELOCITY_ENTITY);

        return this.viewDataService.snapScatterGraph(
            comp.customerId,
            this.viewDataService.filterValues.getValue(),
            [],
            comp.annotationSettings.isBestFit,
            comp.annotationSettings.isSSCurve,
            // comp.annotationSettings.isManualLinear,
            isManual && !isSmooth,
            // comp.annotationSettings.isManualSmooth,
            isManual && isSmooth,
            comp.annotationSettings.isCWRcurve,
            comp.annotationSettings.isManningDesign,
            comp.annotationSettings.isLanfearColl,
            points,
            comp.annotationSettings.isScatterInvert,
            this.viewDataService.scatterToleranceRangeValue * comp.scalingFactor,
            currentEditTimestamp === null ? true : false,
            isRawVelEntitySelected
        )
    }
    public clearManualCurvePoints() {
        const graph = this.componentInstance;
        if (graph) {
            graph.viewDataService.scatterManualCurveSelectedPoints.next([]);

            this.clearDistances();
        }
    }

    public clearDistances() {
        const graph = this.componentInstance;

        if (!graph) return;

        const scatterPoints = graph.advanceScatteroptions.series.find(v => v.name === ScattergraphSeriesNames.scatter);
        scatterPoints.data.forEach(v => v.distance = null);

        if (graph.editedDistances && graph.editedDistances.size > 0) {
            Array.from(graph.editedDistances.keys()).forEach(k => graph.editedDistances.set(k, null));
        }
    }

    private plotOnClick(event) {
        if (!event) return;

        // If you're in manual curve mode and select an actual point without rounding
        // Pass the actual point values into manual curve point selection
        if (this.viewDataService.scatterMenuItem.getValue() === MANUAL_CURVE) {
            const pointSelected = event.point;
            this.updateManualCurvePoints(pointSelected.x, pointSelected.y);
            return;
        }

        const graph = this.componentInstance;
        const { isScatterInvert } = graph.annotationSettings;

        graph.dataEditRowSelected = {
            timeStampSelected: event.point.dateTime,
            rawDataSelected: isScatterInvert ? event.point.x : event.point.y,
            entity: isScatterInvert ? event.point.xAxisEntity : event.point.yAxisEntity,
        };

        graph.viewDataService.notifyDataEditingTable.next(graph.dataEditRowSelected);
    }

    private setExtremes(event) {
        const graph = this.componentInstance;
        const { isBestFit, isSSCurve, isToleranceLines, isPipeOverlay, isScatterInvert } = graph.annotationSettings;
        const { min, max } = event;

        if (typeof min !== 'undefined' && typeof max !== 'undefined') {
            graph.viewDataService.resetZoomClicked.next(false);
            return;
        }

        graph.viewDataService.resetZoomClicked.next(true);

        if ((isBestFit || isSSCurve) && isToleranceLines) {
            graph.isZoomedIn = false;
        }

        if (isPipeOverlay && isScatterInvert && graph.data && graph.data.pipeOverlay) {
            const { pipeHeight, minimumY, maximumY, xForMinimumY, xForMaximumY } = graph.pipeOverlaySettings;
            setTimeout(() => {
                this.plotScatterGraphRenderPipe(pipeHeight, minimumY, maximumY, xForMinimumY, xForMaximumY, '#696969');
            });
        }
    }

    private populateAxesWithValues(options, xMax: number, yMax: number) {
        const { data, annotationSettings } = this.componentInstance;
        const { isScatterInvert: invert, isPipeOverlay: pipe } = annotationSettings;

        let maxX = Number(Number(xMax).toFixed(this.componentInstance.xAxisPrecision));
        this.componentInstance.maximumXvalue = maxX + (maxX * 0.2);
        const maxY = (!invert && pipe && data.pipeOverlay && data.pipeOverlay['maximumSurchargeY'] > yMax) ? data.pipeOverlay['maximumSurchargeY'] : (yMax + (yMax * 0.2));
        options.xAxis = {
            gridLineWidth: 1, // Bug#10327 Scattergraph:  Enable the side graph lines
            events: {
                setExtremes: this.setExtremes.bind(this),
            },
            title: {
                enabled: true,
                text: this.componentInstance.xAxisLabel,
            },
            ordinal: false,
            min: this.componentInstance.xAxisMin >= 0 ? 0 : this.componentInstance.xAxisMin,
            endOnTick: false,
        };

        options.yAxis = {
            title: {
                text: this.componentInstance.yAxisLabel,
            },
            min: this.componentInstance.yAxisMin >= 0 ? 0 : this.componentInstance.yAxisMin,
            max: maxY,
            offset:
                !this.componentInstance.annotationSettings.isScatterInvert &&
                this.componentInstance.annotationSettings.isPipeOverlay &&
                this.componentInstance.data &&
                this.componentInstance.data.pipeOverlay
                    ? 70
                    : 0,
            lineWidth: 0.2,
            endOnTick: false,
        };
    }

    //method is used, dont remove
    private pointFormatter(context: AdvanceScattergraphComponent, isConfirmation = false) {
        const tooltipTimeFormat = context.tooltipTimeFormat === 'h:mm:ss tt' ? ' %l:%M %p' : ' %H:%M';
        const scatterDateFormat = context.scatterDateFormat;
        const point: ScatterPoint = this as any; // any used because linter thinks that 'this' is a service context, however function is used with another context
        const dateFormat = Highcharts.dateFormat(scatterDateFormat + tooltipTimeFormat, point.dateTime);
        const pointExist = point.x !== null && point.y !== null;
        if (!pointExist) {
            return;
        }

        const isScatterInvert = context.annotationSettings ? context.annotationSettings.isScatterInvert : false;
        const { x, xPrecision, xAxisEntity, y, yPrecision, yAxisEntity } = point;
        const xUnit = context.xUnit;
        const yUnit = context.yUnit;
        const firstAxe = isScatterInvert ? x : y;
        const secondAxe = isScatterInvert ? y : x;
        const firstPrecision = isScatterInvert ? xPrecision : yPrecision;
        const secondPrecision = isScatterInvert ? yPrecision : xPrecision;
        const firstUnit = isScatterInvert ? xUnit : yUnit;
        const secondUnit = isScatterInvert ? yUnit : xUnit;
        const firstAxeEntity = isScatterInvert ? xAxisEntity : yAxisEntity;
        const secondAxeEntity = isScatterInvert ? yAxisEntity : xAxisEntity;
        const scatterTooltipFormatter = `${dateFormat}<br/>${
            isConfirmation ? 'CONFIRMATION ' : ''
        }${StringUtils.upperCase(firstAxeEntity)}:<b>
    ${Number(firstAxe || 0).toFixed(firstPrecision)} </b>${firstUnit}<br/>
    ${isConfirmation ? 'CONFIRMATION ' : ''}${StringUtils.upperCase(secondAxeEntity)}:<b>${Number(
            secondAxe || 0,
        ).toFixed(secondPrecision)} </b>${secondUnit}`;

        return scatterTooltipFormatter;
    }

    private setScatterGraphLegends() {
        //  display data quality legend if data quality view is selected
        if (this.componentInstance.annotationSettings.isDataQuality) {
            return scatterLegend;
        } else {
            return false;
        }
    }

    private callArcRenders(chart, centerPoints, rPix, width, myColor) {
        const { centerRightX, centerLowerY, centerUpperY, centerLeftX } = centerPoints;

        this.renderChartArc(chart, centerRightX, centerLowerY, rPix, 6)
            .attr({ fill: '#FCFFC5', stroke: myColor, 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);

        this.renderChartArc(chart, centerRightX, centerUpperY, rPix, 6)
            .attr({ fill: '#FCFFC5', stroke: myColor, 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);

        this.renderChartArc(chart, centerRightX, centerUpperY, rPix, 6, true)
            .attr({ fill: '#FCFFC5', stroke: myColor, 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);

        this.renderChartArc(chart, centerLeftX, centerLowerY, rPix, 6)
            .attr({ fill: '#FCFFC5', stroke: 'black', 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);

        this.renderChartArc(chart, centerLeftX, centerUpperY, rPix, 6, true)
            .attr({ fill: '#FCFFC5', stroke: 'black', 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);

        this.renderChartArc(chart, centerLeftX, centerLowerY, rPix, 6, true)
            .attr({ fill: '#FCFFC5', stroke: 'black', 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);
    }

    private renderOuterPoints(chart, outerPoints, width) {
        const { outerR, outerX, outerY } = outerPoints;

        this.renderChartArc(chart, outerX, outerY, outerR, 2, true)
            .attr({ fill: '#FCFFC5', stroke: 'black', 'stroke-width': width })
            .add(this.componentInstance.pipeGraph);
    }

    private renderByPipePoints(chart, pipePoints, width) {
        const { pipeLeftX, pipeLowerY, pipeRightX, pipeUpperY } = pipePoints;

        chart.renderer
            .path(['M', pipeLeftX, pipeLowerY, 'L', pipeRightX, pipeLowerY])
            .attr({ 'stroke-width': width, stroke: 'black' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path(['M', pipeLeftX, pipeUpperY, 'L', pipeRightX, pipeUpperY])
            .attr({ 'stroke-width': width, stroke: 'black' })
            .add(this.componentInstance.pipeGraph);
    }

    private renderMinPoints(chart, centerMinMax, minYPix, myLeft) {
        chart.renderer
            .path(['M', centerMinMax, minYPix, 'L', myLeft, minYPix])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path([
                'M',
                centerMinMax - 5,
                minYPix - 10,
                'L',
                centerMinMax + 5,
                minYPix - 10,
                centerMinMax,
                minYPix,
                centerMinMax - 5,
                minYPix - 10,
            ])
            .attr({ 'stroke-width': 1, stroke: '#006AC2', fill: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 5, minYPix + 3, 'L', centerMinMax + 5, minYPix + 3])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 3, minYPix + 6, 'L', centerMinMax + 3, minYPix + 6])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .text('min', centerMinMax - 30, minYPix - 5)
            .css({ color: '#006AC2', fontSize: '10px' })
            .add(this.componentInstance.pipeGraph);
    }

    private renderMaxPoints(chart, centerMinMax, maxYPix, myLeft) {
        chart.renderer
            .path(['M', centerMinMax, maxYPix, 'L', myLeft, maxYPix])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path([
                'M',
                centerMinMax - 5,
                maxYPix - 10,
                'L',
                centerMinMax + 5,
                maxYPix - 10,
                centerMinMax,
                maxYPix,
                centerMinMax - 5,
                maxYPix - 10,
            ])
            .attr({ 'stroke-width': 1, stroke: '#006AC2', fill: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 5, maxYPix + 3, 'L', centerMinMax + 5, maxYPix + 3])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 3, maxYPix + 6, 'L', centerMinMax + 3, maxYPix + 6])
            .attr({ 'stroke-width': 1, stroke: '#006AC2' })
            .add(this.componentInstance.pipeGraph);

        chart.renderer
            .text('max', centerMinMax - 30, maxYPix - 5)
            .css({ color: '#006AC2', fontSize: '10px' })
            .add(this.componentInstance.pipeGraph);
    }

    // Retrieves scattergraph data for the current location dashboard filters.
    public plotScatterGraphRenderPipe(pipeDiam, minY, maxY, xMinY, xMaxY, myColor): void {
        const { chart, pipeGraph } = this.componentInstance;
        // destroy pipegraph instance to redraw pipe overlay on zoom actions
        if (pipeGraph) {
            pipeGraph.destroy();
        }

        const xAxis = chart.xAxis[0];
        const yAxis = chart.yAxis[0];
        const pipeTopPix = yAxis.toPixels(pipeDiam);
        const pipeBottomPix = yAxis.toPixels(0);
        const diam = pipeBottomPix - pipeTopPix;
        const r = diam / (4 * Math.sin(Math.PI / 6));
        const xOffset = r - r * Math.cos(Math.PI / 6);

        const yExtremes = yAxis.getExtremes();
        const xExtremes = xAxis.getExtremes();

        const { min, max } = xExtremes;
        const myTop = yAxis.toPixels(yExtremes.max);
        const myBottom = yAxis.toPixels(yExtremes.min);
        const myLeft = xAxis.toPixels(min);
        const myRight = xAxis.toPixels(max);

        const pipeLowerY = yAxis.toPixels(0);
        const pipeUpperY = pipeLowerY - diam;

        const pipeLeftX = myLeft + xOffset;
        const pipeRightX = xAxis.toPixels(max) - xOffset;

        const centerUpperY = diam - r * Math.sin(Math.PI / 6) + pipeUpperY;
        const centerLowerY = diam - 3 * (r * Math.sin(Math.PI / 6)) + pipeUpperY;
        const centerLeftX = myLeft - r + 2 * xOffset;
        const centerRightX = myRight - r;
        const rPix = r;

        const centerPoints = { centerUpperY, centerLowerY, centerLeftX, centerRightX };
        const outerPoints = { outerX: pipeLeftX, outerY: pipeLowerY - diam / 2, outerR: diam / 2 };
        const pipePoints = { pipeLeftX, pipeLowerY, pipeRightX, pipeUpperY };
        const centerMinMax = myLeft - 20;
        const minYPix = yAxis.toPixels(minY);
        this.componentInstance.pipeGraph = chart.renderer.g().add();

        const maxYPix = yAxis.toPixels(maxY);
        const width = 2;

        this.callArcRenders(chart, centerPoints, rPix, width, myColor);
        this.renderOuterPoints(chart, outerPoints, width);
        this.renderByPipePoints(chart, pipePoints, width);
        this.renderMinPoints(chart, centerMinMax, minYPix, myLeft);
        this.renderMaxPoints(chart, centerMinMax, maxYPix, myLeft);
    }

    private renderChartArc(chart, firstDirection, secondDirection, pix, division, isFirstPositive = false) {
        if (isFirstPositive) {
            return chart.renderer.arc(
                firstDirection,
                secondDirection,
                pix,
                pix,
                Math.PI / division,
                -Math.PI / division,
            );
        }
        return chart.renderer.arc(firstDirection, secondDirection, pix, pix, -Math.PI / division, Math.PI / division);
    }

    // Fix for #27833
    // In general for spline, data need to be sorted by X
    // For some reason, we are not using invert on chart, but manually invert all the things
    // So it's more workaround - we should use default Highcharts functionality on it.
    // TODO: In future we should consider using default Highcharts behaviour
    public applyInvertedSplineFix(isInverted: boolean) {
        if(!Highcharts.seriesTypes.spline.prototype.original_getPointSpline) {
            Highcharts.seriesTypes.spline.prototype.original_getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;
        }

        if(isInverted) {
            Highcharts.seriesTypes.spline.prototype.getPointSpline = (
                points,
                point,
                i
            ) => {
                const
                    // 1 means control points midway between points, 2 means 1/3
                    // from the point, 3 is 1/4 etc
                    smoothing = 1.5,
                    denom = smoothing + 1,
                    plotX = point.plotX || 0,
                    plotY = point.plotY || 0,
                    lastPoint = points[i - 1],
                    nextPoint = points[i + 1];

                let leftContX: number,
                    leftContY: number,
                    rightContX: number,
                    rightContY: number;

                const doCurve = (otherPoint) => {
                    return otherPoint &&
                        !otherPoint.isNull &&
                        otherPoint.doCurve !== false &&
                        // #6387, area splines next to null:
                        !point.isCliff;
                }

                // Find control points
                if (doCurve(lastPoint) && doCurve(nextPoint)) {
                    const lastX = lastPoint.plotX || 0,
                        lastY = lastPoint.plotY || 0,
                        nextX = nextPoint.plotX || 0,
                        nextY = nextPoint.plotY || 0;

                    let correction = 0;

                    leftContX = (smoothing * plotX + lastX) / denom;
                    leftContY = (smoothing * plotY + lastY) / denom;
                    rightContX = (smoothing * plotX + nextX) / denom;
                    rightContY = (smoothing * plotY + nextY) / denom;

                    // Have the two control points make a straight line through main
                    // point
                    if (rightContX !== leftContX) { // #5016, division by zero
                        correction = (
                            ((rightContX - leftContX) *
                            (rightContY - plotY)) /
                            (rightContY - leftContY) + plotX - rightContX
                        );
                    }

                    leftContX += correction;
                    rightContX += correction;

                    // to prevent false extremes, check that control points are
                    // between neighbouring points' y values
                    if (leftContX > lastX && leftContX > plotX) {
                        leftContX = Math.max(lastX, plotX);
                        // mirror of left control point
                        rightContX = 2 * plotX - leftContX;

                    } else if (leftContX < lastX && leftContX < plotX) {
                        leftContX = Math.min(lastX, plotX);
                        rightContX = 2 * plotX - leftContX;
                    }

                    if (rightContX > nextX && rightContX > plotX) {
                        rightContX = Math.max(nextX, plotX);
                        leftContX = 2 * plotX - rightContX;

                    } else if (rightContX < nextX && rightContX < plotX) {
                        rightContX = Math.min(nextX, plotX);
                        leftContX = 2 * plotX - rightContX;
                    }

                    // record for drawing in next point
                    point.rightContX = rightContX;
                    point.rightContY = rightContY;
                    point.leftContX = leftContX;
                    point.leftContY = leftContY;
                }

                const ret = [
                    'C',
                    lastPoint.rightContX ? lastPoint.rightContX : lastPoint.plotX ? lastPoint.plotX : 0,
                    lastPoint.rightContY ? lastPoint.rightContY : lastPoint.plotY ? lastPoint.plotY : 0,
                    leftContX ? leftContX : plotX ? plotX : 0,
                    leftContY ? leftContY : plotY ? plotY : 0,
                    plotX,
                    plotY,
                ];

                // reset for updating series later
                lastPoint.rightContX = lastPoint.rightContY = void 0;
                lastPoint.leftContX = lastPoint.leftContY = void 0;

                return ret;
            }
        } else {
            Highcharts.seriesTypes.spline.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.original_getPointSpline;
        }
    }

    public applySeriesColorsToDataPoints(series: ScatterSeries[]) {
        const applyColorToData = function(data: EntityData[], color: string) {
            data.forEach(v => v.color = color);
        }

        series.forEach((serie: ScatterSeries) => {
            if (serie.name === ScattergraphSeriesNames.flagged) {
                applyColorToData(serie.data, RED_COLOR_HEX);
            }

            if (serie.name === ScattergraphSeriesNames.selected) {
                applyColorToData(serie.data, SELECTED_COLOR);
            }

            if (serie.name === ScattergraphSeriesNames.snapped || serie.name === ScattergraphSeriesNames.previousSnapped || serie.name === ScattergraphSeriesNames.edited) {
                applyColorToData(serie.data, EDIT_COLOR);
            }
        });
    }

    public getPointsFromSVGCurve(pointData) {
        const points = pointData.map(p => {return {...p, plotX: p.x, plotY: p.y}});
        const pathPoints = [];
        let last = points[0];
        for(let i = 1; i < points.length; i++) {
            const v = points[i];
            const path = Highcharts.seriesTypes.spline.prototype.getPointSpline.call(this.componentInstance, points, v, i);

            const svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            svgPath.setAttribute('d', `M ${last.x} ${last.y} ${path.join(' ')}`);
            const length = svgPath.getTotalLength();
            pathPoints.push(points[i])
            for(let j = 0; j < length; j += 0.5) {
                pathPoints.push(svgPath.getPointAtLength(j));
            }
            last = v;
        }

        return pathPoints;
    }
}
