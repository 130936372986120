import { ElementRef } from '@angular/core';
import { ChartXY, CursorPoint, SeriesXY } from '@arction/lcjs';
import { BasicSeriesData } from 'app/shared/models/hydrographNEW';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Subscription } from 'rxjs';
import { DEPTH_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY } from 'app/shared/constant';
import { TOOLTIP_ARROW_STYLES, TOOLTIP_DEFAULT_PRECISION, TOOLTIP_ELEMENT_STYLES } from 'app/shared/models/blockage-prediction';

const TOOLTIP_OFFSET_X = 20;
const TOOLTIP_OFFSET_Y = 40;

export class BpTooltip {
    private tooltipElement: HTMLDivElement;
    private arrowElement: HTMLDivElement;
    arrows: HTMLDivElement[] = [];

    private chart: ChartXY;
    private container: HTMLElement;

    private isDarkTheme: boolean;
    private subscriptions: Subscription[] = [];
    constructor(
        private elementRef: ElementRef,
        private seriesData: BasicSeriesData[],
        private statusCodeService: StatusCodeService
    ) {
        this.container = this.elementRef.nativeElement.querySelector('#container');
        this.createTooltipElements();

        this.subscriptions.push(
            this.statusCodeService.userInfoThemeBS.subscribe((isDarkTheme: boolean) => {
                this.dispose(false);
                this.isDarkTheme = isDarkTheme;
                this.createTooltipElements()
            })
        )
    }

    private createTooltipElements() {
        // Create tooltip container
        this.tooltipElement = document.createElement('div');
        Object.assign(this.tooltipElement.style, TOOLTIP_ELEMENT_STYLES(this.isDarkTheme));

        // Create arrow indicator
        this.arrowElement = document.createElement('div');
        Object.assign(this.arrowElement.style, TOOLTIP_ARROW_STYLES);

        // Add elements to container
        this.container.appendChild(this.tooltipElement);
        this.container.appendChild(this.arrowElement);
    }

    public setChart(chart: ChartXY) {
        this.chart = chart;
    }

    public showHydrographTooltip(mouseEvent: MouseEvent, nearestPoints: { point: CursorPoint, series: SeriesXY }[]) {
        if (!this.chart || !nearestPoints.length) return;
        const content = this.formatNearestPointsContent(nearestPoints);
        const mousePoint = this.getMousePoint(mouseEvent);
        this.displayTooltip(content, mousePoint);
    }

    private formatNearestPointsContent(nearestPoints: { point: CursorPoint, series: SeriesXY }[]): string {
        let content = '';
        nearestPoints.forEach((item) => {
            const series = this.seriesData.find(v => item && v.entityName === item.series.getName());
            const { point } = item;
            if (series && point) {
                const value = point.location.y.toFixed(series.precision || TOOLTIP_DEFAULT_PRECISION);
                content += `
                    <div style="margin: 4px 0;">
                        <span style="color: ${series.color}">●</span>
                        <span>${series.entityName}: ${value} ${series.unitOfMeasure || ''}</span>
                    </div>
                `;
            }
        });

        if (nearestPoints[0] && nearestPoints[0].point) {
            const timestamp = new Date(nearestPoints[0].point.location.x);
            content = `<div style="margin-bottom: 8px">${timestamp.toLocaleString()}</div>` + content;
        }

        return content;
    }

    private getMousePoint(mouseEvent: MouseEvent) {
        const boundingRect = this.container.getBoundingClientRect();
        return {
            x: mouseEvent.clientX - boundingRect.left,
            y: mouseEvent.clientY - boundingRect.top
        };
    }

    private displayTooltip(content: string, mousePoint: { x: number, y: number }) {
        this.tooltipElement.innerHTML = content;
        this.tooltipElement.style.display = 'block';

        let tooltipX = mousePoint.x + TOOLTIP_OFFSET_X;
        let tooltipY = mousePoint.y - TOOLTIP_OFFSET_Y;

        const boundingRect = this.container.getBoundingClientRect();
        if (tooltipX + this.tooltipElement.offsetWidth > boundingRect.width) {
            tooltipX = mousePoint.x - this.tooltipElement.offsetWidth - TOOLTIP_OFFSET_X;
        }

        this.tooltipElement.style.left = `${tooltipX}px`;
        this.tooltipElement.style.top = `${tooltipY}px`;

        this.arrowElement.style.display = 'block';
        this.arrowElement.style.left = `${mousePoint.x}px`;
        this.arrowElement.style.top = `${mousePoint.y}px`;
    }

    public showScatterTooltip(mouseEvent: MouseEvent, nearestPoints: CursorPoint[]) {
        if (!this.chart || !nearestPoints.length) return;

        const depthSeries = this.seriesData.find(s => s.entityId === DEPTH_ENTITY);
        const velocitySeries = this.getVelocityEntity();
        const point = nearestPoints[0];

        if (!depthSeries || !velocitySeries || !point) return;

        const content = `
            <div style="padding: 8px">
                <div style="margin-bottom: 4px">
                    <span style="color: ${depthSeries.color || '#000'}">●</span>
                    <span>${depthSeries.entityName}: ${point.location.y.toFixed(depthSeries.precision || TOOLTIP_DEFAULT_PRECISION)} ${depthSeries.unitOfMeasure || ''}</span>
                </div>
                <div>
                    <span style="color: ${velocitySeries.color || '#000'}">●</span>
                    <span>${velocitySeries.entityName}: ${point.location.x.toFixed(velocitySeries.precision || TOOLTIP_DEFAULT_PRECISION)} ${velocitySeries.unitOfMeasure || ''}</span>
                </div>
            </div>
        `;
        const mousePoint = this.getMousePoint(mouseEvent);
        this.displayTooltip(content, mousePoint);
    }

    public hide() {
        this.tooltipElement.style.display = 'none';
        this.arrowElement.style.display = 'none';
    }

    public dispose(unsubscribe: boolean = true) {
        this.tooltipElement?.remove();
        this.arrowElement?.remove();

        if (unsubscribe) {
            this.subscriptions.forEach(v => v.unsubscribe());
        }
    }

    private getVelocityEntity(): BasicSeriesData | undefined {
        return (
            this.seriesData.find(v => v.entityId === RAW_VELOCITY_ENTITY) ||
            this.seriesData.find(v => v.entityId === VELOCITY_ENTITY)
        );
    }
}