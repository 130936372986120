

import { SVGElement } from 'highcharts';
import { Chart } from 'highcharts';
import { ScattergraphSeriesNames, SGFindSeries } from './scatter-graph-constants';
import { AdvanceScattergraphComponent } from './advance-scattergraph.component';

export const LASSO_ANIMATE_DURATION = 1000;
export const LASSO_SELECT_POINTS_DELAY_MS = 260;

export const POLYGON_DEF_L = 'L';
export const POLYGON_DEF_M = 'M';
const DRAG_MIN_DISTANCE = 10;


const LASSO_STROKE_COLOR = 'blue';
const LASSO_STROKE_WIDTH = 1;
const LASSO_STROKE_DASH = 2.2;

export interface LassoSelectionReceiver {
    isLasso: () => boolean;
    onLassoSelection: (selectedPoints) => void;
}

export class ScatterLassoSelection {

    component: AdvanceScattergraphComponent;
    receiver: LassoSelectionReceiver;
    lasso: SVGElement;
    polygon: Array<Array<number>> = [];
    chart: Chart;

    constructor() {}

    polygonToPath(polygon: Array<Array<number>>) {
        return polygon.map(
            (p, i) => [i ? POLYGON_DEF_L : POLYGON_DEF_M, p[0], p[1]]
        );
    }

    /**
     * PnPolly algorithm: https://wrfranklin.org/Research/Short_Notes/pnpoly.html
     */
    pointInPolygon(point: {x: number, y: number}, polygon: Array<Array<number>>) {
        const { x, y } = point;

        let c = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const rel1 = polygon[i][1] > y;
            const rel2 = polygon[j][1] > y;
            if (
                rel1 !== rel2 &&
                (
                    x < (polygon[j][0] - polygon[i][0]) * (y - polygon[i][1]) /
                        (polygon[j][1] - polygon[i][1]) +
                        polygon[i][0]
                )
            ) {
                c = !c;
            }
        }

        return c;
    }


    selectPoints() {
        const selectedPoints = [];

        /**
         * #40888 Lasso selection - in Boost mode SG points does not have any properties.
         * Cannot find later same point by datetime because it does not exist
         */
        const componentSeries = {
            scatterSeries: SGFindSeries(this.component.advanceScatteroptions.series, ScattergraphSeriesNames.scatter),
            editedDataSeries: SGFindSeries(this.component.advanceScatteroptions.series, ScattergraphSeriesNames.edited),
            flaggedSeries: SGFindSeries(this.component.advanceScatteroptions.series, ScattergraphSeriesNames.flagged),
            snappedSeries: SGFindSeries(this.component.advanceScatteroptions.series, ScattergraphSeriesNames.snapped),
            prevSnappedSeries: SGFindSeries(this.component.advanceScatteroptions.series, ScattergraphSeriesNames.previousSnapped)
        }

        const chartSeries = {
            scatterSeries: SGFindSeries(this.chart.series, ScattergraphSeriesNames.scatter),
            editedDataSeries: SGFindSeries(this.chart.series, ScattergraphSeriesNames.edited),
            flaggedSeries: SGFindSeries(this.chart.series, ScattergraphSeriesNames.flagged),
            snappedSeries: SGFindSeries(this.chart.series, ScattergraphSeriesNames.snapped),
            prevSnappedSeries: SGFindSeries(this.chart.series, ScattergraphSeriesNames.previousSnapped)
        }

        for(const key of Object.keys(chartSeries)) {
            const chartSerie = chartSeries[key];
            const componentSerie = componentSeries[key];

            if(!chartSerie || !chartSerie.points) continue;
            chartSerie.points.forEach(point => {
                const xy = {
                    x: this.chart.plotLeft + (point.plotX ?? 0),
                    y: this.chart.plotTop + (point.plotY ?? 0)
                };
                if (this.pointInPolygon(xy, this.polygon)) {
                    /**
                     * #40888 Lasso selection - it seems that Boost mode depends on series.
                     * If serie is NOT in boost mode then it does contains dateTime property, otherwise it does have i (index)
                     **/
                    if(point.dateTime) {
                        selectedPoints.push({...point});
                    } else {
                        const i = point.i;
                        if(i >= 0 && i < componentSerie.data.length) {
                            const componentPoint = componentSerie.data[i];
                            selectedPoints.push({...componentPoint});
                        }
                    }
                }
            });
        }

        this.receiver.onLassoSelection(selectedPoints);
    }

    public lassoMouseDown(e: MouseEvent & {chartX: number, chartY: number}) {
        if(!this.receiver?.isLasso()) return;

        this.lasso = this.chart.renderer.path()
        .attr({
            stroke: LASSO_STROKE_COLOR,
            'stroke-width': LASSO_STROKE_WIDTH,
            'stroke-dasharray': LASSO_STROKE_DASH
        })
        .add();
        this.polygon.push([e.chartX, e.chartY]);
    }

    public lassoMouseMove(e: MouseEvent & {chartX: number, chartY: number}) {
        if(!this.receiver?.isLasso()) return;

        if (this.lasso) {
            this.polygon.push([e.chartX, e.chartY]);
            this.lasso.attr({ d: this.polygonToPath(this.polygon) });
        }
    }

    public lassoMouseUp() {
        if(!this.receiver?.isLasso()) return;

        const p0 = this.polygon[0],
        // Prevent sloppy clicks being interpreted as drag
        hasDragged = this.polygon.some(p =>
            Math.pow(p0[0] - p[0], 2) + Math.pow(p0[1] - p[1], 2) > DRAG_MIN_DISTANCE
        );

        if (hasDragged && this.lasso) {
            this.polygon.push(p0);
            this.lasso.attr({ d: this.polygonToPath(this.polygon) });
            // #40888 Lasso selection - let it do animation before trying to select points which is heavy
            setTimeout(() => {
                this.selectPoints();
                this.polygon = [];
            }, LASSO_SELECT_POINTS_DELAY_MS);
        } else {
            this.polygon = [];
        }

        const lasso = this.lasso;
        this.lasso = null;
        lasso.animate({ opacity: 0 }, {
            duration: LASSO_ANIMATE_DURATION,
            complete: () => {
                lasso.destroy();
            }
        });
    }
}
