import {
    decodePositiveNumber,
    decodePositiveNumberWithDecimals,
    FormField,
    NonEmptyString,
    PositiveNumber,
    updateFormField,
    validNonEmptyAndUniqueString,
    validNonEmptyString,
    validNonNegInt,
    validPositiveNumber,
    validPositiveNumberWithDecimals,
} from 'app/pages/sliicer/shared/utils/composable-validation';
import { isSome, none, Option, some } from 'fp-ts/es6/Option';
import { DesignStorm, DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { either, Either, right } from 'fp-ts/es6/Either';
import * as E from 'fp-ts/es6/Either';
import * as A from 'fp-ts/es6/Array';
import { sequenceS } from 'fp-ts/es6/Apply';

export type DesignStormsDialogData = { storms: DesignStorm[]; activeStorms: Set<string>; isStudyLocked: boolean };
export type DesignStormD = DesignStorm & { localId: number; isActive?: boolean };
export type DesignStormItemD = DesignStormItem & { localId: number; isActive?: boolean };

export type ListStateData = {
    storms: DesignStormItemD[];
    someSelected: boolean;
};
export type EditStateData = {
    localId: Option<number>;
    storms: DesignStormD[];
    name: FormField<string, string & NonEmptyString>;
    peakRain: FormField<string, PositiveNumber>;
    cumulativePeakRain: FormField<string, PositiveNumber>;
    totalStormRain: FormField<string, PositiveNumber>;
    totalEventRain: FormField<string, PositiveNumber>;
};

export type ListState = { view: 'list'; data: ListStateData };
export type EditState = { view: 'edit'; data: EditStateData };

export type State = ListState | EditState;

export const nameChanged: (str: string, uniqueSet: Set<string>) => (state: EditState) => EditState =
    (str, uniqueSet) => (state) => ({
        ...state,
        data: {
            ...state.data,
            name: updateFormField(str, (s: string) => validNonEmptyAndUniqueString(s, uniqueSet))
        },
    });

export const peakRainChanged: (_: string, d: number) => (_: EditState) => EditState = (str, decimal) => (state) => ({
    ...state,
    data: { ...state.data, peakRain: updateFormField(str, decodePositiveNumberWithDecimals(decimal)) },
});

export const cumulativePeakRainChanged: (_: string, d: number) => (_: EditState) => EditState = (str, decimal) => (state) => ({
    ...state,
    data: { ...state.data, cumulativePeakRain: updateFormField(str, decodePositiveNumberWithDecimals(decimal)) },
});

export const totalStormRainChanged: (_: string, d: number) => (_: EditState) => EditState = (str, decimal) => (state) => ({
    ...state,
    data: { ...state.data, totalStormRain: updateFormField(str, decodePositiveNumberWithDecimals(decimal)) },
});

export const totalEventRainChanged: (_: string, d: number) => (_: EditState) => EditState = (str, decimal) => (state) => ({
    ...state,
    data: { ...state.data, totalEventRain: updateFormField(str, decodePositiveNumberWithDecimals(decimal)) }, // Allow only integers
});

export const stormSelectedChanged: (_: DesignStormItemD, __: boolean) => (_: ListState) => ListState =
    (item, val) => (state) => {
        const storms = A.map((x: DesignStormItemD) =>
            ({ ...x, isActive: (x.localId === item.localId && val) })
        )(state.data.storms);
        return { ...state, data: { ...state.data, storms: storms } };
    };

export function deleteSelectedStorms(state: ListState, id: number): ListState {
    return {
        ...state,
        data: {
            ...state.data,
            storms: A.filter<DesignStormItemD>((s) => s.localId !== id)(state.data.storms),
            someSelected: false,
        },
    };
}

export function initState(storms: DesignStormD[], activeStorms: Set<string>): State {
    return initListState(storms, activeStorms);
}

export function initListState(storms: DesignStormD[], activeNames: Set<string>): ListState {
    return {
        view: 'list',
        data: { storms: storms.map((s) => ({ ...s, isActive: activeNames.has(s.name) })), someSelected: false },
    };
}

const initNumFormField = (n: number, maxDecimals: number) => ({
    raw: n === null ? '' : n.toFixed(maxDecimals),
    val: validPositiveNumberWithDecimals(n, maxDecimals)
});
const initNameFormField = (name: string, uniqueSet: Set<string>) => ({ raw: name, val: validNonEmptyAndUniqueString(name, uniqueSet) });

export function initEditState(storm: Option<DesignStormD>, allStorms: DesignStormD[], isMetric: boolean): EditState {
    const decimalPlaces = isMetric ? 1 : 2;
    if (isSome(storm)) {
        const uniqueSet = new Set(allStorms.filter(v => v.localId !== storm.value.localId).map(v => v.name.toLowerCase().trim()));
        return {
            view: 'edit',
            data: {
                localId: some(storm.value.localId),
                storms: [...allStorms],
                name: initNameFormField(storm.value.name, uniqueSet),
                peakRain: initNumFormField(storm.value.peakRain, decimalPlaces),
                cumulativePeakRain: initNumFormField(storm.value.cumulativePeakRain, decimalPlaces),
                totalStormRain: initNumFormField(storm.value.totalStormRain, decimalPlaces),
                totalEventRain: initNumFormField(storm.value.totalEventRain, decimalPlaces),
            },
        };
    } else {
        const uniqueSet = new Set(allStorms.map(v => v.name.toLowerCase().trim()));
        return {
            view: 'edit',
            data: {
                localId: none,
                storms: [...allStorms],
                name: initNameFormField('', uniqueSet),
                peakRain: initNumFormField(null, decimalPlaces),
                cumulativePeakRain: initNumFormField(null, decimalPlaces),
                totalStormRain: initNumFormField(null, decimalPlaces),
                totalEventRain: initNumFormField(null, decimalPlaces),
            },
        };
    }
}

export function stormEditResult(state: EditState): Either<string, DesignStormD[]> {
    const getNewLocalId = (storms: DesignStormD[]): number =>
        storms.reduce((maxId, s) => Math.max(maxId, s.localId), 0) + 1;

    const activeStorm = state.data.storms.find(v => v.isActive)
    const stormResult: Either<string, DesignStormD> = sequenceS(either)({
        localId: right<string, number>(
            isSome(state.data.localId) ? state.data.localId.value : getNewLocalId(state.data.storms),
        ),
        name: state.data.name.val,
        peakRain: state.data.peakRain.val,
        cumulativePeakRain: state.data.cumulativePeakRain.val,
        totalStormRain: state.data.totalStormRain.val,
        totalEventRain: state.data.totalEventRain.val,
        isActive: E.right<string, boolean | undefined>(
            activeStorm && activeStorm.localId === state.data.localId['value']
        )
    });

    const mapStormResult = isSome(state.data.localId)
        ? (allStorms: DesignStormD[]) => (storm: DesignStormD) =>
            allStorms.map((s) => (s.localId === storm.localId ? storm : s))
        : (allStorms: DesignStormD[]) => (storm: DesignStormD) => [...allStorms, storm];

    return E.map(mapStormResult(state.data.storms))(stormResult);
}

export function tagDesignStorms(storms: DesignStorm[]): DesignStormD[] {
    return storms.map((x, i) => ({ ...x, localId: i }));
}
