

export enum BasinQvsiProperties {
    basin = "Basin",
    qvsiGroup = "Q Vs I Group",
    mode = 'Mode',
    useAlternate = 'Use Alternate',
    type = 'Type',
    n = 'N',
    r2 = 'R²',
    slope = 'Slope',
    yIntercept = 'Y-Intercept',
    area = 'Area',
    sewerLength = 'Sewer Length',
    periodType = 'Period Type'
}

const BasinQvsiNumberProperties = ['N', 'R2', 'Slope', 'Y-Intercept'];

export const AREA = 'Area';
export const SEWER_LENGTH = 'Sewer Length';
const PERIOD_TYPE = 'Period Type';

export const PEAK_II = 'Peak I/I';
export const ROLLING_PEAK_RAINFALL = 'Rolling Peak Rainfall Intensity';
export const RAINFAILL_TIME__PEAK = 'Rainfall to Time of Peak I/I';
export const STORM_PERIOD = 'Storm Period RDII';
export const RAINFALL_DEPTH = 'Rainfall Depth';
export const TOTAL_EVENT_RDII = 'Total Event RDII';

export const PROJECTED = 'Projected'
export const YVALUE_FIELD_SUFIX = "Y-Value";
export const PROJECTED_NET_II = 'Projected NetII';
export const PROJECTED_NET_II_SPACED = 'Projected Net II';
export const STORM_NET_II_COLUMN = 'STORM NET II';
export const EVENT_NET_II_COLUMN = 'Event Net II';
export const R_VALUE = 'R-Value';
const isProjectedYValue = (key: string) => key.endsWith(YVALUE_FIELD_SUFIX);
const isProjectedNetII = (key: string) => key.includes(PROJECTED_NET_II_SPACED);
const isRValue = (key: string) => key.includes(R_VALUE);

const isArea = (key: string) => key.includes(AREA);
const isSewLen = (key: string) => key.includes(SEWER_LENGTH);
const isPeriodType = (key: string) => key.includes(PERIOD_TYPE);

export const csvToJSON = (text: string, quoteChar = '"', delimiter = ',') => {
    const rows = text.split("\n");
    const headers = rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
        .map(m => m[2])
        .slice(0, -1);

    let lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `{i}`;
            return { ...acc, [key]: val };
        }, {});
    });
}

export const createPivotMapping = (data: Object): { [key: string]: { type: string; format?: string; interval?: string } } => {

    return Object.keys(data).reduce((result, key) => {
        const value = data[key];

        const isNumber = isArea(key) || isSewLen(key) || isProjectedYValue(key) || BasinQvsiNumberProperties.includes(key);

        const type = isNumber ? 'number' : 'string';
        result[key] = { type };

        return result;
    }, {});
}

export const getBasinQvsiPivotOptions = () => {
    return {
        showAggregationLabels: false,
        showAggregations: false,
        expandAll: true,
        grid: {
            type: "flat",
            showEmptyValues: true,  
            showGrandTotals: "off",
            showTotals: "off",
        },
        chart: {
            multipleMeasures: true,
        },
    }
}

export const getExistingProps = (props: BasinQvsiProperties[], item) => {
    return props.filter(v => getKeyByProp(item, v));
}


export const getKeyByProp = (basinQvsi, prop: BasinQvsiProperties) => {
    return Object.keys(basinQvsi).find(v => v.includes(prop));
}


const BASIN_QVSI_SLICE_ROWS = [
    BasinQvsiProperties.basin
];

const BASIN_QVSI_COLUMNS = [];

export const BASIN_QVSI_MEASURES = [
    BasinQvsiProperties.n,
    BasinQvsiProperties.r2,
];

export const getBasinQvsiPivotSlice = (basinQvsi) => {

    return {
        rows: BASIN_QVSI_SLICE_ROWS.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })),
        columns: [{uniqueName: "[Measures]"}, ...(BASIN_QVSI_COLUMNS.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })))],
        measures: getBasinQvsIPivotMeasures(basinQvsi),
        expandAll: true
    };
}

export const getBasinQvsIPivotMeasures = (basinQvsi) => {
    const designedStorms = [];
    let additionalFields = [];

    let hasDesignStorms = false;

    for (const key of Object.keys(basinQvsi)) {
        if (isProjectedYValue(key)) {
            hasDesignStorms = true;
            designedStorms.push({ uniqueName: key, format: "3_decimal_format" });
        } else if (isRValue(key)) {
            designedStorms.push({ uniqueName: key, format: "3_decimal_format" });
        } else if (isProjectedNetII(key)) {
            designedStorms.push({ uniqueName: key, format: "2_decimal_format" });
        } else if (isArea(key)) {
            additionalFields.push({ uniqueName: key, format: "2_decimal_format" });
        } else if (isSewLen(key)) {
            additionalFields.push({ uniqueName: key, format: "2_decimal_format" });
        } else if (isPeriodType(key)) {
            additionalFields.push({ uniqueName: key });
        }
    }

    // #42468 Period type should be displayed when no design storm
    if (!hasDesignStorms) {
        additionalFields = additionalFields.filter(v => !v.uniqueName.includes(PERIOD_TYPE));
    }

    return [...BASIN_QVSI_MEASURES.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })), ...additionalFields, ...designedStorms];
}
