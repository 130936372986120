<app-loader [isLoading]="isLoading || loadingStormBasinResults"></app-loader>
<div class="custom-graph-container graph-container-ah-2">
    <!-- Fixes #21369 Have to be [hidden] - *ngIf do not work good with @ViewChildren -->
    <div [hidden]="isLoading || !haveData" id="qvi-chart" class="dynamicGraphAH">
        <div #chart [chart]="chartData"></div>
    </div>
    <div *ngIf="!isLoading && !haveData" class="notabledataFound custom-table print-nodata-space">
        <span>{{ 'SLIICER.FLOW_TAB.NO_STORMS_TO_DISPLAY' | translate }}</span>
    </div>
</div>

<div
    id="qviTooltip"
    *ngIf="legendTooltip.visible"
    [ngStyle]="{
        position: 'fixed',
        left: legendTooltip.x + 'px',
        top: legendTooltip.y + 'px',
        border: 'solid ' + legendTooltip.color,
        borderWidth: '2px 1px'
    }"
    class="qvi-tooltip-wrapper"
>
    <div class="qvi-tooltip" [innerHTML]="legendTooltip.content"></div>
</div>

