<div id="customerRainProfileId">
    <app-loader [isLoading]="isLoadingState"></app-loader>
    <div class="custom-vertical-tab">
        <mat-tab-group
            [selectedIndex]="domOperationUtilsService.rainfallTabIndex | async"
            (selectedTabChange)="onSelectedTabChange($event, tabs)" #tabs
        >
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="edit-cus-dry-tab-hint">{{ 'SLIICER_TABLE.SLICER_SUMMARY.DRY_DAYS' | translate }}</div>
                </ng-template>
                <form
                    #dryDefinitionForm="ngForm"
                    novalidate
                >
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-dry-one-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MINIMUM_RAINFALL_1DAY' | translate
                                    }}"
                                    #minRainfallPrevOneDay="ngModel"
                                    name="minRainfallPrevOneDay"
                                    [(ngModel)]="dryDefinitionDetails.minRainPrevOneDay"
                                    (ngModelChange)="minRainfall1DayChange()"
                                    required
                                    [pattern]="IsUnitMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                                    type="number"
                                />
                                <span matSuffix>{{ unit }}</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevOneDay?.errors?.pattern && !IsUnitMetric">{{
                                     'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate
                                    }}</span>
                            </div>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevOneDay?.errors?.pattern && IsUnitMetric">{{
                                     'COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-dry-three-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_RAINFALL_3DAYS' | translate
                                    }}"
                                    #minRainfallPrevThreeDays="ngModel"
                                    name="minRainfallPrevThreeDays"
                                    [(ngModel)]="dryDefinitionDetails.minRainPrevThreeDays"
                                    (ngModelChange)="minRainfall3DayChange()"
                                    required
                                    [pattern]="IsUnitMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                                    type="number"
                                />
                                <span matSuffix>{{ unit }}</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevThreeDays?.errors?.pattern && !IsUnitMetric">{{
                                    'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate
                                    }}</span>
                            </div>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevThreeDays?.errors?.pattern && IsUnitMetric">{{
                                    'COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-dry-five-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_RAINFALL_5DAYS' | translate
                                    }}"
                                    #minRainfallPrevFiveDays="ngModel"
                                    name="minRainfallPrevFiveDays"
                                    [(ngModel)]="dryDefinitionDetails.minRainPrevFiveDays"
                                    (ngModelChange)="minRainfall5DayChange()"
                                    required
                                    [pattern]="IsUnitMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                                    type="number"
                                />
                                <span matSuffix>{{ unit }}</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevFiveDays?.errors?.pattern && !IsUnitMetric">{{
                                    'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate
                                    }}</span>
                            </div>
                            <div class="custom-error-message">
                                <span *ngIf="minRainfallPrevFiveDays?.errors?.pattern && IsUnitMetric">{{
                                    'COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate
                                    }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-dry-min-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MINIMUM_AVERAGE_FLOW' | translate
                                    }}"
                                    #minaverageFlow="ngModel"
                                    name="minaverageFlow"
                                    [(ngModel)]="dryDefinitionDetails.minimumFlowPercentage"
                                    (ngModelChange)="minFlowPrecentageChange()"
                                    required
                                    [pattern]="isPositiveInteger"
                                    type="number"
                                />
                                <span matSuffix>%</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="minaverageFlow?.errors?.pattern">{{
                                    'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-dry-max-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_AVERAGE_FLOW' | translate
                                    }}"
                                    #maxaverageFlow="ngModel"
                                    name="maxaverageFlow"
                                    [(ngModel)]="dryDefinitionDetails.maximumFlowPercentage"
                                    (ngModelChange)="maxFlowPrecentageChange()"
                                    required
                                    [pattern]="isPositiveInteger"
                                    type="number"
                                />
                                <span matSuffix>%</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="maxaverageFlow?.errors?.pattern">{{
                                    'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate
                                    }}</span>
                            </div>
                        </div>
                    </div>

                    <p>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_LIST' | translate }}</p>

                    <ul class="list">
                        <li>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                            <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.1_DAY' | translate }}</b>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                            <i>
                                <b>
                                    <span>
                                        <span>"{{dryDefinitionDetails.minRainPrevOneDay}}</span>
                                        <ng-container *ngIf="unit === unitInMM">
                                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM' | translate }}".
                                        </ng-container>
                                        <ng-container *ngIf="unit === unitInInch">
                                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENTAGE' | translate }}".
                                        </ng-container>
                                    </span>
                                </b>
                            </i>
                        </li>

                        <li>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                            <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.3_DAYS' | translate }}</b>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                            <i>
                                <b>
                                    <span>"{{ dryDefinitionDetails.minRainPrevThreeDays }}</span>
                                    <ng-container *ngIf="unit === unitInMM">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM' | translate }}".
                                    </ng-container>
                                    <ng-container *ngIf="unit === unitInInch">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENTAGE' | translate }}".
                                    </ng-container>
                                </b>
                            </i>
                        </li>

                        <li>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                            <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.5_DAYS' | translate }}</b>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                            <i>
                                <b>
                                    <span>"{{ dryDefinitionDetails.minRainPrevFiveDays }}</span>
                                    <ng-container *ngIf="unit === unitInMM">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM' | translate }}".
                                    </ng-container>
                                    <ng-container *ngIf="unit === unitInInch">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENTAGE' | translate }}".
                                    </ng-container>
                                </b>
                            </i>
                        </li>
                        <li>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.AVERAGE_FLOW' | translate }}
                            <i>
                                <b>"{{ dryDefinitionDetails.minimumFlowPercentage }}
                                    <span>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENT' | translate }}"
                                        <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.GREATER_THAN' | translate }} "</b>
                                        {{ dryDefinitionDetails.maximumFlowPercentage }} </span>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENT' | translate }}"
                                </b> </i>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAYS' | translate }}
                        </li>
                    </ul>

                    <mat-card-actions>
                        <div class="app-flex-filler"></div>
                        <button
                            mat-button
                            [disabled]="!dryDefinitionForm.valid || !dryDefinitionForm.dirty"
                            (click)="saveRainEventInfo('dryDefinition'); dryDefinitionForm.reset();"
                        >
                            {{ 'COMMON.SAVE_BUTTON' | translate }}
                        </button>
                    </mat-card-actions>
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="edit-cus-storm-tab-hint">{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_DEFINITION' | translate }}</div>
                </ng-template>
                <form
                    #stormDefnitionForm="ngForm"
                    novalidate
                >
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-storm-rain-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MINIMUM_RAINFALL_THRESHOLD'
                                            | translate
                                    }}"
                                    name="stormThresholdValue"
                                    #minThresholdValue="ngModel"
                                    [(ngModel)]="stormDefinitionDetails.minRainfallThreshold"
                                    (ngModelChange)="minRainfallThresholdChange()"
                                    required
                                    [pattern]="IsUnitMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                                />
                                <span matSuffix>{{ unit }}</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="minThresholdValue?.errors?.pattern"
                                >{{ IsUnitMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-50p">
                        <div class="custom-error input-text-width">
                            <mat-form-field class="full-width edit-cus-storm-storm-hint">
                                <input
                                    matInput
                                    placeholder="{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MINIMUM_TIME' | translate
                                    }}"
                                    name="stormMinimumTime"
                                    [(ngModel)]="stormDefinitionDetails.minimumTimeBetweenEventsMinutes"
                                    (ngModelChange)="minTimeBwStormEventsChange()"
                                    #stormMinTime="ngModel"
                                    required
                                    [pattern]="isPositiveInteger"
                                />
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <div class="custom-error-message">
                                <span *ngIf="stormMinTime?.errors?.pattern">{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <p>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_LIST' | translate }}</p>

                    <ul class="list">
                        <li class="list-item">
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.NOTE_1' | translate }}
                            <i>
                                <b>"{{ stormDefinitionDetails.minRainfallThreshold }}
                                    <ng-container *ngIf="unit === unitInMM">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM' | translate }}"
                                    </ng-container>
                                    <ng-container *ngIf="unit === unitInInch">
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.INCHES' | translate }}"
                                    </ng-container>
                                </b>
                            </i>
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.RAINFALL_SIGNIFICANT' | translate }}
                        </li>
                        <li class="list-item">
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.NOTE_2' | translate }}
                            <i>
                                <b>"{{ stormDefinitionDetails.minimumTimeBetweenEventsMinutes }} <span></span>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MINUTES' | translate }}"</b>
                            </i>
                        </li>
                    </ul>

                    <mat-card-actions>
                        <div class="app-flex-filler"></div>
                        <button
                            mat-button
                            [disabled]="!stormDefnitionForm.valid || !stormDefnitionForm.dirty"
                            (click)="saveRainEventInfo('stormDefinition'); stormDefnitionForm.reset();"
                        >
                            {{ 'COMMON.SAVE_BUTTON' | translate }}
                        </button>
                    </mat-card-actions>
                </form>
            </mat-tab>
            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.BASE_INFILTRATION' | translate }}">
                <form
                    #CustomerWasteWaterForm="ngForm"
                >
                    <div class="flex">
                        <div class="select-method">
                            <mat-form-field class="select-method-list edit-cus-base-method-hint">
                                <mat-select
                                    placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SELECT' | translate }}"
                                    [(ngModel)]="wasteWaterMethod"
                                    name="method"
                                    (selectionChange)="onSelectMethodChange($event)"
                                >
                                    <mat-option
                                        *ngFor="let option of wasteWaterMethodOptions"
                                        [value]="option.name"
                                    >{{ option.text }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isSchutzbach(wasteWaterMethod) && !isMitchell(wasteWaterMethod)" class="custom-error flex110">
                            <div>
                                <mat-form-field class="constant">
                                    <input
                                        matInput
                                        required
                                        placeholder="Constant"
                                        [(ngModel)]="wasteWaterConstant"
                                        (ngModelChange)="selectConstantChange()"
                                        name="filterConstant"
                                        #filterConstant="ngModel"
                                        type="number"
                                        [pattern]="isPositiveInteger"
                                    />
                                    <span matSuffix>%</span>
                                </mat-form-field>
                                <div class="custom-error-message">
                                    <span *ngIf="filterConstant?.errors?.pattern">{{
                                        'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="method-desc">
                        {{ wasteWaterMethodDescriptions[wasteWaterMethod] | translate}}
                    </p>
                    <mat-card-actions>
                        <div class="app-flex-filler"></div>
                        <button
                            mat-button
                            [disabled]="!CustomerWasteWaterForm.valid || !CustomerWasteWaterForm.dirty || saveDisabled"
                            (click)="saveWasteWaterMethod()"
                        >
                            {{ 'COMMON.SAVE_BUTTON' | translate }}
                        </button>
                    </mat-card-actions>
                </form>
            </mat-tab>
            <!-- Rainfall Depth Duration Frequency -->
            <mat-tab label="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.RAINFALL_DURATION_HEADING' | translate }}">
                <h4>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DDF_TITLE' | translate }}</h4>
                <div class="horizontal">
                    <mat-tab-group
                        [selectedIndex]="domOperationUtilsService.ddfTabIndex | async"
                        (selectedTabChange)="onDDFtabChange($event)"
                    >
                        <!-- mat tab for data -->
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div class="edit-cus-ddf-data-tab-hint">
                                    {{'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.RAINFALL_DATA_HEADING' | translate}}
                                </div>
                            </ng-template>
                            <div class="rainfall-content">
                                <div class="auto-margin">
                                    <button
                                        (click)="openUploadDepthDurationDialog($event)"
                                        class="upload-button edit-cus-ddf-data-upload-hint"
                                        mat-button
                                    >
                                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.UPLOAD_BUTTON' | translate }}
                                    </button>
                                    {{
                                    'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.UPLOAD_BUTTON_CONTENT'
                                    | translate
                                    }}
                                </div>
                                <div class="app-flex-filler"></div>
                                <div
                                    style="display: none"
                                    class="auto-margin column-filter"
                                >
                                    <button
                                        mat-button
                                        [matMenuTriggerFor]="menu"
                                        class="select-box"
                                    >
                                        Choose Columns to Hide
                                    </button>
                                    <mat-menu
                                        #menu="matMenu"
                                        yPosition="below"
                                        xPosition="before"
                                    >
                                        <div class="select-container">
                                            <mat-selection-list
                                                #ddfOptions
                                                [(ngModel)]="selectedOptions"
                                                (ngModelChange)="ddfFilter($event)"
                                                (click)="$event.stopPropagation()"
                                            >
                                                <mat-list-option
                                                    *ngFor="let option of filterOptions"
                                                    [value]="option"
                                                >
                                                    {{ option }}
                                                </mat-list-option>
                                            </mat-selection-list>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                            <!-- rainfalldurationtable -->
                            <div class="rainfalldurationtable">
                                <div class="ddfcolumns-checkbox-container">
                                    <mat-checkbox
                                        *ngFor="let column of ddfTableColumnDefs"
                                        (change)="onShowDDFColumnChange(column.name, $event)"
                                        [checked]="column.show"
                                    >{{ column.name }}</mat-checkbox>
                                </div>
                                <mat-table
                                    #table
                                    [dataSource]="ddfTableSource"
                                    matSort
                                    class="edit-cus-ddf-data-table-hint"
                                >
                                    <ng-container matColumnDef="checked">
                                        <mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox
                                                [checked]="isDepthDurationHeaderChecked"
                                                (change)="depthDurationCheckBoxAll($event)"
                                                name="depthDurationCheckbox"
                                            ></mat-checkbox>
                                        </mat-header-cell>
                                        <!-- <mat-cell *matCellDef="let element; let i = index;">
                                                <mat-checkbox [(ngModel)]="element.delete" (change)="rowSelection(i,element.delete)"></mat-checkbox>
                                            </mat-cell> -->
                                        <mat-cell *matCellDef="let element; let i = index">
                                            <mat-checkbox
                                                [(ngModel)]="element.checked"
                                                [checked]="element.checked"
                                                (change)="depthDurationCheckBox($event)"
                                                name="depthDurationCheckbox"
                                            ></mat-checkbox>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="duration">
                                        <form #formcontrol="ngForm">
                                            <mat-header-cell *matHeaderCellDef>{{
                                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_1'
                                                | translate
                                                }}</mat-header-cell>
                                            <mat-cell *matCellDef="let element; let i = index">
                                                <div class="rainfall-content">
                                                    <div class="duration-value">
                                                        <mat-form-field>
                                                            <input
                                                                matInput
                                                                [(ngModel)]="element.durationMinutes"
                                                                required
                                                                name="durationMinutes{{ i + 1 }}"
                                                                type="number"
                                                                (click)="setColumn(0)"
                                                            />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="duration-units">
                                                        <mat-form-field>
                                                            <mat-select
                                                                [(ngModel)]="element.duration"
                                                                (selectionChange)="onDurationUnitChange($event, i)"
                                                                name="duration{{ i + 1 }}"
                                                            >
                                                                <mat-option
                                                                    *ngFor="let selectvalue of durationOptions"
                                                                    [value]="selectvalue"
                                                                >{{ selectvalue }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </mat-cell>
                                        </form>
                                    </ng-container>
                                    <ng-container matColumnDef="2mo">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_2'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.month2"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(1)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="3mo">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_3'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.month3"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(2)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="4mo">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_4'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.month4"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(3)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="6mo">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_5'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.month6"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(4)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="9mo">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_6'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.month9"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(5)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="1yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_7'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year1"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(6)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="2yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_8'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year2"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(7)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="5yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_9'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year5"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(8)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="10yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_10'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year10"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(9)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="25yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_11'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year25"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(10)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="50yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_12'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year50"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(11)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="100yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_13'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year100"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(12)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="200yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_14'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year200"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(13)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="500yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_15'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year500"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(14)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="1000yr">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_16'
                                            | translate
                                            }}
                                            ({{ unit }})</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.year1000"
                                                    type="number"
                                                    placeholder="-"
                                                    (click)="setColumn(15)"
                                                />
                                            </mat-form-field>
                                        </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="rainfallDataColumns"></mat-header-row>
                                    <mat-row
                                        *matRowDef="let row; columns: rainfallDataColumns; let i = index"
                                        (click)="setPointer(i)"
                                        (change)="saverange()"
                                        (keydown.arrowdown)="arrowDownEvent(i)"
                                        (keydown.arrowup)="arrowUpEvent(i)"
                                        (keydown.arrowleft)="arrowLeftEvent(i)"
                                        (keydown.arrowright)="arrowRightEvent(i)"
                                        (keydown.Tab)="arrowRightEvent(i)"
                                    ></mat-row>
                                </mat-table>
                                <mat-card-actions>
                                    <div class="auto-margin">
                                        <button
                                            mat-button
                                            class="edit-cus-ddf-data-add-hint"
                                            (click)="addNewRow()"
                                        >
                                            {{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.ADD_ROW_BUTTON'
                                            | translate
                                            }}
                                        </button>
                                        <button
                                            mat-button
                                            class="edit-cus-ddf-data-delete-hint"
                                            (click)="deleteRow()"
                                            *ngIf="!deleteButton"
                                        >
                                            {{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETE_ROW_BUTTON'
                                            | translate
                                            }}
                                        </button>
                                        <button
                                            mat-button
                                            class="edit-cus-ddf-data-delete-hint"
                                            (click)="deleteSelectedRow()"
                                            *ngIf="deleteButton"
                                        >
                                            {{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETED_ROW_BUTTON'
                                            | translate
                                            }}
                                        </button>
                                        <button
                                            mat-button
                                            (click)="clearSelectedRow()"
                                            *ngIf="deleteButton"
                                        >
                                            {{
                                            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.CANCEL_DELETE_ROWS'
                                            | translate
                                            }}
                                        </button>
                                    </div>
                                    <div class="app-flex-filler"></div>
                                    <div class="auto-margin">
                                        <button
                                            mat-button
                                            class="DDSave"
                                            (click)="saveDepthDurationFrequency()"
                                            [disabled]="true"
                                        >
                                            {{ 'COMMON.SAVE_BUTTON' | translate }}
                                        </button>
                                    </div>
                                </mat-card-actions>
                            </div>
                        </mat-tab>
                        <!-- Rainfall Depth Duration graph -->
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div class="edit-cus-ddf-data-graph-hint edit-cus-ddf-graph-tab-hint">
                                    {{'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.RAINFALL_GRAPH_HEADING' | translate}}
                                </div>
                            </ng-template>
                            <div
                                class="ddf-graph-container"
                                id="customer-edit-ddf-graph"
                            >
                                <ads-ddf-graph #ddfGraph></ads-ddf-graph>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
