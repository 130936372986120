<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
        <mat-expansion-panel-header class="mat-expasion-header-background">
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 0">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 1">
                {{ 'ADD_EDIT.MP2' | translate }} {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount
                    }}{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p form-input-bottom-space">
                <div class="custom-width">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FLOW' | translate }}"
                            formControlName="ultrasonicFlowType"
                            class="full-width"
                            (selectionChange)="onChangeFlowType($event)"
                            required
                        >
                            <mat-option *ngFor="let flow of flowList" [value]="flow.value">{{ flow.text }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="custom-width">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_PHYSICAL')
                                )
                            "
                            id="physicalOffsetInput"
                            formControlName="physicalOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="ultrasonicEnable"
                    (change)="
                        onToggleChange($event, [
                            'ultrasonicElectronicOffset',
                            'ultrasonicDeadband',
                            'ultrasonicStoreData'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_MEASUREMENT' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            id="electronicOffsetInput"
                            formControlName="ultrasonicElectronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('ultrasonicElectronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DEAD_BAND' | translate }}"
                            formControlName="ultrasonicDeadband"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let band of deadBandList" [value]="band.value">{{
                                band.text
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="pressureEnable"
                    (change)="
                        onToggleChange($event, ['pressureElectronicOffset', 'pressureCalibration', 'pressureStoreData'])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_PRESSURE' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="locationCardService.addDepthUnitToPlaceholder(
                                translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                            )"
                            id="pressureElectronicOffsetInput"
                            formControlName="pressureElectronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('pressureElectronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-checkbox class="custom-slide-toggle" formControlName="pressureCalibration">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_AUTO_CALIBER' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle class="custom-slide-toggle" formControlName="pressureStoreData">
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="velocityEnable"
                    (change)="
                        onToggleChange($event, [
                            'velocitySensitivity',
                            'velocityDirection',
                            'velocityGain',
                            'velocityMaximum',
                            'velocityScrubbing',
                            'velocityStoreData'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_VELOCITY' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSITIVITY' | translate }}"
                            formControlName="velocitySensitivity"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let sensitivity of sensitivityList" [value]="sensitivity.value">
                                {{ sensitivity.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DIRECTION' | translate }}"
                            formControlName="velocityDirection"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let direction of sensorDirectionList" [value]="direction.value"
                                >{{ direction.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <div class="gain-container">
                        <mat-form-field class="required">
                            <input
                                matInput
                                placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MONITORGAIN' | translate }}"
                                id="peakComboGain"
                                formControlName="velocityGain"
                                required
                            />
                        </mat-form-field>
                    </div>
                    <div class="mat-error" *ngIf="form.get('velocityGain').errors?.pattern">
                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.INVALID_INPUT_TXT' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p">
                <mat-form-field class="full-width required">
                    <mat-select
                        placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_VALUE_MAXIMUM' | translate }}"
                        id="peakComboMaxVelocityInput"
                        formControlName="velocityMaximum"
                        class="full-width"
                    >
                        <mat-option *ngFor="let velocity of maxVelocityValues" [value]="velocity.value"
                            >{{ velocity.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FLOW' | translate }}"
                            formControlName="velocityFlowType"
                            (selectionChange)="onChangeVelocityFlowType($event)"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let item of flowList" [value]="item.value">
                                {{ item.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.TRANSMIT_FREQUENCY' | translate }}"
                            formControlName="transmitFrequency"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let item of transmitFrequencyList" [value]="item.value">
                                {{ item.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p pad-b-15">
                <div class="each-Item">
                    <mat-checkbox
                        name="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SCRUBBLING' | translate }}"
                        formControlName="velocityScrubbing"
                    >
                        {{ 'ADD_EDIT.INPUT_PLACEHOLDER_SCRUBBLING' | translate }}
                    </mat-checkbox>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="velocityStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="pad-b-15">
                {{ 'ADD_EDIT.ADD_EDIT_TEXT' | translate }}
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="temperatureStoreData">
                        {{ 'ADD_EDIT.ACTIVE_TOGGLE_STORE' | translate }}
                    </mat-slide-toggle>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSOR' | translate }}"
                            formControlName="sensor"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let item of sensorsList" [value]="item.value">
                                {{ item.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
