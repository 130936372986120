import { GeographicLoc } from './geographic-loc';
import { AnsrEntityGroup } from './locations-entities-data';

export enum LocationStatus {
    Active = 0,
    Maintenance = 1,
    Inactive = 2,
    InactiveRemoved = 3,
}

export class Locations {
    public assignedRainGaugeLocationId?: number;
    public dataCollectTaskID?: number;
    public exportDataToFtp?: boolean;
    public geographicLoc?: GeographicLoc;
    public installationId?: number;
    public installationLoadCustom?: boolean;
    public isActiveLocation?: boolean; // TODO: REMOVEIT: it is here ONLY because the MAP's property inspector. Once this flag will be removed - delete it from here
    public status?: LocationStatus;
    public lastCollectedDate?: Date;
    public locationId: number;
    public locationName: string;
    public locationType?: number;
    public publicationGroupID?: number;
    public rainGaugeAssignmentDate?: Date;
    public wasCreatedFromImport?: boolean;
    public installationType?: number;
    public customerID: number;
    public series?: string;
    public alarmingState?: number;
    public monitoringPoint?: number;
    public id?: number;
    public name?: string;
    public viewable?: boolean;
    public latitude?: number;
    public longitude?: number;
    public rainGaugeList?: Array<string>;
    public ae?: Array<AnsrEntityGroup>;
}

export class LocalLocation {
    public locationId: number;
    public locationName: string;
    public isSelected: boolean;
}

export class LocalLocationGroup {
    public customerID: number;
    public locationGroupID: number;
    public description: string;
    public name: string;
    public locations: number[];
    public isSelected?: boolean;
}

export interface RainLocation {
    customerID: number;
    locationId: number;
    locationName: string;
    locationType: number;
    installationType: number;
    qFinalEntity: number;
    assignedRgID: number;
    status: number;
    maintenanceEnd: string;
}

export interface LocationData {
    customerID: number;
    locationId: number;
    locationName: string;
    status: number;
    latitude: number;
    longitude: number;
    seriesID: number;
    series: string;
    monitoringPoint: number;
    viewable: true;
    locationType: number;
    externalVendorID: number;
    serialNumber: string;
    maintenanceEnd: string;
    communicationType: number;
    assignedRainGaugeID: number;
}

/**
 * Represents location service parameter which would be used to fetch the locations from locations api
 */
export interface LocationArgs {
    // Represents customer id
    customerId: number;

    // Represents location group id
    locationGroupId?: number;

    // Represents the state of active and inactive locations
    IncludeInactiveLocations?: boolean;

    // Represents the uid of the user being edited
    uid?: string;

    // Only load wireless locations.
    validCommunications?: boolean;
}

/** Is using in LD save configuration. */
// TODO: Cannot use positive vaules for now because of compability. Currently save number of days, which is positive. Decide if change it with time.
export enum TimeSpanNumeric {
    Today = -1,
    LastWeek = -2,
    LastMonth = -3,
    PreviousMonth = -4,
    LastThreeMonths = -5,
    LastSixMonths = -6,
    LastYear = -7,
}

export enum InstallationTypes {
    PipeCircular = 200,
    PipeRectangular = 201,
    PipeElliptical = 202,
    PipeStandardEgg = 203,
    PipeOval = 204,
    PipeOvoid = 205,
    PipeHorseshoe = 206,
    PipeHorseshoe1 = 207,
    PipeHorseshoe2 = 208,
    PipeSemielliptical = 209,
    PipeBasket = 210,
    PipeCustom = 211,
}

export enum LocationType {
    Monitor = 1,
    Composite = 3
}

export const RAIN_ALERT_III = 'RainAlert III';
