import {
    Component,
    Output,
    Input,
    ViewEncapsulation,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { UsersService } from 'app/pages/admin/users.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Selectable } from 'app/shared/models/selectable';
import { ViewDataFilterArgs } from 'app/shared/models/view-data-filter';

@Component({
    selector: 'app-location-selection-bar',
    templateUrl: './location-selection-bar.component.html',
    styleUrls: ['./location-selection-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LocationSelectionBarComponent implements OnChanges, OnInit, OnDestroy {
    @Input()
    public selectableLocations: Array<Selectable> = [];
    @Input()
    public customerId: number;
    @Input()
    public locationId: number;
    @Input()
    public selectedLocationId: number;
    @Input()
    public disabled = false;
    @Input()
    public filterSettings: ViewDataFilterArgs;
    @Input()
    public trackSelectedLocation = false;
    @Input()
    public focusOnClear = true;
    @Output()
    public emitChangeLocation: EventEmitter<{}> = new EventEmitter();
    @Output()
    public showHideAnnotation: EventEmitter<boolean> = new EventEmitter();
    @Output()
    public printDashboard: EventEmitter<boolean> = new EventEmitter();
    @Output()
    public exportTelemetryData: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('locationCards', { static: true, read: ElementRef }) public locationCards: ElementRef<any>;

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    public selectedTabIndex = 0;
    public showLocationSearch = false;
    public availablePageHint = false;
    public preselectedLocation: Selectable;
    public somethingSelected = true;

    private SCROLL_SPEED = 550;
    /** Position where we're actually scrolling */
    targetScrollPosition = 0;
    somethingSelectedName: string;

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private cdr: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private customerService: CustomerService,
        private usersService: UsersService,
    ) { }

    public ngOnChanges(changes: SimpleChanges) {
        this.SCROLL_SPEED = 0.75 * this.locationCards.nativeElement.offsetWidth;
        if (changes.customerId) {
            this.showLocationSearch = false;
        }
        if (
            changes.selectableLocations &&
            changes.selectableLocations.currentValue !== changes.selectableLocations.previousValue
        ) {
            this.selectableLocations = changes.selectableLocations.currentValue;
            if (this.selectableLocations) {

            }
            this.uiUtilService.safeChangeDetection(this.cdr);
        }
        if (
            changes.locationId &&
            changes.locationId.previousValue !== changes.locationId.currentValue &&
            this.selectableLocations &&
            this.selectableLocations.length ||
            changes.selectableLocations &&
            changes.selectableLocations.currentValue &&
            changes.selectableLocations.currentValue.length &&
            !(changes.selectableLocations.previousValue && changes.selectableLocations.previousValue.length)
        ) {
            if (changes.selectableLocations) {
                this.selectableLocations = changes.selectableLocations.currentValue;
            }
            if (this.selectableLocations) {
                this.scrollToSelectedLocation();
            }
            this.somethingSelected = true;
            this.uiUtilService.safeChangeDetection(this.cdr);
        }

        if (this.trackSelectedLocation) {
            this.checkIfSameLocationSelected();
        }
    }

    private checkIfSameLocationSelected() {
        if (!this.selectableLocations || this.selectableLocations.length === 0) return;

        const current = this.selectableLocations.find(v => this.locationId === v.id);

        if (!current) {
            this.selectedTabIndex = 0;
            this.changeLocation(this.selectableLocations[0].name);

            return;
        }

        this.changeLocation(current.name);
    }

    public scrollToSelectedLocation() {
        if (!this.selectableLocations) return;

        this.selectedTabIndex = this.selectableLocations.findIndex(
            (location) => location.id === this.locationId,
        );
        this.preselectedLocation = this.selectableLocations.find((location) => location.id === this.locationId);
        this.scrollToIndex(this.selectedTabIndex);
    }

    public ngOnInit() {
        // Checks if page hint is available
        this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });
        let startLocName: string;

        if (
            this.locationId &&
            this.selectableLocations &&
            this.selectableLocations.find((loc) => loc.id === this.locationId)
        ) {
            startLocName = this.selectableLocations.find((loc) => loc.id === this.locationId).name;
        } else if (this.selectableLocations && this.selectableLocations.length) {
            startLocName = this.selectableLocations[0].name;
        }

        if (startLocName) {
            this.somethingSelectedName = startLocName;
            this.emitChangeLocation.emit(startLocName);
        }

        this.SCROLL_SPEED = 0.75 * this.locationCards.nativeElement.offsetWidth;

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
            this.SCROLL_SPEED = 0.75 * this.locationCards.nativeElement.offsetWidth;
        });
    }

    ngOnDestroy(): void {
        this.resizeSubscription$.unsubscribe();
    }

    public toggeleSearch() {
        if (this.locationId === 0) {
            this.locationId = this.selectedLocationId;
        }
        this.showLocationSearch = !this.showLocationSearch;
    }

    scrollLeftDisabled() {
        return this.targetScrollPosition <= 0;
    }

    scrollRightDisabled() {
        const leftScrollMaxX =
            this.locationCards.nativeElement.scrollWidth - this.locationCards.nativeElement.offsetWidth;
        return this.targetScrollPosition >= leftScrollMaxX;
    }

    scrollLeft() {
        if (this.scrollLeftDisabled()) return;

        this.targetScrollPosition -= this.SCROLL_SPEED;
        if (this.targetScrollPosition < 0) this.targetScrollPosition = 0;

        this.locationCards.nativeElement.scrollTo({ left: this.targetScrollPosition, behavior: 'smooth' });
    }

    scrollRight() {
        if (this.scrollRightDisabled()) return;

        const leftScrollMaxX =
            this.locationCards.nativeElement.scrollWidth - this.locationCards.nativeElement.offsetWidth;

        this.targetScrollPosition += this.SCROLL_SPEED;
        if (this.targetScrollPosition > leftScrollMaxX) this.targetScrollPosition = leftScrollMaxX;

        this.locationCards.nativeElement.scrollTo({ left: this.targetScrollPosition, behavior: 'smooth' });
    }

    scrollToIndex(index: number) {
        if (this.locationCards.nativeElement.children[index]) {
            this.locationCards.nativeElement.children[index].scrollIntoView({ block: 'nearest' });
            this.targetScrollPosition = this.locationCards.nativeElement.scrollLeft;
        }
    }

    public onChangeLocationMatTab(locationName: string) {
        this.somethingSelectedName = locationName;
        this.changeLocation(locationName);
    }

    public changeLocationDropdown(selectables: Array<Selectable>) {
        // ensure something is selected
        if (!selectables || selectables.length < 1) {
            this.somethingSelected = false;
            return;
        }

        const selectedItem = selectables[0];
        const index = this.selectableLocations.findIndex((x) => x.name === selectedItem.name);

        if (!selectedItem) {
            return;
        }

        this.somethingSelected = true;
        this.somethingSelectedName = selectedItem.name;
        this.changeLocation(selectedItem.name);

        this.scrollToIndex(index);
    }

    public inputChanged(input) {
        if (input === '' || (this.somethingSelectedName && this.somethingSelectedName !== input)) {
            this.somethingSelected = false;
        } else if (!this.somethingSelectedName || this.somethingSelectedName === input) {
            this.somethingSelected = true;
        }
    }

    private changeLocation(locationName: string) {
        this.selectedTabIndex = this.selectableLocations.findIndex((location) => location.name === locationName);
        this.emitChangeLocation.emit(locationName);
    }
}
