<div id="designStormsDialog">
    <div class="popupHeader" mat-dialog-title>
        <span class="design-storm-header">{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TITLES.' + state.view | translate }}</span>
    </div>
    <div mat-dialog-content>
        <div *ngIf="state.view === 'list' ? state.data : null; let data" class="column">
            <mat-table *ngIf="data && data.storms && data.storms.length" [dataSource]="data.storms">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="flex flex-column align-center">
                            <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DESIGN_STORM_NAME_HEADER_1' | translate}}</span>
                            <span class="text-center">{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DESIGN_STORM_NAME_HEADER_2' | translate}}</span>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                        element.name
                    }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="peakRain">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="flex flex-column align-center">
                            <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.PEAK_RAIN' | translate }}</span>
                            <span class="text-center">({{ unitOfMeasure }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }})</span>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.peakRain | number : decimals }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="cumulativePeakRain">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="flex flex-column align-center">
                            <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CUMULATIVE_PEAK_RAIN_HEADER_1' | translate }}</span>
                            <span class="text-center">{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CUMULATIVE_PEAK_RAIN_HEADER_2' | translate }} ({{ unitOfMeasure
                            }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }})</span>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.cumulativePeakRain | number : decimals }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalStormRain">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="flex flex-column align-center">
                            <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_STORM_RAIN' | translate }}</span>
                            <span class="text-center">({{ unitOfMeasure }})</span>
                        </div>
                    </mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.totalStormRain | number : decimals }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalEventRain">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="flex flex-column align-center">
                            <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_EVENT_RAIN' | translate }}</span>
                            <span class="text-center">({{ unitOfMeasure }})</span>
                        </div>
                    </mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.totalEventRain | number : decimals }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'COMMON.TEST_ACTIVE' | translate }}</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [checked]="dialogData.activeStorms.has(element.name)"
                            (change)="activeStormChange(element, $event.checked)"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="design-storm-table-actions">
                            <button (click)="delete(element.localId)" matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"  [disabled]="isStudyLocked"  mat-icon-button><mat-icon>delete</mat-icon></button>
                            <button (click)="editStorm(element)" matTooltip="{{ 'COMMON.EDIT_BUTTON' | translate }}"  [disabled]="isStudyLocked" mat-icon-button><mat-icon>edit</mat-icon></button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>

            <div *ngIf="!data || !data.storms || !data.storms.length" class="notabledataFound custom-table print-nodata-space">
                <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.NO_DATA' | translate }}</span>
            </div>
        </div>

        <form
            *ngIf="state.view === 'edit' ? state.data : null; let data"
            class="column"
            id="stormEditForm"
            (ngSubmit)="saveStorm()"
        >
            <mat-form-field>
                <input
                    matInput
                    maxLength="24"
                    [ngModelOptions]="{ standalone: true }"
                    [ngModel]="data.name.raw"
                    (ngModelChange)="nameChanged($event)"
                    [eitherValidation]="data.name.val"
                    placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DESIGN_STORM_NAME' | translate }}{{required}}"
                />
                <mat-error *ngIf="data.name.val | asLeft; let error">{{ error.value | translate }}</mat-error>
            </mat-form-field>
            <div class="row items-row">
                <div class="item full-width">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.peakRain.raw"
                            (ngModelChange)="peakRainChanged($event)"
                            [eitherValidation]="data.peakRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.PEAK_RAIN' | translate }} ({{
                                unitOfMeasure
                            }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }}){{required}}"
                        />
                        <mat-error *ngIf="data.peakRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="item full-width">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.cumulativePeakRain.raw"
                            (ngModelChange)="cumulativePeakRainChanged($event)"
                            [eitherValidation]="data.cumulativePeakRain.val"
                            placeholder="{{
                                'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CUMULATIVE_PEAK_RAIN' | translate
                            }} ({{ unitOfMeasure }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }}){{required}}"
                        />
                        <mat-error *ngIf="data.cumulativePeakRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row items-row">
                <div class="item full-width">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.totalStormRain.raw"
                            (ngModelChange)="totalStormRainChanged($event)"
                            [eitherValidation]="data.totalStormRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_STORM_RAIN' | translate }} ({{
                                unitOfMeasure
                            }}){{required}}"
                        />
                        <mat-error *ngIf="data.totalStormRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="item full-width">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.totalEventRain.raw"
                            (ngModelChange)="totalEventRainChanged($event)"
                            [eitherValidation]="data.totalEventRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_EVENT_RAIN' | translate }} ({{
                                unitOfMeasure
                            }}){{required}}"
                        />
                        <mat-error *ngIf="data.totalEventRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions *ngIf="state.view === 'list' ? state.data : null; let data">
        <button tabindex="-1" mat-button (click)="close()">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
        <button mat-button [disabled]="isStudyLocked || state?.data?.storms.length >= 5" (click)="new()">{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.NEW_STORM' | translate }}</button>
        <button mat-button (click)="save()" [disabled]="!isDirty">{{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}</button>
    </div>

    <div mat-dialog-actions *ngIf="state.view === 'edit'">
        <button mat-button (click)="cancelEditStorm()">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
        <button mat-button type="submit" form="stormEditForm" [disabled]="!isFormValid()">
            {{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}
        </button>
    </div>
</div>
