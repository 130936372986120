<div class="storm-table-container loaderContainer">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="storm-header">
        <div class="storm-widget-header-wrapper">
            <span class="flex gap-2">
                <mat-icon class="thunderstorm-icon">thunderstorm</mat-icon>
                <h4 class="graph-heading">
                    {{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_EVENTS' | translate }}
                </h4>
            </span>
            <i
                class="material-icons inverted-icon zoom-out"
                (click)="expandChooserFull()"
            >
                {{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.FULLSCREEN' | translate }}
            </i>
        </div>
        <div class="group-selector">
            <mat-form-field>
                <mat-select
                    [disabled]="!isOwnStudy || isStudyLocked"
                    [(ngModel)]="QvsiGroup"
                    (selectionChange)="qvsiGroupSelect(QvsiGroup, true)"
                >
                    <mat-option value="All Storms">{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.ALL_STORMS' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let item of originalQvsIConfigurations"
                        [value]="item.name"
                    >{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div #stormsTable id="stormTable">
        <mat-table
            #table
            [dataSource]="stormDataSource"
            matSort
        >
            <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef>Color</mat-header-cell>
                <mat-cell
                    (click)="!element.stormId || setSelectedStorm(element.stormId)"
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element && selectedStorm && element.stormId === selectedStorm.stormId"
                >
                    <div *ngIf="!element.stormExists"
                        class="not-existing-storm-box"
                    ></div>
                    <div *ngIf="element.stormExists && isExcluded(element.stormId)"
                        class="excluded-storm-box"
                    ></div>

                    <div *ngIf="element.stormExists && !isExcluded(element.stormId)"
                        class="box"
                        [ngStyle]="{'background-color': element.color}"
                    ></div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>
                        {{ 'SLIICER.FLOW_TAB.QvsIGroup.QVSIGROUP' | translate }}
                </mat-header-cell>
                <mat-cell
                    (click)="!element.stormId || setSelectedStorm(element.stormId)"
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element && selectedStorm && element.stormId === selectedStorm.stormId"
                >

                <span *ngIf="element.stormExists && !isExcluded(element.stormId)">{{element.qvsiGroup}}</span>
                <span
                    *ngIf="!element.stormExists"
                    matTooltip="{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.REMOVED_STORM_TOOLTIP' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="removed-storm-tooltip"><b>{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.REMOVED' | translate }}</b></span>

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="stormStartTime">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_DATE_TIME' | translate }}
                </mat-header-cell>
                <mat-cell
                    (click)="!element.stormId || setSelectedStorm(element.stormId)"
                    class="noWrap"
                    [class.activeStormDateRow]="element && selectedStorm && element.stormId === selectedStorm.stormId"
                    *matCellDef="let element"
                >
                    {{ element.stormStartTime | date: customDateFormat }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>
                </mat-header-cell>
                <mat-cell
                    class="text-right"
                    [class.activeStormDateRow]="element && selectedStorm && element.stormId === selectedStorm.stormId"
                    *matCellDef="let element"
                >
                    <div class="storm-actions-wrapper">
                        <button
                            mat-icon-button
                            [disabled]="isStudyLocked"
                            matTooltip="{{ 'SLIICER.COMMON.STORM_EVENT.INCLUDE_STORM' | translate}}"
                            *ngIf="element.stormExists && isExcluded(element.stormId)"
                            (click)="toggleStormBasinExclusion({checked: false}, element.stormId)"
                            type="button"
                        >
                            <mat-icon> visibility_off </mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            [disabled]="isStudyLocked"
                            matTooltip="{{ 'SLIICER.COMMON.STORM_EVENT.EXCLUDE_STORM' | translate}}"
                            *ngIf="element.stormExists && !isExcluded(element.stormId)"
                            (click)="toggleStormBasinExclusion({checked: true}, element.stormId)"
                            type="button"
                        >
                            <mat-icon> visibility </mat-icon>
                        </button>


                        <button
                            mat-icon-button
                            [disabled]="isStudyLocked"
                            matTooltip="{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.EDIT_STORM' | translate }}"
                            (click)="setSelectedStorm(element.stormId, true)"
                            type="button"
                        >
                            <mat-icon> mode_edit </mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            [disabled]="isStudyLocked"
                            matTooltip="{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.DELETE_STORM' | translate }}"
                            (click)="deleteStorm(element)"
                            type="button"
                        >
                            <mat-icon> delete </mat-icon>
                        </button>

                    </div>
                </mat-cell>
            </ng-container>
            <!-- US #30401 TASK #30466 SLiiCER - Deleting and adding a storm features - DELETE this functionality from the UI -->
            <!-- <mat-header-cell *matHeaderCellDef class="custom-strom"></mat-header-cell> -->
            <!-- <ng-container matColumnDef="stormId">
            <mat-header-cell *matHeaderCellDef>X</mat-header-cell>
            <mat-cell
                *matCellDef="let element"
                [class.activeStormDateRow]="element && selectedStorm && element.stormId === selectedStorm.stormId"
            >
                <mat-checkbox
                    [checked]="element.isExcluded"
                    [disabled]="isStudyLocked || !element.stormId"
                    (change)="toggleStormBasinExclusion($event, element.stormId)"
                >
                </mat-checkbox>
            </mat-cell>
            </ng-container> -->

            <mat-header-row *matHeaderRowDef="stormColumns"></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: stormColumns"
                [ngClass]="row.statusTag ? row.statusTag + 'StormEvent' : ''"
            >
            </mat-row>
        </mat-table>

    </div>
    <div class="auto-margin storm-chooser-bottom">
        <span
            class="custom-flat-button right-align"
            [matTooltip]="
                !hasYears && (!availableSeasons || availableSeasons.length === 0) && (!regimes || regimes.length === 0)
                ? ('SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.CUSTOM_FILTERS_DISABLED' | translate)
                : ''"
            >
            <button
                class="mat-button"
                mat-button
                (click)="setCustomFilter()"
                [disabled]="isStudyLocked || !isOwnStudy || (!hasYears && (!availableSeasons || availableSeasons.length === 0) && (!regimes || regimes.length === 0))"
            >
                {{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.CUSTOM_FILTERS' | translate }}
            </button>
        </span>
        <div class="clear"></div>
        <mat-paginator
            [hidden]="paginationMaxLength > pageSize "
            [length]="paginationMaxLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 15]"
        ></mat-paginator>
    </div>

</div>
