import {
    Component,
    Inject,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    NgZone,
    ViewChild,
    AfterViewInit,
    ElementRef,
    HostListener,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'app/shared/models/users';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { CustomerService } from 'app/shared/services/customer.service';
import { StatusCodeService } from '../../shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DomOperationUtilsService } from '../../shared/utils/dom-operation-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { GISService } from 'app/shared/services/gis-service';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import { first } from 'rxjs/operators';
import { UserPreferencesDateQuickSpan, UserSettings } from 'app/shared/models/user-settings';
import { combineLatest } from 'rxjs';
import { DefaultResponse } from 'app/shared/models/default-response';
import { GetPermissionsResponseCustomer, THEMES } from 'app/shared/models/users-permission';
import {
    AlarmUserPreference,
    AlarmUserPreferenceUI,
    EditUserRequest,
    UserPreference,
} from 'app/shared/models/user-preferences';

import { CountryISO, SearchCountryField } from "ngx-intl-tel-input-gg";

import { Payload } from 'app/shared/models/payload';
import { IComponentCustomizedConfirmation } from 'app/shared/models/customized-confirmation-box';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { customerQueryParam } from 'app/shared/models/customer';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { DateService } from 'app/shared/services/date.service';
const totalAlarmLength = 12;
const email = 'email';
const smsPrefrence = 'SMS';
const emailPrefrence = 'Email';
const bothPrefrence = 'Both';
const BLACKSCREENID = 4;
const WHITESCREENID = 2;

@Component({
    selector: 'app-user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class UserPreferencesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("phoneNotificationsToggle") phoneNotificationsToggle: MatSlideToggle;

    private subscriptions = new Array<Subscription>();
    private customerId: number;
    public customerCtrl = new FormControl();
    public filteredCustomer: Observable<GetPermissionsResponseCustomer[]>;
    public customerName: string;
    public favoriteCustomer: GetPermissionsResponseCustomer;
    public selectedcustomer: GetPermissionsResponseCustomer;
    public userPreferences = new UserPreference();
    public customers = new Array<GetPermissionsResponseCustomer>();
    public userPreferenceResponse = <DefaultResponse<Array<Payload>>>{};
    public userPreferenceLoding = false;
    public isAlarmUserPreferencesChange = false;
    public isThemeChangeFlag = false;
    public isIncludeRainLocsFlag = false;
    public isMarkersFlageChange = false;
    public isAutoCorrectChangeFlag = false;
    public isChangeQuickSpanCumulativeRain = false;
    public userID: string;
    private originalFavoritedCustomer: GetPermissionsResponseCustomer;
    public selectAll = false;
    public selectAllEmail = false;
    public isSmsSelected: boolean;
    public isEmailSelected: boolean;
    public isSms: boolean;

    public gisUserSetting: gisUserSettings;

    public dropdownLocation: SafeHtml;
    public phoneNumberObject;
    public SearchCountryField = SearchCountryField;
    public CountryISO = CountryISO;
    public preferredCountries: CountryISO[] = [
      CountryISO.UnitedStates,
      CountryISO.UnitedKingdom
    ];

    @ViewChild('userPrefPhoneNumberInput', {read: ElementRef}) public userPrefPhoneNumberInput: ElementRef;
    @HostListener('window:resize', ['$event']) onResize() { this.setPhoneNumberDropdownLocation(); }
    @HostListener('window:scroll', ['$event']) onScroll() { this.setPhoneNumberDropdownLocation(); }


    /**
     * flag to check if associated customers exist
     */
    public customersFound: boolean;

    /**
     * Represents inpus for Confirmation Box
     */
    public conformationDataInput: IComponentCustomizedConfirmation;

    /**
     * Represents show Confirmation box flag
     */
    public showConfirmation: boolean;

    /**
     * Represents Selected Customer
     */
    public selectedCustomer: AlarmUserPreferenceUI;

    /**
     * Reg Ex. Pattern for email validation
     */
    public emailPattern = REGEX_CONFIG.strictEmailPattern;

    /**
     * Reg Ex. Pattern for First Name
     */
    public firstNamePattern = REGEX_CONFIG.firstNamePattern;

    /**
     * Reg Ex. Pattern for Last Name
     */
    public lastNamePattern = REGEX_CONFIG.lastNamePattern;
    /**
     * Primary Email Address
     */
    public primaryEmailAddress: string;
    /**
     * Secondary Email Address
     */
    public secondaryEmailAddress: string;
    public showDialog: boolean;
    /**
     * Phone number
     */
    public phoneNumber: string;

    /* Notifications */
    public userNotification: UserSettings = {};

    /**
     * User First Name
     */
    public firstName: string;
    /**
     * User Last Name
     */
    public lastName: string;

    /**
     * Check for admin role
     */
    public isAdmin: boolean;

    public isDarkThemeEnabled: boolean;
    public includeRainLocations: boolean;

    public beforeAutoCorrectChange: boolean;
    public beforeDarkThemeChange: boolean;
    public beforeIncludeRainChange: boolean;

    public viewValueEnglish: string;
    public viewValueFrench: string;
    public viewValueChinese: string;
    public alarmNotificationUpdateSuccess: string;
    public alarmNotificationSaveErr: string;
    public userPreferenceUpdateFail: string;
    public userPreferenceUpdateSuccess: string;
    public userPreferenceSaveErr: string;
    public defaultCustomerErr: string;
    public favCustomerChange: string;
    public updateConfirmationMsg: string;
    public notificationsText: string;
    public updateConfirmationTitle: string;
    public dismissText: string;
    public unsubscribeBtn: string;
    public successfullyText: string;
    public cancelBtn: string;
    public languagesList: Language[];
    public prefrence: string;
    public selectedLang: any;
    public lowBatteryAlarm: string;
    public highHighAlarm: string;
    public highLevelAlarm: string;
    public lowLevelAlarm: string;
    public rainAlarm: string;
    public lowFlowAlarm: string;
    public submergedAlarm: string;
    public tiltAlarm: string;
    public fullPipeAlarm: string;
    public overflowAlarm: string;
    public flowLossAlarm: string;
    public wetOverflowAlarm: string;
    public dryOverflowAlarm: string;
    public phoneNumberRequired: string;
    public phoneNumberRequiredMessage: string;
    public alarmsData;
    public displayedColumns;
    public dataSource;
    public confrim: string;

    originalValues = {
        primaryEmailAddress: null,
        secondaryEmailAddress: null,
        firstName: null,
        lastName: null,
        phoneNumberObject: null,

        theme: null,
        notificationPrimaryEmail: null,
        notificationSecondaryEmail: null,
        notificationSMS: null,

        quickSpanCumulativeRain: null,

        languageSelection: null
    }

    translations = {
        attention: '',
        accept: '',
        reject: '',
        phoneConfirmationInfo: ''
    }

    public quickSpanCumulativeRain: UserPreferencesDateQuickSpan;

    // #37073 I want to be able to customize the default time period used for the Rainfall Widget to 1 day, 1 week, or 1 month
    public quickRangesOptions = [
        {id: UserPreferencesDateQuickSpan.OneDay, name: ''},
        {id: UserPreferencesDateQuickSpan.OneWeek, name: ''},
        {id: UserPreferencesDateQuickSpan.OneMonth, name: ''},
    ];

    constructor(
        public userPreferencesDialog: MatDialogRef<UserPreferencesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: string },
        private usersService: UsersService,
        private customerService: CustomerService,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        public router: Router,
        private zone: NgZone,
        private domOperationUtilsService: DomOperationUtilsService,
        public translate: TranslateService,
        private matDialog: MatDialog,
        private dateService: DateService,
        public gisService: GISService,
        public domSanitizer: DomSanitizer
    ) {
        this.translations.attention = this.translate.instant('COMMON.ATTENTION');
        this.translations.accept = this.translate.instant('COMMON.ACCEPT_BTN');
        this.translations.reject = this.translate.instant('COMMON.REJECT_TEXT');
        this.translations.phoneConfirmationInfo = this.translate.instant('USER_PREFERENCES.PHONE_CONFIRMATION_INFO');
    }

    /**
     * close user prefreences popup
     */
    public emitUserPreferences() {
        this.userPreferencesDialog.close({ success: false });
    }

    /**
     * init method
     */
    public ngOnInit() {
        this.dropdownLocation = this.domSanitizer.bypassSecurityTrustHtml("<style></style");
        this.customerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam) || 0);

        for(const quickRangeOption of this.quickRangesOptions) {
            quickRangeOption.name = this.dateService.quickSpanDescriptionForQuickSpan(quickRangeOption.id);
        }

        this.translate.get('USER_PREFERENCES.ALARM_NOTIFICATION_UPDATE_SUCCESS').subscribe((res: string) => {
            this.alarmNotificationUpdateSuccess = res;
        });
        this.translate.get('USER_PREFERENCES.ALARM_NOTIFIACATION_SAVE_ERR').subscribe((res: string) => {
            this.alarmNotificationSaveErr = res;
        });
        this.translate.get('USER_PREFERENCES.OK_TEXT').subscribe((res: string) => {
            this.confrim = res;
        });
        this.translate.get('USER_PREFERENCES.USER_PREFERENCE_UPDATE_FAIL').subscribe((res: string) => {
            this.userPreferenceUpdateFail = res;
        });
        this.translate.get('USER_PREFERENCES.USER_PREFERENCE_UPDATE_SUCCESS').subscribe((res: string) => {
            this.userPreferenceUpdateSuccess = res;
        });
        this.translate.get('USER_PREFERENCES.USER_PREFERENCE_SAVE_ERR').subscribe((res: string) => {
            this.userPreferenceSaveErr = res;
        });
        this.translate.get('USER_PREFERENCES.DEFAULT_CUSTOMER_ERR').subscribe((res: string) => {
            this.defaultCustomerErr = res;
        });
        this.translate.get('USER_PREFERENCES.FAV_CUST_CHANGE').subscribe((res: string) => {
            this.favCustomerChange = res;
        });
        this.translate.get('USER_PREFERENCES.UPDATE_CONFIRMATION_MSG').subscribe((res: string) => {
            this.updateConfirmationMsg = res;
        });
        this.translate.get('USER_PREFERENCES.NOTIFICATIONS_TEXT').subscribe((res: string) => {
            this.notificationsText = res;
        });
        this.translate.get('USER_PREFERENCES.PHONE_NUMBER_REQUIRED').subscribe((res: string) => {
            this.phoneNumberRequired = res;
        });
        this.translate.get('USER_PREFERENCES.PHONE_NUMBER_REQUIRED_MESSAGE').subscribe((res: string) => {
            this.phoneNumberRequiredMessage = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        this.translate.get('COMMON.UNSUBSCRIBE_BTN').subscribe((res: string) => {
            this.unsubscribeBtn = res;
        });
        this.translate.get('COMMON.UPDATE_CONFIRMATION_TITLE').subscribe((res: string) => {
            this.updateConfirmationTitle = res;
        });
        this.translate.get('COMMON.SUCCESSFULLY_TEXT').subscribe((res: string) => {
            this.successfullyText = res;
        });
        this.translate.get('COMMON.CANCEL_BUTTON').subscribe((res: string) => {
            this.cancelBtn = res;
        });

        this.translate.get('COMMON.LOW_BATTERY_ALARM').subscribe((res: string) => {
            this.lowBatteryAlarm = res;
        });
        this.translate.get('COMMON.HIGH_HIGH_ALARAM').subscribe((res: string) => {
            this.highHighAlarm = res;
        });
        this.translate.get('COMMON.HIGH_LEVEL_ALARM').subscribe((res: string) => {
            this.highLevelAlarm = res;
        });
        this.translate.get('COMMON.LOW_LEVEL_ALARM').subscribe((res: string) => {
            this.lowLevelAlarm = res;
        });
        this.translate.get('COMMON.RAIN_ALARM').subscribe((res: string) => {
            this.rainAlarm = res;
        });
        this.translate.get('COMMON.LOW_FLOW_ALARM').subscribe((res: string) => {
            this.lowFlowAlarm = res;
        });
        this.translate.get('COMMON.SUBMERGED_ALARM').subscribe((res: string) => {
            this.submergedAlarm = res;
        });
        this.translate.get('COMMON.TILT_ALARM').subscribe((res: string) => {
            this.tiltAlarm = res;
        });
        this.translate.get('COMMON.FULL_PIPE_ALARM').subscribe((res: string) => {
            this.fullPipeAlarm = res;
        });
        this.translate.get('COMMON.OVERFLOW_ALARM').subscribe((res: string) => {
            this.overflowAlarm = res;
        });
        this.translate.get('COMMON.FLOW_LOSS_ALARM').subscribe((res: string) => {
            this.flowLossAlarm = res;
        });
        this.translate.get('COMMON.WET_OVERFLOW_ALARM').subscribe((res: string) => {
            this.wetOverflowAlarm = res;
        });
        this.translate.get('COMMON.DRY_OVERFLOW_ALARM').subscribe((res: string) => {
            this.dryOverflowAlarm = res;
        });

        this.languagesList = [
            { value: 'en-us', viewValue: 'English (US)' },
            { value: 'fr', viewValue: 'French (Francais)' },
            // { value: 'zh-CN', viewValue: 'Chinese(Simplified)' }
        ];

        this.selectedLang = this.translate.currentLang;
        this.originalValues.languageSelection = this.selectedLang;
        this.loadGisUserSettings();

        // Subscribing to the result value from permission API
        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            this.isDarkThemeEnabled = response;
            this.beforeDarkThemeChange = this.isDarkThemeEnabled;
        });

        combineLatest([this.usersService.getUserPermissions(), this.usersService.getUserSettings()]).subscribe(
            ([perms, settings]) => {
                this.primaryEmailAddress = this.originalValues.primaryEmailAddress = perms.userEmail || perms.payload.userEmail;
                this.secondaryEmailAddress = this.originalValues.secondaryEmailAddress = perms.secondaryEmail || perms.payload.secondaryEmail;
                this.firstName = this.originalValues.firstName = perms.firstName || perms.payload.firstName;
                this.lastName = this.originalValues.lastName = perms.lastName || perms.payload.lastName;
                this.phoneNumberObject = this.originalValues.phoneNumberObject = perms.phoneNumber
                    ? perms.phoneNumber.toString()
                    : perms.payload && perms.payload.phoneNumber
                    ? perms.payload.phoneNumber.toString()
                    : '';

                this.userNotification = settings;
                this.originalValues = {...this.originalValues, ...this.userNotification};
                this.includeRainLocations = settings.includeRainLocations;
                this.beforeIncludeRainChange = settings.includeRainLocations;
                this.quickSpanCumulativeRain = this.originalValues.quickSpanCumulativeRain = settings.quickSpanCumulativeRain;

                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                this.secondaryEmailAddress = '';
                this.firstName = '';
                this.lastName = '';
                // this.phoneNumber = '';
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );

        // set loading state
        this.userPreferenceLoding = false;

        // detect changes
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public ngAfterViewInit(){
        this.setPhoneNumberDropdownLocation();
    }

    public isModified() {
        const noPhoneNumber = !this.phoneNumberObject || this.phoneNumberObject === ''
            || (this.phoneNumberObject && (!this.phoneNumberObject.number || this.phoneNumberObject.number === ''));
        const noOriginalPhoneNumber = !this.originalValues.phoneNumberObject || this.originalValues.phoneNumberObject === '';

        return this.primaryEmailAddress !== this.originalValues.primaryEmailAddress
            || this.secondaryEmailAddress !== this.originalValues.secondaryEmailAddress
            || this.quickSpanCumulativeRain !== this.originalValues.quickSpanCumulativeRain
            || this.firstName !== this.originalValues.firstName
            || this.includeRainLocations !== this.beforeIncludeRainChange
            || this.lastName !== this.originalValues.lastName
            || (noPhoneNumber !== noOriginalPhoneNumber)
            || (!noPhoneNumber && (this.phoneNumberObject && this.phoneNumberObject.number && this.phoneNumberObject.number !== this.originalValues.phoneNumberObject))
            || this.usersService.getThemeName(this.isDarkThemeEnabled) !== this.originalValues.theme
            || this.userNotification.notificationPrimaryEmail !== this.originalValues.notificationPrimaryEmail
            || this.userNotification.notificationSecondaryEmail !== this.originalValues.notificationSecondaryEmail
            || this.userNotification.notificationSMS !== this.originalValues.notificationSMS
            || this.isLanguageModified()
    }

    public isLanguageModified() {
        return this.selectedLang !== this.originalValues.languageSelection;
    }

    private setPhoneNumberDropdownLocation() {
        // The international phone number component doesn't play nicely with our page and overflows hidden etc
        // Only way to avoid rewriting portions of their component, scroll bars, or changing page size is hacking the css
        // The dropdown gets popped out from its position, put in fixed position on page, and manually put in place

        // Get the location of the phone number input on the page
        const currentTop = Math.round((this.userPrefPhoneNumberInput.nativeElement).getBoundingClientRect().top);
        const currentLeft =  Math.round((this.userPrefPhoneNumberInput.nativeElement).getBoundingClientRect().left);

        // Drop down and over a bit from the parent component
        const newTop = 'top:' + (currentTop + 88) + 'px !important;';
        const newLeft = 'left:' + (currentLeft + 16) + 'px !important;';
        const position = 'position: fixed !important;';
        const maxWidth = 'max-width: 60vw !important;';

        // Generate new values for stylesheet
        // Add style to the page to affect the 'country-dropdown' class
        const newHtml = '.country-dropdown{' + newTop + newLeft + position + maxWidth + '}';
        this.dropdownLocation = this.domSanitizer.bypassSecurityTrustHtml("<style>" + newHtml + "</style>");
    }

    /**
     * Framework level hook.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * set user preference customers.
     */
    public saveUserPreferences() {
        if(this.isLanguageModified()) {
            this.changeLanguage();
        }

        this.showDialog = false;

        this.phoneNumber = this.phoneNumberObject?.e164Number;
        if (
            this.alarmsData &&
            (this.isAlarmUserPreferencesChange || this.phoneNumber === undefined || this.phoneNumber === '')
        ) {
            const alarmUserPreferences = new Array<AlarmUserPreference>();
            this.alarmsData.forEach((alarmNotification) => {
                if (alarmNotification.smsNotification || alarmNotification.emailNotification) {
                    if (alarmNotification.smsNotification) {
                        this.prefrence = smsPrefrence;
                    }
                    if (alarmNotification.emailNotification) {
                        this.prefrence = emailPrefrence;
                    }
                    if (alarmNotification.smsNotification && alarmNotification.emailNotification) {
                        this.prefrence = bothPrefrence;
                    }
                    const alarmUserPreference: AlarmUserPreference = {
                        UserID: this.usersService.userName,
                        SubscriptionType: alarmNotification.SubscriptionType,
                        Threshold: 0,
                        LocationID: 0,
                        Preference: this.prefrence,
                    };
                    alarmUserPreferences.push(alarmUserPreference);
                    alarmUserPreferences.forEach((alarms) => {
                        if (alarms.Preference === bothPrefrence || alarms.Preference === smsPrefrence) {
                            this.isSms = true;
                        }
                    });
                }
            });

            if (
                this.isSms &&
                alarmUserPreferences.length > 0 &&
                (this.phoneNumber === undefined || this.phoneNumber === '')
            ) {
                this.isSms = false;
                this.showDialog = true;
                this.matDialog.open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.phoneNumberRequired,
                        message: this.phoneNumberRequiredMessage,
                        okText: this.confrim,
                    },
                });
            } else {
                this.userPreferenceLoding = true;
                const updateUsersPreferenceSubscription = this.usersService
                    .saveUserPreferenceNotification(
                        this.usersService.userName,
                        this.selectedcustomer.customer.customerID,
                        alarmUserPreferences,
                    )
                    .subscribe(
                        () => {
                            this.usersService.customerAlarmsCache = {
                                cid: this.selectedcustomer.customer.customerID,
                                res: alarmUserPreferences,
                            };
                            this.userPreferenceLoding = false;
                            this.close();
                            this.snackBar.open(this.alarmNotificationUpdateSuccess, this.dismissText, <MatSnackBarConfig>{ duration: 10000 });
                        },
                        (err) =>
                            this.snackBar.open(this.alarmNotificationSaveErr, this.dismissText, {
                                panelClass: 'custom-error-snack-bar',
                            }),
                    );

                this.subscriptions.push(updateUsersPreferenceSubscription);
            }
        }

        if (!this.showDialog) {
            this.showDialog = false;

            this.usersService.userPhoneNumber.next(this.phoneNumber || null);
            this.usersService
                .editUser(<EditUserRequest>{
                    userEmail: this.primaryEmailAddress,
                    secondaryEmail: this.secondaryEmailAddress,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNumber ? this.phoneNumber : '',
                })
                .subscribe(
                    (response: DefaultResponse<{}>) => {
                        if (!response || response.isError) {
                            this.snackBar.open(this.userPreferenceUpdateFail, this.dismissText, {
                                panelClass: 'custom-error-snack-bar',
                            });
                        } else {
                            this.snackBar.open(this.userPreferenceUpdateSuccess, this.dismissText, <MatSnackBarConfig>{
                                duration: 10000,
                            });

                            if(this.primaryEmailAddress !== this.originalValues.primaryEmailAddress) {
                                this.usersService.changePrimaryEmail(this.primaryEmailAddress);
                            }
                            this.close();
                        }
                    },
                    (error) => {
                        this.snackBar.open(this.userPreferenceSaveErr, this.dismissText, <MatSnackBarConfig>{
                            panelClass: 'custom-error-snack-bar',
                        });
                    },
                );

            this.updateUserSettings();

            if (this.isThemeChangeFlag) {
                if (this.isDarkThemeEnabled) {
                    this.domOperationUtilsService.setCustomTheme(THEMES.themeColorDark);
                    this.statusCodeService.selectedMapTypeId.next(BLACKSCREENID);
                    this.statusCodeService.userInfoThemeBS.next(true);
                    this.statusCodeService.isThemeChanged.next(true);
                    // userservice method to update dark theme reference on user choosing to save the preferences
                    this.subscriptions.push(
                        this.usersService.updateUserSettingsTheme(THEMES.darkTheme).subscribe(
                            (res) => {
                                if (res) {
                                    return;
                                }
                            },
                            (error) => {
                                // empty error block
                            },
                        ),
                    );
                } else {
                    this.domOperationUtilsService.setCustomTheme(THEMES.themeColorLight);
                    this.statusCodeService.selectedMapTypeId.next(WHITESCREENID);
                    this.statusCodeService.userInfoThemeBS.next(false);
                    this.statusCodeService.isThemeChanged.next(true);
                    // userservice method to update light theme reference on user choosing to save the preferences
                    this.subscriptions.push(
                        this.usersService.updateUserSettingsTheme(THEMES.lightTheme).subscribe(
                            (res) => {
                                if (res) {
                                    return;
                                }
                            },
                            (error) => {
                                // empty error block
                            },
                        ),
                    );
                }

                // reload current page
                const currentPage = this.router.url.toString();
                this.router.navigateByUrl(currentPage);
            }
        }
    }

    private updateUserSettings() {
        const oldSettings = this.usersService.userSettings.getValue();

        oldSettings.theme = this.usersService.getThemeName(this.isDarkThemeEnabled);
        oldSettings.notificationPrimaryEmail = this.userNotification.notificationPrimaryEmail;
        oldSettings.notificationSecondaryEmail = this.userNotification.notificationSecondaryEmail;
        oldSettings.notificationSMS = this.userNotification.notificationSMS;
        oldSettings.includeRainLocations = this.includeRainLocations;
        oldSettings.quickSpanCumulativeRain = this.quickSpanCumulativeRain;
        this.usersService.updateUserSettings({ ...oldSettings }).subscribe(() => {
            this.usersService.userSettings.next({ ...oldSettings });
        });
    }

    /**
     * close dialog-box.
     */
    public close() {
        this.userPreferencesDialog.close({ success: false });
    }

    /**
     * Sets the currently favored customer
     * @param customer selected customer
     * @param event mouse event
     */
    public setFavoredCustomer(event: MouseEvent) {
        // set loader
        this.userPreferenceLoding = true;

        this.usersService
            .editUser(<EditUserRequest>{
                defaultCustomer: this.selectedcustomer.customer.customerID,
            })
            .subscribe(
                (response: DefaultResponse<{}>) => {
                    if (!response || response.isError) {
                        this.snackBar.open(this.defaultCustomerErr, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    } else {
                        // set current favorite customer
                        this.favoriteCustomer = this.selectedcustomer;
                        this.originalFavoritedCustomer = this.favoriteCustomer;

                        //  update the current customer list
                        const oldDefaultCustomer = this.customers.find((cust) => cust.defaultCustomer);
                        if (oldDefaultCustomer) {
                            oldDefaultCustomer.defaultCustomer = false;
                        }

                        const newDefaultCustomer = this.customers.find(
                            (cust) => cust.customer.customerID === this.favoriteCustomer.customer.customerID,
                        );
                        if (newDefaultCustomer) {
                            newDefaultCustomer.defaultCustomer = true;
                        }

                        this.snackBar.open(
                            `"${this.favCustomerChange} ${this.selectedcustomer.customer.customerName} ${this.successfullyText}"`,
                            this.dismissText,
                            <MatSnackBarConfig>{ duration: 10000 },
                        );
                    }

                    // set loader
                    this.userPreferenceLoding = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                },
                (error) => {
                    console.error(error);

                    // set loader
                    this.userPreferenceLoding = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                },
            );
    }

    public onIncludeRainLocsToggle(event: MatSlideToggleChange) {
        this.includeRainLocations = event.checked;
        this.isIncludeRainLocsFlag = this.beforeIncludeRainChange !== this.includeRainLocations;
    }

    public onChange(e: MatSlideToggleChange) {
        if (e.checked === true) {
            this.isDarkThemeEnabled = true;
            //   this.statusCodeService.toggleTheme();
        } else {
            this.isDarkThemeEnabled = false;
            //  this.statusCodeService.toggleTheme();
        }
        this.beforeDarkThemeChange === this.isDarkThemeEnabled
            ? (this.isThemeChangeFlag = false)
            : (this.isThemeChangeFlag = true);
    }

    public changeLanguage() {
        if (!this.selectedLang) {
            return;
        }
        this.translate.use(this.selectedLang);
        this.gisUserSetting.languageSelection = this.selectedLang;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);

        this.saveGisUserSettings();
    }

    public async loadGisUserSettings() {
        const userSettings = await this.gisService.gisUserSettingsGet();

        if (userSettings != null) {
            this.gisUserSetting = userSettings;
            this.selectedLang = userSettings.languageSelection;
            this.originalValues.languageSelection = this.selectedLang;
        }
    }
    public saveGisUserSettings() {
        if (this.gisUserSetting != undefined) {
            this.gisUserSetting.lastViewedCustomerId = this.customerId;
            this.gisService.updateGisUserSettings(this.gisUserSetting).subscribe();
        }
    }

    private phoneSetNotifications(val: boolean) {
        this.userNotification.notificationSMS = val;
        this.phoneNotificationsToggle.checked = val;
    }

    public phoneToggleChange(event: PointerEvent) {
        // #36625 Confirmation when phone notifications are going to be turned on
        event.preventDefault();

        if(!this.userNotification.notificationSMS) {

            this.matDialog
            .open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.translations.attention,
                    message: this.translations.phoneConfirmationInfo,
                    cancelText: this.translations.reject,
                    okText: this.translations.accept,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if(res.whichButtonWasPressed === ConfirmationButton.ok) {
                    this.phoneSetNotifications(true);
                }
            });
        } else {
            this.phoneSetNotifications(false);
        }
    }

    public onChangeQuickSpanCumulativeRain() {
        this.isChangeQuickSpanCumulativeRain = this.quickSpanCumulativeRain !== this.originalValues.quickSpanCumulativeRain;
    }
}
