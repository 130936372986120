import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { GraphScale } from '../graph.model';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import * as Highcharts from 'highcharts';
import {
  populateDepthVelocityData,
  displayIsoQLines,
  populateBestFitSSCurves,
  optimizeScatterData,
  displayFroudeLines,
  createManualCurve,
  populateSavedCurveData,
} from './scatter-graph-utils';
import {
  BEST_FIT,
  STEVENS_CURVE,
  generateEntityInformationData,
  MANUAL_SMOOTH_CURVE,
  MANUAL_LINEAR_CURVE,
  generateNewSeries,
  ScattergraphSeriesNames,
  HOVER_COLOR,
  LINKED_BORDER_GREEN_COLOR,
  LINKED_COLOR,
  COLEBROOK_CURVE,
  MANNING_DESIGN_CURVE,
  LANFEAR_COLL_CURVE,
  TOLERANCE_LINE,
  sgCurveType,
  SGFindSeries,
  SCATTER_SERIES_NAMES,
  SELECTION_ZOOM_BOX_COLOR,
  SELECTION_ZOOM_BOX_INVISIBLE,
} from './scatter-graph-constants';
import { IGNORE_DATA, ScatterGraphBuilder, SELECT_DATA, UNIGNORE_DATA } from './scattergraph-builder.service';
import { HG_COLOR_PIPE_HEIGHT, HG_COLOR_MANHOLE_DEPTH, HG_COLOR_SILT, VELOCITY_ENTITY, DEPTH_ENTITY, DEPTH_DISPLAY_GROUP, VELOCITY_DISPLAY_GROUP, RAW_VELOCITY_ENTITY, PIPE_HEIGHT } from 'app/shared/constant';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { GraphTypes } from 'app/shared/enums/graph-types';
import {
  AdvanceScatterOptions,
  Annotations,
  EntityData,
  HistoricalCurve,
  LinkedEntityData,
  ScatterData,
  ScatterRanges,
  ToleranceLine,
} from 'app/shared/models/scatter-data';
import { AnnotationSettings, BestFitCurve, DataEditingRow, SeparateWindowActionTypes, SSCurve } from 'app/shared/models/view-data';
import { customerQueryParam } from 'app/shared/models/customer';
import { ScatterSeries } from 'app/shared/models/scatter-data';
import { DataEditingCurveAPINames, DataEditingCurveTypes, DataEditOriginalValue, DataEditPreview, ScatterLastGainEditModel, SnapDataResponse, UpdatePointForSG, UpdatePointForSGXY } from 'app/shared/models/data-edit';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { UsersService } from 'app/pages/admin/users.service';
import { MathUtils } from 'app/shared/utils/math-utils';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ManualScale } from 'app/shared/models/hydrograph';
import { ConfirmationEntitiesEnum } from 'app/shared/models/view-data-filter';
import { TranslateService } from '@ngx-translate/core';
import { SGSelectionMode } from '../scattergraph-edit-menu/scattergraph-edit-menu.component';
import { ScatterLassoSelection } from './scatter-lasso-selection';

declare let require: any;
const Boost = require('highcharts/modules/boost');
Boost(Highcharts);

export const FROUDE_LINE = 'Froude';
export const ISO_Q_LINE = 'Iso-Q™';
const MANHOLE_DEPTH = 'Manhole Depth';
const SILT = 'Silt';

@Component({
  selector: 'app-advance-scattergraph',
  templateUrl: './advance-scattergraph.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class AdvanceScattergraphComponent implements OnInit, OnDestroy {
  @Input() public isDataEditingModeEnabled = false;
  @Input() public data: ScatterData;
  @Input() private flaggedData: ScatterData;
  @Input() public locationId: number;
  @Input() public selectedEntityIds: number[];
  // Represents Date Format For Scatter Graph
  @Input() public scatterDateFormat: string;
  @Input() public editedPoints: UpdatePointForSG[] = [];
  @Output() private enablePreviewChange = new EventEmitter<boolean>();
  @Output() private setLoading = new EventEmitter<boolean>();
  @Output() private chartUpdated = new EventEmitter();
  @Output() public dataSelect = new EventEmitter();
  @Output() public dataSelectForSnap = new EventEmitter();
  @Output() public curveChanged = new EventEmitter<string[]>();
  // Represents Tooltip Date Format
  @Input() public tooltipTimeFormat: string;
  @Input() public annotationSettings: AnnotationSettings;
  @Input() public flaggedPoints = [];
  @Input() public snappingPosition: number;
  @Input() public zoomFromHydro;
  @Input() public syncZoomWithHg: boolean;
  @Input() public savedCurve: { d: string[]; name: string, depthOnY: boolean, smoothCurve?: boolean }[];
  @Input() public advanceScatterId = 'advance-scattergraph-chart';
  @Input() public effectiveRoughness: number;
  @Input() public slope: number;
  @Input() public allowHighlight = true; // #32757 Ability to disable Highlight of connected graphs
  @Input() public selectedConfirmationEntity: ConfirmationEntitiesEnum;

  private subscriptionOnThemeChange: Subscription;

  public advanceScatteroptions: AdvanceScatterOptions;

  public xValues: GraphScale = new GraphScale();
  public yValues: GraphScale = new GraphScale();

  public xAxisLabel: string;
  public yAxisLabel: string;
  public xAxisPrecision: number;
  public yAxisPrecision: number;
  public manualScales: ManualScale[];

  // Represents hydrograph chart index which would be used for co-relation of hydro and scatter chart
  public hydrographChartIndex = 0;

  public customerId: number;
  public subscriptions = new Array<Subscription>();
  public isYAxisSet: boolean;
  public isoQlines = [];
  public froudeLines = [];

  // flag to disable zoom
  public selectedPoints = [];

  public pipeGraph;
  public chart: Highcharts.ChartObject;
  public pipeOverlaySettings;

  public dataEditRowSelected: DataEditingRow;
  public isManholeDepth;
  public xUnit: string;
  public yUnit: string;

  public selected = 3;
  public bestFitCurve: BestFitCurve = null;
  public colebrookCurve: BestFitCurve = null;
  public manningDesignCurve: BestFitCurve = null;
  public lanfearCollCurve: BestFitCurve = null;
  private savedCurveData: BestFitCurve = null;
  public ssCurve: SSCurve = null;
  public manualCurve: BestFitCurve =  null;
  public isZoomedIn = false;
  public scalingFactor: number;

  public xAxisMin: number;
  public yAxisMin: number;
  public latestGainEditResults: ScatterLastGainEditModel;
  public depthVelocityData: EntityData[] = [];
  public flaggedPointsData: EntityData[] = [];
  public ignoredPointsData: EntityData[] = [];
  public editedSeriesData: EntityData[] = [];
  public snappedPointsData: EntityData[] = [];
  public hydroFlaggedPoints: {stamp: number, flagged?: boolean}[] = [];
  public previousSnappedPoints: EntityData[] = [];
  public isPointsInverted = false;
  public zoomEvent;
  public linkedData: Map<number, LinkedEntityData> = new Map();
  private multipleSeries: ScatterSeries[] = [];
  private previousHighlightPoints: number;

  public shouldUpdateCurve = false;
  public editedDistances: Map<number, number> = new Map();
  public highlightLinkedPointsSubject = new BehaviorSubject<LinkedEntityData[]>([]);
  public maximumXvalue: number;
  public isStaticTooltip = false;
  public rawvelEdits: Map<number, DataEditOriginalValue>;
  public flaggedPointsOnResponse: EntityData[] = [];

  public selectionMode: SGSelectionMode = SGSelectionMode.Rectangle;
  public lassoSelection: ScatterLassoSelection = new ScatterLassoSelection();

  private selectedScatterMenuItem: string;

  constructor(
    public viewDataService: ViewDataService,
    private element: ElementRef,
    public cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public uiUtilsService: UiUtilsService,
    private statusCodeService: StatusCodeService,
    public graphBuilder: ScatterGraphBuilder,
    public dataEditService: DataEditService,
    private sepWinHgService: SeparateWindowHydrographService,
    private usersService: UsersService,
    private dateutilService: DateutilService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.customerId = Number(params.get(customerQueryParam));
    });
    this.selectedScatterMenuItem = this.viewDataService.scatterMenuItem.getValue();
    const unSelectSubs = this.viewDataService.scatterMenuItem.subscribe((v) => {
        if (v === 'activeUnflagPoint' || v === 'activeFlagPoint' || v === 'activeClearFlag') {
            this.graphBuilder.unSelectPoints();
        }
        this.selectedScatterMenuItem = v;
        this.updateSelectionColorBox();
    });

    this.usersService.staticTracerSetting.subscribe((isStatic) => {
        this.graphBuilder.updatePointFormatter(this.chart, {isStatic: isStatic, additionalText: (tooltip, event) => this.tooltipAdditionalText(this, tooltip, event)});
    });

    this.listenHGChanges();
    this.listenForPointHighlight();
    this.listenManualCurveChanges();
    this.listenForLinkedPoints();
    this.subscriptions.push(unSelectSubs);
  }

  public setManualScales(manualScale : ManualScale[]) {
    this.manualScales = manualScale;
    this.setScatterGraphValueToService();
  }

  private listenManualCurveChanges() {
    const manualPointsSub = this.viewDataService.scatterManualCurveSelectedPoints.subscribe( pointData => {
      if (this.advanceScatteroptions && this.advanceScatteroptions.series && this.chart && this.chart.series) {
        const affectedSeri = this.advanceScatteroptions.series.find(seri => seri.name === MANUAL_LINEAR_CURVE || seri.name === MANUAL_SMOOTH_CURVE);
        if (affectedSeri) {
            // TODO: It seems Highchart do not care mutch about points order.
            // There should be another way of doing it then what is currently - remove serie and add new one
            // Tried: setData, remove and addPoint, update({data: ...})
            const affectedChartSeri = this.chart.series.find(seri => seri.name === MANUAL_LINEAR_CURVE || seri.name === MANUAL_SMOOTH_CURVE);

            if(this.manualCurve) this.manualCurve.data = pointData;

            const opt = affectedChartSeri.options;
            affectedChartSeri.remove(false)
            opt.data = pointData;
            this.chart.addSeries(opt, true);
        }
      }
    });
    this.subscriptions.push(manualPointsSub);
  }

  private listenForPointHighlight() {
    const highlightSubs = this.viewDataService.highlightScatterPoint
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((data) => {
        if (!data && this.chart) {
          this.chart.tooltip.hide();
        }

        if(!this.allowHighlight) return;
        if (!this.advanceScatteroptions) return;

        if(data && data.dateTime === this.previousHighlightPoints) return;
        if(data) this.previousHighlightPoints = data.dateTime;

        let highlightedSeries: any = this.advanceScatteroptions.series.find(
          (v) => v.name === ScattergraphSeriesNames.highlighted,
        );

        if (!data && !highlightedSeries) {
          this.displayLinkedPointsFromHg([]);
          return;
        }

        if (!data) {
          this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
            (v) => v.name !== ScattergraphSeriesNames.highlighted,
          );
          this.displayLinkedPointsFromHg([]);
          this.setScatterGraphValueToService();
          return;
        }

        let point;
        let isPointFound = false;
        for (let i = 0; i < this.advanceScatteroptions.series.length; i++) {
          const series = this.advanceScatteroptions.series[i];

          point = series && series.data ? series.data.find((i: any) => i.dateTime === data.dateTime) : null;

          if (point) {
            const linkedPoint = this.linkedData.get(point.dateTime);
            if (linkedPoint) {
              const prev = this.linkedData.get(linkedPoint.prev);
              const next = this.linkedData.get(linkedPoint.next);
              this.displayLinkedPointsFromHg([prev, next]);
            }
            if (!highlightedSeries) {
              highlightedSeries = generateNewSeries(ScattergraphSeriesNames.highlighted);
              highlightedSeries.tooltip.stickOnContact = true;
              highlightedSeries.tooltip.shared = true;
              highlightedSeries.marker.radius = 6;
              highlightedSeries.showInLegend = false;
              highlightedSeries.marker.lineColor = 'rgb(100,100,100)';
              highlightedSeries.marker.lineWidth = 1;
              highlightedSeries.marker.fillColor = HOVER_COLOR;
              this.graphBuilder.setPointFormatter(
                this.advanceScatteroptions,
                this,
                this.graphBuilder,
                highlightedSeries,
              );
              this.advanceScatteroptions.series.push(highlightedSeries);
            }
            highlightedSeries.data = [point];

            this.setScatterGraphValueToService();
            isPointFound = true;
            this.viewDataService.correlateAdvancedGraphs(data.container, data.dateTime);
            break;
          }
        }

        if (!isPointFound) {
          this.displayLinkedPointsFromHg([]);
          this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
            (v) => v.name !== ScattergraphSeriesNames.highlighted,
          );
          this.setScatterGraphValueToService();
        }
      });

    this.subscriptions.push(highlightSubs);
  }

  private displayLinkedPointsFromHg(points: LinkedEntityData[]) {
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.linked,
    );

    if (!points.length) {
        return;
    }

    const linkedData = this.findLinkedPoints(points);
    const series = this.generateLinkedSeries(linkedData);
    this.advanceScatteroptions.series.push(series);
  }

  // #15819 Display additional info in a SG tooltip
  private tooltipAdditionalText(self, tooltip, event) {
    if(!event || !event.point || !event.point.series) {
        return null;
    }

    const seriesName = event.point.series.name;

    if(self.lanfearCollCurve && seriesName === DataEditingCurveAPINames.LanfearColl) {
        const curveData = self.data.curves[0]
        const errorVal = curveData.error;
        const hcVal = curveData.hc;

        return [` R2: ${errorVal}, HC: ${hcVal.toPrecision(2)}`];
    } else if(self.manningDesignCurve && seriesName === DataEditingCurveAPINames.ManningDesign) {
        const curveData = self.data.curves[0]
        const hcVal = curveData.hc;

        return [` HC: ${hcVal.toPrecision(2)}`];
    } else {
        return null;
    }
  }

  private generateLinkedSeries(data: EntityData[]) {
    const series = generateNewSeries(ScattergraphSeriesNames.linked);
    series.tooltip.stickOnContact = true;
    series.tooltip.shared = true;
    series.marker.radius = 5;
    series.showInLegend = false;
    series.marker.lineColor = LINKED_BORDER_GREEN_COLOR;
    series.marker.lineWidth = 0.5;
    series.marker.fillColor = LINKED_COLOR;
    series.enableMouseTracking = false;
    series.data = data;

    return series;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onHydroZoomChange() {
    const isCurveLocked = this.viewDataService.lockSGCurve.getValue();
    if (!this.zoomFromHydro || !this.zoomFromHydro.length || !this.advanceScatteroptions) {
      return;
    }

    if (this.syncZoomWithHg) {
      this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.zoomed,
      );
      const [start, end] = this.zoomFromHydro;
      this.advanceScatteroptions.series.forEach((series) => {
        if ((series.name === BEST_FIT || series.name === STEVENS_CURVE
          || series.name === MANUAL_SMOOTH_CURVE || series.name === MANUAL_LINEAR_CURVE) && isCurveLocked) {
          return;
        }

        if (series.data && series.data.length) {
          series.data = series.data.filter((v) => !v.dateTime || (v.dateTime >= start && v.dateTime <= end));
        }
      });
    } else {
      this.setZoomedHgPointsSeries();
    }

    this.setScatterGraphValueToService();
  }

  public pipeOverlaySetup() {
    this.pipeOverlaySettings = {
        maximumY: this.data.pipeOverlay['maximumY'],
        minimumY: this.data.pipeOverlay['minimumY'],
        pipeHeight: this.data.pipeOverlay['pipeHeight'],
        xForMaximumY: this.data.pipeOverlay['xForMaximumY'],
        xForMinimumY: this.data.pipeOverlay['xForMinimumY'],
        maximumSurchargeY: this.data.pipeOverlay['maximumSurchargeY']
      };
  }

  public displayScatterGraph(data: ScatterData) {
    this.data = data;
    if (!this.data || !this.annotationSettings) {
      this.setLoading.emit(false);
      return;
    }

    // #37662 need to destroy previous chart
    if (this.viewDataService.advancedCharts && this.viewDataService.advancedCharts[1]) {
      this.viewDataService.advancedCharts[1].destroy();
      this.viewDataService.advancedCharts[1] = null;
    }
    const invertScatter = this.annotationSettings && this.annotationSettings.isScatterInvert;
    this.xAxisLabel = invertScatter ? this.data.yAxis.label : this.data.xAxis.label;
    this.yAxisLabel = invertScatter ? this.data.xAxis.label : this.data.yAxis.label;
    this.xUnit = invertScatter ? this.data.yAxis.unit : this.data.xAxis.unit;
    this.yUnit = invertScatter ? this.data.xAxis.unit : this.data.yAxis.unit;
    this.xAxisPrecision = invertScatter ? this.data.yAxis.precision : this.data.xAxis.precision;
    this.yAxisPrecision = invertScatter ? this.data.xAxis.precision : this.data.yAxis.precision;

    this.flaggedPointsOnResponse = [];
    let depthVelocityData: EntityData[] = populateDepthVelocityData(
      this.data,
      this.annotationSettings.isDataQuality,
      this.annotationSettings.isScatterInvert, this.isDataEditingModeEnabled, null,
      this.editedDistances, this.flaggedPointsOnResponse
    );

    depthVelocityData.forEach(v => this.editedDistances.set((v.dateTime as number), v.distance));
    this.linkedData.clear();

    const allPoints = [...depthVelocityData, ...this.flaggedPointsOnResponse].sort((a, b) => Number(a.dateTime) - Number(b.dateTime));

    if (this.selectedEntityIds.includes(RAW_VELOCITY_ENTITY) && this.rawvelEdits && this.rawvelEdits.size > 0) {
      allPoints.forEach((point: EntityData) => {
        const fromEdit = this.rawvelEdits.get(point.dateTime as number);

        if (!fromEdit) return;

        const key = this.annotationSettings.isScatterInvert ? 'y' : 'x';

        point[key] = fromEdit.y;
      });

      const flaggedData = Array.from(this.rawvelEdits.values()).filter(v => v.flagged).map(v => ({ stamp: v.x, flagged: true }));

      this.hydroFlaggedPoints = [...this.hydroFlaggedPoints, ...flaggedData];
      this.hydroFlaggedPoints = this.hydroFlaggedPoints.filter((v, i) => i === this.hydroFlaggedPoints.findIndex(x => x.stamp === v.stamp))
    }

    // #37506 Grab current state for ignored points. HG does redraw when it does zoom so need to update that.
    const { points: ignoredPoints, inverted } = this.dataEditService.sgIgnoredPointsData$.getValue();
    // #40379 Format ignored points to reflect current SG Invert state
    const shouldInvert = this.annotationSettings.isScatterInvert !== inverted;
    this.ignoredPointsData = ignoredPoints.map(p => shouldInvert ? this.invertPoint(p) : p);

	// only exists after gain table edits and will be erased on next get scattergraph data call
	if (this.latestGainEditResults) {
    // when unflag, we need to add unflagged points to normal scatter series
		const unflaggedPoints: EntityData[] = this.applyLatestGainEdits(allPoints);

    unflaggedPoints.forEach(v => depthVelocityData.push(v));
	}

    this.linkedData = allPoints.reduce((acc, curr, index, arr) => {
        const prev = arr[index - 1] ? arr[index - 1].dateTime : null;
        const next = arr[index + 1] ? arr[index + 1].dateTime : null;

        return acc.set(curr.dateTime as number, { ...curr, prev, next });
    }, new Map<number, any>());
    const toUnflag = [];

    this.flaggedPointsOnResponse.forEach(v => {
        const isFlagged = this.hydroFlaggedPoints.find(p => p.stamp === v.dateTime as number);
        const stillFlagged = isFlagged && isFlagged.flagged;

        if (!stillFlagged) {
            toUnflag.push(v.dateTime);
            depthVelocityData.push(v);
        }
    });

    if (toUnflag.length) {
        this.flaggedPointsData = this.flaggedPointsData.filter(v => !toUnflag.includes(v.dateTime));
        this.flaggedPointsOnResponse = this.flaggedPointsOnResponse.filter(v => !toUnflag.includes(v.dateTime));
    }

	this.flaggedPointsData = this.flaggedPointsData.filter(v => this.selectedEntityIds.includes(v.xId) && this.selectedEntityIds.includes(v.yId))

	if (this.zoomFromHydro && this.zoomFromHydro.length && this.syncZoomWithHg) {
      const [start, end] = this.zoomFromHydro;
      depthVelocityData = depthVelocityData.filter((v) => v.dateTime >= start && v.dateTime <= end);
    }

    this.depthVelocityData = depthVelocityData;
    this.updatePointsOnInversion();
    const depth = this.editedPoints.filter(v => v.id === DEPTH_ENTITY);
    const velocity = this.editedPoints.filter(v => v.id ===  this.selectedVelocityEntity());

    if (this.editedPoints.length && (depth.length || velocity.length)) {
        this.xAxisMin = this.annotationSettings.isScatterInvert
            ? MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['x']), ...depth.map(v => v.value)])
            : MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['x']), ...velocity.map(v => v.value)]);

        this.yAxisMin = this.annotationSettings.isScatterInvert
            ? MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['x']), ...velocity.map(v => v.value)])
            : MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['x']), ...depth.map(v => v.value)]);
    } else {
        this.xAxisMin = MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['x'])]);
        this.yAxisMin = MathUtils.getMathMaxMin([...depthVelocityData.map((x) => x['y'])]);
    }
    if (this.annotationSettings.isPipeOverlay && this.data && this.data.pipeOverlay) {
        this.pipeOverlaySetup();
    }

    this.initScatterChart(depthVelocityData, this.data.yAxis.annotations);
    this.updateSelectionColorBox();
    this.setLoading.emit(false);
  }

  private applyLatestGainEdits(allPoints: EntityData[]) {
    const { results } = this.latestGainEditResults;

    // for force entity update we need to unflag points
    const toUnflag = new Set<number>();

    allPoints.forEach((point: EntityData) => {
      const { xId, yId, dateTime } = point;

      if (results.get(xId) && results.get(xId).get(dateTime as number)) {
        const editedPoint = results.get(xId).get(dateTime as number);

        point.x = editedPoint.y;	// we take Y as reading from edited series, because its X value is timestamp
      }

      if (results.get(yId) && results.get(yId).get(dateTime as number)) {
        const editedPoint = results.get(yId).get(dateTime as number);

        point.y = editedPoint.y;	// we take Y as reading from edited series, because its X value is timestamp
      }
    });

    this.flaggedPointsData = this.flaggedPointsData.filter(v => !toUnflag.has(v.dateTime as number));
    this.hydroFlaggedPoints = this.hydroFlaggedPoints.filter(v => !toUnflag.has(v.stamp));

    const toAddToNormalSeries: EntityData[] = [];
    this.flaggedPointsOnResponse = this.flaggedPointsOnResponse.filter(v => {
      const isUnflag = toUnflag.has(v.dateTime as number);

      if (isUnflag) {
        toAddToNormalSeries.push(v);
        return false;
      }

      return true;
    });

    return toAddToNormalSeries;
  }

  public displayScatterGraphCustom(data, seriesConfig) {
    this.multipleSeries.push({data: data} as ScatterSeries)
    this.data = data;

    if (!this.data || !this.annotationSettings) {
      this.setLoading.emit(false);
      return;
    }

    const invertScatter = this.annotationSettings && this.annotationSettings.isScatterInvert;
    this.xAxisLabel = invertScatter ? this.data.yAxis.label : this.data.xAxis.label;
    this.yAxisLabel = invertScatter ? this.data.xAxis.label : this.data.yAxis.label;
    this.xUnit = invertScatter ? this.data.yAxis.unit : this.data.xAxis.unit;
    this.yUnit = invertScatter ? this.data.xAxis.unit : this.data.yAxis.unit;
    this.xAxisPrecision = invertScatter ? this.data.yAxis.precision : this.data.yAxis.precision;
    this.yAxisPrecision = invertScatter ? this.data.xAxis.precision : this.data.yAxis.precision;

    const depthVelocityData: EntityData[] = populateDepthVelocityData(
      this.data,
      false,
      false,
      this.isDataEditingModeEnabled,
      seriesConfig.color,
      new Map()
    );

    this.depthVelocityData = this.depthVelocityData.concat(depthVelocityData);

    this.updatePointsOnInversion();
    this.xAxisMin = MathUtils.getMathMaxMin(depthVelocityData.map((x) => x['x']));
    this.yAxisMin = MathUtils.getMathMaxMin(depthVelocityData.map((x) => x['y']));
    if (this.annotationSettings.isPipeOverlay && this.data && this.data.pipeOverlay) {
        this.pipeOverlaySetup();
    }

    this.initScatterChart(depthVelocityData, this.data.yAxis.annotations, true);
    this.setLoading.emit(false);
  }

  private updatePointsOnInversion() {
    if (this.isPointsInverted === this.annotationSettings.isScatterInvert) {
      return;
    }

    this.isPointsInverted = this.annotationSettings.isScatterInvert;

    if (this.selectedPoints.length) {
      this.selectedPoints = this.selectedPoints.map((v) => this.invertPoint(v));
    }

    if (this.flaggedPointsData.length) {
      this.flaggedPointsData = this.flaggedPointsData.map((v) => this.invertPoint(v));
    }

    if (this.previousSnappedPoints.length) {
      this.previousSnappedPoints = this.previousSnappedPoints.map((v) => this.invertPoint(v));
      this.snappedPointsData = this.snappedPointsData.map((v) => this.invertPoint(v));
    }
    if (this.annotationSettings.isManualLinear || this.annotationSettings.isManualSmooth) {
      this.invertManualSeries();
    }
  }

  private invertManualSeries() {
    const series = this.viewDataService.scatterManualCurveSelectedPoints.getValue();
    if (!series) { return; }
    const inverted = series.map(point => {return {x: point.y, y: point.x};});
    this.viewDataService.scatterManualCurveSelectedPoints.next(inverted);
    this.refreshToleranceLines();
  }
  private invertPoint(point: EntityData) {
    return {
      dateElement: point.dateElement,
      x: Number(point.y),
      y: Number(point.x),
      xId: point.yId,
      yId: point.xId,
      xUnit: point.yUnit,
      yUnit: point.xUnit,
      xPrecision: point.yPrecision,
      yPrecision: point.xPrecision,
      xAxisEntity: point.yAxisEntity,
      yAxisEntity: point.xAxisEntity,
      dateTime: point.dateTime,
      distance: point.distance,
    };
  }

  private subscribeOnThemeChangeIfNot() {
    if (this.subscriptionOnThemeChange) return;

    this.subscriptionOnThemeChange = this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
      if (response) {
        StringUtils.setHighChartTheme();
      } else {
        StringUtils.setHighchartWhiteTheme();
      }
    });
  }

  public generateSeriesDataForUpdate(isScatterInvert: boolean, curves, key: string) {
    if (isScatterInvert) {
      return this.graphBuilder.swapDataPoints(
        optimizeScatterData(curves.filter((x) => x.name === key)[0].d, this.xUnit, this.yUnit),
      );
    }

    return optimizeScatterData(curves.filter((x) => x.name === key)[0].d, this.xUnit, this.yUnit);
  }

  private populateFroudeIsoLines(depthVelocityData: EntityData[]) {
    const ranges: ScatterRanges = { xMin: 0, yMin: 0, xMax: this.maximumXvalue, yMax: this.advanceScatteroptions.yAxis.max };
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(v => v.name !== FROUDE_LINE && v.name !== ISO_Q_LINE);
    this.isoQlines = [];
    this.froudeLines = [];

    if (this.annotationSettings.isIsoQ) {
        if (this.data && this.data.isoQ && ranges) {
          this.isoQlines = displayIsoQLines(
            this.data,
            depthVelocityData,
            ranges,
            this.annotationSettings,
            this.xUnit,
            this.yUnit,
          );
          this.isoQlines.forEach((v) => this.advanceScatteroptions.series.push(v));
        }
      }

      if (this.annotationSettings.isFroude) {
        if (this.data && this.data.froude && ranges) {
          this.froudeLines =
            displayFroudeLines(
              this.data,
              depthVelocityData,
              ranges,
              this.annotationSettings,
              this.xUnit,
              this.yUnit,
            ) || [];
          if (this.froudeLines) {
            this.froudeLines.forEach((v) => this.advanceScatteroptions.series.push(v));
          }
        }
      }
  }

  private populateCurveValues(depthVelocityData: EntityData[]) {
    const isCurveLocked = this.viewDataService.lockSGCurve.getValue();

    if (this.savedCurve) {
        const { depthOnY } = this.savedCurve[0];
        this.savedCurveData = populateSavedCurveData(this.savedCurve, depthOnY, this.annotationSettings.isScatterInvert, this.xUnit, this.yUnit);

      this.graphBuilder.updateGraphScale(this.savedCurveData.data, this.advanceScatteroptions);

      if(this.viewDataService.isCurveEditable) {
        if(this.savedCurveData.smoothCurve) {
            this.annotationSettings.isManualSmooth = true;
          } else {
            this.annotationSettings.isManualLinear = true;
          }

          this.viewDataService.scatterManualCurveSelectedPoints.next(this.savedCurveData.data);
      } else {
        this.viewDataService.scatterManualCurveSelectedPoints.next(null);
        this.advanceScatteroptions.series.push(this.savedCurveData);
      }

    } else {
      this.savedCurveData = null;
    }

    if (this.annotationSettings.isCWRcurve) {
        this.colebrookCurve = populateBestFitSSCurves(
            this.data,
            depthVelocityData,
            this.annotationSettings,
            this.xUnit,
            this.yUnit,
            this.slope,
            this.effectiveRoughness
          );

    } else {
        this.colebrookCurve = null;
    }

    if (this.colebrookCurve) {
        this.graphBuilder.updateGraphScale(this.colebrookCurve.data, this.advanceScatteroptions);
        this.advanceScatteroptions.series.push(this.colebrookCurve);
    }

    if (this.annotationSettings.isManningDesign) {
        if(!isCurveLocked || !this.manningDesignCurve || !this.manningDesignCurve.data) {
            this.manningDesignCurve = populateBestFitSSCurves(
                this.data,
                depthVelocityData,
                this.annotationSettings,
                this.xUnit,
                this.yUnit,
            );
            }

    } else {
        this.manningDesignCurve = null;
    }

    if (this.manningDesignCurve) {
        this.graphBuilder.updateGraphScale(this.manningDesignCurve.data, this.advanceScatteroptions);
        this.advanceScatteroptions.series.push(this.manningDesignCurve);
    }

    if (this.annotationSettings.isLanfearColl) {
        if(!isCurveLocked || !this.lanfearCollCurve || !this.lanfearCollCurve.data) {
            this.lanfearCollCurve = populateBestFitSSCurves(
                this.data,
                depthVelocityData,
                this.annotationSettings,
                this.xUnit,
                this.yUnit,
            );
            }

    } else {
        this.lanfearCollCurve = null;
    }

    if (this.lanfearCollCurve) {
        this.graphBuilder.updateGraphScale(this.lanfearCollCurve.data, this.advanceScatteroptions);
        this.advanceScatteroptions.series.push(this.lanfearCollCurve);
    }

    if (this.annotationSettings.isBestFit) {
      if (this.syncZoomWithHg && this.zoomFromHydro && this.zoomFromHydro.length) {
        const [start, end] = this.zoomFromHydro;
        this.getHistoricalCurve(start, end, true);
      }
      if (!isCurveLocked || !this.bestFitCurve || !this.bestFitCurve.data) {
        this.bestFitCurve = populateBestFitSSCurves(
          this.data,
          depthVelocityData,
          this.annotationSettings,
          this.xUnit,
          this.yUnit,
        );
      }
    } else {
      this.bestFitCurve = null;
    }

    if (this.bestFitCurve) {
      this.graphBuilder.updateGraphScale(this.bestFitCurve.data, this.advanceScatteroptions);
      this.advanceScatteroptions.series.push(this.bestFitCurve);
    }

    if (this.annotationSettings.isSSCurve) {
      if (this.syncZoomWithHg && this.zoomFromHydro && this.zoomFromHydro.length) {
        const [start, end] = this.zoomFromHydro;
        this.getHistoricalCurve(start, end, false);
      }
      if (!isCurveLocked || !this.ssCurve || !this.ssCurve.data) {
        this.ssCurve = populateBestFitSSCurves(
          this.data,
          depthVelocityData,
          this.annotationSettings,
          this.xUnit,
          this.yUnit,
        );
      }
    } else {
      this.ssCurve = null;
    }

    if (this.ssCurve) {
      this.graphBuilder.updateGraphScale(this.ssCurve.data, this.advanceScatteroptions);
      this.advanceScatteroptions.series.push(this.ssCurve);
    }

    if (this.annotationSettings.isManualLinear || this.annotationSettings.isManualSmooth) {
      const curveName = this.annotationSettings.isManualLinear ? MANUAL_LINEAR_CURVE : MANUAL_SMOOTH_CURVE;
      this.manualCurve = createManualCurve(
        curveName,
        this.viewDataService.scatterManualCurveSelectedPoints.getValue(),
        this.xUnit,
        this.yUnit,
        this.annotationSettings.isManualLinear
      );
    } else {
      this.manualCurve = null;
    }

    if (this.manualCurve) {
      this.graphBuilder.updateGraphScale(this.manualCurve.data, this.advanceScatteroptions);
      this.advanceScatteroptions.series.push(this.manualCurve);
    }

    if (this.selectedPoints.length) {
      this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.selected,
      );

      const selectedSeries = generateNewSeries(ScattergraphSeriesNames.selected);
      selectedSeries.data = this.selectedPoints;
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, selectedSeries);

      this.advanceScatteroptions.series.push(selectedSeries);

      // #37913 Cannot emit selected points here - it should be event and should be emited outside of CREATE chart methods
    }

    if (this.multipleSeries && this.multipleSeries.length) {
      this.advanceScatteroptions.series[0].name = this.multipleSeries[this.multipleSeries.length -1].data['title'];
      this.multipleSeries[this.multipleSeries.length -1] = this.advanceScatteroptions.series[0];
      this.multipleSeries.forEach(v => v.color = v.data[0].color);
      this.advanceScatteroptions.series = this.multipleSeries;
      this.graphBuilder.setCustomDashboardLegends(this.advanceScatteroptions);
    }


    if (this.snappedPointsData.length) {
      this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.snapped && v.name !== ScattergraphSeriesNames.selected,
      );

      const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
      scatterSeries.data = scatterSeries.data.filter(
        (v) => !this.snappedPointsData.find((i) => i.dateTime === v.dateTime),
      );
      const snappedSeries = generateNewSeries(ScattergraphSeriesNames.snapped);
      snappedSeries.data = this.snappedPointsData;
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, snappedSeries);

      this.advanceScatteroptions.series.push(snappedSeries);
    }

    if (this.previousSnappedPoints.length && this.isDataEditingModeEnabled) {
      this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.previousSnapped,
      );

      const snappedSeries = generateNewSeries(ScattergraphSeriesNames.previousSnapped);
      snappedSeries.data = this.previousSnappedPoints;
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, snappedSeries);

      this.advanceScatteroptions.series.push(snappedSeries);
    }

    if (this.zoomFromHydro && this.zoomFromHydro.length && !this.syncZoomWithHg) {
      this.setZoomedHgPointsSeries();
    }

    if (this.flaggedPointsOnResponse.length && this.isDataEditingModeEnabled) {
        const onResponseFlaggedMap = this.flaggedPointsOnResponse.reduce((acc, curr) => acc.set(curr.dateTime as number, curr), new Map<number, EntityData>());

        this.flaggedPointsData.forEach((p) => {
            if (onResponseFlaggedMap.get(p.dateTime as number)) {
                onResponseFlaggedMap.delete(p.dateTime as number);
            }
        });

        onResponseFlaggedMap.forEach((point: EntityData) => this.flaggedPointsData.push(point));
    }

    const editedPoints = this.getFilteredEditedPoints()
    if (editedPoints.length && this.isDataEditingModeEnabled) {
        const flaggedPoints = editedPoints.filter(v => v.flagged === true);

        if (flaggedPoints.length) {
            const mappedPoints = [];
            flaggedPoints.forEach(v => mappedPoints[v.stamp] = true);

            const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
            const scatterFlaggedPoints = scatterSeries.data.filter((v) => mappedPoints[v.dateTime as number]);
            scatterSeries.data = scatterSeries.data.filter((v) => !mappedPoints[v.dateTime as number]);

            const alreadyFlaggedTimestamps = this.flaggedPointsData.reduce((acc, curr) => acc.set(curr.dateTime as number, true), new Map<number, boolean>());
            scatterFlaggedPoints.forEach(v => {
                if (!alreadyFlaggedTimestamps.get(v.dateTime as number)) {
                    this.flaggedPointsData.push(v);
                }
            });
        }

        // #32416 need to unflag points that are not flagged anymore
        const unflaggedPoints = editedPoints.filter(v => v.flagged === false);

        if(unflaggedPoints.length) {
            const unflaggedAssoc = [];
            for(const p of unflaggedPoints) {
                unflaggedAssoc[p.stamp] = true;
            }
            this.flaggedPointsData = this.flaggedPointsData.filter(p => !unflaggedAssoc[p.dateTime as number]);
        }
    }

    const filteredEditedPoints = this.getFilteredEditedPoints();
    if (filteredEditedPoints.length && this.isDataEditingModeEnabled) {
      const xId = this.annotationSettings.isScatterInvert ? this.selectedEntityIds[0] : this.selectedEntityIds[1];
      const yId = this.annotationSettings.isScatterInvert ? this.selectedEntityIds[1] : this.selectedEntityIds[0];
      const mappedPoints = new Map<number, UpdatePointForSGXY>();
      for(const v of filteredEditedPoints) {
            if(v.id !== xId && v.id !== yId) continue;
            if(mappedPoints.has(v.stamp)) {
                const existingPoint = mappedPoints.get(v.stamp);
                if(!existingPoint.x) {
                    existingPoint.xId = v.id;
                    existingPoint.x = v.value;
                }
                if(!existingPoint.y) {
                    existingPoint.yId = v.id;
                    existingPoint.y = v.value;
                }
            } else {
                const point: UpdatePointForSGXY = {
                    flagged: v.flagged,
                    stamp: v.stamp,
                    edited: v.edited
                };
                if(v.id === xId) {
                    point.xId = v.id;
                    point.x = v.value;
                }
                if(v.id === yId) {
                    point.yId = v.id;
                    point.y = v.value;
                }
                mappedPoints.set(v.stamp, point);
            }
      }

      const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);

      this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
        (v) => v.name !== ScattergraphSeriesNames.edited,
      );

      const isSameFn = (v: EntityData, point: UpdatePointForSGXY) => {
        let isSame = true;
        if (v.xId === point.xId) {
            isSame = isSame && MathUtils.compareWithMinimalDecimal(v.x, point.x, 2);
        }
        if (v.xId === point.yId) {
            isSame = isSame && MathUtils.compareWithMinimalDecimal(v.x, point.y, 2);
        }
        if (v.yId === point.yId) {
            isSame = isSame && MathUtils.compareWithMinimalDecimal(v.y, point.y, 2);
        }
        if (v.yId === point.xId) {
            isSame = isSame && MathUtils.compareWithMinimalDecimal(v.y, point.x, 2);
        }
        return isSame;
      }

      scatterSeries.data = scatterSeries.data.filter((v) => {
        const point = mappedPoints.get(v.dateTime as number);
        return point ? isSameFn(v, point) : true;
      });

      this.editedSeriesData = depthVelocityData
        .filter(v => {
          const point = mappedPoints.get(v.dateTime as number);

          if (!point || point.flagged) return false;

          if (!point.edited) {
            return true;
          }

          // #37738 if edited value is equal to current value ignore
          // need to round points to 2 decimals to check
          const isSame = isSameFn(v, point);
          if(isSame) return false;

        })
        .map((v) => {
          const editedPoint = mappedPoints.get(v.dateTime as number);

          if (v.xId === editedPoint.xId || v.xId === editedPoint.yId) {
            v.x = v.xId === editedPoint.xId ? editedPoint.x : editedPoint.y;
          }
          if (v.yId === editedPoint.yId || v.yId === editedPoint.xId) {
            v.y = v.yId === editedPoint.yId ? editedPoint.y : editedPoint.x;
          }

          return v;
        });

      const editedSeries = generateNewSeries(ScattergraphSeriesNames.edited);
      editedSeries.data = this.editedSeriesData;
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, editedSeries);
      this.graphBuilder.updateGraphScale(this.editedSeriesData, this.advanceScatteroptions);
      this.advanceScatteroptions.series.push(editedSeries);
    }
    // #29264 Flagged points from block editor will be inside editedPoints, we need to make sure we hide them outside of edit mode
    else if (filteredEditedPoints.length && !this.isDataEditingModeEnabled && filteredEditedPoints.some(v => v.flagged)) {
        const flaggedPointsMap = filteredEditedPoints.filter(v => v.flagged).reduce((acc, curr) => acc.set(curr.stamp, true), new Map<number, boolean>());
        const scatterSeries = SGFindSeries(this.advanceScatteroptions.series, ScattergraphSeriesNames.scatter);

        scatterSeries.data = scatterSeries.data.filter(v => !flaggedPointsMap.get(v.dateTime as number));
    }
    if (this.flaggedPointsData.length) {
        this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
          (v) => v.name !== ScattergraphSeriesNames.flagged,
        );

        const flaggedSeries = generateNewSeries(ScattergraphSeriesNames.flagged);
        flaggedSeries.data = this.flaggedPointsData; // AMP MERGE: or this.selectedPoints
        this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, flaggedSeries);
        this.advanceScatteroptions.series.push(flaggedSeries);

        const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
        scatterSeries.data = scatterSeries.data.filter((v) => {
            return !this.flaggedPointsData.find((x) => x.dateTime as number === v.dateTime as number)
        });
    }

    if (this.hydroFlaggedPoints.length && this.isDataEditingModeEnabled) {
      const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
      scatterSeries.data = scatterSeries.data.filter((v) => !this.hydroFlaggedPoints.find(p => p.stamp === v.dateTime as number && p.flagged));

      const newFlaggedPoints = [...this.flaggedPointsOnResponse, ...depthVelocityData].filter(v => this.hydroFlaggedPoints.find(p => p.stamp === v.dateTime as number && p.flagged));

      let flagged = [...this.flaggedPointsData, ...newFlaggedPoints];
      flagged = flagged.filter((v, i) => flagged.findIndex((x) => x.dateTime === v.dateTime) === i); //remove duplicate flag points
      const oldFlaggedPoints = [...this.flaggedPointsData];
      this.flaggedPointsData = flagged.filter(p => {
        const fp = this.hydroFlaggedPoints.find(pp => pp.stamp === p.dateTime);
        return !fp || fp.flagged;
      });

      this.setFlaggedSeries();
      scatterSeries.data.push(...oldFlaggedPoints.filter(p => {
        const fp = this.hydroFlaggedPoints.find(pp => pp.stamp === p.dateTime);
        return fp && fp.flagged === false;
      }));
    }
    if(this.ignoredPointsData.length) {
        this.ignoredSeriesSet();
    }

    if (this.zoomFromHydro && this.zoomFromHydro.length && this.syncZoomWithHg) {
      this.applyZoomToAllSeries();
    }

    this.populateFroudeIsoLines(depthVelocityData);
  }

  private applyZoomToAllSeries() {
    const [start, end] = this.zoomFromHydro;

    const series =  this.advanceScatteroptions.series.filter(v => SCATTER_SERIES_NAMES.includes(v.name as ScattergraphSeriesNames));

    series.forEach(s => {
      s.data = s.data.filter(v => v.dateTime >= start && v.dateTime <= end);
    });
  }

  private findLinkedPoints(points: LinkedEntityData[]) {
    const [prev, next] = points;
    let prevPoint: EntityData, nextPoint: EntityData;

    this.advanceScatteroptions.series.forEach((series: ScatterSeries) => {
        if (!series || !series.data || !series.data.length) {
            return;
        }

        series.data.forEach(v => {
            if (prev && v.dateTime === prev.dateTime) {
                prevPoint = v;
            }

            if (next && v.dateTime === next.dateTime) {
                nextPoint = v;
            }
        });
    });

    if (!prevPoint && !nextPoint) {
        return [];
    }

    return [prevPoint, nextPoint].filter(v => !!v);
  }

  private listenForLinkedPoints() {
    const linkedPointsSubs = this.highlightLinkedPointsSubject.pipe(debounceTime(300)).subscribe((points: LinkedEntityData[]) => {
        if (!this.chart || !this.chart.series) return;

        let linkedSeries = this.chart.series.find(v => v.name === ScattergraphSeriesNames.linked);

        if (!points.length) {
            if (linkedSeries) { linkedSeries.remove(true, false, false); }

            return;
        }

        const linkedData = this.findLinkedPoints(points);
        let shouldAddSeries = false;
        if (!linkedSeries) {
            shouldAddSeries = true;
            linkedSeries = this.generateLinkedSeries(linkedData);
        }

        if (shouldAddSeries) {
            this.chart.addSeries(linkedSeries, true, false);
        } else {
            linkedSeries.update({ data: linkedData }, true);
        }
    });

    this.subscriptions.push(linkedPointsSubs);
  }

  public getHistoricalCurve(start: number, end: number, isBestFit: boolean) {
    const isCurveLocked = this.viewDataService.lockSGCurve.getValue();

    if (isCurveLocked) return;

    const startDate = (start ? this.dateutilService.getLocalDateFromUTCDate(new Date(start)) : new Date(start));
    const endDate = (end ? this.dateutilService.getLocalDateFromUTCDate(new Date(end)) : new Date(end));

    const startStr = startDate ? startDate.toISOString().slice(0,-5) : null;
    const endStr = endDate ? endDate.toISOString().slice(0,-5) : null;

    const { startDate: dStart, endDate: dEnd } = this.viewDataService.filterValues.getValue();
    const formattedDistanceStart = this.dateutilService.getLocalDateFromUTCDate(new Date(dStart));
    const formattedDistanceEnd = this.dateutilService.getLocalDateFromUTCDate(new Date(dEnd));

    const distancesStart = formattedDistanceStart.toISOString().slice(0,-5);
    const distancesEnd = formattedDistanceEnd.toISOString().slice(0,-5);
    const isRawVelEntitySelected = this.selectedEntityIds.includes(RAW_VELOCITY_ENTITY);

    const selectedEntityIds = isRawVelEntitySelected ? [RAW_VELOCITY_ENTITY, DEPTH_ENTITY] : [VELOCITY_ENTITY, DEPTH_ENTITY];

    this.viewDataService
      .getScatterGraphHistoricalCurve(
        this.customerId,
        this.locationId,
        startStr,
        endStr,
        selectedEntityIds,
        isBestFit ? [GraphTypes.bestfit] : [GraphTypes.stevensSchutzbach],
        !this.annotationSettings.isScatterInvert,
        distancesStart, distancesEnd
      )
      .subscribe((historicalCurve: HistoricalCurve[]) => {
        this.graphBuilder.onEditScatterGraph(
          { c: historicalCurve[0].d },
          this.annotationSettings.isScatterInvert,
        );

        if (historicalCurve && historicalCurve[0] && historicalCurve[0].distances) {
          this.updateDistances(historicalCurve[0].distances);
        }
        this.plotToleranceLines(this.viewDataService.scatterToleranceRangeValue, this.selected)
      });
  }

  public setMaxYAxisValueByPipe() {
    const { isPipeOverlay, isScatterInvert } = this.annotationSettings;

    if (isPipeOverlay && this.data.pipeOverlay && !isScatterInvert) {
        this.pipeOverlaySetup();

      const { pipeHeight, minimumY, maximumY, xForMaximumY, xForMinimumY } = this.pipeOverlaySettings;
      this.graphBuilder.plotScatterGraphRenderPipe(
        pipeHeight,
        minimumY,
        maximumY,
        xForMinimumY,
        xForMaximumY,
        '#696969',
      );
    }
  }

  // Redraws existing chart with current zoom level on change in range when bestfit and tolerance annotations chosen
  private redrawChartOnAnnotationsChosen() {
    const { isBestFit, isSSCurve, isManualLinear, isManualSmooth, isToleranceLines } = this.annotationSettings;

    if (this.isZoomedIn && (isBestFit || isSSCurve || isManualLinear || isManualSmooth) && isToleranceLines) {
      // updating previous chart instance with current scatteroptions without rendering entire chart when user zoomed in
      this.chart.update(this.advanceScatteroptions, false, true);

      const toleranceLines = this.chart.series.filter((v) => v.name.includes('ToleranceLines'));
      const scatterOptionSeries = this.advanceScatteroptions.series.filter((v) =>
        v.name.includes('ToleranceLines'),
      );

      /* adds or removes additional tolerance series plot data and redraws chart if user toggles between selected
//  values of above, below, both */
      if (toleranceLines.length > scatterOptionSeries.length) {
        const lastToleranceLine = toleranceLines[toleranceLines.length - 1];
        lastToleranceLine.remove(true);
      } else if (toleranceLines.length < scatterOptionSeries.length) {
        const scatterOptionsLength = this.advanceScatteroptions.series.length;
        const lastLine = this.advanceScatteroptions.series[scatterOptionsLength - 1];

        this.chart.addSeries(lastLine);
        toleranceLines.push(lastLine);
      }

      toleranceLines.forEach((v, i) => (v.remove ? v.update(scatterOptionSeries[i]) : null));
    }
  }

  // sets the hydrograph chart options in service variable
  // so that we can use it to co-relate between hydrograph and scattergraph charts
  public setScatterGraphValueToService() {
    const graph = this.element.nativeElement.querySelector('#' + this.advanceScatterId);
    if (!graph) return;

    // #34313 need to recalculate Y scales
    this.graphBuilder.setYaxisMax();

    this.graphBuilder.applyInvertedSplineFix(!this.isPointsInverted);

    if (this.annotationSettings.isDataQuality) {
      this.graphBuilder.applySeriesColorsToDataPoints(this.advanceScatteroptions.series);
    }
    this.viewDataService.advancedCharts[1] = this.chart = new Highcharts.Chart(this.advanceScatteroptions);

    this.graphBuilder.installLassoSelection(this.viewDataService.advancedCharts[1], this);

    this.applyManualScales();

    if (this.zoomEvent) {
      const { xAxis, yAxis } = this.zoomEvent;
      const { min: xMin, max: xMax } = xAxis[0];
      const { min: yMin, max: yMax } = yAxis[0];

      this.chart.xAxis[0].setExtremes(xMin, xMax, true, false);
      this.chart.yAxis[0].setExtremes(yMin, yMax, true, false);
      this.chart.showResetZoom();
    }

    this.setMaxYAxisValueByPipe();
    this.chart.redraw(false, false, false);
  }

  private applyManualScales() {
    if (!this.manualScales || !this.manualScales.length || this.manualScales.every(a => a.min === null && a.max === null)) {
      this.chart.xAxis[0].setExtremes(this.advanceScatteroptions.xAxis.min, this.maximumXvalue, true, false);
      return;
    }

    const inverted = this.annotationSettings.isScatterInvert;
    const depth = this.manualScales.find(v => v.displayGroupId === DEPTH_DISPLAY_GROUP) || { min: null, max: null };
    const velocity = this.manualScales.find(v => v.displayGroupId === VELOCITY_DISPLAY_GROUP) || { min: null, max: null };

    let yMin = inverted ? velocity.min : depth.min;
    let yMax = inverted ? velocity.max : depth.max;

    let xMin = inverted ? depth.min : velocity.min;
    let xMax = inverted ? depth.max : velocity.max;

    if (yMin === null) {
      yMin = this.advanceScatteroptions.yAxis.min || 0;
    }

    if (yMax === null) {
      yMax = this.advanceScatteroptions.yAxis.max || 0;
    }

    if (xMin === null) {
      xMin = this.advanceScatteroptions.xAxis.min || 0;
    }

    if (xMax === null) {
      xMax = this.maximumXvalue || 0;
    }

    this.chart.xAxis[0].setExtremes(xMin, xMax, false, false);
    this.chart.yAxis[0].setExtremes(yMin, yMax, true, false);
  }

  private setZoomedHgPointsSeries() {
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter((v) => v.name !== ScattergraphSeriesNames.zoomed);

    const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
    const zoomedSeries = generateNewSeries(ScattergraphSeriesNames.zoomed);

    const [start, end] = this.zoomFromHydro;
    zoomedSeries.data = scatterSeries.data.filter((v) => v.dateTime >= start && v.dateTime <= end);
    if (zoomedSeries.data.length >= scatterSeries.data.length) return;

    this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, zoomedSeries);
    this.advanceScatteroptions.series.push(zoomedSeries);
  }

  public setSelectedSeries() {
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
      (v) => v.name !== ScattergraphSeriesNames.selected,
    );

    const selectedSeries = generateNewSeries(ScattergraphSeriesNames.selected);
    selectedSeries.data = this.selectedPoints;
    this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, selectedSeries);
    this.advanceScatteroptions.series.push(selectedSeries);

    this.setScatterGraphValueToService();
  }

  public setFlaggedSeries() {
    let flaggedSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.flagged);

    if (!flaggedSeries) {
      flaggedSeries = generateNewSeries(ScattergraphSeriesNames.flagged)
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, flaggedSeries);
      this.advanceScatteroptions.series.push(flaggedSeries);
    }

    flaggedSeries.data = this.flaggedPointsData;
    this.setScatterGraphValueToService();
  }

  public ignoredSeriesSet() {
    let ignoredSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.ignored);

    if (!ignoredSeries) {
      ignoredSeries = generateNewSeries(ScattergraphSeriesNames.ignored)
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, ignoredSeries);
      this.advanceScatteroptions.series.push(ignoredSeries);
    }

    ignoredSeries.data = this.ignoredPointsData;
    this.setScatterGraphValueToService();
  }

  public ignoredSeriesNotify() {
    // #37506 Notify HG about ignored points
    this.dataEditService.sgIgnoredPointsData$.next({ points: this.ignoredPointsData, inverted: this.annotationSettings.isScatterInvert });
  }

  public setEditedSeries() {
    let editedSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.edited);

    if (!editedSeries) {
      editedSeries = generateNewSeries(ScattergraphSeriesNames.edited);
      this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, editedSeries);
      this.advanceScatteroptions.series.push(editedSeries);
    }

    editedSeries.data = this.editedSeriesData;
    this.setScatterGraphValueToService();
  }

  public setSnappedSeries() {
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
      (v) => v.name !== ScattergraphSeriesNames.snapped && v.name !== ScattergraphSeriesNames.selected,
    );

    const snappedSeries = generateNewSeries(ScattergraphSeriesNames.snapped);
    snappedSeries.data = this.snappedPointsData;
    this.graphBuilder.setPointFormatter(this.advanceScatteroptions, this, this.graphBuilder, snappedSeries);
    this.advanceScatteroptions.series.push(snappedSeries);
  }

  public clearSeries(isSnapped: boolean, isEditMode: boolean, doEmit = true) {
    this.selectedPoints = [];
    this.flaggedPointsData = [];
    this.editedSeriesData = [];
    if (!isSnapped) {
      this.previousSnappedPoints = this.previousSnappedPoints.filter(
        (v) => !this.snappedPointsData.find((i) => i.dateTime === v.dateTime),
      );
    }
    this.snappedPointsData = [];

    if (!this.advanceScatteroptions) {
      return;
    }

    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
      (v) =>
        v.name !== ScattergraphSeriesNames.snapped &&
        v.name !== ScattergraphSeriesNames.flagged &&
        v.name !== ScattergraphSeriesNames.selected &&
        v.name !== ScattergraphSeriesNames.previousSnapped &&
        v.name !== ScattergraphSeriesNames.edited
    );

    this.setScatterGraphValueToService();

    if(doEmit) {
        const snappedForHG = this.previousSnappedPoints.map((v) => v.dateTime);
        this.dataSelectForSnap.emit({ selected: [], snapped: isEditMode ? snappedForHG : [] });
    }
  }

  /**
   * Initializes the Scattergraph.
   * @param depthVelocityData - Data Array for depth and velocity series in scattergraph.
   * @param yAxisAnnotations - Represents the annotations in scattergraph.
   */
  // tslint:disable-next-line:cyclomatic-complexity
  private initScatterChart(depthVelocityData: EntityData[], yAxisAnnotations: Array<Annotations>, isCustomDashboard = false) {
    if (!this.data) {
      this.setLoading.emit(false);
      return;
    }

    this.subscribeOnThemeChangeIfNot();
    const { isScatterInvert, isBestFit, isSSCurve } = this.annotationSettings;

    if (isScatterInvert && (isBestFit || isSSCurve)) {
      this.scalingFactor = this.data.xAxis.factor;
    } else {
      this.scalingFactor = this.data.yAxis.factor;
    }
    const isTooltipStatic = this.usersService.staticTracerSetting.getValue();

    let confirmations = [];

    if (this.annotationSettings.isConfirmationPoints) {
      if (this.selectedConfirmationEntity === ConfirmationEntitiesEnum.Average) {
          if(this.data.confirmations) {
            confirmations = this.data.confirmations;
          } else {
            confirmations = this.data.c;
          }
      } else if (this.selectedConfirmationEntity === ConfirmationEntitiesEnum.Peak) {
        confirmations = this.data.cRaw;
      }
    }

    if (isCustomDashboard) {
      this.graphBuilder = new ScatterGraphBuilder(this.viewDataService, this.sepWinHgService);
    }

    this.advanceScatteroptions = this.graphBuilder.initializeOptions(this, depthVelocityData, confirmations, {isStatic: isTooltipStatic, additionalText: (tooltip, event) => this.tooltipAdditionalText(this, tooltip, event)});
    this.populateCurveValues(depthVelocityData);
    // if user has selected pipeHeight or manholeDepth
    if (yAxisAnnotations.length > 0) {
      this.setAnnotations(yAxisAnnotations);
    }

    this.redrawChartOnAnnotationsChosen();

    if (this.viewDataService.scatterToleranceRangeValue &&
        (this.bestFitCurve || this.ssCurve || this.savedCurve || this.manualCurve || this.colebrookCurve || this.manningDesignCurve || this.lanfearCollCurve)
    ) {
       this.plotToleranceLines(this.viewDataService.scatterToleranceRangeValue, this.selected);
    } else {
        this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(v => !v.name.includes(TOLERANCE_LINE));
    }

    // #34333 for historical curve we need to include distances separately each time
    const noDistances = Array.from(this.editedDistances.values()).every(v => v === null);
    if (noDistances && this.data.c && this.data.c[0] && (this.data.c[0] as HistoricalCurve).distances) {
      this.updateDistances((this.data.c[0] as HistoricalCurve).distances);
    }

    this.setScatterGraphValueToService();
  }

  private getAxis() {
    return this.annotationSettings.isScatterInvert ? 'xAxis' : 'yAxis';
  }

  /**
   * Sets the annotations for the chart
   * @param chart - instance of Y-Axis Annotations
   */
  private setAnnotations(yAxisAnnotations) {
    const axisValue = this.getAxis();
    // Creating empty array to store plotlines
    this.advanceScatteroptions[axisValue].plotLines = [];

    // Setting plotlines for each annotation
    for (let i = 0; i < yAxisAnnotations.length; i++) {
      const currentAnnotation = yAxisAnnotations[i];
      // remove this code if correct color code comes from Hydrograph API
      const { value, name } = currentAnnotation;
      const lineColor = this.pickAnnotationLineColor(name);

      const isPipeHeightGreater = this.checkMaximumGraphScale('Pipe', currentAnnotation);
      this.isManholeDepth = this.checkMaximumGraphScale('Manhole', currentAnnotation);
      const isSilt = this.checkMaximumGraphScale('Silt', currentAnnotation);
      this.isYAxisSet = this.isManholeDepth;

      if (isPipeHeightGreater || this.isManholeDepth) {
        this.advanceScatteroptions[axisValue].plotLines.push({
          color: lineColor,
          width: 2,
          value: value,
          dashStyle: 'shortdash',
          zIndex: 4,
          label: {
            text: name,
            align: 'center',
            verticalAlign: 'middle',
            y: 15,
            style: { color: lineColor },
          },
        });
      } else if (isSilt) {
        const { unit, precision } = this.data.yAxis;
        this.advanceScatteroptions[axisValue].plotLines.push({
            color: lineColor,
            width: 3,
            value: value,
            zIndex: 4,
            label: {
              text: `SILT: ${Number(value).toFixed(precision)} ${unit}`,
              align: 'center',
              verticalAlign: 'middle',
              y: -10,
              style: { color: lineColor },
            },
          });
      }

      if (isPipeHeightGreater || this.isManholeDepth || isSilt) {
          const scaleValue = value + (value * 0.2);
          const axisMax = this.advanceScatteroptions[axisValue].max;
          if (scaleValue > axisMax && !this.annotationSettings.isScatterInvert) {
            this.advanceScatteroptions[axisValue].max = scaleValue;
          } else if (scaleValue > this.maximumXvalue && this.annotationSettings.isScatterInvert) {
            const series = generateNewSeries(ScattergraphSeriesNames.blank);    // create blank series with no marker so highcharts will scale the graph to fit the line
            series.marker.enabled = false;
            series.data = [{ y: 0, x: scaleValue }];
            this.maximumXvalue = scaleValue;
            this.advanceScatteroptions.series.push(series);
          }
        }
      }
    }

    private checkMaximumGraphScale(type: string, currentAnnotation) {
      if (type === 'Pipe') {
        return this.annotationSettings.isPipeHeight && currentAnnotation.name.indexOf(type) >= 0;
      } else if (type === 'Manhole') {
        return this.annotationSettings.isManholeDepth && currentAnnotation.name.indexOf(type) >= 0;
      } else if (type === 'Silt') {
        return this.annotationSettings.isSilt && currentAnnotation.name.indexOf(type) >= 0;
      }
    }

  private pickAnnotationLineColor(type: string): string {
    if (type === PIPE_HEIGHT) {
        return HG_COLOR_PIPE_HEIGHT;
    } else if (type === MANHOLE_DEPTH) {
        return HG_COLOR_MANHOLE_DEPTH;
    } else if (type === SILT) {
        return HG_COLOR_SILT;
    }
  }

public refreshToleranceLines() {
  // On manual curve, when you add points to the curve while tolerance lines are showing
  // the lines do not recalculate / refresh until you do something else like change their value
  // this forces them to update
  const toleranceFactor = this.viewDataService.scatterToleranceRangeValue;
  if (toleranceFactor !== 0) {
    const linesPosition = this.selected;

    this.plotToleranceLines(toleranceFactor, linesPosition);
  }
}
  /**
* Plots the Tolerance Lines for Scatter Data
* @param toleranceFactor - toleranceFactor Value that variates the lines
@param linesPosition - Tolerance Lines Position - Above , Below or Both
*/
  public plotToleranceLines(toleranceFactor: number, linesPosition: number) {
    if(!this.annotationSettings) return;
    const lineType: DataEditingCurveTypes = this.getCurveTypeForTolerance();
    if (lineType === null) return;
    switch (linesPosition) {
      case 3:
        this.drawToleranceLines(
          lineType,
          this.addToleranceFactor(toleranceFactor, lineType),
          false,
          this.addToleranceFactor(-toleranceFactor, lineType),
        );
        break;
      case 1:
        this.drawToleranceLines(lineType, this.addToleranceFactor(-toleranceFactor, lineType), false);
        break;
      case 2:
        this.drawToleranceLines(lineType, this.addToleranceFactor(toleranceFactor, lineType), true);
        break;
      default:
        break;
    }
  }

  /**
   * Adds the Tolerance Factor to the best fit curve
   * @param toleranceFactor - toleranceFactor Value that variates the lines
   */
  private addToleranceFactor(toleranceFactor, linetype: DataEditingCurveTypes) {
    let firstLine;
    switch (linetype) {
      case DataEditingCurveTypes.BestFit: {
        if (this.flaggedData && this.flaggedData.curves) {
          firstLine = this.findFirstCurveByName(this.flaggedData.curves, BEST_FIT);
        }
        if ((this.bestFitCurve && this.bestFitCurve.data) || (firstLine && firstLine['d'])) {
          return this.getToleranceCurve(toleranceFactor, this.bestFitCurve.data);
        }

        firstLine = null;
        break;
      }

      case DataEditingCurveTypes.StevensSchutzbach: {
        if (this.flaggedData && this.flaggedData.curves) {
          firstLine = this.findFirstCurveByName(this.flaggedData.curves, STEVENS_CURVE);
        }

        if ((this.ssCurve && this.ssCurve.data) || (firstLine && firstLine['d'])) {
          return this.getToleranceCurve(toleranceFactor, this.ssCurve.data);
        }

        break;
      }

      case DataEditingCurveTypes.Colebrook: {
        if (this.flaggedData && this.flaggedData.curves) {
            firstLine = this.findFirstCurveByName(this.flaggedData.curves, COLEBROOK_CURVE);
          }

          if ((this.colebrookCurve && this.colebrookCurve.data) || (firstLine && firstLine['d'])) {
            return this.getToleranceCurve(toleranceFactor, this.colebrookCurve.data);
          }

          break;
      }

      case DataEditingCurveTypes.ManningDesign: {
        if (this.flaggedData && this.flaggedData.curves) {
            firstLine = this.findFirstCurveByName(this.flaggedData.curves, MANNING_DESIGN_CURVE);
          }

          if ((this.manningDesignCurve && this.manningDesignCurve.data) || (firstLine && firstLine['d'])) {
            return this.getToleranceCurve(toleranceFactor, this.manningDesignCurve.data);
          }

          break;
      }

      case DataEditingCurveTypes.LanfearColl: {
        if (this.flaggedData && this.flaggedData.curves) {
            firstLine = this.findFirstCurveByName(this.flaggedData.curves, LANFEAR_COLL_CURVE);
          }

          if ((this.lanfearCollCurve && this.lanfearCollCurve.data) || (firstLine && firstLine['d'])) {
            return this.getToleranceCurve(toleranceFactor, this.lanfearCollCurve.data);
          }

          break;
      }

      case DataEditingCurveTypes.ManualLinear: // Manual smooth or manual linear
      case DataEditingCurveTypes.ManualSmooth: {
        const curveData = this.viewDataService.scatterManualCurveSelectedPoints.getValue();
        return this.getToleranceCurve(toleranceFactor, curveData);
      }

      case DataEditingCurveTypes.SavedCurve: {
        const manualCurveData = this.viewDataService.scatterManualCurveSelectedPoints.getValue();
        const curveData = manualCurveData && manualCurveData.length ? manualCurveData : this.savedCurveData && this.savedCurveData.data ? this.savedCurveData.data : [];
        return this.getToleranceCurve(toleranceFactor, curveData);
      }

      default:
        break;
    }

    if (!this.bestFitCurve && !this.ssCurve && !this.manualCurve && this.savedCurve) {
      return this.getToleranceCurve(toleranceFactor, this.savedCurveData.data);
    }
  }

  private findFirstCurveByName(curves, key: string) {
    return curves.find((v) => v['name'] === key);
  }

  private getToleranceCurve(toleranceFactor, curveData) {
    const clonedSSFitLines = JSON.parse(JSON.stringify(curveData));
    this.scalingFactor = this.data.xAxis.factor;

    for (let i = 0; i < clonedSSFitLines.length; i++) {
      if (this.annotationSettings.isScatterInvert) {
        clonedSSFitLines[i]['y'] = Number((clonedSSFitLines[i]['y'] + toleranceFactor * this.scalingFactor).toFixed(4));
      } else {
        clonedSSFitLines[i]['x'] = Number((clonedSSFitLines[i]['x'] + toleranceFactor * this.scalingFactor).toFixed(4));
      }
    }

    return clonedSSFitLines;
  }

  /**
   * Draws the Tolerance Lines for Scatter Data
   * @param toleranceLines - data for Tolerance Lines
   */
  private drawToleranceLines(lineType: DataEditingCurveTypes, toleranceLine: ToleranceLine[], above: boolean, toleranceLinesBoth?: undefined) {
    if(!this.advanceScatteroptions) {
        return;
    }
    this.advanceScatteroptions.series = this.advanceScatteroptions.series.filter(
      (v) => !v.name || !v.name.includes('ToleranceLines'),
    );

    let drawLineType: string;
    if (this.savedCurveData) {
        drawLineType = this.savedCurveData.type;
    } else {
        drawLineType = lineType === DataEditingCurveTypes.ManualSmooth ? 'spline' : 'line';
    }
    const entityInformationData: any = generateEntityInformationData(drawLineType, toleranceLine, above, toleranceLinesBoth);
    this.advanceScatteroptions.series.push(entityInformationData);

    if (toleranceLinesBoth) {
      const clonedToleranceLinesData = {
        ...entityInformationData,
        data: toleranceLinesBoth,
        name: 'ToleranceLines_0',
      };
      this.advanceScatteroptions.series.push(clonedToleranceLinesData);
    }

    this.setLoading.emit(false);

    this.setScatterGraphValueToService();
  }

  private listenHGChanges() {
    this.subscriptions.push(
        this.dataEditService.sgUpdateCurveAndDistances$.subscribe((value: { res: DataEditPreview, storeEdit: boolean }) => {
            if (value) {
                const { res, storeEdit } = value;
                if (res.c) {
                    if (this.data && this.data.curves && this.data.curves.length) {
                        this.shouldUpdateCurve = true;

                        // #37926 Has to revert it as long as scatter-graph-utils.ts:populateBestFitSSCurves rely on original data
                        this.data.curves[0].d = this.annotationSettings?.isScatterInvert
                            ? [...res.c].map(e => {

                              // TODO: #42612 fix type inconsistency here, for Undo edit we are sending { x: number; y: number }, for other cases we are sending string representation
                              if (typeof e === 'string') {
                                const pe = e.substring(1, e.length-1).split(':');
                                return `[${pe[1]}:${pe[0]}]`;
                              } else {
                                const { x, y } = e;
                                return `[${x}:${y}]`;
                              }
                            })
                            : [...res.c];
                    }
                }

                this.graphBuilder.handleCurveAPIResponse(res, storeEdit);

                if (storeEdit) {
                    this.dataEditService.notifyOtherWindow.next({
                        type: SeparateWindowActionTypes.updateStoredEdits, payload: {
                            edits: this.dataEditService.storedEdits, currentTS: this.dataEditService.currentEditTimestamp
                        }
                    });
                }
            }
        })
    );

    this.subscriptions.push(
      this.dataEditService.sgApplyGainEdits$.subscribe((entityData: ScatterLastGainEditModel) => {
        if (!entityData) {
          return;
        }

        this.latestGainEditResults = entityData;
        this.displayScatterGraph(this.data);
      })
    );
  }

  private getCurveTypeForTolerance() {
    if (!this.annotationSettings) return null;

    return sgCurveType(this.annotationSettings, !!this.savedCurve);
  }

  public applySnapToCurve() {
    const editedPoints = this.viewDataService.selectedSnappedPoints.getValue();

    this.chart.showLoading();
    const curveData = this.getSelectedCurveData();
    if (this.dataEditService.currentEditTimestamp === null && this.dataEditService.storedEdits.length > 0) {
        DataEditService.clearUUID();
        this.dataEditService.storedEdits = [];
    }

    const isRawVelEntitySelected = this.selectedEntityIds.includes(RAW_VELOCITY_ENTITY);

    this.viewDataService
      .snapScatterGraph(
        this.customerId,
        this.viewDataService.filterValues.getValue(),
        editedPoints,
        this.annotationSettings.isBestFit,
        this.annotationSettings.isSSCurve,
        this.annotationSettings.isManualLinear,
        this.annotationSettings.isManualSmooth,
        this.annotationSettings.isCWRcurve,
        this.annotationSettings.isManningDesign,
        this.annotationSettings.isLanfearColl,
        curveData,
        this.annotationSettings.isScatterInvert,
        this.viewDataService.scatterToleranceRangeValue * this.scalingFactor,
        false,
        isRawVelEntitySelected
      )
      .subscribe(
        (scatterResult: SnapDataResponse) => {
            this.handleSnapResponse(scatterResult);
        },
        (error) => {
          this.enablePreviewChange.emit(true);
          this.chart.hideLoading();
          this.uiUtilsService.safeChangeDetection(this.cdr);
        },
      );
    const axis = this.chart.xAxis[0];
    axis.removePlotBand('selection-plot-band');
  }

  public handleSnapResponse(scatterResult: SnapDataResponse, notifyHG = true) {
    if (!scatterResult) {
        this.chart.hideLoading();
        return;
      }

      const cachedDistances = new Map<number, number>();
      this.editedDistances.forEach((v, k) => cachedDistances.set(k, v));

      const { sgd, snapData, curve } = scatterResult;

      const snappingVelocityPoints = snapData.reduce((acc, curr) => {
          const isVel = curr.entities.find(v => v.id === this.selectedVelocityEntity());

          if (!isVel) {
            return acc;
          }

          return [...acc, { stamp: curr.stamp, ...isVel }]
      }, []);

      if (sgd && sgd.length) {
        this.updateDistances(sgd);
      }
        const oldCurveValues = this.advanceScatteroptions.series.find(v => v.name === this.pickCurveKey());
        const originalCurveData: { x: number; y: number; }[] = [];
        oldCurveValues && oldCurveValues.data && oldCurveValues.data.forEach(v => originalCurveData.push({ x: v.x, y: v.y }));

        if (curve) {
            // #29429 need to get original (not inverted) curve data here, because we are assigning it to global data
            let curveData: string[] = [];
            if (this.annotationSettings.isScatterInvert) {
                curveData = curve.map((row: string) => {
                    const [y, x] = row.substring(1, row.length - 2).split(':');

                    return `[${x}:${y}]`;
                });
            } else {
                curveData = curve;
            }

            this.curveChanged.emit(curveData);
            this.graphBuilder.onEditScatterGraph({ c: curve });
        }

      this.plotToleranceLines(this.viewDataService.scatterToleranceRangeValue, this.selected)

      if (snappingVelocityPoints.length) {
        const scatterPoints = [];
        const snappedPoints = [];

        let snap;
        this.depthVelocityData.forEach((v) => {
          snap = snappingVelocityPoints.find((i) => i.stamp === v.dateTime);
          if (snap) {
            this.annotationSettings.isScatterInvert
              ? snappedPoints.push({ ...v, y: Number(snap.xValue) })
              : snappedPoints.push({ ...v, x: Number(snap.xValue) });
          } else {
            scatterPoints.push(v);
          }
        });

        if (notifyHG) {
            const toCache = { snap: [...this.snappedPointsData], prevSnap: [...this.previousSnappedPoints] };
            this.dataEditService.updateHydrographOnScattergraphSnap(scatterResult, true, true, toCache);

            const edits = this.dataEditService.storedEdits;
            const lastEdit = edits[edits.length - 1];

            if (lastEdit) {
                lastEdit.originalDistances = cachedDistances;
                const inverted = this.annotationSettings.isScatterInvert;
                lastEdit.originalCurve = originalCurveData.map(v => (inverted ? `[${v.y}:${v.x}]` : `[${v.x}:${v.y}]`));
            }
        }
        this.snappedPointsData = [...this.snappedPointsData, ...snappedPoints];
        this.previousSnappedPoints = [...this.previousSnappedPoints, ...snappedPoints];

        const selectedPointsAssoc = {};
        for(const p of this.selectedPoints) {
            selectedPointsAssoc[p.dateTime] = p;
        }
        this.flaggedPoints = this.flaggedPoints.filter(v => !selectedPointsAssoc[v.dateTime as number]);
        this.flaggedPointsData = this.flaggedPointsData.filter(v => !selectedPointsAssoc[v.dateTime as number]);
        this.editedSeriesData = this.editedSeriesData.filter(v => !selectedPointsAssoc[v.dateTime as number]);
        const previousIgnoredPointsLength = this.ignoredPointsData?.length;
        this.ignoredPointsData = this.ignoredPointsData.filter(v => !selectedPointsAssoc[v.dateTime as number]);

        this.selectedPoints = [];

        const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
        scatterSeries.data = scatterPoints;

        if (this.zoomFromHydro && this.zoomFromHydro.length && !this.syncZoomWithHg) {
            this.setZoomedHgPointsSeries();
        }
        this.setFlaggedSeries();
        this.ignoredSeriesSet();
        if(previousIgnoredPointsLength !== this.ignoredPointsData.length) this.ignoredSeriesNotify();
        this.setEditedSeries();
        this.setSnappedSeries();
        this.setScatterGraphValueToService();
      }

      this.dataSelectForSnap.emit({
        selected: [],
        snapped: this.previousSnappedPoints.map((v) => v.dateTime),
      });

      this.enablePreviewChange.emit(false);
      this.chart.hideLoading();
  }

  private getSelectedCurveData() {
    if (this.bestFitCurve && this.bestFitCurve.data) {
      return this.bestFitCurve.data;
    }

    if (this.ssCurve && this.ssCurve.data) {
      return this.ssCurve.data;
    }

    if (this.manualCurve && this.manualCurve.data) {
      return this.manualCurve.data;
    }

    if (this.savedCurveData && this.savedCurveData.data) {
      return this.savedCurveData.data;
    }

    if (this.colebrookCurve && this.colebrookCurve.data) {
        return this.colebrookCurve.data;
    }

    if (this.manningDesignCurve && this.manningDesignCurve.data) {
        return this.manningDesignCurve.data;
    }

    if (this.lanfearCollCurve && this.lanfearCollCurve.data) {
        return this.lanfearCollCurve.data;
    }
  }

  public pickCurveKey() {
    if (this.annotationSettings.isBestFit) {
        return BEST_FIT;
    }

    if (this.annotationSettings.isSSCurve) {
        return STEVENS_CURVE;
    }

    if (this.annotationSettings.isManualLinear) {
        return MANUAL_LINEAR_CURVE;
    }

    if (this.annotationSettings.isManualSmooth) {
        return MANUAL_SMOOTH_CURVE;
    }

    if (this.annotationSettings.isCWRcurve) {
        return COLEBROOK_CURVE;
    }

    if (this.annotationSettings.isManningDesign) {
        return MANNING_DESIGN_CURVE;
    }

    if (this.annotationSettings.isLanfearColl) {
        return LANFEAR_COLL_CURVE;
    }
  }

  public selectedVelocityEntity() {
    return this.selectedEntityIds.includes(RAW_VELOCITY_ENTITY) ? RAW_VELOCITY_ENTITY : VELOCITY_ENTITY;
  }

  public handleInterpolateResponse(
    response: DataEditPreview,
    isSelectedPoints: boolean,
    editedEntityId: number,
  ) {
    if (!this.advanceScatteroptions) return;

    const velocityEntity = this.selectedVelocityEntity();
    // #38614 #38615 Edit was done on VELOCITY and we do present RAW VEL, or opposite. Just ignore
    if(Number(editedEntityId) !== velocityEntity) return;

    this.graphBuilder.onEditScatterGraph(response);

    const responsePointsMap = new Map(); // in order to get response points by key, to avoid iterations

    Object.keys(response.d).forEach((v) => {
      const pointData = response.d[v].substr(1, response.d[v].length - 2); //remove [  ]  brackets
      const entities = pointData.split(';');

      entities.forEach((element) => {
        const [entityId, value] = element.split(':');

        if (Number(entityId) === Number(editedEntityId)) {
          responsePointsMap.set(Number(v) * 1000, value);
        }
      });
    });

    let editedAxis: 'x' | 'y';

    if (this.annotationSettings.isScatterInvert) {
      editedAxis = Number(editedEntityId) === velocityEntity ? 'y' : 'x';
    } else {
      editedAxis = Number(editedEntityId) === velocityEntity ? 'x' : 'y';
    }

    if (isSelectedPoints) {
      this.updatePointsAfterInterpolate(responsePointsMap, editedAxis);
    }

    if (response.sgd && response.sgd.length) {
      this.updateDistances(response.sgd);
    }
  }

  private updatePointsAfterInterpolate(pointsMap: Map<number, number>, editedAxis: 'x' | 'y') {
    this.advanceScatteroptions.series.forEach((series) => {
        if (!series || !series.data) return;

        series.data.forEach((point: any) => {
            const pointFromResponse = pointsMap.get(point.dateTime);
            if (pointFromResponse) {
                point[editedAxis] = Number(pointFromResponse);
            }
        });
    });

    this.setScatterGraphValueToService();
  }

  public updateDistances(sgdValues: string[]) {
    if (!this.advanceScatteroptions) return;

    this.editedDistances = new Map();
    const scatterSeries = this.advanceScatteroptions.series.filter(
        (v) => v.name === ScattergraphSeriesNames.scatter || v.name === ScattergraphSeriesNames.edited);

    const newDistances = sgdValues.reduce((acc, curr) => {
      const [dateTime, distance] = curr.substr(1, curr.length - 2).split(':');

      acc[Number(dateTime) * 1000] = { distance };
      this.editedDistances.set(Number(dateTime) * 1000, Number(distance));

      return acc;
    }, {});

    if (!scatterSeries || !scatterSeries.length) return;
    scatterSeries.forEach((serie) =>
        serie.data.forEach((point: EntityData) => {
            const newDis = newDistances[point.dateTime as number];

            if (!newDis) return;

            point.distance = newDis.distance;
        })
    )
  }

  public undoDistances(distancesMap: Map<number, number>) {
    const scatterSeries = this.advanceScatteroptions.series.find((v) => v.name === ScattergraphSeriesNames.scatter);
    this.editedDistances = distancesMap;
    scatterSeries.data.forEach((point: EntityData) => {
        const distance = distancesMap.get(point.dateTime as number);

        if (distance !== undefined) {
            point.distance = Number(distance);
        }
    });
  }

  // this will filter edited points according to the selected entities on the scattergraph
  private getFilteredEditedPoints() {
    if (!this.selectedEntityIds) return [];

    const velocityEntity = this.selectedVelocityEntity();

    return this.editedPoints.filter(v => v.id === DEPTH_ENTITY || v.id === velocityEntity);
  }

  public updateSelectionMode(mode: SGSelectionMode) {
    this.selectionMode = mode;
    this.updateSelectionColorBox();
  }

  /** Whenever to display blue box on chart mouse drag or not */
  private updateSelectionColorBox() {
        let color = SELECTION_ZOOM_BOX_COLOR;

        if(this.selectionMode === SGSelectionMode.Lasso && (
            this.selectedScatterMenuItem === SELECT_DATA
            || this.selectedScatterMenuItem === IGNORE_DATA
            || this.selectedScatterMenuItem === UNIGNORE_DATA
        )) {
            color = SELECTION_ZOOM_BOX_INVISIBLE;
        }

        if(this.advanceScatteroptions?.chart) {
            this.advanceScatteroptions.chart.selectionMarkerFill = color;
        }
        this.chart?.update({chart: {selectionMarkerFill: color}});
  }
}
