<div>
    <div class="location-selection-bar">
        <div
            class="pagination"
            (click)="scrollLeft()"
            [ngClass]="{ 'pagination-disabled': scrollLeftDisabled() }"
        >
            <div class="left"></div>
        </div>

        <div
            [class.location-list--narrow]="showLocationSearch"
            class="location-list ld-location-hint"
        >
            <div
                id="locationCards"
                #locationCards
            >
                <button
                    mat-button
                    *ngFor="let location of selectableLocations; index as i"
                    (click)="onChangeLocationMatTab(location.name)"
                    [ngClass]="{ 'mat-tab-label-active': i === selectedTabIndex }"
                    class="mat-tab-label"
                >
                    <mat-icon *ngIf="location.isComposite">add_circle</mat-icon>
                    {{ location.name }}
                </button>
            </div>
        </div>
        <div
            class="pagination"
            (click)="scrollRight()"
            [ngClass]="{ 'pagination-disabled': scrollRightDisabled() }"
        >
            <div class="right"></div>
        </div>
        <div
            class="location-selected"
            *ngIf="showLocationSearch"
        >
            <app-auto-complete
                [items]="selectableLocations"
                [preselectedItem]="preselectedLocation"
                placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
                [isShowAll]="false"
                (selectedItems)="changeLocationDropdown($event)"
                (inputChanged)="inputChanged($event)"
                [focusOnClear] = "focusOnClear"
            >
            </app-auto-complete>
            <div class="validation">
                <span [hidden]="somethingSelected">{{ 'COMMON.LOCATION_SELECT_VALIDATION' | translate }}
                </span>
            </div>
        </div>
        <div>
            <button
                id="locationDbSearch"
                class="filterFlatIconButton activeBackGround ld-search-hint"
                [class.active]="showLocationSearch"
                mat-button
                matTooltip="{{ 'COMMON.SEARCH_LOC_PLACEHOLDER' | translate }}"
                (click)="toggeleSearch()"
                [disabled]="availablePageHint"
            >
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <mat-menu
        #menu="matMenu"
        xPosition="before"
        yPosition="below"
        [overlapTrigger]="false"
    >
        <button
            mat-menu-item
            [disabled]="!filterSettings?.isExportPrintEnabled"
            (click)="showHideAnnotation.emit(true)"
        >
            <mat-icon>spellcheck</mat-icon>
            <span>{{ 'COMMON.OPTIONS_TEXT' | translate }}</span>
        </button>
        <button
            class="search-button"
            mat-menu-item
            [disabled]="!filterSettings?.isExportPrintEnabled"
            (click)="exportTelemetryData.emit($event)"
            *ngIf="filterSettings?.isDisplayExportMenu"
        >
            <mat-icon>import_export</mat-icon>
            <span>{{ 'COMMON.EXPORT_TEXT' | translate }}</span>
        </button>

        <button
            mat-menu-item
            [disabled]="!filterSettings?.isExportPrintEnabled"
            (click)="printDashboard.emit(true)"
        >
            <mat-icon>print</mat-icon>
            <span>{{ 'COMMON.PRINT_TEXT' | translate }}</span>
        </button>
    </mat-menu>
</div>