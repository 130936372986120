import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, SimpleChanges, SimpleChange, Output, EventEmitter, OnInit } from '@angular/core';
import { VautImportFileSummary } from 'app/shared/models/telemetry';
import { DateutilService } from 'app/shared/services/dateutil.service';
import * as moment from 'moment';

export interface DateRangeForFile {filename: string,startDate: Date,endDate: Date}; 

@Component({
    selector: 'app-vault-import-file-summary',
    templateUrl: './vault-import-file-summary.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultImportFileSummaryComponent implements OnInit {
    @Input() public file: VautImportFileSummary;
    @Output() public selectedTimespanChange = new EventEmitter<DateRangeForFile>();

    tickInterval = 1; 
    public startDate: Date;
    public endDate: Date;

    public minDate: Date;
    public maxDate: Date;

    private dateFormat: string;

    public confStartDate: string;
    public confEndDate: string;

    constructor(private dateutilService: DateutilService) {}

    ngOnInit() {
        this.getDateFormat();
        if (this.file) {
            this.startDate = this.startDate ?? this.getFormattedDate(this.file.startDate) ;
            this.endDate = this.endDate ?? this.getFormattedDate(this.file.endDate);

            this.minDate = this.getFormattedDate(this.file.startDate);
            this.maxDate = this.getFormattedDate(this.file.endDate);

            if(this.file.fileType){
                this.confStartDate = moment(this.file.startDate).format(this.dateFormat);
                this.confEndDate = moment(this.file.endDate).format(this.dateFormat);
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.file &&
            changes.file.currentValue !== changes.file.previousValue
        ) {
            this.file = changes.file.currentValue;
        }
        if(!this.dateFormat && !this.file?.fileType)
        {
            this.getDateFormat();
        }
        this.startDate = this.getFormattedDate(this.file.startDate);
        this.endDate = this.getFormattedDate(this.file.endDate);
    }

    getDateFormat(){
        this.dateFormat = `${String(
            this.dateutilService.getFormat(),
        ).toUpperCase()} ${this.dateutilService.getTimeFormat()}`;
    }

    updateDateChange() {   
        this.selectedTimespanChange.emit({filename:this.file.fileName, startDate: this.startDate, endDate: this.endDate})
    }

    getFormattedDate(dateStr: string): Date | null {
        return dateStr ? moment(dateStr, this.dateFormat).toDate() : null;
    }

}
