<div class="cost-allocation-dialog customDialogLayer">
	<div class="customDialog">
		<div class="customDialogPosition">
			<div id="resonForEditId">
				<div class="popupHeaderWithButton" mat-dialog-title>
					<div class="auto-margin pad-l-15">
						{{ 'ADMIN.ADMIN_USAGE.COST_ALLOCATION_REPORT' | translate }}
					</div>
					<div class="app-flex-filler"></div>
				</div>
				<div mat-dialog-content class="eachTabContent">
					<div>
						<app-date-range-picker
                            [startDate]="startDate"
                            [endDate]="endDate"
                            [label]="translations.dateRangePlaceholder"
                            [autoFocus]="false"
                            [quickOneDay]="false"
                            [quickOneWeek]="false"
                            [dateRangeType]="'date'"
							(startDateChange)="onStartDateChange($event)"
							(endDateChange)="onEndDateChange($event)"
						></app-date-range-picker>
					</div>
				</div>
				<div mat-dialog-actions>
					<div class="app-flex-filler"></div>
					<div class="auto-margin">
						<button mat-flat-button (click)="cancel()">
							{{ 'COMMON.CANCEL' | translate | uppercase }}
						</button>
					</div>
					<div class="auto-margin">
						<button (click)="generateReport()" mat-button>
							{{ 'ADMIN.ADMIN_USAGE.GENERATE_REPORT' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
