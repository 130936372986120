import { IComponentCustomizedConfirmation } from 'app/shared/models/customized-confirmation-box';
import {
    Component,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { combineLatest, Observable, of } from 'rxjs';
import { Subscription } from 'rxjs';

import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { TelemetryService } from 'app/shared/services/telemetry.service';
import { TelogService } from 'app/shared/services/telog.service';
import {
    TelemetryFiles,
    TelemetryImportDialogData,
    TelemetryImportResult,
    ValidateTelemetryFiles,
} from 'app/shared/models/vault';
import { TelemetryValidationResult, VautImportFileSummary } from 'app/shared/models/telemetry';
import { TELEMETRY, TELOG } from 'app/shared/models/customer';
import { catchError, map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DateRangeForFile } from '../vault-import-file-summary/vault-import-file-summary.component';
import * as moment from 'moment';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { genericDateFormat } from 'app/shared/constant';


const ACTIVE = 'active';
const EXCEL_FILE_EXTENSION = 'xlsx';
export interface DateRangeFileRequest {filename: string, startDate: string, endDate: string}; 

@Component({
    selector: 'app-vault-telemetry-import',
    templateUrl: './vault-telemetry-import.component.html',
    styleUrls: ['./vault-telemetry-import.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TelemetryImportComponent implements OnDestroy, OnInit {
    public telemetryInputData: TelemetryFiles;
    public telemetryInputDataList = new Array<TelemetryFiles>();
    public date_range_dict: { [key: string]: DateRangeForFile } = {};
    public telemetryValidatedFiles = new Array<ValidateTelemetryFiles>();
    public validatedFilesDates = new Array<DateRangeForFile>();
    public telemetryData: TelemetryValidationResult;
    public isRequestImport = false;
    public isLoading = false;
    public isImportEnable: boolean;
    public isValid = false;
    public isTelemetryFile: boolean;
    public isTelogFile: boolean;
    public hasRequestedValidation = false;
    public entitiesList = '';

    private subscriptions = new Array<Subscription>();


    /**
     * Represents inpus for Confirmation Box
     */
    public conformationDataInput: IComponentCustomizedConfirmation;

    /**
     * Represents show Confirmation box flag
     */
    public showConfirmation: boolean;
    public isTelogDataFile: boolean;
    public isTelogDataDialog: boolean;

    public confirmDialogTitle: string;
    public confirmDialogMessage: string;
    public telementryImportSuccessMsg: string;
    public telementryImportErrMsg: string;
    public confirmDialogOkBtn: string;
    public confirmDialogCancleBtn: string;
    private uploadConfirmationSuccess: string;

    private dateFormat: string;
    public isFileUploaded: boolean;
    constructor(
        private dialogRef: MatDialogRef<TelemetryImportComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: TelemetryImportDialogData,
        private changeDetector: ChangeDetectorRef,
        private telemetryService: TelemetryService,
        private telogService: TelogService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private dateutilService: DateutilService
    ) {
        translate.get('VAULT.VAULT_TELEMETRY.IMPORT.CONFIRM_DIALOG_TITLE').subscribe((res: string) => {
            this.confirmDialogTitle = res;
        });
        translate.get('VAULT.VAULT_TELEMETRY.IMPORT.CONFIRM_DIALOG_MSG').subscribe((res: string) => {
            this.confirmDialogMessage = res;
        });
        translate.get('VAULT.VAULT_TELEMETRY.IMPORT.TELEMENTRY_MSG_SUCCESS').subscribe((res: string) => {
            this.telementryImportSuccessMsg = res;
        });
        translate.get('VAULT.VAULT_TELEMETRY.IMPORT.TELEMENTRY_MSG_ERR').subscribe((res: string) => {
            this.telementryImportErrMsg = res;
        });
        translate.get('COMMON.CONFIRM_BTN').subscribe((res: string) => {
            this.confirmDialogOkBtn = res;
        });
        translate.get('COMMON.CLOSE').subscribe((res: string) => {
            this.confirmDialogCancleBtn = res;
        });

        translate.get('VAULT.UPLOAD_CONFIRMATION_SUCCESS').subscribe((res: string) => {
            this.uploadConfirmationSuccess = res;
        });

    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnInit(): void {
        this.dateFormat = `${String(
            this.dateutilService.getFormat(),
        ).toUpperCase()} ${this.dateutilService.getTimeFormat()}`;
        if (this.dialogData.importFileType === TELEMETRY) {
            this.isTelemetryFile = true;
            this.isFileUploaded = this.dialogData.isFileUploaded;
            this.telemetryInputDataList = this.dialogData.files;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
            this.validateImportFiles();
        } else if (this.dialogData.importFileType === TELOG) {
            this.isTelogFile = true;
            this.telemetryInputData = this.dialogData.files[0];
            this.isTelogDataFile =
                this.telemetryInputData.fileName.toLowerCase().includes(ACTIVE) &&
                this.telemetryInputData.fileName.toLowerCase().includes(EXCEL_FILE_EXTENSION);
        }
    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    selectedTimespanChange(dateRange: DateRangeForFile) {
        if (!this.date_range_dict) {
            this.date_range_dict = {};
        }
        this.date_range_dict[dateRange.filename] = dateRange;
        this.changeDetector.markForCheck(); 
    }

    public validateImportFiles() {
        if (this.telemetryInputDataList && this.telemetryInputDataList.length > 0) {
            this.isLoading = true;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
            const filePathList = [];
            this.telemetryInputDataList.forEach((file, index) => {
                filePathList.push(this.fileUrl(file.id));
            });
            const validateImportFilesSubscribe = this.telemetryService.validateTelemetryFiles(filePathList).subscribe(
                (result) => {
                    if (result && result.length > 0) {
                        let hasInvalidDate = false;
                        result.forEach((file, index) => {
                            const isSuccessful = file.validation.toLowerCase() === 'successful';

                            // TODO: fix type inconsistency after API adjustments are made #42611
                            const startDate = file.startDate ? file.startDate : file.start ? file.start : null;
                            const endDate = file.endDate ? file.endDate : file.end ? file.end : null;
    
                            if (!startDate || !endDate) {
                                hasInvalidDate = true;
                                const data: VautImportFileSummary = {
                                    id: '/' + (file.filePath || ''),
                                    fileName: this.fileName(file.filePath || ''),
                                    customerFullName: file.customerFullName || '',
                                    fileType: file.fileType,
                                    startDate: null,
                                    endDate: null,
                                    locationName: file.locationName || '',
                                    entities: file.entities ? file.entities.join() : '',
                                    isValidationSuccessful: isSuccessful,
                                    isChecked: isSuccessful,
                                    validation: file.validation.toLowerCase(),
                                };
                                this.telemetryValidatedFiles.push(data);
                            }
                            else{                        
                                const data: VautImportFileSummary = {
                                    id: '/' + (file.filePath || ''),
                                    fileName: this.fileName(file.filePath || ''),
                                    customerFullName: file.customerFullName || '',
                                    fileType: file.fileType,
                                    startDate: startDate,
                                    endDate: endDate,
                                    locationName: file.locationName || '',
                                    entities: file.entities ? file.entities.join() : '',
                                    isValidationSuccessful: isSuccessful,
                                    isChecked: isSuccessful,
                                    validation: file.validation.toLowerCase(),
                                };
                                this.telemetryValidatedFiles.push(data);

                            }
                        });
    
                        if (hasInvalidDate) {
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.changeDetector);
                            return;
                        }

                        this.checkIsImportEnable();
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.changeDetector);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                },
            );
            this.subscriptions.push(validateImportFilesSubscribe);
        }
    }
       
    public importFileSelectChange(id) {
        const foundItem = this.telemetryValidatedFiles.find((s: ValidateTelemetryFiles) => s.id === id);
        // set checked
        foundItem.isChecked = !foundItem.isChecked;
        this.checkIsImportEnable();
    }

    public checkIsImportEnable() {
        this.isImportEnable = this.telemetryValidatedFiles.filter((selectable) => selectable.isChecked).length > 0;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public overwriteConfirmBeforeImport() {
        if (!this.telemetryInputDataList || this.telemetryInputDataList.length === 0) {
            return;
        }

        const okText = this.translate.instant('COMMON.OK');
        const warnText = this.translate.instant('VAULT.VAULT_TELEMETRY.IMPORT.IMPORT_OVERWRITE');
        const cancelText = this.translate.instant('COMMON.CANCEL')
        this.confirmDialogTitle

        this.dialog
        .open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
                title: this.confirmDialogTitle,
                message: warnText,
                okText,
                messageClass: 'danger',
                cancelText,
            },
        }).afterClosed().subscribe((result) => {
            if (result && result.whichButtonWasPressed === 'ok') {
                this.ImportAllTelemetrySelected();
            }
        });
    }

    public ImportAllTelemetrySelected() {
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);

        const selectedFiles = this.telemetryValidatedFiles.filter((selectable) => selectable.isChecked);
        const telemetryFiles = selectedFiles.filter(v => v.fileType === 0);
        const confirmationFiles = selectedFiles.filter(v => v.fileType === 1);
            telemetryFiles.forEach(file => {
                if (!this.date_range_dict[file.fileName]) {
                    this.date_range_dict[file.fileName] = {
                        filename: file.fileName,
                        startDate: this.getFormattedDate(file.startDate),
                        endDate: this.getFormattedDate(file.endDate)
                    };
                }
            });

        const recentExportSettingsSubscribe = combineLatest([this.generateImportRequestByFileType(telemetryFiles, 0, this.date_range_dict), this.generateImportRequestByFileType(confirmationFiles, 1,this.date_range_dict)]).pipe(
            map(([telemRes, confirRes]) => ([...telemRes, ...confirRes]))
        ).subscribe(
            (result) => {
                if (result && result.length > 0) {
                    let message = '';
                    let confirmationSuccessMessage = '';
                    let confirmationErrorMessage = '';

                    result.forEach((eachResponse, index) => {
                        const space = eachResponse.isError ? ' ' : '. ';
                        const isConfirmation = eachResponse.fileType === 1 && eachResponse.locationName;

                        if(isConfirmation) {
                            if(eachResponse.isError) {
                                confirmationErrorMessage += eachResponse.message + '. ';
                            } else {
                                if(confirmationSuccessMessage !== '') {
                                    confirmationSuccessMessage += ', ';
                                }
                                confirmationSuccessMessage += eachResponse.locationName;
                            }
                        } else {
                            message = message + (eachResponse.message || '') + space;
                        }
                    });
                    this.isLoading = false;

                    let finalMessage = '';
                    if(message !== '') finalMessage += message;
                    if(confirmationSuccessMessage !== '') finalMessage += this.uploadConfirmationSuccess + ' ' + confirmationSuccessMessage;
                    if(confirmationErrorMessage !== '') finalMessage += confirmationErrorMessage;

                    this.dialogRef.close(<TelemetryImportResult>{
                        message: finalMessage,
                    });
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }
            },
            (error: HttpErrorResponse) => {
                if (error && error.error) {
                    this.snackbar.open(
                        error.error,
                        this.translate.instant('COMMON.CLOSE'),
                        {
                        panelClass: 'custom-error-snack-bar',
                    });
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );
        this.subscriptions.push(recentExportSettingsSubscribe);
    }

    private generateImportRequestByFileType(files: ValidateTelemetryFiles[], type: 0 | 1, fileDateRange: { [key: string]: DateRangeForFile }) {
        if (!files.length) {
            return of([]);
        }
        const filePathList = [];
        files.forEach((file, index) => {
            filePathList.push(this.fileUrl(file.id));
        });
    
        const fileDateRangeArray: DateRangeFileRequest[] = Object.keys(fileDateRange).map(key => ({
            filename: fileDateRange[key].filename,
            startDate: moment(fileDateRange[key].startDate).format(genericDateFormat),
            endDate: moment(fileDateRange[key].endDate).format(genericDateFormat)
        }));
    
    
        if (type === 0) {
            return this.telemetryService.importTelemetryFilesV3(fileDateRangeArray);
        }

        return this.telemetryService.importConfirmationFiles(filePathList);
    }  

    public fileName(id) {
        if (id.indexOf('/') !== -1) {
            id = id.substring(id.lastIndexOf('/') + 1);
        }
        return id;
    }
    public fileUrl(id) {
        if (id.charAt(0) === '/') {
            id = id.substr(1, id.length);
        }
        return id;
    }

    public importTelogFile(): void {
        this.isTelogDataDialog = true;
        this.isTelogDataFile = false;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public importTelogDialog(): void {
        // Represents the Confirmation Action of Import
        this.isLoading = true;

        const telogImportSubscription = this.telogService
            .importTelogFile(this.telemetryInputData.id)
            .pipe(
                catchError((err: ErrorEvent, caught: Observable<boolean>) => {
                    this.isLoading = false;

                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                    // exit immediately
                    return of(null);
                }),
            )
            .subscribe((isTelemetryImported: boolean) => {
                this.isLoading = false;

                // set message based on result
                const message = isTelemetryImported
                    ? `"${this.telemetryInputData.id}" "${this.telementryImportSuccessMsg}"`
                    : `${this.telementryImportErrMsg} ${this.telemetryInputData.id}!`;

                // close the dialog on a successfull import
                return this.dialogRef.close(<TelemetryImportResult>{
                    message: message,
                    fileId: this.telemetryInputData.fileName,
                });
            });

        this.subscriptions.push(telogImportSubscription);
    }

    getFormattedDate(dateStr: string): Date | null {
        return dateStr ? moment(dateStr, this.dateFormat).toDate() : null;
    }
}
