<div class="paginator-container">
    <button class="paginator-button edge-button" (click)="onPageChange(1)" [disabled]="isFirstPage()">
        <mat-icon>first_page</mat-icon>
    </button>

    <button class="paginator-button" (click)="onPageChange(currentPage - 1)" [disabled]="isFirstPage()">
        Back
    </button>

    <ng-container *ngFor="let page of visiblePages">
        <button class="paginator-button" [class.active]="page === currentPage" (click)="onPageChange(page)">
            {{ page }}
        </button>
    </ng-container>

    <button class="paginator-button" (click)="onPageChange(currentPage + 1)" [disabled]="isLastPage()">
        Next
    </button>

    <button class="paginator-button edge-button" (click)="onPageChange(totalPages)" [disabled]="isLastPage()">
        <mat-icon>last_page</mat-icon>
    </button>
</div>