import { Pipe, PipeTransform } from '@angular/core';
import { UptimeReportItem } from 'app/shared/models/uptime';

@Pipe({
    name: 'entityDayValue',
})
export class EntityDayValuePipe implements PipeTransform {
    transform(column: { id: string; value: string }, data: UptimeReportItem, entityIndex: number, entitiesNumber: number) {
        const dayIndex = data.uptimeDays.findIndex(
            (v) => v.day === column.id,
        );
        if (entityIndex >= entitiesNumber) {
            entityIndex = entityIndex % entitiesNumber;
        }
        const value = data.uptimeDays[dayIndex].uptimeValues[entityIndex];

        return value && value.uptime !== undefined ? Math.round(Number(value.uptime)) : '—';
    }
}
