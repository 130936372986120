import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'bpMode',
})
@Injectable()
export class BPModeFormat implements PipeTransform {
    constructor(private translate: TranslateService) {}

    public transform(value: 0 | 1 | -1): string {
        if (Number(value) === 0) {
            return this.translate.instant('BLOCKAGE_PREDICT.UNFLAGGED');
        } else if (Number(value) === 1) {
            return this.translate.instant('BLOCKAGE_PREDICT.BLOCKAGE');
        } else if (Number(value) === -1) {
            return this.translate.instant('BLOCKAGE_PREDICT.NO_BLOCKAGE');
        }

        return '';
    }
}
