import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BP_REPORT_COLORS } from 'app/shared/models/blockage-prediction';

export enum OverrideFlagOptions {
	Unflagged = 'unflagged',
	Blockage = 'blockage',
	NoBlockage = 'no-blockage'
}

@Component({
	selector: 'app-bp-review-context-menu',
	template: `
    <div 
      *ngIf="isVisible"
      class="context-menu"
      [style.left.px]="position.x"
      [style.top.px]="position.y">
      <div 
        class="menu-item"
        [style.backgroundColor]="colors.SCORE_LOW"
        (click)="onOptionSelect(options.Unflagged)">
        {{ 'BLOCKAGE_PREDICT.UNFLAGGED' | translate }}
      </div>
      <div 
        class="menu-item"
        [style.backgroundColor]="colors.FLAG_POSITIVE"
        (click)="onOptionSelect(options.Blockage)">
        {{ 'BLOCKAGE_PREDICT.BLOCKAGE' | translate }}
      </div>
      <div 
        class="menu-item"
        [style.backgroundColor]="colors.FLAG_NEGATIVE"
        (click)="onOptionSelect(options.NoBlockage)">
        {{ 'BLOCKAGE_PREDICT.NO_BLOCKAGE' | translate }}
      </div>
    </div>
  `,
	styles: [`
    .context-menu {
      position: fixed;
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      z-index: 1000;

      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
    }
    .menu-item {
      padding: 8px 16px;
      cursor: pointer;
      color: white;
      transition: opacity 0.2s;

      border-radius: 10px;
    }
    .menu-item:hover {
      opacity: 0.8;
    }
    .menu-item:not(:last-child) {
      border-bottom: 1px solid rgba(255,255,255,0.2);
    }
  `]
})
export class BpReviewContextMenuComponent {
	@Input() isVisible = false;
	@Input() position = { x: 0, y: 0 };
	@Output() optionSelected = new EventEmitter<OverrideFlagOptions>();
	@Output() closeMenu = new EventEmitter<void>();
  
	options = OverrideFlagOptions;
  colors = BP_REPORT_COLORS;


	onOptionSelect(option: OverrideFlagOptions) {
		this.optionSelected.emit(option);
		this.closeMenu.emit();
	}
}