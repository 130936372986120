<div id="usersPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'ADMIN.ADMIN_USERS.USERS_TEXT' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin"></div>
            <div class="auto-margin pad-r-15 cus-filter-hint" *ngIf="isMatTableVisible">
                <mat-icon matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}" (click)="showUsersFilters()">
                    filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <mat-card-content>
            <div class="filterSection" *ngIf="showUserSearch && isMatTableVisible">
                <div class="eachSection" *ngIf="loggedInUserRole !== USER_ROLES.CUSTOMER_USER_MANAGER">
                    <mat-form-field class="full-width cus-filter-feature-hint">
                        <mat-select
                            placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_USERS.ROLE'  | translate }}"
                            multiple
                            (selectionChange)="onRoleSelection($event.value)"
                        >
                            <mat-option *ngFor="let each of roles" [value]="each.id">
                                {{ each.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="eachSection">
                    <mat-form-field class="full-width cus-filter-feature-hint">
                        <mat-select
                            placeholder="{{ 'CUSTOMER_EDITOR.ASSIGNED_FEATURES' | translate }}"
                            multiple
                            (selectionChange)="getUserByFeatures($event.value)"
                        >
                            <mat-option *ngFor="let each of loggedInUserFeatures" [value]="each.id">
                                {{ each.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="eachSection">
                    <mat-form-field class="full-width cus-filter-inactive-hint">
                        <mat-label>{{ 'CUSTOMER_EDITOR.ACTIVE_INACTIVE' | translate }}</mat-label>
                        <mat-select (selectionChange)="onSelection($event.value)">
                            <mat-option *ngFor="let each of activeInactiveList" [value]="each.text">
                                {{ each.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="search-section">
                <mat-form-field class="full-width search-users-hint">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                        matInput
                        type="text"
                        placeholder="{{ 'ADMIN.ADMIN_USERS.SEARCH_PLACEHOLDER' | translate }}"
                        [formControl]="searchString"
                    />
                </mat-form-field>
            </div>
            <app-loader [isLoading]="isLoading"></app-loader>
            <div>
                <div id="usersPageTable">
                    <mat-table #table [dataSource]="userDataSource" matSort>
                        <!-- USERS Name Column -->
                        <ng-container matColumnDef="userName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'ADMIN.ADMIN_USERS.USER_NAME' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let user" [ngClass]="{ selected: user.highLight }">
                                {{ user.userName }}
                            </mat-cell>
                        </ng-container>
                        <!-- USERS EMAIL Column -->
                        <ng-container matColumnDef="userEmail">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'ADMIN.ADMIN_USERS.EMAIL_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let user" [ngClass]="{ selected: user.highLight }">
                                {{ user.userEmail }}
                            </mat-cell>
                        </ng-container>

                        <!-- USERS NAME Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'COMMON.NAME_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let user" [ngClass]="{ selected: user.highLight }">
                                {{ user.name }}
                            </mat-cell>
                        </ng-container>

                        <!-- USERS ROLE Column -->
                        <ng-container matColumnDef="role">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="fixCellWidth110">{{
                                'ADMIN.ROLE_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let user" [ngClass]="{ selected: user.highLight }">
                                <span>{{ user.role }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="customers">
                            <mat-header-cell *matHeaderCellDef class="text-right fixCellWidth150">{{
                                'ADMIN.ADMIN_USERS.ASSOCIATED_CUSTOMERS' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let user"
                                class="text-right"
                                [ngClass]="{ selected: user.highLight }"
                                >{{ user.customers }}</mat-cell
                            >
                        </ng-container>

                        <!-- USERS FEATURES Column -->
                        <ng-container matColumnDef="features">
                            <mat-header-cell *matHeaderCellDef>{{
                                'ADMIN.ADMIN_USERS.ASSIGNED_FEATURES' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let user" [ngClass]="{ selected: user.highLight }">
                                <div
                                    [matTooltip]="
                                        validateLongStringLength(user.features, 38) ? provideSpace(user.features) : ''
                                    "
                                >
                                    {{ provideSpace(user.features) }}
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!-- USERS Active/Inactive Column -->
                        <ng-container matColumnDef="active">
                            <mat-header-cell *matHeaderCellDef
                                >{{ 'ADMIN.ADMIN_USERS.ACTIVE_INACTIVE' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let user; let i = index"
                                class="custom-disabled-View"
                                [ngClass]="{ selected: user.highLight }"
                            >
                                <mat-slide-toggle
                                    [ngClass]="{ 'user-status-hint': i === 0 }"
                                    [color]="'accent'"
                                    [checked]="user.isActive"
                                    [disabled]="!addNewUserVisible || user.disable"
                                    (change)="activateUser(user)"
                                >
                                </mat-slide-toggle>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="editUser">
                            <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                            <mat-cell
                                *matCellDef="let user; let i = index"
                                class="text-right"
                                [ngClass]="{ selected: user.highLight }"
                            >
                                <mat-icon
                                    [ngClass]="{ 'user-edit-hint': i === 0 }"
                                    matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                                    matTooltipPosition="left"
                                    (click)="customerAssociation(user)"
                                    >mode_edit</mat-icon
                                >
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="userFilterColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: userFilterColumns"></mat-row>
                    </mat-table>
                </div>
                <div></div>
            </div>
        </mat-card-content>
        <mat-card-actions [hidden]="isLoading">
            <div *ngIf="true">
                <button
                    class="user-add-hint"
                    [disabled]="!addNewUserVisible"
                    (click)="setupAdminDetailRoute('')"
                    mat-fab
                    color="accent"
                    id="addNewUser"
                    matTooltip="{{ 'COMMON.ADD_NEW_USER' | translate }}"
                    matTooltipPosition="above"
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                <mat-paginator
                    #batteryWidgetPaginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="25"
                    [pageSizeOptions]="[10, 25, 100]"
                    (page)="changePage($event)"
                >
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<router-outlet></router-outlet>
